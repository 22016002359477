import fetch from '../utils/fetch';
import {
  READ_MESSAGE,
  GET_MESSAGES,
  ENABLE_FETCHING_OVERLAY,
  DISABLE_FETCHING_OVERLAY, UPDATE_OPEN_MODAL_BEFORE_LEAVE, UPDATE_PAGE_LOADING,
} from '../constants/actionTypes';

export function openConfirmLeaveModal(url = '') {
  return {
    type: 'app.ui.openConfirmLeaveModal',
    payload: { url },
  };
}

export function closeConfirmLeaveModal() {
  return {
    type: 'app.ui.closeConfirmLeaveModal',
  };
}

export function getMessages() {
  return dispatch => fetch('/messages_v2', { method: 'GET', noDefaultNotification: true })
    .then(payload => dispatch({ type: GET_MESSAGES, payload: payload.result }));
}

export function readMessage({ id, data }) {
  return dispatch => fetch(`/messages_v2/read/${id}`, { method: 'POST', body: data })
    .then(payload => dispatch({ type: READ_MESSAGE, payload: payload.result, meta: { id, data } }));
}

export function enableFetchingOverlay({ message, messageShowTime, messageDefaultShown }) {
  return {
    type: ENABLE_FETCHING_OVERLAY,
    payload: { message, messageShowTime, messageDefaultShown },
  };
}

export function disableFetchingOverlay() {
  return {
    type: DISABLE_FETCHING_OVERLAY,
  };
}

export function updateOpenModalBeforeLeave(payload) {
  return {
    type: UPDATE_OPEN_MODAL_BEFORE_LEAVE,
    payload,
  };
}

export function updatePageLoading({ pageLoading }) {
  return {
    type: UPDATE_PAGE_LOADING,
    payload: { pageLoading },
  };
}

// export function startTransaction(txId) {
//   return {
//     type: START_TRANSACTION,
//     payload: txId,
//   };
// }

// export function cancelTransaction() {
//   return (dispatch, getState) => {
//     const state = getState();
//     return fetch('/merchant/cancel', { method: 'POST', body: { tx: state.ui.startedTxId } })
//       .then(payload => dispatch({ type: CANCEL_TRANSACTION, payload: payload.result }));
//   };
// }

// export function removeStartedTransaction() {
//   return dispatch => dispatch({ type: CLEAR_STARTED_TRANSACTION });
// }
