import { connect } from 'react-redux';
import { getCats } from '../../../actions/catalog';
import { updatePageLoading } from '../../../actions/ui';
import TransactionsHistory from './TransactionsHistory';
import { loadTransaction } from '../../../actions/transaction';

const mapStateToProps = state => ({
  user: state.user,
  cats: state.catalog.cats,
  pageLoading: state.ui.pageLoading,
});

const mapDispatchToProps = {
  getCats,
  loadTransaction,
  updatePageLoading,
};

const TransactionsHistoryContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TransactionsHistory);

export default TransactionsHistoryContainer;
