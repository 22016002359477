import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import NewMaskInput from '../../../../components/ui/NewMaskInput';
import { currencySymbolByISOName } from '../../../../utils/currencySymbol';
import Condition from './Condition';
import FinalFormPropsWrapper from './FinalFormPropsWrapper';

export default class AmountFinalFieldWrap extends React.Component {
  shouldComponentUpdate(nextProps) {
    return nextProps.currencyValue !== this.props.currencyValue
      || nextProps.value !== this.props.value
      || nextProps.disabled !== this.props.disabled
      || nextProps.visible !== this.props.visible;
  }

  formatField = (value) => {
    const isUndefined = typeof value === 'undefined';
    const isEmptyString = typeof value === 'string' && value.trim() === '';

    const dividedValueAsString = `${value / 100}`;

    // const { value } = this.props;
    // const lastTwoDigitsInFormValue = `${value}`.substr(-2);
    // const lastTwoDigitsInValue = `${value}`.substr(-2);

    // if (lastTwoDigitsInFormValue !== lastTwoDigitsInValue && lastTwoDigitsInValue === '00') {
    //   return `${dividedValueAsString},`;
    // }

    return isUndefined || isEmptyString ? '' : dividedValueAsString;
  };

  parseField = (inputValue) => {
    if (inputValue === '') return undefined;
    const valueWithoutSpaces = inputValue.replace(/\s/g, '');
    const parsedValue = parseFloat(valueWithoutSpaces, 10);
    // const separatedStuff = `${val}`.split('.')[1] || `${val}`.split(',')[1];
    // const parsedStateValue = parseFloat(this.state.value, 10);

    // if (separatedStuff && separatedStuff.length > 2) {
    //   return (Number.isNaN(parsedStateValue) || parsedStateValue < 0) ? value : +(parsedStateValue * 100).toFixed(0);
    // }

    if (inputValue === '0') {
      return 0;
    }

    const { value } = this.props;

    return (Number.isNaN(parsedValue) || parsedValue < 0) ? value : +(parsedValue * 100).toFixed(0);
  };

  render() {
    const {
      item, visible, styleFromParent, validation, disabled, value, currencyValue,
    } = this.props;

    const currencySymbol = currencyValue ? ` ${currencySymbolByISOName[currencyValue]}` : '';

    return (
      <Condition visible={visible}>
        <Field
          type="string"
          name={item.field_name}
          placeholder={item.placeholder}
          validate={v => validation(v, item)}
          format={this.formatField}
          parse={this.parseField}
        >
          {FinalFormPropsWrapper(NewMaskInput, {
            value,
            item,
            disabled,
            styleFromParent,
            type: 'text',
            placeholder: `0,00${currencySymbol}`,
            maskProps: {
              mask: [
                {
                  mask: '',
                },
                {
                  mask: `num${currencySymbol}`,
                  lazy: false,
                  blocks: {
                    num: {
                      mask: Number,
                      max: 9999999999,
                      scale: 2,
                      radix: ',',
                      mapToRadix: ['.'],
                      thousandsSeparator: ' ',
                    },
                  },
                },
              ],
            },
          })}
        </Field>
      </Condition>
    );
  }
}

AmountFinalFieldWrap.propTypes = {
  value: PropTypes.number,
  currencyValue: PropTypes.string,
  item: PropTypes.object.isRequired,
  styleFromParent: PropTypes.object,
  visible: PropTypes.bool,
  disabled: PropTypes.bool,
  validation: PropTypes.func,
};

AmountFinalFieldWrap.defaultProps = {
  value: undefined,
  currencyValue: '',
  styleFromParent: {},
  visible: false,
  disabled: false,
  validation: () => {},
};
