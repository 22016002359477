const initialState = {
  complexSalesGuid: '0',
};

export default function oneC(state = initialState, action) {
  switch (action.type) {
    case 'app.oneC.Query.storeComplexSalesGuid':
      const { complexSalesGuid } = action.payload;
      return {
        ...state,
        complexSalesGuid,
      };
    default:
      return { ...state };
  }
}
