export const arrayToObject = (arr, keyToAdd, skipUndefined = false) => arr.reduce((obj, item) => {
  if (item) {
    if (keyToAdd instanceof Array) {
      obj[item.result.id] = {};
      keyToAdd.forEach((key) => {
        if (!skipUndefined || typeof item.result[key] !== 'undefined') {
          obj[item.result.id][key] = item.result[key];
        }
      });
    } else {
      obj[item.result.id] = item.result[keyToAdd];
    }
  }
  return obj;
}, {});
