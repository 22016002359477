/* global fetch */
import React from 'react';
import 'whatwg-fetch';
import notification from 'antd/lib/notification';
import { disableFetchingOverlay, enableFetchingOverlay } from '../actions/ui';
import store from '../store/store';

function convertToFourDigitView(int) {
  const stringInt = `${int}`;
  const lngth = stringInt.length;
  const reversedArray = stringInt.split('').reverse();
  reversedArray.length = 4;
  reversedArray.fill(0, lngth);
  return reversedArray.reverse().join('');
}

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  const error = new Error(response.statusText || 'Internal server error');
  error.response = response;
  throw error;
}

function parseJSON(response) {
  return response.json().catch((err) => { console.error(err); return Promise.reject(err); });
}

export default (path, options = {}) => new Promise((resolve, reject) => {
  const apiHost = typeof options.backendUrl !== 'undefined' ? options.backendUrl : process.env.BACKEND_URL;
  const apiPrefix = typeof options.prefix !== 'undefined' ? options.prefix : '/api';
  const url = apiHost + apiPrefix + path;

  if (options.withOverlay) {
    store.dispatch(enableFetchingOverlay({
      message: options.overlayMessage,
      messageShowTime: options.overlayMessageShowTime,
      messageDefaultShown: options.overlayMessageDefaultShown,
    }));
  }

  fetch(url, {
    method: options.method || 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-auth-token':
        window.localStorage.getItem('access_token') || '',
    },
    body: options.body ? JSON.stringify(options.body) : undefined,
  })
    .then((response) => {
      if (typeof options.notJson === 'undefined' && isJsonContentType(response)) {
        return parseJSON(checkStatus(response));
      }
      return checkStatus(response);
    })
    .then((data) => {
      if (data.success === false) {
        if (data.code) {
          if (!options.noDefaultNotification) {
            // const errorCode = convertToFourDigitView(data.code);
            const message = 'Неизвестная ошибка!';
            notification.open({
              duration: 5,
              description: React.createElement('div', { dangerouslySetInnerHTML: { __html: `${message.capitalize()}` } }),
            });
          }
        }
        reject({
          message: data.error,
          code: data.code || -1,
          result: data.result || null,
        });
      }
      // eslint-disable-next-line
      if (data.result && data.result.REQUEST_SIGN && data.result.REQUEST_SIGN != '0') {
        if (!options.noDefaultNotification) {
          // const errorCode = convertToFourDigitView(data.result.REQUEST_SIGN);
          const message = 'Неизвестная ошибка!';
          notification.open({
            duration: 5,
            description: React.createElement('div', { dangerouslySetInnerHTML: { __html: `${message.capitalize()}` } }),
          });
        }
      }
      if (options.withOverlay) {
        store.dispatch(disableFetchingOverlay());
      }
      if (options.returnError === true) {
        resolve([null, data]);
      } else {
        resolve(data);
      }
    })
    .catch((e) => {
      if (options.withOverlay) {
        store.dispatch(disableFetchingOverlay());
      }
      if (options.returnError === true) {
        resolve([e, null]);
      } else {
        if (!options.noDefaultNotification) {
          if (typeof e.response === 'undefined') {
            e.noConnection = true;
            notification.open({
              duration: 5,
              message: React.createElement('div', null, 'Неизвестная ошибка'),
              description: React.createElement('div', { dangerouslySetInnerHTML: { __html: 'Возможно отсутствует интернет соединение' } }),
            });
          } else if (e.response.status === 401) {
            notification.open({
              duration: 5,
              message: React.createElement('div', null, 'Ошибка авторизации'),
              description: React.createElement('div', { dangerouslySetInnerHTML: { __html: 'Токен недействителен<br />или отсутствует' } }),
            });
          } else {
            notification.open({
              duration: 5,
              description: React.createElement('div', { dangerouslySetInnerHTML: { __html: `${e.message}:<br/>${JSON.stringify(e)}` } }),
            });
          }
        }
        if (typeof e.response === 'undefined') {
          e.response = {
            status: -1,
          };
          e.noConnection = true;
        }

        if (typeof e.message === 'undefined') {
          e.message = 'При выполнении запроса возникла ошибка';
        }

        reject(e);
      }
    });
});

export function Post(url, params) {
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': window.localStorage.getItem('access_token') || '',
      },
      body: JSON.stringify(params),
    })
      .then(checkStatus)
      .then(parseJSON)
      .then((data) => {
        resolve(data);
      })
      .catch((e) => {
        notification.open({
          duration: 5,
          description: React.createElement('div', { dangerouslySetInnerHTML: { __html: `${e.message}:<br/>${JSON.stringify(e)}` } }),
        });
        reject(e);
      });
  });
}

function isJsonContentType(response) {
  try {
    const header = response.headers.get('content-type');
    return header && header.includes('application/json');
  } catch (e) {
    return false;
  }
}
