import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import notification from 'antd/lib/notification';
import Form from '../../../components/ui/Form';
import ValidateInput from '../../../components/ui/ValidateInput';
import Button from '../../../components/ui/Button';
import { backBtnLabel } from '../../../constants/backBtnLabel';
import { formatMoneyWithCurrency } from '../../../utils/format';

export default class SubmitEasySale extends PureComponent {
  static propTypes = {
    stepIndex: PropTypes.number.isRequired,
    full_amount: PropTypes.number.isRequired,
    formError: PropTypes.bool,
    updateCurrentStep: PropTypes.func.isRequired,
    merchantPay: PropTypes.func.isRequired,
  };

  static defaultProps = {
    formError: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      currentAmountFromBuyer: '',
      loading: false,
    };
  }

  onBackClick = () => {
    this.props.updateCurrentStep(2);
  };

  onAcceptClick = () => {
    if (this.state.loading) return;
    const change = (+this.state.currentAmountFromBuyer - ((+this.props.full_amount) / 100));

    if (change < 0) {
      // TODO: refactor to get values from tx data
      notification.open({
        duration: 5,
        description: React.createElement('div', { dangerouslySetInnerHTML: { __html: 'Полученная сумма меньше суммы к оплате' } }),
      });
    } else {
      this.setState({ loading: true });
      // payment
      this.props.merchantPay()
        .catch(() => {
          this.setState({ loading: false });
        });
    }
  };

  onAmountFromBuyerChange = currentAmountFromBuyer => this.setState({ currentAmountFromBuyer });

  render() {
    const { stepIndex, full_amount, formError } = this.props;
    const { currentAmountFromBuyer, loading } = this.state;

    // TODO: refactor to get values from tx data + use rate
    const change = (+currentAmountFromBuyer - ((+full_amount) / 100));

    return (
      <div className={classnames('payment-submit', { 'hidden-by-error': formError })}>
        <Form onSubmit={this.onAcceptClick} focusFirstInput>
          <h3 className="page-title h3">{stepIndex}. Подтверждение платежа</h3>
          <div className="ui-alert ui-alert-blue">
            <div className="ui-alert-content">
              <span dangerouslySetInnerHTML={{ __html: full_amount ? 'Запросите деньги для оплаты' : 'Для оформления данной услуги внесение денежных средств не&nbsp;требуется' }} />
            </div>
          </div>
          {!!full_amount && (
            <React.Fragment>
              <div className="form-element" key="amount-from-buyer-input-wrap">
                <ValidateInput
                  type="number"
                  label="Введите полученную сумму"
                  value={currentAmountFromBuyer}
                  placeholder=""
                  validator={this.notNullValidator}
                  onChange={this.onAmountFromBuyerChange}
                  message="Не введена сумма"
                />
              </div>
              <div className="info-element" key="info-about-money">
                <span className="info-element-label">Сдача</span>
                <span className="info-element-value">{change >= 0 ? formatMoneyWithCurrency({ amount: change, withDivision: false }) : '-'}</span>
              </div>
            </React.Fragment>
          )}
          <div className={classnames('form-footer-controls')}>
            <Button appearance={Button.Appearance.link} label={backBtnLabel} onClick={this.onBackClick} />
            <Button loading={loading} label="Подтвердить" type="submit" color={Button.Color.blue} />
          </div>
        </Form>
      </div>
    );
  }

}
