import React, { memo, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import classnames from 'classnames';
import moment from 'moment';
import ClipboardJS from 'clipboard';
import { DatePicker, Pagination, Tooltip } from 'antd';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Sidebar from '../../../components/common/Sidebar';
import Button from '../../../components/ui/Button';
import ProductLogo from '../../../components/ui/ProductLogo';
import withViewport from '../../../components/viewport/withViewport';
import { defaultProviderConfigByCat, getProviderConfig } from '../../../utils/getProviderConfig';
import TxDataField from '../../../components/common/TxDataField';
import Select from '../../../components/ui/Select';
import StatusIcon from '../../../components/common/StatusIcon';
import { formatMoneyWithCurrency } from '../../../utils/format';
import { ViewportSize } from '../../../components/viewport/Viewport';
import { arrayToObject } from '../../../utils/arrayToObject';

import statusLabels from '../../../constants/statusLabels';
import TxDotsIcon from '../assets/svg/tx-dots-extra.svg';
import TxControlDropdown from './TxControlDropdown';
import TxControlDropdownWrap from './TxControlDropdownWrap';
import Modal from '../../../components/ui/Modal';

import makeCancelable from '../../../utils/makeCancelable';
import fetch from '../../../utils/fetch';

import Loupe from '../../../assets/svg/magnifier.svg';
import ArrowDown from '../../../assets/img/icons/arrow-down.svg';
import DoubleArrowRight from '../../../assets/img/icons/double-arrow-right.svg';
import Close from '../../../assets/svg/close-black.svg';
import Copy from '../../../assets/svg/copy.svg';

const colNameById = {
  providerConfig: 'Наименование',
  sum: 'Сумма',
  commission: 'Комиссия',
  toCashbox: 'В кассу',
  startTime: 'Дата',
  status: 'Статус',
};

const statusFilters = [
  { label: 'Все', value: 'all' },
  { label: 'Успешно', value: 'ok' },
  { label: 'В процессе', value: 'inProgress' },
  { label: 'Ошибка', value: 'failed' },
  // { label: 'Перенаправлена', value: 'redirect' }, [RET-223]
  { label: 'Аннулирована', value: 'rollback' },
];

const statusColors = {
  inProgress: '#F5A623',
  ok: '#06CF7F',
  success: '#06CF7F',
  failed: '#FF1E31',
  redirect: '#602FFF',
  rollback: '#1875F0',
  loading: '#333',
};

function disabledDate(current) {
  // Can not select days after today
  return current && current > moment().endOf('day');
}

function onClickWithStopPropagation(e) {
  e.stopPropagation();
}

let tooltipShowTimerId = 0;

const Provider = memo((props) => {
  const copyBtnRef = useRef();
  const [copyTooltipShown, setCopyTooltipShown] = useState(false);
  const [copiedTooltipShown, setCopiedTooltipShown] = useState(false);

  useEffect(
    () => {
      const clipboard = new ClipboardJS(copyBtnRef.current, {
        text: () => props.gateTx,
      });
      clipboard.on('success', () => {
        setCopiedTooltipShown(true);
      });
      return () => clipboard.destroy();
    },
    [props.gateTx, copyBtnRef.current],
  );

  useEffect(
    () => {
      if (copiedTooltipShown) {
        clearTimeout(tooltipShowTimerId);
        tooltipShowTimerId = setTimeout(() => {
          setCopyTooltipShown(false);

          setTimeout(() => {
            setCopiedTooltipShown(false);
          }, 300);
        }, 800);
      }
      return () => clearTimeout(tooltipShowTimerId);
    },
    [copiedTooltipShown],
  );

  const tooltipVisible = {};
  if (props.tooltipDisabled) {
    tooltipVisible.visible = false;
  }

  const productConfig = { id: props.id, cat: props.cat || 'others', name: props.name, logo: props.logo, multiLogo: props.multiLogo };

  return (
    <div className="tx-provider">
      <div className="tx-label-logo">
        <ProductLogo productConfig={productConfig} />
        <Tooltip title={props.categoryName} overlayClassName="ui-tooltip ui-tooltip-xs" placement="bottom" align={{ offset: [0, 4] }} {...tooltipVisible}>
          <img className="tx-provider-category-img" src={`/assets/svg/txs-history-category-icons/${props.cat}.svg`} alt={props.categoryName} />
        </Tooltip>
      </div>
      <div className="tx-label-text">
        <div className="tx-provider-name">{props.name}</div>
        <div className="tx-provider-id">
          <span className="tx-provider-id-text">{props.gateTx}</span>
          <Tooltip
            title={(
              <div className={classnames('copy-tooltip-title-wrap', { copied: copiedTooltipShown })}>
                <div className="opaque-text">Скопировано</div>
                <div className="copy-tooltip-title copy-text">Скопировать</div>
                <div className="copy-tooltip-title copied-text">Скопировано</div>
                {/*<ReactCSSTransitionGroup*/}
                {/*  transitionName="copy-tooltip-title"*/}
                {/*  transitionEnterTimeout={450}*/}
                {/*  transitionLeaveTimeout={300}*/}
                {/*  component={React.Fragment}*/}
                {/*>*/}
                {/*  {copiedTooltipShown && <div className="copy-tooltip-title">Скопировано</div>}*/}
                {/*  {!copiedTooltipShown && <div className="copy-tooltip-title">Скопировать</div>}*/}
                {/*</ReactCSSTransitionGroup>*/}
              </div>
            )}
            overlayClassName={classnames('ui-tooltip ui-tooltip-xs ui-tooltip-no-arrow', { 'ui-tooltip-green': copiedTooltipShown })}
            placement="top"
            align={{ offset: [0, 0] }}
            visible={copyTooltipShown}
          >
            <button
              ref={copyBtnRef}
              className="tx-provider-id-copy-btn"
              type="button"
              onClick={onClickWithStopPropagation}
              onMouseEnter={() => { setCopyTooltipShown(true); }}
              onMouseLeave={() => { if (!copiedTooltipShown) { setCopyTooltipShown(false); } }}
            >
              <Copy className="copy-icon" />
            </button>
          </Tooltip>
        </div>
      </div>
    </div>
  );
});

const defaultHeaderRender = row => (
  <div className={classnames('rt-header-item', { 'rt-header-sortable': row.column.sortable !== false })}>
    <span>{colNameById[row.column.id]}</span>
  </div>
);

const moneyCellRender = row => row.value;

const startTimeCellRender = row => row.value;

class TransactionsHistory extends React.Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    cats: PropTypes.object.isRequired,
    getCats: PropTypes.func.isRequired,
    pageLoading: PropTypes.bool.isRequired,
    onPageDidMount: PropTypes.func.isRequired,
    onPageWillUnmount: PropTypes.func.isRequired,
    loadTransaction: PropTypes.func.isRequired,
    updatePageLoading: PropTypes.func.isRequired,
    viewport: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      currentPage: 1,
      currentTxData: null,
      currentTxVisibleFields: {},
      providerIdToConfigMap: {},
      groupById: {},
      extrasById: {},
      transactions: [],
      showTxDetails: false,
      openedTxControlDropdownId: '',
      sidebarTxControlDropdownOpened: false,
      filterStatus: 'all',
      loading: true,
      reportGenerationLoading: false,
      reportGenerationDisabledUntilUserInfoUp: false,
      selectedDate: moment().format('x'),
      // rowsPerPage: 5,
    };

    this.fetchTxsWrap = null;
    this.sidebarDropdownControlBtn = React.createRef();
    this.handlers = {};
    this.columns = [
      {
        Header: defaultHeaderRender,
        accessor: 'providerConfig',
        headerClassName: 'name-col',
        className: 'name-col',
        minWidth: 320,
        maxWidth: 440,
        sortMethod: (a, b) => (a.name > b.name ? 1 : -1),
        Cell: cell => <Provider {...cell.value} />,
      },
      {
        Header: defaultHeaderRender,
        accessor: 'sum',
        headerClassName: 'sum-col',
        className: 'sum-col',
        minWidth: 110,
        maxWidth: 165,
        Cell: moneyCellRender,
      },
      {
        Header: defaultHeaderRender,
        accessor: 'commission',
        headerClassName: 'commission-col',
        className: 'commission-col',
        minWidth: 106,
        maxWidth: 165,
        Cell: moneyCellRender,
      },
      {
        Header: defaultHeaderRender,
        accessor: 'toCashbox',
        headerClassName: 'to-cashbox-col',
        className: 'to-cashbox-col',
        minWidth: 110,
        maxWidth: 165,
        Cell: moneyCellRender,
      },
      {
        Header: defaultHeaderRender,
        accessor: 'startTime',
        headerClassName: 'start-time-col',
        className: 'start-time-col',
        minWidth: 144,
        maxWidth: 205,
        Cell: startTimeCellRender,
      },
      {
        Header: defaultHeaderRender,
        accessor: 'status',
        sortMethod: (a, b) => (statusLabels[a] > statusLabels[b] ? 1 : -1),
        headerClassName: 'status-col',
        className: 'status-col',
        width: 90,
        resizable: false,
        Cell: this.statusCellRender,
      },
    ];
  }

  componentDidMount() {
    const startTime = moment().startOf('day').format('x');
    const finishTime = moment().endOf('day').format('x');
    this.fetchWithHandling(startTime, finishTime);
    this.props.onPageDidMount('transactions-history');
  }

  componentDidUpdate(prevProps) {
    if (prevProps.cats.root !== this.props.cats.root) {
      this.props.updatePageLoading({ pageLoading: false });
    }
    if (
      prevProps.user.info.user.reportGeneratorAvailable
      && prevProps.user.info.user.reportGeneratorAvailable !== this.props.user.info.user.reportGeneratorAvailable
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ reportGenerationDisabledUntilUserInfoUp: false });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !(nextState === this.state
      && this.props.pageLoading === nextProps.pageLoading
      && this.props.cats === nextProps.cats
      && this.props.viewport.size === nextProps.viewport.size
      && nextProps.user.info.user.reportGeneratorAvailable === this.props.user.info.user.reportGeneratorAvailable);
  }

  componentWillUnmount() {
    if (this.fetchTxsWrap) {
      this.fetchTxsWrap.cancel();
    }
    this.props.onPageWillUnmount();
    this.props.updatePageLoading({ pageLoading: true });
  }

  onPageChange = currentPage => this.setState({ currentPage });

  onStatusChange = filterStatus => this.setState({ filterStatus });

  onPeriodChange = (date) => {
    if (this.fetchTxsWrap) {
      this.fetchTxsWrap.cancel();
    }

    if (!date) {
      this.setState({ currentPage: 1, transactions: [], providerIdToConfigMap: {}, groupById: {} });
      return;
    }

    this.setState({ loading: true, selectedDate: date.format('x') });

    const startTime = date.startOf('day').format('x');
    const finishTime = date.endOf('day').format('x');

    this.fetchWithHandling(startTime, finishTime);
  };

  onPrevDateClick = () => {
    this.onPeriodChange(moment(this.state.selectedDate, 'x').subtract(1, 'day'));
  };

  onNextDateClick = () => {
    this.onPeriodChange(moment(this.state.selectedDate, 'x').add(1, 'day'));
  };

  onTxControlBtnClick = (id) => {
    if (!this.handlers[id]) {
      this.handlers[id] = (e) => {
        e.stopPropagation();
        if (this.state.openedTxControlDropdownId === id) {
          this.setState({ openedTxControlDropdownId: '', parentDomElem: null });
        } else {
          this.setState({ openedTxControlDropdownId: id, parentDomElem: e.currentTarget });
        }
      };
    }
    return this.handlers[id];
  };

  onSidebarTxControlBtnClick = () => {
    this.setState(prevState => ({ sidebarTxControlDropdownOpened: !prevState.sidebarTxControlDropdownOpened }));
  };

  onTxControlDropdownClose = () => {
    this.setState({ openedTxControlDropdownId: '', parentDomElem: null });
  };

  onSidebarTxControlDropdownClose = () => {
    this.setState({ sidebarTxControlDropdownOpened: false });
  };

  onTxDetailsClose = () => this.setState({ showTxDetails: false, currentTxData: null, currentTxVisibleFields: {} });

  onReportClick = () => {
    this.setState({ reportGenerationLoading: true, reportGenerationDisabledUntilUserInfoUp: true });

    const { selectedDate } = this.state;
    const selectedDateMoment = moment(selectedDate, 'x');
    const startTime = selectedDateMoment.startOf('day').format('x');
    const finishTime = selectedDateMoment.endOf('day').format('x');

    fetch(`/txs-report?finish_t=${finishTime}&start_t=${startTime}`)
      .then(() => {
        this.setState({ reportGenerationLoading: false });
      })
      .catch(() => {
        this.setState({ reportGenerationLoading: false });
      });
  };

  activeFieldsChecker = (fields, dst_extras) => {
    let string = '';
    fields.forEach((element) => {
      if (!Number.isNaN(parseInt(element.field_name, 10)) && typeof parseInt(element.field_name, 10) === 'number') return;
      string += ` var ${element.field_name}; `;
    });

    Object.keys(dst_extras).forEach((key) => {
      if (!Number.isNaN(parseInt(key, 10)) && typeof parseInt(key, 10) === 'number') return;
      if (typeof dst_extras[key] === 'number') {
        string += `var ${key} = ${dst_extras[key]}; `;
      } else if (typeof dst_extras[key] === 'string') {
        string += `var ${key} = ${JSON.stringify(dst_extras[key])}; `;
      } else if (typeof dst_extras[key] === 'object') {
        string += `var ${key} = ${JSON.stringify(dst_extras[key])}; `;
      } else if (typeof dst_extras[key] === 'boolean') {
        string += `var ${key} = ${dst_extras[key]}; `;
      }
    });

    string += ' return {';
    fields.forEach((item) => {
      if (item.condition && (typeof item.condition === 'boolean' || item.condition.indexOf('.') < 0)) {
        string += `${item.field_name}: !!${item.condition},`;
      } else {
        string += `${item.field_name}: ${item.condition ? ` (function() { try { return  !!${item.condition} } catch(e) { return false } })()` : 'true'},`;
      }
    });
    string += ' };';

    return new Function('', string)();
  };

  fetchTxs = (start, finish) => fetch(`/txs?finish_t=${finish}&start_t=${start}`)
    .then((response) => {
      const paymentIds = response.result.map(item => ({ cat: item.paymentId, provider: item.provider })).filter((obj, pos, arr) => arr.map(mapObj => mapObj.cat).indexOf(obj.cat) === pos);
      const arrayOfPromises = paymentIds.map(item => this.fetchCat(item));

      return new Promise((resolve) => {
        Promise.all(arrayOfPromises)
          .then((arrayOfCats) => {
            resolve({ arrayOfCats, transactions: response.result });
          });
      });
    });

  fetchWithHandling = (start, finish) => {
    const { cats, getCats } = this.props;
    this.fetchTxsWrap = makeCancelable(Promise.all([this.fetchTxs(start, finish), getCats('root')]));

    this.fetchTxsWrap
      .promise
      .then((res) => {
        const txs = res[0];
        const providerIdToConfigMap = arrayToObject(txs.arrayOfCats, ['id', 'cat', 'name', 'logo', 'multiLogo'], true);
        // const idToNameMap = arrayToObject(txs.arrayOfCats, 'name');
        const groupById = arrayToObject(txs.arrayOfCats, 'group');
        const extrasById = arrayToObject(txs.arrayOfCats, 'extras');
        this.setState({
          currentPage: 1, transactions: txs.transactions, providerIdToConfigMap, groupById, extrasById, loading: false,
        }, () => {
          if (cats.root) {
            this.props.updatePageLoading({ pageLoading: false });
          }
        });
      })
      .catch((err) => {
        this.setState({ loading: false }, () => {
          if (cats.root) {
            this.props.updatePageLoading({ pageLoading: false });
          }
        });
        if (!err.isCanceled) {
          console.warn(err);
        }
      });
  };

  fetchCat = item => new Promise((resolve) => {
    fetch(`/categories/${item.cat}`, { noDefaultNotification: true })
      .then((result) => {
        resolve(result);
      })
      .catch(() => {
        const name = defaultProviderConfigByCat[item.provider] ? defaultProviderConfigByCat[item.provider].label : 'Неизвестная категория';
        resolve({ result: { id: item.cat, cat: item.provider, name, group: [], extras: {} } });
      });
  });

  // type: 'page' | 'prev' | 'next'
  paginationItemRender = (page, type, originalElement) => {
    if (type === 'prev' || type === 'next') {
      return (<a className={`ui-pagination-link ${type}-link`}><ArrowDown /></a>);
    } if (type === 'jump-next' || type === 'jump-prev') {
      return (<a className={`ui-pagination-link ${type}-link`}><DoubleArrowRight /></a>);
    }

    return originalElement;
  };

  normalizeTransactions = (transactions) => {
    const { providerIdToConfigMap, groupById, extrasById } = this.state;
    const { cats } = this.props;

    return transactions.map((transaction) => {
      const {
        provider, paymentId, amount, commission: rawCommission,
        full_amount, start_t, status, gateTx,
      } = transaction;
      const paymentCat = providerIdToConfigMap[paymentId].cat;
      const initialProviderConfig = getProviderConfig(provider, paymentCat, cats.root.byId);
      const providerConfig = {
        ...providerIdToConfigMap[paymentId],
        name: providerIdToConfigMap[paymentId].name || 'Неизвестный продукт',
        categoryName: initialProviderConfig.name || 'Неизвестная категория',
        gateTx,
      };
      const sum = formatMoneyWithCurrency({ amount: amount.amount, currency: amount.currency, exponent: amount.exp, symbol: true });
      const commission = formatMoneyWithCurrency({ amount: rawCommission, exponent: full_amount.exp, symbol: true });
      const toCashbox = formatMoneyWithCurrency({ amount: full_amount.amount, exponent: full_amount.exp, symbol: true });
      const startTime = moment(start_t).format('DD.MM.YYYY HH:mm');

      const modalData = {
        txData: { ...transaction },
        paymentData: {
          paymentCat,
          paymentGroup: groupById[transaction.paymentId],
          paymentExtras: extrasById[transaction.paymentId],
        },
      };

      return {
        providerConfig, sum, commission, toCashbox, startTime, status, gateTx, modalData,
      };
    });
  };

  refreshTransactionStatus = (gateTx, status) => {
    this.setState((prevState) => {
      const newTransactions = prevState.transactions.map((tx) => {
        if (tx.gateTx === gateTx) {
          return { ...tx, status };
        }
        return tx;
      });

      return { transactions: newTransactions };
    });
  };

  statusCellRender = row => (
    <div className="status-with-controls">
      <Tooltip title={statusLabels[row.value]} overlayClassName="ui-tooltip" align={{ offset: [0, -2] }}>
        <div className="status-icon-wrap">
          <StatusIcon status={row.value} />
        </div>
      </Tooltip>
      {this.controlsRender(row)}
    </div>
  );

  controlsRender = (row) => {
    const { pageLoading, loadTransaction, history } = this.props;

    if (pageLoading) return null;

    const { openedTxControlDropdownId, parentDomElem } = this.state;

    return (
      <div className="tx-controls-wrap">
        <Tooltip title="Действие" overlayClassName="ui-tooltip" align={{ offset: [0, 3] }}>
          <div className="tx-control-btn" onClick={this.onTxControlBtnClick(row.original.gateTx)}>
            <TxDotsIcon />
          </div>
        </Tooltip>
        <TransitionGroup component={null}>
          {openedTxControlDropdownId === row.original.gateTx && (
            <CSSTransition
              classNames="absolute-dropdown-layer"
              timeout={300}
              mountOnEnter
              unmountOnExit
            >
              <TxControlDropdownWrap
                row={row.original.modalData}
                parentDomElem={parentDomElem}
                loadTransaction={loadTransaction}
                refreshTransactionStatus={this.refreshTransactionStatus}
                onClose={this.onTxControlDropdownClose}
                history={history}
              />
            </CSSTransition>
          )}
        </TransitionGroup>
      </div>
    );
  };

  getTdProps = (state, rowInfo, column, instance) => ({
    onClick: (e, handleOriginal) => {
      // IMPORTANT! React-Table uses onClick internally to trigger
      // events like expanding SubComponents and pivots.
      // By default a custom 'onClick' handler will override this functionality.
      // If you want to fire the original onClick handler, call the
      // 'handleOriginal' function.
      if (column.id !== 'status' && this.state.openedTxControlDropdownId === '') {
        const fields = rowInfo.original.modalData.paymentData.paymentGroup.reduce((res, group) => res.concat(group.fields), []);
        const dstExtras = rowInfo.original.modalData.txData.dst_extras;
        this.setState({
          showTxDetails: true,
          currentTxData: { ...rowInfo.original },
          currentTxVisibleFields: this.activeFieldsChecker(fields, dstExtras),
        });
      }
    },
  });

  getTrProps = (state, rowInfo) => {
    if (rowInfo && rowInfo.original) {
      const { openedTxControlDropdownId, currentTxData } = this.state;
      const { original: { gateTx } } = rowInfo;
      const { viewport } = this.props;
      const sidebarOpened = currentTxData && currentTxData.gateTx === gateTx && viewport.size === ViewportSize.DESKTOP;

      const isActive = gateTx === openedTxControlDropdownId || sidebarOpened;

      return {
        className: isActive ? 'active' : '',
      };
    }
    return {};
  };

  renderTxDetails() {
    const { currentTxData, currentTxVisibleFields, sidebarTxControlDropdownOpened, groupById } = this.state;

    if (!currentTxData) return null;

    const { loadTransaction, history } = this.props;
    const {
      providerConfig, modalData: { txData, paymentData },
      sum, commission, toCashbox, startTime,
    } = currentTxData;
    const txControlsDropdownData = { txData, paymentData };
    const txCommonInfo = { sum, commission, toCashbox, startTime };

    return (
      <>
        <div className="tx-details-header">
          <div className="tx-details-header-top-panel" style={{ backgroundColor: statusColors[txData.status] }}>
            <StatusIcon status={txData.status} inverted />
            <span className="status-label">{statusLabels[txData.status]}</span>
          </div>
          <div className="tx-details-header-provider-info">
            <Provider {...providerConfig} tooltipDisabled={sidebarTxControlDropdownOpened} />
            <div className="tx-control-btn-with-dd-wrap">
              <Tooltip title="Действие" overlayClassName="ui-tooltip ui-tooltip-xs ui-tooltip-no-arrow" align={{ offset: [0, 10] }}>
                <div ref={this.sidebarDropdownControlBtn} className="tx-control-btn" onClick={this.onSidebarTxControlBtnClick}>
                  <TxDotsIcon />
                </div>
              </Tooltip>
              <CSSTransition
                classNames="absolute-dropdown-layer"
                timeout={300}
                in={sidebarTxControlDropdownOpened}
                mountOnEnter
                unmountOnExit
              >
                <div className="absolute-dropdown-layer">
                  <TxControlDropdown
                    row={txControlsDropdownData}
                    parentDomElem={this.sidebarDropdownControlBtn.current}
                    loadTransaction={loadTransaction}
                    refreshTransactionStatus={this.refreshTransactionStatus}
                    onClickOutside={this.onSidebarTxControlDropdownClose}
                    history={history}
                  />
                </div>
              </CSSTransition>
            </div>
          </div>
          <ul className="tx-details-header-common-info">
            {Object.keys(txCommonInfo).map(commonInfoKey => (
              <li className="common-info-item" key={commonInfoKey}>
                <div className="common-info-item-label">{colNameById[commonInfoKey]}</div>
                <div className="common-info-item-value">{txCommonInfo[commonInfoKey]}</div>
              </li>
            ))}
          </ul>
        </div>
        {groupById[txData.paymentId] && (
          <div className="tx-details-body">
            {groupById[txData.paymentId].filter(group => group.id !== 'common').map((group) => {
              const filledFields = group.fields.filter(field => txData.dst_extras[field.field_name]);

              return filledFields.length === 0 ? null : (
                <div key={group.name + group.id} className="fields-group">
                  <div className="fields-group-title" dangerouslySetInnerHTML={{ __html: group.name }} />
                  {filledFields.filter(field => currentTxVisibleFields[field.field_name]).map(field => (
                    <TxDataField
                      key={group.name + field.field_name}
                      field={field}
                      value={txData.dst_extras[field.field_name]}
                      amountCurrency={txData.dst_extras.currency}
                    />
                  ))}
                </div>
              );
            })}
          </div>
        )}
      </>
    );
  }

  render() {
    const { user, pageLoading, viewport } = this.props;

    if (pageLoading) return null;

    const {
      currentPage, showTxDetails, transactions, filterStatus, loading: txsLoading,
      reportGenerationLoading, selectedDate, reportGenerationDisabledUntilUserInfoUp,
    } = this.state;
    const filteredTransactions = filterStatus === 'all' ? transactions : transactions.filter(tx => tx.status === filterStatus);
    const normalizedTransactions = this.normalizeTransactions(filteredTransactions);

    const successTransaction = transactions.filter(tx => ['ok', 'success'].includes(tx.status));

    const successTransactionsCount = successTransaction.length;
    const successTransactionsSum = successTransaction.reduce((sum, tx) => {
      sum += tx.full_amount.amount;
      return sum;
    }, 0);

    const selectedDateMoment = moment(selectedDate, 'x');
    const nextBtnDisabled = txsLoading || selectedDateMoment.isSame(moment(), 'day');

    const reportGenerationAvailable = user.info.user.reportGeneratorAvailable;

    return (
      <>
        <div className="transactions-history-page">
          <div className="container">
            <h1 className="page-title h1">История транзакций</h1>
            <div className="transaction-table-wrapper">
              <div className="controls">
                <div className="controls-filters-and-stats">
                  <div className="controls-item">
                    <div className="ui-datepicker">
                      <p className="datepicker-label">Период</p>
                      <div className="datepicker-input-and-buttons">
                        <DatePicker value={selectedDateMoment} format="DD.MM.YYYY" onChange={this.onPeriodChange} placeholder="Выберите день" disabledDate={disabledDate} />
                        <div className="ui-buttons-group">
                          <button type="button" className={classnames('ui-button ui-button-wide ui-button-white datepicker-prev-button', { disabled: txsLoading })} disabled={txsLoading} onClick={this.onPrevDateClick}><ArrowDown /></button>
                          <button type="button" className={classnames('ui-button ui-button-wide ui-button-white datepicker-next-button', { disabled: nextBtnDisabled })} disabled={nextBtnDisabled} onClick={this.onNextDateClick}><ArrowDown /></button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="controls-item controls-item-status">
                    <Select label="Статус" items={statusFilters} value={filterStatus} onChange={this.onStatusChange} />
                  </div>
                  <div className="controls-items-group">
                    <div className="controls-item">
                      <p className="stats-label">Итого в кассу</p>
                      <p className="stats-value">{formatMoneyWithCurrency({ amount: successTransactionsSum, exponent: 2, symbol: true })}</p>
                    </div>
                    <div className="controls-item">
                      <p className="stats-label">Количество успешных</p>
                      <p className="stats-value">{successTransactionsCount}</p>
                    </div>
                  </div>
                </div>

                <div className="controls-actions">
                  <div className="controls-item controls-item-report">
                    <Button
                      label="Сформировать отчет"
                      className="download-button"
                      size={Button.Size.wide}
                      color={Button.Color.white}
                      disabled={!transactions.length || txsLoading || !reportGenerationAvailable || reportGenerationDisabledUntilUserInfoUp}
                      loading={reportGenerationLoading}
                      onClick={this.onReportClick}
                    />
                  </div>
                </div>

                {/*<div className="controls-item">*/}
                {/*<label>Результатов на страницу</label>*/}
                {/*<Select*/}
                {/*value={this.state.rowsPerPage}*/}
                {/*onChange={val => this.setState({ rowsPerPage: val, currentPage: 1 })}*/}
                {/*items={[*/}
                {/*{ label: '5', value: 5 },*/}
                {/*{ label: '10', value: 10 },*/}
                {/*{ label: '15', value: 15 },*/}
                {/*]}*/}
                {/*/>*/}
                {/*</div>*/}
              </div>
              {/*{!txsLoading && normalizedTransactions.length === 0 ? (*/}
              {/*<div className="no-txs-block">*/}
              {/*<Loupe className="no-txs-icon" />*/}
              {/*<div className="no-txs-title">Операций пока нет</div>*/}
              {/*<div className="no-txs-desc">На заданный период операции отсутствуют</div>*/}
              {/*</div>*/}
              {/*) : (*/}
              <div className="table">
                <ReactTable
                  className={classnames({ narrow: viewport.size === ViewportSize.DESKTOP && showTxDetails })}
                  loading={txsLoading}
                  LoadingComponent={({
                    className, loading, loadingText, ...rest
                  }) => (
                    <div className={classnames('ui-table-loading-overlay', { active: loading }, className)} {...rest}>
                      <div className="app-loading" />
                    </div>
                  )}
                  NoDataComponent={() => (
                    <div className="no-txs-block">
                      <Loupe className="no-txs-icon" />
                      <div className="no-txs-title">Операций пока нет</div>
                      <div className="no-txs-desc">На заданный период операции отсутствуют</div>
                      {/*<div className="fake-no-data" />*/}
                    </div>
                  )}
                  PaginationComponent={() => (
                    <Pagination
                      onChange={this.onPageChange}
                      current={currentPage}
                      defaultCurrent={1}
                      pageSize={10}
                      total={normalizedTransactions.length}
                      hideOnSinglePage
                      itemRender={this.paginationItemRender}
                    />
                  )}
                  page={currentPage - 1}
                  data={!txsLoading ? normalizedTransactions : []}
                  columns={this.columns}
                  defaultPageSize={10}
                  pageSize={10}
                  minRows={0}
                  getTdProps={this.getTdProps}
                  getTrProps={this.getTrProps}
                />
              </div>
              {/*)}*/}

              {viewport.size !== ViewportSize.DESKTOP && (
                <Modal
                  backdrop
                  active={showTxDetails}
                  onClickOutside={this.onTxDetailsClose}
                  transitionEnterTimeout={300}
                  transitionLeaveTimeout={300}
                  transitionName="ui-modal"
                  className="tx-details-modal"
                >
                  <div className="modal-close-btn" onClick={this.onTxDetailsClose}><Close /></div>
                  {this.renderTxDetails()}
                  <div className="modal-footer">
                    <Button label="Закрыть" color={Button.Color.white} onClick={this.onTxDetailsClose} />
                  </div>
                </Modal>
              )}

            </div>
          </div>
        </div>
        {viewport.size === ViewportSize.DESKTOP && (
          <Sidebar
            opened={showTxDetails}
            onClose={this.onTxDetailsClose}
          >
            {this.renderTxDetails()}
          </Sidebar>
        )}
      </>
    );
  }
}

export default withViewport()(TransactionsHistory);
