import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Tooltip } from 'antd';
import ProductLogo from './ProductLogo';
import SmartprotectStatusCheck from './SmartprotectStatusCheck'

// import Info from '../../pages/catalog-showcase/assets/svg/info.svg';

class CatalogCard extends React.PureComponent {
  static CardSizes = {
    small: 'sm',
    large: 'lg',
  };

  static propTypes = {
    card: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    cardIndex: PropTypes.number,
    size: PropTypes.string,
    updatePageLoading: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
  };

  static defaultProps = {
    disabled: false,
    cardIndex: 0,
    size: CatalogCard.CardSizes.large,
  };

  state = {
    modalOpened: false,
  };

  onceLogoLoaded = false;

  switchModal = () => {
    const { modalOpened } = this.state;

    this.setState({ modalOpened: !modalOpened });
  }

  onClick = () => {
    const { card, disabled, history } = this.props;

    if (!card.disabled && !disabled) {
      this.props.updatePageLoading({ pageLoading: true });

      if (card.id === 'smart_protect_status') {
        this.switchModal();
        // перекинуть на smart_protect_check

        this.props.updatePageLoading({ pageLoading: false });
        return;
      }
      if (card.cat) {
        if (card.cat === 'tickets') {
          history.push(`/iframes/${card.id}`);
          return;
        }
        if (card.subcat) {
          history.push(`/catalog/${card.subcat}`);
        } else {
          history.push(`/form/${card.id}`);
        }
      } else {
        history.push(`/catalog/${card.id}`);
      }
    }
  };

  renderCard = () => {
    // const { card, cardIndex } = this.props;
    const { card, disabled, size, history } = this.props;
    const cardClass = card.textColorClass || '';
    // const cardType = card.cat ? 'Услуга' : 'Категория';
    // const tooltipPlacement = cardIndex % 4 ? 'top' : 'topRight'; // 4 - count of cards in row
    // const tooltipXOffset = cardIndex % 4 ? 0 : 20;

    return (
      <>
        <div
          className={classnames('catalog-card', `catalog-card-${size}`, cardClass, { disabled: card.disabled || disabled })}
          style={{ background: card.background }}
          onClick={this.onClick}
        >
          {/*{card.disabled && (*/}
          {/*  <Tooltip placement={tooltipPlacement} title={(<div dangerouslySetInnerHTML={{ __html: card.disabledTooltip || defaultTooltipTitle }} />)} overlayClassName="ui-tooltip ui-tooltip-wide" align={{ offset: [tooltipXOffset, -4] }}>*/}
          {/*    <div className="disabled-info-icon">*/}
          {/*      <Info />*/}
          {/*    </div>*/}
          {/*  </Tooltip>*/}
          {/*)}*/}
          <div className="catalog-item-logo">
            <ProductLogo productConfig={card} />
          </div>
          <div className="catalog-item-title" dangerouslySetInnerHTML={{ __html: card.name }} />
        </div>
        {this.state.modalOpened && (
          <SmartprotectStatusCheck
            switchModal={this.switchModal}
            modalOpened={this.state.modalOpened}
            history={history}
          />
        )}
      </>
    );
  };

  render() {
    const { card, disabled } = this.props;
    const cardType = card.cat ? 'Услуга' : 'Категория';
    const defaultTooltipTitle = `<h3>Ведутся технические работы</h3><p>${cardType} временно недоступна, попробуйте позже</p>`;

    if (card.disabled || disabled) {
      return (
          <Tooltip
            placement="top"
            trigger="click"
            title={(<div dangerouslySetInnerHTML={{ __html: card.disabledTooltip || defaultTooltipTitle }} />)}
            overlayClassName="ui-tooltip ui-tooltip-for-card ui-tooltip-for-card-lg"
            align={{ offset: [0, -6] }}
          >
            {this.renderCard()}
          </Tooltip>
      );
    }

    return this.renderCard();
  }
}

export default CatalogCard;
