export const detectSteps = [{
  name: 'Предварительная оценка',
  id: 'detect-steps',
  fields: [{
    field_name: 'brand',
    caption: 'Бренд',
    type: 'typeahead',
    required: true,
    reg_ex: '',
    min_length: 0,
    placeholder: 'Выберите бренд устройства',
    comment: 'Модель',
    condition: 'true',
    visual: {
      size: 100,
      off: 0,
      post: 0,
    },
    toSave: false,
    items: [
      {
        name: 'раз',
        value: '1',
      },
      {
        name: 'Dva',
        value: '2',
      },
      {
        name: 'Tri',
        value: '3',
      },
      {
        name: '4',
        value: '4',
      },
      {
        name: '5',
        value: '5',
      },
      {
        name: '6',
        value: '6',
      },
    ],
  },
  {
    field_name: 'model',
    caption: 'Модель',
    type: 'typeahead',
    required: true,
    reg_ex: '',
    min_length: 0,
    placeholder: 'Выберите модель',
    comment: 'Модель',
    condition: 'true',
    visual: {
      size: 100,
      off: 0,
      post: 0,
    },
    toSave: false,
    items: [
      {
        name: 'раз',
        value: '1',
      },
      {
        name: 'Dva',
        value: '2',
      },
      {
        name: 'Tri',
        value: '3',
      },
      {
        name: '4',
        value: '4',
      },
      {
        name: '5',
        value: '5',
      },
      {
        name: '6',
        value: '6',
      },
    ],
  }, {
    field_name: 'memory',
    caption: 'Объем памяти',
    type: 'typeahead',
    required: true,
    reg_ex: '',
    min_length: 0,
    placeholder: 'ГБ',
    comment: 'Модель',
    condition: 'true',
    visual: {
      size: 100,
      off: 0,
      post: 0,
    },
    toSave: false,
    items: [
      {
        name: 'раз',
        value: '1',
      },
      {
        name: 'Dva',
        value: '2',
      },
      {
        name: 'Tri',
        value: '3',
      },
      {
        name: '4',
        value: '4',
      },
      {
        name: '5',
        value: '5',
      },
      {
        name: '6',
        value: '6',
      },
    ],
  },
  {
    field_name: 'imei',
    caption: 'IMEI устройства',
    type: 'text',
    required: true,
    reg_ex: '^[0-9]{15}$',
    min_length: 0,
    placeholder: '',
    comment: 'Неверно введен IMEI. Количество символов в поле должно быть не менее 15',
    condition: 'true',
    visual: {
      size: 100,
      off: 0,
      post: 0,
    },
    toSave: false,
  },
  {
    field_name: 'device_color',
    caption: 'Цвет устройства',
    type: 'text',
    required: true,
    disabled: false,
    reg_ex: '',
    min_length: 0,
    placeholder: '',
    comment: '-',
    condition: 'true',
    visual: {
      size: 100,
      off: 0,
      post: 0,
    },
    toSave: false,
  },
  {
    field_name: 'sostoyanie',
    caption: 'Состояние устройства',
    type: 'radio',
    required: true,
    reg_ex: '',
    min_length: 0,
    placeholder: 'Выберите бренд устройства',
    comment: 'Модель',
    condition: 'true',
    visual: {
      size: 100,
      off: 0,
      post: 0,
    },
    toSave: false,
    items: [
      {
        name: 'Отличное',
        value: '1',
      },
      {
        name: 'Хорошее',
        value: '2',
      },
      {
        name: 'Плохое',
        value: '3',
      },
    ],
  }, {
    field_name: 'preprice',
    caption: 'Предварительная стоимость',
    type: 'amount',
    required: true,
    disabled: true,
    reg_ex: '',
    min_length: 0,
    placeholder: '',
    comment: '-',
    condition: 'true',
    visual: {
      size: 100,
      off: 0,
      post: 0,
    },
    toSave: false,
  },
  ],
},
// {
//   name: 'Данные клиента',
//   id: 'lalaal',
//   fields: [
//     {
//       field_name: 'fio',
//       caption: 'ФИО клиента',
//       type: 'text',
//       required: true,
//       disabled: false,
//       reg_ex: '',
//       min_length: 0,
//       placeholder: '',
//       comment: '-',
//       condition: 'true',
//       visual: {
//         size: 100,
//         off: 0,
//         post: 0,
//       },
//       toSave: false,
//     },
//     {
//       field_name: 'id_series',
//       caption: 'Серия паспорта',
//       type: 'text',
//       required: true,
//       disabled: false,
//       reg_ex: '^[a-zA-ZА-Яа-я0-9]*$',
//       min_length: 0,
//       placeholder: '',
//       comment: 'Ошибка в паспортных данных.',
//       condition: 'true',
//       visual: {
//         size: 50,
//         off: 0,
//         post: 0,
//       },
//       toSave: false,
//     },
//     {
//       field_name: 'id_number',
//       caption: 'Номер паспорта',
//       type: 'text',
//       required: true,
//       disabled: false,
//       reg_ex: '^[0-9]*$',
//       min_length: 0,
//       placeholder: '',
//       comment: 'Ошибка в паспортных данных.',
//       condition: 'true',
//       visual: {
//         size: 50,
//         off: 0,
//         post: 0,
//       },
//       toSave: false,
//     },
//     {
//       field_name: 'phone',
//       caption: 'Номер телефона клиента',
//       type: 'phone',
//       required: true,
//       disabled: false,
//       reg_ex: '',
//       min_length: 0,
//       placeholder: '',
//       comment: '-',
//       condition: 'true',
//       visual: {
//         size: 100,
//         off: 0,
//         post: 0,
//       },
//       toSave: false,
//     },
//     {
//       field_name: 'card',
//       caption: 'Номер карты',
//       type: 'cardnumber',
//       required: true,
//       disabled: false,
//       reg_ex: '',
//       min_length: 0,
//       placeholder: '',
//       comment: '-',
//       condition: 'true',
//       visual: {
//         size: 100,
//         off: 0,
//         post: 0,
//       },
//       toSave: false,
//     },
//     {
//       field_name: 'imei',
//       caption: 'IMEI устройства',
//       type: 'text',
//       required: true,
//       reg_ex: '^[0-9]{15}$',
//       min_length: 0,
//       placeholder: '',
//       comment: 'Неверно введен IMEI. Количество символов в поле должно быть не менее 15',
//       condition: 'true',
//       visual: {
//         size: 100,
//         off: 0,
//         post: 0,
//       },
//       toSave: false,
//     },
//     {
//       field_name: 'device_color',
//       caption: 'Цвет устройства',
//       type: 'text',
//       required: true,
//       disabled: false,
//       reg_ex: '',
//       min_length: 0,
//       placeholder: '',
//       comment: '-',
//       condition: 'true',
//       visual: {
//         size: 100,
//         off: 0,
//         post: 0,
//       },
//       toSave: false,
//     },
//     {
//       field_name: 'device_id',
//       caption: 'ID устройства',
//       type: 'text',
//       required: true,
//       disabled: true,
//       reg_ex: '',
//       min_length: 0,
//       placeholder: '',
//       comment: '-',
//       condition: 'true',
//       visual: {
//         size: 100,
//         off: 0,
//         post: 0,
//       },
//       toSave: false,
//     },
//   ],
// }
];

export const clientForm = [
  {
    name: 'Данные клиента',
    id: 'lalaal',
    fields: [
      {
        field_name: 'fio',
        caption: 'ФИО клиента',
        type: 'text',
        required: true,
        disabled: false,
        reg_ex: '',
        min_length: 0,
        placeholder: '',
        comment: '-',
        condition: 'true',
        visual: {
          size: 100,
          off: 0,
          post: 0,
        },
        toSave: false,
      },
      {
        field_name: 'id_series',
        caption: 'Серия паспорта',
        type: 'text',
        required: true,
        disabled: false,
        reg_ex: '^[a-zA-ZА-Яа-я0-9]*$',
        min_length: 0,
        placeholder: '',
        comment: 'Ошибка в паспортных данных.',
        condition: 'true',
        visual: {
          size: 50,
          off: 0,
          post: 0,
        },
        toSave: false,
      },
      {
        field_name: 'id_number',
        caption: 'Номер паспорта',
        type: 'text',
        required: true,
        disabled: false,
        reg_ex: '^[0-9]*$',
        min_length: 0,
        placeholder: '',
        comment: 'Ошибка в паспортных данных.',
        condition: 'true',
        visual: {
          size: 50,
          off: 0,
          post: 0,
        },
        toSave: false,
      },
      {
        field_name: 'phone',
        caption: 'Номер телефона клиента',
        type: 'phone',
        required: true,
        disabled: false,
        reg_ex: '',
        min_length: 0,
        placeholder: '',
        comment: '-',
        condition: 'true',
        visual: {
          size: 100,
          off: 0,
          post: 0,
        },
        toSave: false,
      },
      {
        field_name: 'card',
        caption: 'Номер карты',
        type: 'cardnumber',
        required: true,
        disabled: false,
        reg_ex: '',
        min_length: 0,
        placeholder: '',
        comment: '-',
        condition: 'true',
        visual: {
          size: 100,
          off: 0,
          post: 0,
        },
        toSave: false,
      },
      // {
      //   field_name: 'device_id',
      //   caption: 'ID устройства',
      //   type: 'text',
      //   required: true,
      //   disabled: true,
      //   reg_ex: '',
      //   min_length: 0,
      //   placeholder: '',
      //   comment: '-',
      //   condition: 'true',
      //   visual: {
      //     size: 100,
      //     off: 0,
      //     post: 0,
      //   },
      //   toSave: false,
      // },
    ],
  },
];
