import { connect } from 'react-redux';
import { updatePageLoading } from '../../../actions/ui';
import Auth from './Auth';

const mapStateToProps = state => ({
  pageLoading: state.ui.pageLoading,
});

const mapDispatchToProps = {
  updatePageLoading,
};

const AuthContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Auth);

export default AuthContainer;
