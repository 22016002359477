import React, { Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';

const AddapterPage = React.lazy(() => import('./addapter-page'));

const Addapter = () => {
  return (
    <Suspense fallback="">
      <Switch>
        <Route exact path="/form/addappter" component={AddapterPage} />
      </Switch>
    </Suspense>
  )
}

export default Addapter;
