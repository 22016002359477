import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  closeConfirmLeaveModal,
  openConfirmLeaveModal,
  updateOpenModalBeforeLeave,
  updatePageLoading,
} from '../actions/ui';

export function usePageLoading() {
  const dispatch = useDispatch();
  return (status) => {
    dispatch(updatePageLoading({ pageLoading: status }));
  };
}

export function usePageLoadingStatus() {
  return useSelector((state) => state.ui.pageLoading);
}

