import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { IMaskInput } from 'react-imask';
import newid from '../../utils/newid';

import ResetIcon from '../../assets/img/icons/close-input-black.svg';
import { FormContext } from './Form';

function uppercaseMaskPrepare(str) {
  return str.toUpperCase();
}

export default class NewMaskInput extends React.Component {
  static propTypes = {
    type: PropTypes.string,
    uppercase: PropTypes.bool,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    maskProps: PropTypes.object,
    tabIndex: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    valid: PropTypes.bool,
    fullFilled: PropTypes.bool,
    disabled: PropTypes.bool,
    withResetIcon: PropTypes.bool,
    message: PropTypes.string,
    mask: PropTypes.string,
    maskChar: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
  };

  static contextType = FormContext;

  static defaultProps = {
    type: 'text',
    uppercase: false,
    label: '',
    maskProps: {},
    valid: undefined,
    tabIndex: '0',
    fullFilled: false,
    disabled: false,
    withResetIcon: true,
    message: '',
    placeholder: '',
    value: '',
    mask: '',
    maskChar: null,
    onBlur: () => {},
    onFocus: () => {},
    onChange: () => {},
  };

  constructor(props) {
    super(props);

    this.state = {
      value: props.value,
    };

    this.id = newid();
    this.input = null;
  }

  shouldComponentUpdate(nextProps, nextState) {
    // parseInt to detect whether it was real value change, but not just comma add/remove
    return (parseInt(nextState.value) !== parseInt(this.state.value))
      || JSON.stringify(nextProps.maskProps) !== JSON.stringify(this.props.maskProps)
      || nextProps.valid !== this.props.valid
      || nextProps.disabled !== this.props.disabled
      || nextProps.fullFilled !== this.props.fullFilled;
  }

  componentDidMount() {
    if (this.context) {
      this.context.onInputAddedToForm(this.input);
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (!props.value !== state.value) {
      return { ...state, value: props.value };
    }
    return state;
  }

  onAccept = (value, mask) => {
    this.setState({ value });
    this.props.onChange(value);
  };

  onBlur = (e) => {
    this.props.onBlur(e);
  };

  onFocus = (e) => {
    this.props.onFocus(e);
  };

  onReset = () => {
    this.props.onChange('');
    this.input.setSelectionRange(4, 5);
    this.input.focus();
  };

  render() {
    const {
      label, type, uppercase, disabled, placeholder, valid, fullFilled, tabIndex, withResetIcon, maskProps,
    } = this.props;
    const { value } = this.state;
    const className = classnames('ui-input', {
      disabled,
      filled: value && value.length,
      fullFilled,
      valid: valid === true,
      invalid: valid === false,
    });
    const enrichedMaskProps = uppercase ? { ...maskProps, prepare: uppercaseMaskPrepare } : maskProps;

    return (
      <div className={className}>
        {!!label && <label htmlFor={this.id}>{label}</label>}
        <div className="input-wrap">
          <IMaskInput
            id={this.id}
            inputRef={ref => this.input = ref}
            type={type}
            value={value}
            tabIndex={tabIndex}
            placeholder={placeholder}
            disabled={disabled}
            onAccept={this.onAccept}
            onBlur={this.onBlur}
            onFocus={this.onFocus}
            unmask
            {...enrichedMaskProps}
            // blocks={{
            //   num: {
            //     mask: Number,
            //     scale: 2,
            //     thousandsSeparator: ' ',
            //     signed: false,
            //     normalizeZeros: true,
            //     radix: '.',
            //     mapToRadix: [','],
            //     max: Infinity,
            //     min: 0,
            //   },
            // }}
            // mask={`num${currencySymbol}`}
            // lazy={false}
            // digits after point, 0 for integers
            // disallow negative
            // signed={false}
            // any single char
            // thousandsSeparator=" "
            // if true, then pads zeros at end to the length of scale
            // padFractionalZeros={false}
            // appends or removes zeros at ends
            // normalizeZeros
            // fractional delimiter
            // radix="."
            // symbols to process as radix
            // mapToRadix={[',']}
            // additional number interval options (e.g.)
            // max={Infinity}
          />
          {withResetIcon && (
            <div className="reset-icon" onClick={this.onReset}>
              <ResetIcon className="reset-icon-svg" />
            </div>
          )}
        </div>
        {/*<div className="ui-input-msg">{message}</div>*/}
      </div>
    );
  }
}
