import React from 'react';
import NotFoundContainer from './components/NotFoundContainer';

import './styles/not-found.scss';

export default function NotFoundPage({ location, history, match }) {
  return (
    <NotFoundContainer location={location} history={history} match={match} />
  );
}
