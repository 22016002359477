import fetch from '../utils/fetch';
import { GET_STATS, GET_CATS, GET_ALL_CATALOG_ITEMS } from '../constants/actionTypes';

export function getStats() {
  return dispatch => fetch('/stats', { method: 'GET', noDefaultNotification: true })
    .then(payload => dispatch({ type: GET_STATS, payload: payload.result }));
}

export function getCats(id) {
  return (dispatch, getState) => {
    const state = getState();

    if (state.catalog.cats[id]) {
      return Promise.resolve();
    }

    if (id !== 'root') {
      return fetch(`/categories?cat=${id}`, { method: 'GET' })
        .then(payload => dispatch({ type: GET_CATS, payload: payload.result, meta: { catId: id } }));
    }

    return fetch('/cats', { method: 'GET' })
      .then(payload => dispatch({ type: GET_CATS, payload: payload.result, meta: { catId: id } }));
  };
}

export function getAllCatalogItems() {
  return (dispatch, getState) => {
    const state = getState();

    if (state.catalog.allItems.length) {
      return Promise.resolve();
    }

    return fetch('/categories?cat=all', { method: 'GET' })
      .then(payload => dispatch({ type: GET_ALL_CATALOG_ITEMS, payload: payload.result }));
  };
}
