import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Skeleton from 'react-loading-skeleton';
import AnimateHeight from 'react-animate-height';
import { useHistory } from 'react-router';
import { usePageLoading, usePageLoadingStatus } from '../../components/hooks';

import ImgSkeleton from '../../assets/svg/img_skeletone.svg';

import './styles/cart.scss';
import Button from '../../components/ui/Button';
import LabelValue from './styles/components/LabelValue';
import { formatMoneyWithCurrency } from '../../utils/format';
import { useDispatch, useSelector } from 'react-redux';
import {
  addToCartAsync,
  getCartFromOneCAsync,
  getSuggestedItemAsync,
  removeFromCartAsync,
  getSums,
  getCartFromTekoAsync, setActiveCartItem,
} from '../../actions/cart';
import useCart from './hooks/useCart';
import { sendNotify } from '../../utils/processing';

function declOfNum(number, titles)
{
  const cases = [2, 0, 1, 1, 1, 2];
  return titles[ (number%100>4 && number%100<20)? 2 : cases[(number%10<5)?number%10:5] ];
}

const Cart = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const updatePage = usePageLoading();
  const loadingStatus = usePageLoadingStatus();
  const [disableButtons, useDisableButtons] = useState(false);

  const addToCart = useCallback((objectToAdd) => {
    dispatch(addToCartAsync(objectToAdd));
  }, []);

  const removeFromCart = useCallback((objectToRemove, tovarName) => {
    dispatch(removeFromCartAsync(objectToRemove, tovarName));
  }, []);

  const cart = useCart();
  const suggestedItemsInStore = useSelector((state) => state.cartData.suggestedItems.suggestedItemsList);

  const { oneCItems: { deviceList }, suggestedItems: { isLoaded, suggestedItemsList }, tekoCart, tekoCart: { cartList, txs } } = cart;
  // getCartFromTekoAsync
  useEffect(() => {
    sessionStorage.setItem('cartWasInited', 'true');
    dispatch(getCartFromOneCAsync())
      .then(() => {
        // forEach Promise All -> getSuggestedItemAsync(nomen)
        return dispatch(getSuggestedItemAsync());
      })
      .then(() => {
        updatePage(false);
        dispatch(getSums(suggestedItemsInStore))
      })
  }, []);

  const goToFillForms = useCallback(() => {
    const nextFormIndex = cartList.findIndex(item => item.tx === '');
    if (nextFormIndex !== -1) {
      dispatch(setActiveCartItem(nextFormIndex, true));
      sessionStorage.setItem('imei', cartList[nextFormIndex].good_id);
      const currentItem = deviceList.find(device => {
        return device.id == cartList[nextFormIndex].good_id;
      });

      sessionStorage.setItem('goods', currentItem.group_id);
      sessionStorage.setItem('price', currentItem.price / 100);
      history.push(`/reload-catalog-form-fill/${cartList[nextFormIndex].id}`);
    } else {
      // todo notify + close
    }
  }, [cartList, deviceList]);

  const goToOneC = useCallback(() => {
    useDisableButtons(true)
    dispatch(getCartFromOneCAsync())
      .then(() => {
        // forEach Promise All -> getSuggestedItemAsync(nomen)
        return dispatch(getSuggestedItemAsync());
      })
      .then(() => {
        updatePage(false);
        // dispatch(getSums(suggestedItemsInStore))
        const txList = cartList.map(item => ({ unableToDelete: item.unableToDelete, ...txs.find(tx => tx.id === item.tx)}));
        const PromiseArray = []

        txList.forEach(tx => {
          if (tx.unableToDelete) {
            return;
          }
          PromiseArray.push(new Promise((resolve) => {
            const coreObjectToOneC = {
              price: tx.dst_extra.full_amount,
              GoodId: tx.dst_extra.insuranceNomenKey,
              product: tx.item_id,
              txId: tx.id,
              complexSalesGuid: sessionStorage.getItem('complexSalesGuid')
            };

            const additionalObjectToOneC = {};

            // todo wip
            // if (passToOneC && Object.keys(passToOneC).length > 0) {
            //   Object.keys(passToOneC).forEach((item) => {
            //     const keyFromForm = passToOneC[item];
            //     const splittedKeys = keyFromForm.split('+');
            //
            //     // if lastName+firstName+middleName => return concat of three fields: 'Ivanov Ivan Ivanovich';
            //     if (splittedKeys.length > 1) {
            //       const splittedData = splittedKeys.map(it => txAfterCheck.dst.extra[it]).filter(it => it && it.trim() && it !== '-');
            //       additionalObjectToOneC[item] = splittedData.join(' ');
            //     } else {
            //       const value = txAfterCheck.dst.extra[keyFromForm];
            //       additionalObjectToOneC[item] = (value && value !== '-') ? value : '';
            //     }
            //   });
            // }

            sendNotify(coreObjectToOneC, additionalObjectToOneC)
              .then(resolve);
          }))
        })

        Promise.all(PromiseArray)
          .then(() => {
            window.close();
          })
      })
  }, [cartList, txs]);

  const isThereAnyFormsToFill = useMemo(() => {
    return cartList.length > 0 && cartList.findIndex(item => item.tx === '') !== -1;
  }, [cartList]);

  const canWeSubmitToOneC = useMemo(() => {
    return cartList.length > 0 && cartList.findIndex(item => item.tx === '') === -1;
  }, [cartList]);

  const filteredSuggestedItems = useMemo(() => {

    const result = {};

    suggestedItemsList.forEach((item) => {

      const itemInCart = cartList.find(cartItem => (cartItem.good_id == item.good_id && cartItem.id == item.id))
      if (!itemInCart) {
        if (result[item.good_id]) {
          result[item.good_id].push(item);
        } else {
          result[item.good_id] = [item];
        }
      }
    });

    // cartList.forEach((item) => {
    //   if (result[item.good_id]) {
    //     result[item.good_id].push(item);
    //   } else {
    //     result[item.good_id] = [item];
    //   }
    // });
    return result;
  }, [suggestedItemsList, cartList]);


  const sums = useMemo(() => {

    const deviceSum = deviceList.reduce((acc, item) => {
      acc += item.price;
      return acc;
    }, 0);
    const suggestedItemsSum = tekoCart.cartList.reduce((acc, item) => {
      acc += item.price;
      return acc;
    }, 0);


    return {
      deviceSum,
      suggestedItemsSum,
      totalSum: deviceSum + suggestedItemsSum,
    };
  }, [deviceList, suggestedItemsList, tekoCart]);

  const cartByNomen = useMemo(() => {
    return tekoCart.cartList.reduce((acc, item) => {
      if (acc[item.good_id]) {
        acc[item.good_id].push(item);
      } else {
        acc[item.good_id] = [item];
      }

      return acc;
    }, {});

  }, [tekoCart]);

  const title = useMemo(() => {
    if (!deviceList || deviceList.length === 0) return '';
    return declOfNum(deviceList.length, ['товар', 'товара', 'товаров']);
  }, [deviceList]);

  if (loadingStatus) {
    return <div />;
  }


  return (
    <div className="cart-page">
      <div className="cart-page-inner">
        <div className="cart-header">
          {deviceList.length} {title} в корзине
        </div>
        <div className="cart-segments-wrapper">
          <div className="cart-left-block-wrapper">
            {deviceList.map(item => (
              <div className="parent-item-block" key={item.name}>
                <div className="parent-item-block-header">
                  <div className="cart-item-with-logo">
                    <ImgSkeleton className="cart-item-logo" />
                    <div className="cart-item-name">{item.name}</div>
                  </div>
                  <div className="price">{formatMoneyWithCurrency({ amount: item.price, symbol: true })}</div>
                </div>
                {isLoaded ? (
                  <>
                    {cartList.length > 0 && (
                      <div className="suggested-items-wrapper">
                        <div className="suggested-items-header">Товары в корзине</div>
                        {cartList.map(suggItem => (
                          <div className="suggested-items-list-wrapper" key={suggItem.name}>
                            <div className="suggested-item">
                              <LabelValue label={suggItem.cat} className="cat" value={suggItem.name} />
                              <LabelValue label="Стоимость" className="price" value={suggItem.price ? formatMoneyWithCurrency({ amount: suggItem.price }) : <Skeleton /> } />
                              <div className="suggested-item-button-wrap">
                                <Button label="Удалить" color={Button.Color.orange} disabled={!!suggItem.unableToDelete} onClick={() => removeFromCart(suggItem, suggItem.name)} loading={tekoCart.loading} />
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                    {filteredSuggestedItems[item.id] && filteredSuggestedItems[item.id].length > 0 && (<div className="suggested-items-wrapper" key={item.id}>
                      <div className="suggested-items-header">Рекомендуем с товаром</div>
                      {filteredSuggestedItems[item.id] && filteredSuggestedItems[item.id].map(suggItem => (
                        <div className="suggested-items-list-wrapper">
                          <div className="suggested-item">
                            <LabelValue label={suggItem.cat} className="cat" value={suggItem.name} />
                            <LabelValue label="Стоимость" className="price" value={suggItem.price ? formatMoneyWithCurrency({ amount: suggItem.price }) : <Skeleton /> } />
                            <div className="suggested-item-button-wrap">
                              <Button label="Добавить" color={Button.Color.orange} onClick={() => addToCart(suggItem)} disabled={!suggItem.price} loading={tekoCart.loading} />
                            </div>
                          </div>
                      </div>
                    ))}
                  </div>)}
                </>
                  ) : (
                    <div className="suggested-items-wrapper">
                      <div className="suggested-items-header">Рекомендуем с товаром</div>
                      <div className="suggested-items-list-wrapper">
                        <div className="suggested-item">
                          <LabelValue label={<Skeleton />} className="cat" value={<Skeleton />} />
                          <LabelValue label={<Skeleton />} className="cat" value={<Skeleton />} />
                        </div>
                      </div>
                    </div>
                )}
              </div>
            ))}
            <div className="buttons-wrap">
                {/*<Button color={Button.Color.white} label="Вернуться в 1С" />*/}
              {isThereAnyFormsToFill && (
                <Button label="Перейти к оформлению" color={Button.Color.blue} onClick={goToFillForms} loading={disableButtons || tekoCart.loading} />
              )}
              {canWeSubmitToOneC && (
                <Button label="Добавить в 1С" color={Button.Color.blue} onClick={goToOneC} loading={disableButtons || tekoCart.loading} />
              )}
            </div>
          </div>
          <div className="cart-right-block-wrapper">
            <div className="cart-cheque">
              <span className="cart-cheque-header">Ваш заказ</span>
              <div className="cart-cheque-items-wrap">

                {deviceList.map(item => (
                  <>
                    <div className="cart-cheque-item-header">{item.name}</div>
                      {isLoaded ? (
                        <div className="suggested-items-wrap">
                          <TransitionGroup>
                            {cartByNomen[item.good_id] && cartByNomen[item.good_id].map(suggItem => (
                              <CSSTransition
                                classNames="suggested-item"
                                timeout={800}
                                mountOnEnter
                                unmountOnExit
                              >
                                {stage => (
                                  <div className="suggested-item">
                                    <AnimateHeight
                                      duration="300"
                                      height={stage === 'exited' || stage === 'exiting' ? 0 : 'auto'}
                                    >
                                      <LabelValue label={suggItem.cat} className="normal" value={suggItem.name} />
                                      <div className="suggested-items-cost">{suggItem.price ? formatMoneyWithCurrency({ amount: item.price, symbol: true }) : <Skeleton /> }</div>
                                    </AnimateHeight>
                                  </div>
                                )}
                              </CSSTransition>
                            ))}
                          </TransitionGroup>
                        </div>
                      ) : (
                        <div className="suggested-item">
                          <LabelValue label={<Skeleton />} className="normal" value={<Skeleton />} />
                          <div className="suggested-items-cost"><Skeleton /></div>
                        </div>
                      )}
                  </>
                ))}
              </div>
              <div className="cart-cheque-summary-wrapper">
                <div className="cart-cheque-summary-sub-item">
                  <span>Товары ({deviceList.length})</span>
                  <span>{formatMoneyWithCurrency({ amount: sums.deviceSum })}</span>
                </div>
                <div className="cart-cheque-summary-sub-item">
                  <span>Итого за доп. услуги</span>
                  <span>{formatMoneyWithCurrency({ amount: sums.suggestedItemsSum })}</span>
                </div>
                <div className="cart-cheque-summary-final-item">
                  <span className="label">Итого к оплате</span>
                  <span className="value">{formatMoneyWithCurrency({ amount: sums.totalSum })}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

export default Cart;
