import React from 'react';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';
import DomHolder from '../common/DomHolder';
import Alert from './Alert';

const onAlertEnter = (node) => {
  node.style.marginBottom = '0px';
  node.style.height = '0px';
  node.getBoundingClientRect();
  node.style.height = `${node.getElementsByClassName('ui-alert-content')[0].clientHeight}px`;
  node.style.marginBottom = '20px';
};

const onAlertEntered = (node) => {
  node.style.height = '';
  node.style.marginBottom = '';
};

const onAlertExit = (node) => {
  node.style.marginBottom = '20px';
  node.style.height = `${node.getElementsByClassName('ui-alert-content')[0].clientHeight}px`;
};

const onAlertExiting = (node) => {
  node.getBoundingClientRect();
  node.style.height = '0px';
  node.style.marginBottom = '0px';
};

const onAlertExited = (node) => {
  node.style.height = '';
  node.style.marginBottom = '';
};

export default class AnimatedAlert extends React.PureComponent {
  static Color = {
    blue: 'blue',
    green: 'green',
    orange: 'orange',
    red: 'red',
    gray: 'gray',
  };

  static propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    color: PropTypes.string,
    in: PropTypes.bool,
  };

  static defaultProps = {
    in: false,
    color: 'blue',
    className: '',
  };

  render() {
    const { children, color, className, in: inProp, style = {} } = this.props;

    return (
      <Transition
        in={inProp}
        mountOnEnter
        unmountOnExit
        timeout={300}
        onEnter={onAlertEnter}
        onEntered={onAlertEntered}
        onExit={onAlertExit}
        onExiting={onAlertExiting}
        onExited={onAlertExited}
      >
        <Alert color={color} className={className} style={style}>
          <DomHolder>
            {children}
          </DomHolder>
        </Alert>
      </Transition>
    );
  }
}
