import rutoken from 'rutoken';

const promisifiedRutoken = new Promise((resolve, reject) => {
  rutoken.ready.then(() => {
    if (window.chrome || typeof InstallTrigger !== 'undefined') {
      return rutoken.isExtensionInstalled();
    }
    return Promise.resolve(true);
  }).then((result) => {
    if (result) {
      return rutoken.isPluginInstalled();
    }
    // eslint-disable-next-line
    throw "Rutoken Extension wasn't found";
  }).then((result) => {
    if (result) {
      return rutoken.loadPlugin();
    }

    // eslint-disable-next-line
    throw "Rutoken Plugin wasn't found";
  }).then((plugin) => {

    return resolve(plugin);
  })
    .then(undefined, (reason) => {
      reject(reason);
    });
});


export default promisifiedRutoken;
