import { GET_ALL_CATALOG_ITEMS, GET_CATS, GET_STATS } from '../constants/actionTypes';

const initialState = {
  stats: {
    643: { turnover: 0, commission: 0, average: 0, operations: 0 },
    balance: { amount: '-', currency: 643, exponent: 2 },
  },
  cats: {},
  allItems: [],
};

export default function catalog(state = initialState, action) {
  switch (action.type) {
    case GET_STATS:
      return {
        ...state,
        stats: {
          ...state.stats,
          ...action.payload,
        },
      };

    case GET_CATS:
      if (!action.payload.length) return { ...state };

      const filtered = action.payload.filter(cat => !['popular', 'search'].includes(cat.id));
      const allIds = filtered.map(cat => cat.id);
      const byId = filtered.reduce((acc, item) => { acc[item.id] = item; return acc; }, {});

      return {
        ...state,
        cats: {
          ...state.cats,
          [action.meta.catId]: {
            ...state.cats[action.meta.catId],
            allIds,
            byId,
          },
        },
      };

    case GET_ALL_CATALOG_ITEMS:
      return {
        ...state,
        allItems: action.payload,
      };

    default:
      return state;
  }
}
