import fetch from '../utils/fetch';
import logger from '../utils/logger';

const pureUserRequest = noDefaultNotification => fetch('/user/check', { method: 'POST', noDefaultNotification });

const fetchUserRequest = (noDefaultNotification, userCallTryes = 0, upperResolve, upperReject) => new Promise((resolve, reject) => {
  pureUserRequest(noDefaultNotification)
    .then(resolve)
    .catch((error) => {
      logger({ message: 'Ошибка в user/check', data: { error, userCallTryes }, level: 'info', tag: 'user_check_error' });
      if (userCallTryes < 3) {
        setTimeout(() => {
          fetchUserRequest(noDefaultNotification, userCallTryes + 1, upperResolve || resolve, upperReject || reject);
        }, 500);
      } else if (upperReject) {
        upperReject(error);
      } else {
        reject(error);
      }
    });
});

const restartStunnel = cb => error => new Promise((resolve, reject) => {
  setTimeout(() => {
    if (error && error.response && error.response.status === -1) {
      console.warn('RestartStunnel in check');

      const driverUrl = localStorage.getItem('DRIVER_ADDRESS');

      const urlString = driverUrl || process.env.DRIVER_ADDRESS;

      return fetch('/RestartStunnel', {
        backendUrl: urlString,
        prefix: '/fr',
        notJson: true,
      }).then(result => result.text())
        .then((result) => {
          if (result === '{ok}') {
            setTimeout(() => resolve(true), 1000);
          } else {
            return reject(new Error('Ошибка в перезапуске станнела'));
          }
        })
        .then(cb)
        .catch((e) => {
          return reject(new Error('Возникли неполадки с сетевым соединением. Проверьте его и повторите попытку.'));
        });
    }
    return reject(new Error('Возникли неполадки с сетевым соединением. Проверьте его и повторите попытку.'));
  }, 1500);
});

const stunnelWrapedUserRequest = noDefaultNotification => fetchUserRequest(noDefaultNotification).catch(restartStunnel(() => {
  fetchUserRequest(noDefaultNotification);
}));

export function fetchUser({ noDefaultNotification = false } = {}) {
  return dispatch => stunnelWrapedUserRequest(noDefaultNotification)
    .then((res) => {
      if (res.result.info.user.deepIntegration1C === 'true') {
        const driverUrl = localStorage.getItem('DRIVER_ADDRESS');

        const urlString = driverUrl || process.env.DRIVER_ADDRESS;

        return fetch('/GetCurrentUser', {
          backendUrl: urlString,
          prefix: '/fr',
          noDefaultNotification: true,
        }).then((result) => {
          const user = result.result.user.trim();
          const fetchedUser = (res.result.info.user.fullName || res.result.info.user.FIO || '').trim();

          if (user !== fetchedUser) {
            console.warn(`1c пользователь ${user} не соответствует пользователю интерфейса ${fetchedUser}`);
            throw new Error('user does not match');
          } else {
            dispatch({ type: 'app.user.fetchSuccess', payload: res.result });
          }
        }).catch((e) => {
          console.warn(e);
          if (e.message === 'user does not match') {
            localStorage.clear();
            throw new Error('user does not match');
          } else {
            dispatch({ type: 'app.user.fetchSuccess', payload: res.result });
          }
        });
      }
      dispatch({ type: 'app.user.fetchSuccess', payload: res.result });
    });
}

export function clear() {
  return {
    type: 'app.User.clear',
  };
}
