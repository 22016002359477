// Catalog
export const GET_STATS = 'GET_STATS';
export const GET_CATS = 'GET_CATS';
export const GET_ALL_CATALOG_ITEMS = 'GET_ALL_CATALOG_ITEMS';
// Ui
export const GET_MESSAGES = 'GET_MESSAGES';
export const READ_MESSAGE = 'READ_MESSAGE';
export const ENABLE_FETCHING_OVERLAY = 'ENABLE_FETCHING_OVERLAY';
export const DISABLE_FETCHING_OVERLAY = 'DISABLE_FETCHING_OVERLAY';
export const UPDATE_OPEN_MODAL_BEFORE_LEAVE = 'UPDATE_OPEN_MODAL_BEFORE_LEAVE';
export const UPDATE_PAGE_LOADING = 'UPDATE_PAGE_LOADING';
// Transaction
export const LOAD_TRANSACTION = 'LOAD_TRANSACTION';
export const REMOVE_TRANSACTION = 'REMOVE_TRANSACTION';
// ExternalForm
export const LOAD_FORM_DATA = 'LOAD_FORM_DATA';
export const LOAD_FORM = 'LOAD_FORM';
export const REMOVE_FORM = 'REMOVE_FORM';
export const REMOVE_FORM_DATA = 'REMOVE_FORM_DATA';
export const REMOVE_FORM_AND_FORM_DATA = 'REMOVE_FORM_AND_FORM_DATA';
//t2marketplace
export const ADD_MARKETPLACE_DATA = 'ADD_MARKETPLACE_DATA';
export const REMOVE_MARKETPLACE_DATA = 'REMOVE_MARKETPLACE_DATA';
