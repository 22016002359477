import React from 'react';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';
import DomHolder from '../common/DomHolder';
import FormTooltip from './FormTooltip';

const onFormTooltipEnter = (node) => {
  node.style.height = '0px';
  node.getBoundingClientRect();
  node.style.height = `${node.getElementsByClassName('ui-form-tooltip-content-wrap')[0].clientHeight}px`;
};

const onFormTooltipEntered = (node) => {
  node.style.height = '';
};

const onFormTooltipExit = (node) => {
  node.style.height = `${node.getElementsByClassName('ui-form-tooltip-content-wrap')[0].clientHeight}px`;
};

const onFormTooltipExiting = (node) => {
  node.getBoundingClientRect();
  node.style.height = '0px';
};

const onFormTooltipExited = (node) => {
  node.style.height = '';
};

export default class AnimatedFormTooltip extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    in: PropTypes.bool,
  };

  static defaultProps = {
    in: false,
    className: '',
  };

  render() {
    const { children, className, in: inProp } = this.props;

    return (
      <Transition
        in={inProp}
        mountOnEnter
        unmountOnExit
        timeout={300}
        onEnter={onFormTooltipEnter}
        onEntered={onFormTooltipEntered}
        onExit={onFormTooltipExit}
        onExiting={onFormTooltipExiting}
        onExited={onFormTooltipExited}
      >
        <FormTooltip className={className}>
          <DomHolder>
            {children}
          </DomHolder>
        </FormTooltip>
      </Transition>
    );
  }
}
