import React from 'react';
import TransactionsHistoryContainer from './components/TransactionsHistoryContainer';

import './styles/transactions-history.scss';

export default function TransactionsHistoryPage(props) {
  return (
    <TransactionsHistoryContainer {...props} />
  );
}
