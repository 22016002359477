/* eslint-disable react/no-unused-state */
import React from 'react';
import PropTypes from 'prop-types';

export const FormContext = React.createContext(null);

export default class Form extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node,
    focusFirstInput: PropTypes.bool,
    onSubmit: PropTypes.func,
  };

  static defaultProps = {
    children: null,
    focusFirstInput: true,
    onSubmit: () => {},
  };

  constructor(props) {
    super(props);

    this.validateInputs = [];
    this.inputs = [];
    this.firstEnabledInputFocused = false;

    this.state = {
      formSubmitted: false,
      onValidateInputAddedToForm: this.onValidateInputAddedToForm,
      onInputAddedToForm: this.onInputAddedToForm,
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  onValidateInputAddedToForm = (input) => {
    this.validateInputs = this.validateInputs.concat(input);
  };

  onInputAddedToForm = (input) => {
    this.inputs = this.inputs.concat(input);
    if (this.props.focusFirstInput && !this.firstEnabledInputFocused && !input.disabled) {
      input.focus();
      setTimeout(() => {
        const inputVal = input.value;
        input.value = '';
        input.value = inputVal;
      }, 10);
      this.firstEnabledInputFocused = true;
    }
  };

  onSubmit(event) {
    event.preventDefault();

    let isValid = true;

    this.setState({ formSubmitted: true });

    this.validateInputs.forEach((input) => {
      input.forceUpdate();
      if (!input.isValid()) {
        isValid = false;
      }
    });

    if (isValid) {
      this.props.onSubmit();
    }
  }

  render() {
    return (
      <FormContext.Provider value={this.state}>
        <form onSubmit={this.onSubmit} noValidate autoComplete="off">
          {this.props.children}
        </form>
      </FormContext.Provider>
    );
  }
}
