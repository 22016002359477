import classnames from 'classnames';
import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import ReactDOM from 'react-dom';

import Button from './Button';

export default class ModalsMachine extends React.PureComponent {
  static propTypes = {
    active: PropTypes.bool,
    modals: PropTypes.array.isRequired,
    onMinimizeMessageModalClick: PropTypes.func.isRequired,
  };

  static defaultProps = {
    active: false,
  };

  el = window.document.createElement('div');

  componentDidMount() {
    window.document.body.appendChild(this.el);
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (this.props.active !== nextProps.active) {
      const { body } = window.document;

      if (nextProps.active) {
        body.classList.add('contains-modal');
      } else {
        body.classList.remove('contains-modal');
      }
    }
  }

  componentWillUnmount() {
    window.document.body.removeChild(this.el);
    window.document.body.classList.remove('contains-modal');
  }

  // onModalCloseClick = (id) => {
  //   this.props.readMessage({ id });
  //
  //   // this.setState((prevState) => {
  //   //   const newAlerts = prevState.alerts.slice(0);
  //   //   newAlerts.push(newAlerts.shift());
  //   //
  //   //   return { alerts: newAlerts };
  //   // });
  // };

  onMinimizeMessageModalClick = (modal) => {
    this.props.onMinimizeMessageModalClick(modal);
  };

  render() {
    const { modals, active } = this.props;

    const wrapper = (
      <CSSTransition
        classNames="ui-modal"
        timeout={300}
        in={active}
        mountOnEnter
        unmountOnExit
      >
        <div className="ui-modal ui-modals-machine">
          <div className="ui-modal-backdrop" />
          <TransitionGroup className="ui-modals-machine-item" component={null}>
            {modals.slice(0, 1).map(modal => (
              <CSSTransition
                key={modal.id}
                timeout={600}
                classNames="ui-modals-machine-item"
              >
                <div className="ui-modal-content ui-modals-machine-item">
                  <div className="modal-header">Уведомление</div>
                  <div className="modal-body">
                    <div className="message-icon-wrap">
                      <div className={classnames('message-icon', `message-icon-${modal.lvl.toLowerCase()}`)} />
                    </div>
                    <div className="message-content-wrap">
                      <div className="message-content" dangerouslySetInnerHTML={{ __html: modal.msg }} />
                      <div className="message-date">{moment(modal.createdT, 'x').calendar()}</div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <Button label="Свернуть" color={Button.Color.white} onClick={(e) => { this.onMinimizeMessageModalClick(modal); e.stopPropagation(); }} />
                  </div>
                </div>
              </CSSTransition>
            ))}
          </TransitionGroup>
        </div>
      </CSSTransition>
    );


    return ReactDOM.createPortal(
      wrapper,
      this.el,
    );
  }
}
