import moment from 'moment';
import fetch from './fetch';
import store from '../store/store';

/* eslint-disable no-template-curly-in-string */

// loto legacy
const startTx = ({ amount, product, formData }) => {
  const { complexSalesGuid } = store.getState().oneC;
  return fetch('/merchant/check', {
    method: 'POST',
    body: {
      amount: +amount,
      amountWithCommission: +amount,
      currency: '643',
      formData: {
        TERMINAL_ID: '5002025',
        REQUEST_TIME: `${moment().format('X')}`,
        EXTERNAL_TRANSACTION_ID: '',
        complexSalesGuid,
        ...formData,
      },
      inner: '${billing}',
      product,
      srcCls: 'cash',
    },
  }).then(result => ({
    ...result.result,
  }));
};

const sendNotify = ({ price = 0, GoodId = '0100-296667', product, txId, complexSalesGuid = '0', PhoneNumber, Type }, additionalObjectToOneC = {}, platId = '') => {
  // const { complexSalesGuid } = store.getState().oneC;
  const { FIO } = store.getState().user.info.user;

  const driverUrl = window.localStorage.getItem('DRIVER_ADDRESS');

  const urlString = driverUrl ? `${driverUrl}/fr/Notify1C` : `${process.env.DRIVER_ADDRESS}/fr/Notify1C`;

  const url = new URL(urlString);
  const params = {
    status: 'ok',
    ...(platId !== 'sdalkupilbuyout' && { GoodId }),
    FIO,
    Type,
    PhoneNumber,
    Summa: +price / 100,
    guid: complexSalesGuid,
    tekoguid: txId || complexSalesGuid,
    ...(platId !== 'complex_plus_barcode_discount' && { Comment: txId }), //наша транзакция
    ...additionalObjectToOneC,
    // Comment: '34234n32jn432432ln432jl42', //наша транзакция
  };
  Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
  return fetch(url, {
    backendUrl: '',
    prefix: '',
    withOverlay: true,
  });
  //   minorAmount : request.formData.full_amount,
  //   GoodId      : GoodId
};

const applyTx = ({ amount, product, partnerTx }) =>
  fetch('/merchant/payment', {
    method: 'POST',
    body: {
      amount: +amount,
      amountWithCommission: +amount,
      currency: 643,
      formData: {
        hello: 'Privet',
      },
      inner: '${100}',
      product,
      partnerTx, //ticket.txData.tx.id
      srcCls: 'cash',
    },
  });

/* eslint-enable no-template-curly-in-string */

// const canselTx = txId =>
//   txId;//todo code it!
const getMobileOperator = ({ phoneNumber }) => fetch(`/merchant/form?phone=${phoneNumber}`, { noDefaultNotification: true });


export { startTx, applyTx, sendNotify, getMobileOperator };
