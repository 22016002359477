import { connect } from 'react-redux';
import { readMessage, getMessages, openConfirmLeaveModal, updatePageLoading } from '../actions/ui';
import { fetchUser } from '../actions/user';
import { storeComplexSalesGuid } from '../actions/oneC';
import App from './App';

const mapStateToProps = state => ({
  user: state.user,
  messages: state.ui.messages,
  pageLoading: state.ui.pageLoading,
  fetchingOverlayActive: state.ui.fetchingOverlayActive,
  fetchingOverlayMessage: state.ui.fetchingOverlayMessage,
  fetchingOverlayMessageShowTime: state.ui.fetchingOverlayMessageShowTime,
  fetchingOverlayMessageDefaultShown: state.ui.fetchingOverlayMessageDefaultShown,
  openModalBeforeLeave: state.ui.openModalBeforeLeave,
  complexSalesGuid: state.oneC.complexSalesGuid,
});

const mapDispatchToProps = dispatch => ({
  fetchUser: params => dispatch(fetchUser(params)),
  getMessages: params => dispatch(getMessages(params)),
  readMessage: params => dispatch(readMessage(params)),
  storeComplexSalesGuid: params => dispatch(storeComplexSalesGuid(params)),
  openConfirmLeaveModal: params => dispatch(openConfirmLeaveModal(params)),
  updatePageLoading: params => dispatch(updatePageLoading(params)),
});

const AppContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(App);

export default AppContainer;
