import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { CSSTransition } from 'react-transition-group';
import newid from '../../../utils/newid';


const MemedComponent = React.memo(function ErrorBody(props) {
  const { meta: { touched, error }, hint } = props;
  const label = (error && touched) || !hint ? error : hint;
  // console.log('ERROR FIRED');
  return (
    <CSSTransition
      timeout={300}
      in={(error && touched) || !!hint}
      classNames="ui-input-msg"
      unmountOnExit
    >
      <div className={classnames('ui-input-msg', { _info: hint && !(error && touched) })} dangerouslySetInnerHTML={{ __html: label }} />
    </CSSTransition>
  );
}, (pp, np) => {
  return pp.meta.touched === np.meta.touched && pp.meta.error === np.meta.error;
});

const ErrorTooltip = ({ name, randomKey, hint }) => (
  <Field
    name={name}
    key={randomKey}
    hint={hint}
    subscribe={{ touched: true, error: true }}
    component={MemedComponent}
  />
);

ErrorTooltip.propTypes = {
  name: PropTypes.string,
  randomKey: PropTypes.string,
  hint: PropTypes.string,
};

ErrorTooltip.defaultProps = {
  name: '',
  randomKey: newid(),
  hint: '',
};

export default ErrorTooltip;
