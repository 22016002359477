import React from 'react';
import { createAction, createAsyncAction } from 'typesafe-actions';
import { useSelector } from 'react-redux';

import newid from '../utils/newid';
import fetch from '../utils/fetch';
import { ISO_VALUE } from '../constants/currencies';
import notification from "antd/lib/notification";

export const getCartFromOneC = createAsyncAction(
  'GET_CART_REQUEST',
  'GET_CART_SUCCESS',
  'GET_CART_FAIL',
)();

export const getCartFromTeko = createAsyncAction(
  'GET_CART_FROM_TEKO_REQUEST',
  'GET_CART_FROM_TEKO_SUCCESS',
  'GET_CART_FROM_TEKO_FAIL',
)();

export const getCalcData = createAsyncAction(
  'GET_CALC_DATA_REQUEST',
  'GET_CALC_DATA_SUCCESS',
  'GET_CALC_DATA_FAIL',
)();

export const getSuggestedItems = createAsyncAction(
  'GET_SUGGESTED_ITEMS_REQUEST',
  'GET_SUGGESTED_ITEMS_SUCCESS',
  'GET_SUGGESTED_ITEMS_FAIL',
)();

export const addToCart = createAsyncAction(
  'ADD_TO_CART_REQUEST',
  'ADD_TO_CART_SUCCESS',
  'ADD_TO_CART_FAIL',
)();

export const removeFromCart = createAsyncAction(
  'REMOVE_FROM_CART_REQUEST',
  'REMOVE_FROM_CART_SUCCESS',
  'REMOVE_FROM_CART_FAIL',
)();

export const editCartItem = createAsyncAction(
  'EDIT_CART_ITEM_REQUEST',
  'EDIT_CART_ITEM_SUCCESS',
  'EDIT_CART_ITEM_FAIL',
)();

export const setCartItemStatus = createAsyncAction(
  'SET_CART_ITEM_STATUS_REQUEST',
  'SET_CART_ITEM_STATUS_SUCCESS',
  'SET_CART_ITEM_STATUS_FAIL',
)();

export function getCartFromTekoAsync(dataFrom) {
  return (dispatch, getState) => new Promise((resolve, reject) => {
    dispatch(getCartFromTeko.request());

    fetch('/cart/init', {
      method: 'POST',
      noDefaultNotification: true,
      withOverlay: true,
      body: {
        cart_id: window.sessionStorage.getItem('complexSalesGuid'),
        goods: getState().cartData.oneCItems.deviceList.map(item => ({...item })),
      },
    })
      .then((result) => {

        const unfilteredDeviceList = [...getState().cartData.oneCItems.deviceList];

        const alreadyExistedItems = [];
        result.result.cart.items.forEach((item) => {
          if (item.tx !== '') {
            result.result.cart.txs.forEach((txItem) => {
              if (txItem.id === item.tx) {
                alreadyExistedItems.push({ ...item, nomen: txItem.dst_extra.insuranceNomenKey });
              }
            });
          }
        });

        const modifiedCart = { ...result.result.cart};

        console.log('alreadyExistedItems ', alreadyExistedItems)



        const actualDeviceList = [];

        const alreadyAdded = [];

        unfilteredDeviceList.forEach((device, index) => {
          if(alreadyExistedItems.length === 0) {
            if (alreadyAdded.indexOf(device.group_id) === -1) {
              alreadyAdded.push(device.group_id)
              console.log('device.group_id === existedItem.nomen ', device.group_id)
              actualDeviceList.push(unfilteredDeviceList[index])
            }
          }
          alreadyExistedItems.forEach((existedItem) => {
            // if (device.group_id === existedItem.nomen) {

            if (unfilteredDeviceList.findIndex(d => d.group_id === existedItem.nomen) === -1 && alreadyAdded.indexOf(device.group_id) === -1  && alreadyAdded.indexOf(existedItem.nomen) === -1) {
              alreadyAdded.push(device.group_id);
              alreadyAdded.push(existedItem.nomen)

              actualDeviceList.push(unfilteredDeviceList[index])

              // unfilteredDeviceList.splice(index,1);
            } else {
              if (alreadyAdded.indexOf(device.group_id) === -1 && alreadyAdded.indexOf(existedItem.nomen) === -1) {
                alreadyAdded.push(device.group_id)
                // alert(existedItem.nomen)
                alreadyAdded.push(existedItem.nomen)
                console.log('device.group_id === existedItem.nomen ', device.group_id, existedItem.nomen)
                actualDeviceList.push(unfilteredDeviceList[index])
              } else {
                alreadyAdded.push(existedItem.nomen)
              }
            }
          })
        });

        console.log('!!! ', unfilteredDeviceList, modifiedCart.items)

        modifiedCart.items = modifiedCart.items.map((cItem) => {

          if (cItem.tx !== '') {

            const relativeTxIndex = modifiedCart.txs.findIndex(tx => tx.id === cItem.tx);

            const actualNomen = modifiedCart.txs[relativeTxIndex].dst_extra.insuranceNomenKey;

            console.log(' ааа ', unfilteredDeviceList, actualNomen);

            if (unfilteredDeviceList.findIndex(ei => ei.group_id === actualNomen) !== -1) {
              return { ...cItem, unableToDelete: true }
            } else {
              return cItem
            }

          } else {
            return cItem
          }
        })


        console.log(' unfilteredDeviceList ', unfilteredDeviceList);
        console.log(' alreadyAdded ', alreadyAdded);
        console.log(' modifiedCart ', modifiedCart);
        console.log(' actualDeviceList ', actualDeviceList);
        dispatch(getCartFromOneC.success(actualDeviceList)); //modified


        dispatch(getCartFromTeko.success(modifiedCart));
        resolve(modifiedCart);
      })
      .catch((e) => {
        console.log(e)
        reject();
      })
  });
}

function returnObjectFromDotNotation(objString, value) {
  if (objString.split('.')[1]) {
    return {
      [objString.split('.')[0]]: {
        [objString.split('.')[1]]: value,
      },
    };
  }
  return {
    [objString]: value,
  };
}

export function getCartFromOneCAsync(guid) {
  return (dispatch, getState) => new Promise((resolve) => {
    dispatch(getCartFromOneC.request());

    // setTimeout(() => {
    //   dispatch(getCartFromOneC.success([{
    //     good_id: '0100-123123',
    //     id: 1234123412341234,
    //     name: 'Телефон Нокиа 3310',
    //     price: 5000,
    //     count: 1,
    //   }, {
    //     good_id: '0100-123121',
    //     id: 2132131,
    //     name: 'Кшаоми',
    //     price: 5000,
    //     count: 1,
    //   },{
    //     good_id: '0100-123122',
    //     id: 21321321,
    //     name: 'Яблофон',
    //     price: 5000,
    //     count: 1,
    //   }]));
    //   resolve();
    // }, 1000);

    const driverUrl = localStorage.getItem('DRIVER_ADDRESS');
    const urlString = driverUrl ? `${driverUrl}/fr/InvokeFunction1C?func=rmkinfo` : `${process.env.DRIVER_ADDRESS}/fr/InvokeFunction1C?func=rmkinfo`;

    return fetch(urlString, {
      backendUrl: '',
      prefix: '',
    })
      .then((result) => {
        const items = result['Товары'];
        console.log('getState ', getState());
        const formattedItems = items.map(item => ({
          group_id: item['КодНоменклатуры'],
          id: item['IMEI']*1,
          name: item['Наименование'],
          price: item['Цена']*100,
          count: item['Количество']*1,
        }));


        dispatch(getCartFromOneC.success(formattedItems));
        resolve();
      })
      .catch(e => console.log(e))
  }).then(() => {
    return dispatch(getCartFromTekoAsync());
  })
}

export function addToCartAsync(object) {
  return (dispatch) => {
    const objToSend = {
      cart_id: window.sessionStorage.getItem('complexSalesGuid'),
      item: {
        ...object,
      },
    };
    return new Promise((resolve) => {
      dispatch(addToCart.request());
      fetch('/cart/item', {
        method: 'PUT',
        body: objToSend,
      })
        .then((result) => {
          dispatch(getCartFromOneCAsync());
          // resolve(dispatch(addToCart.success({ arrayOfItems: result.result.updated_cart.items, localId: newid() })));
        })
        .catch(console.log);
      // setTimeout(() => {
      //   console.log('123 ')
      // }, 1000);
    });
  };
}

export function removeFromCartAsync(object, tovarName) {
  return (dispatch) => {
    const objToSend = {
      cart_id: window.sessionStorage.getItem('complexSalesGuid'),
      item: {
        ...object
      },
    };
    return new Promise((resolve) => {
      dispatch(removeFromCart.request());
      fetch('/cart/item', {
        method: 'DELETE',
        body: objToSend,
        noDefaultNotification: true,
      })
        .then((result) => {
          // resolve(dispatch(removeFromCart.success({ arrayOfItems: result.result.updated_cart.items, txs: result.result.updated_cart.txs, localId: newid() })));
          dispatch(getCartFromOneCAsync());
        })
        .catch(() => {

          notification.open({
            duration: 5,
            description: React.createElement('div', { dangerouslySetInnerHTML: { __html: `В процессе оформления корзины возникла ошибка при удалении товара ${tovarName}. Вернитесь в 1С, чтобы удалить содержимое корзины и обратитесь в службу поддержки TEKO` } }),
          });

          dispatch(removeFromCart.failure());
        });
      // setTimeout(() => {
      //   console.log('123 ')
      // }, 1000);
    });
  };
  return dispatch => new Promise((resolve) => {
    dispatch(removeFromCart.request());

    setTimeout(() => {
      dispatch(removeFromCart.success(object));
    }, 1000);
  });
}

export function getSuggestedItemAsync(nomen) {
  return dispatch => new Promise((resolve) => {
    dispatch(getSuggestedItems.request());

    return fetch(`/recommendation/${sessionStorage.getItem('complexSalesGuid')}`)
      .then((result) => {
        const items = result.result.recommendations.reduce((acc, item) => {
          const {
            good,
            good: {
              group_id,
              id,
              // price,
            },
            items,
          } = item;

          // {
          //   cat = 'Неизвестная категория',
          //     id: product_id,
          //   name: productName,
          //   commissionFormData: calcFormData = {},
          // }

          const newItems = items.map((i) => {
            let extra = {};

            i.commissionFormData.forEach((data) => {
              switch (data.type) {
                case 'constant':
                  extra = { ...extra, ...returnObjectFromDotNotation(data.key, data.value) };
                  break;
                case 'dynamic':
                  extra = { ...extra, [data.key]: good[data.reference] };
                  break;
                default:
                  break;
              }
            });

            const actualCalcFormData = {
              amount: -1,
              currency: 643,
              extra,
              noDefaultNotification: true,
              good_id: i.id,
              src_cls: i.src_cls,
            };

            return {
              cat: i.cat || 'Неизвестная категория',
              id: i.id,
              name: i.name,
              good_id: id,
              src_cls: i.src_cls,
              calcFormData: actualCalcFormData,
              group_id,
              // price,
            };
          });

          // return {
          //   group_id,
          //   id,
          //   cat,
          //   product_id,
          //   productName,
          //   calcFormData,
          // };

          return [...acc, ...newItems];
        }, []);

        resolve(dispatch(getSuggestedItems.success(items)));
      });
    // setTimeout(() => {
    //   dispatch(getSuggestedItems.success([


    //   {
    //   nomen: 1234,
    //   cat: 'Платное ПО',
    //   product_id: 'digitalcare',
    //   productName: 'Digital care',
    //   // cost: 10000,
    //   calcFormData: {
    //     "product": "digitalcare",
    //     "amount": -1,
    //     "currency": 643,
    //     "src_cls": "cash",
    //     "extra": {
    //       "amount_type": "out",
    //       "tpe": {
    //         "name": "Сертификат \"Защита экрана\" Digital Care 3 мес",
    //         "value": "three_monthes"
    //       }
    //     },
    //     "noDefaultNotification": true
    //   },
    // },


    //   {
    //     nomen: 456789,
    //     cat: 'Страховки',
    //     product_id: 'alfas',
    //     productName: 'Страховка',
    //     // cost: 20000,
    //     calcFormData: {
    //       "product": "alfas",
    //       "amount": -1,
    //       "currency": 643,
    //       "src_cls": "cash",
    //       "extra": {
    //         "Period": {
    //           "value": "666",
    //           "name": "2 года"
    //         },
    //         "Cost": 1231300,
    //       },
    //       "noDefaultNotification": true,
    //     },
    //   }]));
    //   console.log('suggested Items set')
    //   resolve();
    // }, 1000);
  });
}

export function getSums() {
  return (dispatch, getState) => {
    console.log(1, getState());
    return new Promise((resolve) => {
      // const suggestedItemsList = useSelector((state) => state.cartData.suggestedItemsList);

      const arrayOfPromises = getState().cartData.suggestedItems.suggestedItemsList.map((item, index) => new Promise((res) => {
        fetch('/merchant/calc', {
          method: 'POST',
          body: { ...item.calcFormData, product: item.calcFormData.good_id, src_cls: 'cash' },
          withOverlay: false,
        }).then((result) => {
          dispatch(getCalcData.success({ calcResult: result, index }));
          res();
        })
          .catch(() => {
            res();
          });
      }));
      Promise.all(arrayOfPromises)
        .then(resolve);
    });
  };
}

export function updateCartData({coreObjectToOneC, additionalObjectToOneC, nomen, cat, product_id}) {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch(editCartItem.success({ coreObjectToOneC, additionalObjectToOneC, nomen, cat, product_id }))
      resolve();
    });
  };
}

export function setActiveCartItem(index, status) {
  return (dispatch) => {
    return dispatch(setCartItemStatus.success({ index, status }));
  }
}
