import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import classnames from 'classnames';
import InputMask from 'react-input-mask';
import detectCardType from '../../utils/detectCardType';
import Svg from './Svg';
import newid from '../../utils/newid';

export default class CardnumberInput extends React.PureComponent {
  static propTypes = {
    type: PropTypes.string,
    value: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    valid: PropTypes.bool,
    showValidity: PropTypes.bool,
    disabled: PropTypes.bool,
    message: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
  };

  static contextTypes = {
    formContext: PropTypes.object,
  };

  static defaultProps = {
    type: 'text',
    label: '',
    valid: undefined,
    showValidity: false,
    disabled: false,
    placeholder: '',
    message: '',
    value: '',
    onBlur: () => {},
    onFocus: () => {},
    onChange: () => {},
  };

  constructor(props) {
    super(props);

    this.id = newid();

    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onFocus = this.onFocus.bind(this);
  }

  // componentDidMount() {
  //   if (this.props.value && this.state.type === 'text') {
  //     this.setState({ type: 'date' });А
  //   }
  // }

  // static getDerivedStateFromProps(props, state) {
  //   if (props.value && state.type === 'text') {
  //     return { ...state, type: 'date' };
  //   }
  //   return null;
  // }

  onChange(e) {
    console.log(' e.target.value ',e.target.value)
    this.props.onChange(e.target.value);
  }

  onBlur(e) {
    this.props.onBlur(e);
  }

  onFocus(e) {
    this.props.onFocus(e);
  }

  render() {
    const {
      label, value, disabled, message, valid, showValidity,
    } = this.props;
    const className = classnames('ui-cardnumber-input', {
      disabled,
      filled: value && value.length,
      valid: showValidity && valid === true,
      invalid: showValidity && valid === false,
    });
    const messageShown = showValidity && !!message;
    const cardIcon = `${window.location.protocol}//${window.location.host}/assets/svg/card-types/${detectCardType(value) || 'undefined'}.svg`;

    return (
      <div className={className}>
        {!!label && <label className="input-label" htmlFor={this.id}>{label}</label>}
        <div className="input-with-icon">
          <InputMask
            id={this.id}
            placeholder="0000 0000 0000 0000"
            type="text"
            value={value}
            disabled={disabled}
            onChange={this.onChange}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            autoComplete="off"
            mask="9999 9999 9999 9999 999"
            maskChar={null}
          />
          <div className="input-icon">
            <Svg path={cardIcon} className="input-icon-svg" />
          </div>
        </div>
        <CSSTransition
          timeout={300}
          in={messageShown}
          classNames="ui-input-msg"
          unmountOnExit
        >
          <div className="ui-input-msg">{message}</div>
        </CSSTransition>
      </div>
    );
  }
}
