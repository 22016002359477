import React from 'react';
import PropTypes from 'prop-types';
import { FormContext } from './Form';
import MaskInput from './MaskInput';

export default class ValidateInput extends React.PureComponent {
  static propTypes = {
    label: PropTypes.string,
    placeholder: PropTypes.string,
    type: PropTypes.string,
    disabled: PropTypes.bool,
    autocomplete: PropTypes.bool,
    value: PropTypes.string,
    tabIndex: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    validator: PropTypes.func,
    mask: PropTypes.string,
    maskChar: PropTypes.string,
    blockBlur: PropTypes.bool,
    resetValidationOnFocus: PropTypes.bool,
    valid: PropTypes.bool,
  };

  static contextType = FormContext;

  static defaultProps = {
    label: '',
    disabled: false,
    type: undefined,
    mask: '',
    maskChar: '',
    value: '',
    valid: true,
    tabIndex: '0',
    blockBlur: false,
    autocomplete: false,
    placeholder: '',
    resetValidationOnFocus: true,
    validator: () => '',
    onBlur: () => {},
    onFocus: () => {},
    onChange: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      value: '',
      // message: '',
      // valid: true,
      touched: false,
    };
    this.input = null;
    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onFocus = this.onFocus.bind(this);
  }

  componentDidMount() {
    if (this.context) {
      this.context.onValidateInputAddedToForm(this);
    }
    const startVal = this.props.value !== null ? this.props.value : '';
    this.onChange(startVal);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { value } = nextProps;
    if (nextProps.value !== null && value !== this.state.value) {
      this.onChange(value);
    }
  }

  onChange(value) {
    if (this.props.resetValidationOnFocus && this.context && this.context.formSubmitted) {
      this.setState({ value });
      this.props.onChange(value);
    } else {
      const message = this.props.validator(value) || '';
      this.setState({
        value,
        // message,
        // valid: !message,
      });
      this.props.onChange(message ? null : value, value);
    }
  }

  onBlur(e) {
    if (this.props.resetValidationOnFocus && this.context && this.context.formSubmitted) {
      // const message = this.props.validator(this.state.value) || '';
      this.setState({
        // message,
        // valid: !message,
      });
    }
    this.props.onBlur(e);
    if (!this.props.blockBlur) {
      this.setState({
        touched: true,
      });
    }
  }

  onFocus(e) {
    if (this.props.resetValidationOnFocus && this.context && this.context.formSubmitted) {
      this.setState({
        // message: '',
        // valid: undefined,
      });
    }
    this.props.onFocus(e);
  }

  setErrorMessage() {
    this.setState({
      // message,
      // valid: false,
      touched: true,
    });
  }

  isValid = () => !this.props.validator(this.state.value);

  render() {
    // let valid = null;
    let message = '';
    if ((!this.context && this.state.touched) || (this.context && this.context.formSubmitted)) {
      // eslint-disable-next-line
      // valid = this.state.valid;
    }
    // valid = this.props.valid;
    if (this.props.valid === false) {
      // eslint-disable-next-line
      message = this.props.message;
    }
    // const InputComponent = this.props.mask ? MaskInput : Input;
    return (
      <MaskInput
        ref={ref => this.input = ref}
        type={this.props.type}
        autocomplete={this.props.autocomplete}
        disabled={this.props.disabled}
        label={this.props.label}
        placeholder={this.props.placeholder}
        value={this.state.value}
        tabIndex={this.props.tabIndex}
        message={message}
        valid={this.props.valid}
        onChange={this.onChange}
        onBlur={this.onBlur}
        onFocus={this.onFocus}
        mask={this.props.mask}
        maskChar={this.props.maskChar}
      />
    );
  }
}
