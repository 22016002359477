import React, { useContext, useEffect } from 'react';
import IframesContainer from './components/IframesContainer';
import { ThunderBotContext } from '../../components/App';

export default function IframesPage({ location, history, match }) {

  const tb = useContext(ThunderBotContext);

  return (
    <IframesContainer location={location} history={history} match={match} tb={tb} />
  );
}
