import moment from 'moment';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Tooltip } from 'antd';
import ReactTable from 'react-table';
import Button from '../../../components/ui/Button';
import Checkbox from '../../../components/ui/Checkbox';
import ClassicForm from '../../../components/ui/ClassicForm';
import ClassicValidateInput from '../../../components/ui/ClassicValidateInput';
import Modal from '../../../components/ui/Modal';
import { backBtnLabel } from '../../../constants/backBtnLabel';
import TxDataField from '../../../components/common/TxDataField';
import fetch from '../../../utils/fetch';
import { formatRawPhoneToMasked } from '../../../utils/format';

import Close from '../../../assets/svg/close-black.svg';
import Reset from '../../../assets/svg/reset.svg';

const colNameById = {
  phone: 'Номер телефона',
  birthday: 'Дата рождения',
  date: 'Дата',
};

const phoneRegexp = /^(\+7|7|8)?[\s-]?\(?[3489][0-9]{2}\)?[\s-]?[0-9]{3}[\s-]?[0-9]{2}[\s-]?[0-9]{2}$/;

export default class TemplatesBlock extends PureComponent {
  static propTypes = {
    productId: PropTypes.string,
    fieldGroups: PropTypes.array,
    selectedTx: PropTypes.object,
    onUpdateSelectedTx: PropTypes.func.isRequired,
    onApplyTemplate: PropTypes.func.isRequired,
    onResetTemplate: PropTypes.func.isRequired,
  };

  static defaultProps = {
    productId: '',
    fieldGroups: [],
    selectedTx: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      tooltipVisible: false,
      modalOpened: false,
      step: 0,
      tab: 'phone',
      phone: '',
      lastName: '',
      firstName: '',
      middleName: '',
      noMiddleName: false,
      transactions: [],
      selectedTx: props.selectedTx,
      templateApplied: !!props.selectedTx,
    };

    this.tooltipVisibleTimeout = 0;
  }

  componentDidMount() {
    this.tooltipVisibleTimeout = setTimeout(() => {
      this.setState({ tooltipVisible: true });
    }, 750);
  }

  componentWillUnmount() {
    this.props.onUpdateSelectedTx(this.state.selectedTx);
    clearTimeout(this.tooltipVisibleTimeout);
  }

  onTooltipVisibleChange = () => {
    this.setState(prevState => ({ tooltipVisible: !prevState.tooltipVisible }));
  };

  onUseTemplateClick = () => this.setState({ modalOpened: true });

  onModalClose = () => this.setState({ modalOpened: false });

  onPhoneChange = (phone) => {
    this.setState({ phone });
  };

  onFieldChange = (field, value) => this.setState({ [field]: value });

  onFullNameSubmit = () => {
    const { productId } = this.props;
    const { lastName, firstName, middleName } = this.state;

    fetch('/tx_template', {
      method: 'POST',
      body: { lastName, firstName, middleName, productId },
    }).then((response) => {
      this.setState({ step: 1, transactions: response.result });
    });
  };

  onPhoneSubmit = () => {
    const { productId } = this.props;
    const phone = this.state.phone.replace(/[\s-(+)]/g, '');

    if (phone[0] === '8') {
      phone.replaceAt(0, '7');
    }

    fetch('/tx_template', {
      method: 'POST',
      body: { phone, productId },
    }).then((response) => {
      this.setState({ step: 1, transactions: response.result });
    });
  };

  onPersonReset = () => {
    this.setState({
      step: 0,
      phone: '',
      lastName: '',
      firstName: '',
      middleName: '',
      noMiddleName: false,
      transactions: [],
      selectedTx: null,
      templateApplied: false,
    });
  };

  onBackClick = () => {
    this.setState(prevState => ({ step: prevState.step - 1, selectedTx: null }));
  };

  onNoMiddleNameChange = noMiddleName => this.setState({ noMiddleName, middleName: noMiddleName ? '-' : '' });

  onApplyTemplate = () => {
    const { selectedTx } = this.state;
    this.setState({ templateApplied: true, modalOpened: false }, () => {
      this.props.onApplyTemplate({ loyaltyCode: selectedTx.tekoCustomerId, dst: selectedTx.dst });
    });
  };

  onResetTemplate = () => {
    this.onPersonReset();
    this.props.onResetTemplate();
  };

  requiredValidator = (value) => {
    if (!value) return 'Поле обязательно';
    return '';
  };

  phoneValidator = (value) => {
    if (!value) return 'Поле обязательно';
    if (!phoneRegexp.test(value)) return 'Невалидный номер';
    return '';
  };

  normalizeTransactions = (transactions) => {
    return transactions.map((transaction) => {
      const {
        id, tekoCustomerId, date: rawDate, dst,
        dst: { id_phone_number, id_name, id_lastname, id_patronymic, id_birthday },
      } = transaction;
      // const phone = formatRawPhoneToMasked(id_phone_number);
      const date = moment(rawDate).format('DD.MM.YYYY HH:mm');
      // const birthday = moment(id_birthday).format('DD.MM.YYYY');
      const txData = { id, tekoCustomerId, date: rawDate, dst };
      // const fullName = `${id_lastname} ${id_name} ${id_patronymic}`;

      // return { phone, birthday, date, txData };
      return { date, txData };
    });
  };

  defaultHeaderRender = row => (
    <div className={classnames('rt-header-item', { 'rt-header-sortable': row.column.sortable !== false })}>
      <span>{colNameById[row.column.id]}</span>
    </div>
  );

  defaultCellRender = row => row.value;

  getTdProps = (state, rowInfo, column, instance) => ({
    onClick: (e, handleOriginal) => {
      // IMPORTANT! React-Table uses onClick internally to trigger
      // events like expanding SubComponents and pivots.
      // By default a custom 'onClick' handler will override this functionality.
      // If you want to fire the original onClick handler, call the
      // 'handleOriginal' function.
      this.setState({ step: 2, selectedTx: rowInfo.original.txData });
    },
  });

  render() {
    const {
      tooltipVisible, modalOpened, step, tab, phone, lastName, firstName, middleName, noMiddleName,
      transactions, selectedTx, templateApplied,
    } = this.state;
    const { fieldGroups } = this.props;
    const normalizedTransactions = this.normalizeTransactions(transactions);
    let templateInput = 'Шаблон не выбран';

    if (selectedTx && templateApplied) {
      const { dst: { id_phone_number = '', id_lastname = '', id_name = '', id_patronymic = '' } } = selectedTx;
      const maskedPhone = formatRawPhoneToMasked(id_phone_number);
      const patronymic = id_patronymic.length > 0 && id_patronymic !== '-' ? id_patronymic : '';
      templateInput = `${maskedPhone}, ${id_lastname} ${id_name} ${patronymic}`;
    }

    return (
      <React.Fragment>
        <div className={classnames('templates-block', { 'template-applied': templateApplied })}>
          <div className="form-item">
            <div className="template-input-label input-label">Выберите шаблон операции (не обязательно)</div>
            <div className="template-input">
              <span className="input-text">{templateInput}</span>
              {templateApplied && (
                <span className="reset-btn" onClick={this.onResetTemplate}>
                  <Reset />
                </span>
              )}
            </div>
          </div>
          {!templateApplied && (
            <div className="form-item">
              <Tooltip visible={tooltipVisible} onVisibleChange={this.onTooltipVisibleChange} title="Сэкономьте время с шаблонами" overlayClassName="ui-tooltip templates-tooltip" align={{ offset: [0, -7] }}>
                <div className="btn-wrap">
                  <Button label="Использовать шаблон" color={Button.Color.white} onClick={this.onUseTemplateClick} />
                </div>
              </Tooltip>
            </div>
          )}
        </div>
        <Modal
          backdrop
          active={modalOpened}
          onClickOutside={this.onModalClose}
          transitionEnterTimeout={300}
          transitionLeaveTimeout={300}
          transitionName="ui-modal"
          className="templates-modal"
        >
          <div className="modal-close-btn" onClick={this.onModalClose}>
            <Close />
          </div>
          <div className="modal-header">
            {step === 0 && (
              <React.Fragment>
                <span>Поиск по операциям</span>
                <div className="templates-hint">Если клиент уже совершал операцию по данному платежу за последнюю
                  неделю, воспользуйтесь поиском для упрощения ввода данных.</div>
              </React.Fragment>
            )}
            {step > 0 && (
              <React.Fragment>
                <span>
                  {step === 1 && 'Выберите операцию данного шаблона'}
                  {step === 2 && 'Примените шаблон'}
                </span>
                <div className="chosen-person">
                  <span className="chosen-person-text">{tab === 'phone' ? phone : `${lastName} ${firstName} ${noMiddleName ? '' : middleName}`}</span>
                  <div className="chosen-person-reset" onClick={this.onPersonReset}>
                    <Reset />
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>
          <div className={classnames('modal-body', `modal-body-step-${step + 1}`)} key={step}>
            {step === 0 && (
              <div className="first-step">
                {false && (
                  <div className="tabs-menu">
                    <div className={classnames('tabs-menu-item', { active: tab === 'phone' })} onClick={() => this.setState({ tab: 'phone' })}>По номеру телефона</div>
                    <div className={classnames('tabs-menu-item', { active: tab === 'fullName' })} onClick={() => this.setState({ tab: 'fullName' })}>По ФИО</div>
                  </div>)
                }
                <div className="tabs-content">
                  {tab === 'fullName' && (
                    <ClassicForm onSubmit={this.onFullNameSubmit} focusFirstInput id="fullName-template-search-form">
                      <ClassicValidateInput key="lastName" label="Фамилия отправителя" onChange={value => this.onFieldChange('lastName', value)} value={lastName} validator={this.requiredValidator} />
                      <ClassicValidateInput key="firstName" label="Имя отправителя" onChange={value => this.onFieldChange('firstName', value)} value={firstName} validator={this.requiredValidator} />
                      <div className="form-group middle-name-group">
                        <ClassicValidateInput key="middleName" label="Отчество отправителя" onChange={value => this.onFieldChange('middleName', value)} value={middleName} validator={this.requiredValidator} disabled={noMiddleName} />
                        <Checkbox value={noMiddleName} label="Отчество отсутствует" onChange={this.onNoMiddleNameChange} />
                      </div>
                    </ClassicForm>
                  )}
                  {tab === 'phone' && (
                    <ClassicForm onSubmit={this.onPhoneSubmit} focusFirstInput id="phone-template-search-form">
                      <ClassicValidateInput label="Номер телефона" onChange={this.onPhoneChange} value={phone} validator={this.phoneValidator} mask="+7 (999) 999-99-99" maskChar={null} />
                    </ClassicForm>
                  )}
                </div>
              </div>
            )}
            {step === 1 && (
              <div className="second-step">
                {!normalizedTransactions.length && (
                  <div className="nothing-found">По вашему запросу не найдена ни одна операция. Измените запрос и попробуйте снова</div>
                )}
                {!!normalizedTransactions.length && (
                  <ReactTable
                    data={normalizedTransactions}
                    columns={[
                      {
                        Header: this.defaultHeaderRender,
                        accessor: 'birthday',
                        headerClassName: 'id-col',
                        className: 'id-col',
                        width: 340,
                        resizable: false,
                        Cell: this.defaultCellRender,
                      },
                      {
                        Header: this.defaultHeaderRender,
                        accessor: 'date',
                        headerClassName: 'date-col',
                        className: 'date-col',
                        width: 180,
                        resizable: false,
                        Cell: this.defaultCellRender,
                      },
                    ]}
                    style={{ maxHeight: '446px' }}
                    showPagination={false}
                    minRows={0}
                    getTdProps={this.getTdProps}
                  />
                )}
              </div>
            )}
            {step === 2 && (
              <div className="third-step">
                {fieldGroups.map((group) => {
                  const filledFields = group.fields.filter(field => selectedTx.dst[field.field_name]);

                  return filledFields.length === 0 ? null : (
                    <div key={group.name + group.id} className="fields-group">
                      <div className="fields-group-title" dangerouslySetInnerHTML={{ __html: group.name }} />
                      {filledFields.map(field => (
                        <TxDataField
                          key={group.name + field.field_name}
                          field={field}
                          value={selectedTx.dst[field.field_name]}
                          amountCurrency={selectedTx.dst.currency}
                        />
                      ))}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          <div className={classnames('modal-footer', { 'with-scroll-fading': step === 2 })}>
            {step === 0 && (
              <React.Fragment>
                <Button label="Отменить" color={Button.Color.white} onClick={this.onModalClose} />
                <Button label="Найти" color={Button.Color.blue} type="submit" form={`${tab}-template-search-form`} />
              </React.Fragment>
            )}
            {step > 0 && (
              <Button appearance={Button.Appearance.link} label={backBtnLabel} onClick={this.onBackClick} />
            )}
            {step === 1 && (
              <Button label="Отменить" color={Button.Color.white} onClick={this.onPersonReset} />
            )}
            {step === 2 && (
              <Button label="Применить шаблон" color={Button.Color.blue} onClick={this.onApplyTemplate} />
            )}
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}
