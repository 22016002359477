import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import FinalFormPropsWrapper from './FinalFormPropsWrapper';
import FileInput from '../../../../components/ui/FileInput';
import Condition from './Condition';

export default class FileInputFinalFieldWrap extends React.PureComponent {
  static propTypes = {
    item: PropTypes.object.isRequired,
    visible: PropTypes.bool,
    disabled: PropTypes.bool,
    validation: PropTypes.func,
  };

  static defaultProps = {
    visible: false,
    disabled: false,
    validation: () => {},
  };

  customValidation = (value) => {
    if (!value || !value.length) return 'Поле обязательно для заполнения';
    return this.props.validation(value, this.props.item);
  };

  render() {
    const { item, disabled, visible, values } = this.props;

    return (
      <Condition visible={visible}>
        <Field
          type="file"
          name={item.field_name}
          validate={this.customValidation}
          placeholder={item.placeholder}
          format={value => value}
        >
          {FinalFormPropsWrapper(FileInput, { item, ...item.fileInputConfig, disabled, values })}
        </Field>
      </Condition>
    );
  }
}
