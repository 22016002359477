import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';

export default class ConditionalTooltip extends React.PureComponent {
  static propTypes = {
    hint: PropTypes.string,
    children: PropTypes.node,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
  };

  static defaultProps = {
    hint: '',
    children: null,
    onFocus: () => {},
    onBlur: () => {},
  };

  constructor(props) {
    super(props);

    this.state = {
      tooltipVisible: false,
    };
  }

  onFocus = () => {
    this.setState({ tooltipVisible: true });
    this.props.onFocus();
  };

  onBlur = () => {
    this.setState({ tooltipVisible: false });
    this.props.onBlur();
  };

  render() {
    const { hint, children } = this.props;
    const { tooltipVisible } = this.state;

    if (hint) {
      return (
        <Tooltip visible={tooltipVisible} onVisibleChange={this.onTooltipVisibleChange} title={hint} overlayClassName="ui-tooltip templates-tooltip" align={{ offset: [0, -14] }}>
          {React.cloneElement(children, { onFocus: this.onFocus, onBlur: this.onBlur })}
        </Tooltip>
      );
    }

    return children;
  }
}
