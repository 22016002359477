import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

class Svg extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    path: PropTypes.string,
  };

  static defaultProps = {
    className: undefined,
    path: undefined,
  };

  state = {
    svg: null,
    innerHTML: { __html: '' },
  };

  componentDidMount() {
    this.load();
    this._isMounted = true;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.path !== this.props.path) {
      this.load();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  load = () => {
    const { path } = this.props;

    if (!path) {
      return;
    }

    const protocol = /^https?:\/\//i;
    const url = protocol.test(path) || path.startsWith('/') ? path : `/${path}`;

    fetch(url)
      .then(resp => resp.text())
      .then(this.onLoad)
      .catch(this.onError);
  };

  onLoad = (text) => {
    if (!this._isMounted) return;

    const parser = new DOMParser();
    const document = parser.parseFromString(text, 'image/svg+xml');
    const node = document.childNodes[0];

    if (!node || !node.tagName || node.tagName.toLowerCase() !== 'svg') {
      throw new Error(`[Svg] SVG parsing error: ${this.props.path} :: ${text}`);
    }

    const html = node.innerHTML || getSvgContent(node);

    this.setState({
      svg: node,
      innerHTML: { __html: html },
    });
  };

  onError = e => console.error(e);

  render() {
    const { className } = this.props;
    const { svg, innerHTML } = this.state;

    if (!svg) {
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="0" height="0" viewBox="0 0 0 0" className={className} />
      );
    }

    const attributes = Array.from(svg.attributes);
    const props = attributes.reduce((result, attr) => {
      const { name, value } = attr;

      if (name === 'class' || name.includes(':')) {
        return result;
      }

      return Object.assign(result, ({
        [name]: value,
      }));
    }, {});

    return (
      <svg
        className={classnames(className, svg.getAttribute('class'))}
        dangerouslySetInnerHTML={innerHTML}
        {...props}
      />
    );
  }
}

function getSvgContent(svg) {
  const serializer = new XMLSerializer();

  return Array.from(svg.childNodes).map(node => serializer.serializeToString(node)).join('');
}

export default Svg;
