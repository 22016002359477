// import '@babel/polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';
import Root from './components/Root';

import 'moment/locale/ru';

import 'simplebar/dist/simplebar.min.css';
import 'react-table/react-table.css';
import './styles/index.scss';

moment.locale('ru');

// eslint-disable-next-line
String.prototype.capitalize = function() {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

(function(ELEMENT) {
  ELEMENT.matches = ELEMENT.matches || ELEMENT.mozMatchesSelector || ELEMENT.msMatchesSelector || ELEMENT.oMatchesSelector || ELEMENT.webkitMatchesSelector;
  ELEMENT.closest = ELEMENT.closest || function closest(selector) {
    if (!this) return null;
    if (this.matches(selector)) return this;
    if (!this.parentElement) {return null}
    else return this.parentElement.closest(selector)
  };
}(Element.prototype));

if (window.navigator.userAgent.indexOf('Mac') > 0) {
  const elemHTML = window.document.getElementsByTagName('html')[0];

  elemHTML.className += ' mac-os';

  const ua = window.navigator.userAgent;
  if ((ua.indexOf('Safari') > 0) && (ua.indexOf('Chrome') < 0)) elemHTML.className += ' mac-safari';
  if (ua.indexOf('Chrome') > 0) elemHTML.className += ' mac-chrome';
}

console.log('Boxretail v.0.0.151');

ReactDOM.render(<Root />, window.document.getElementById('root'));
