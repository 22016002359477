import fetch from './fetch';

export function downloadFile(path, name, withPrefix = true) {
  // const isChrome = navigator.userAgent.toLowerCase().indexOf('chrome') > -1;
  // const isSafari = navigator.userAgent.toLowerCase().indexOf('safari') > -1;
  const apiHost = process.env.BACKEND_URL;
  const apiPrefix = '/api';
  const uri = withPrefix ? (apiHost + apiPrefix + path) : path;

  //todo probably rewrite it in iframe methods
  // if (isChrome || isSafari) {
  //   const link = document.createElement('a');
  //   link.download = name;
  //   link.href = uri;
  //   // link.target = '_blank';
  //   link.click();
  // } else {
  window.open(uri);
  // }
}

export const downloadFiles = ({ id, txId, formData = {}, step = 'precheque' }) => fetch(`/attachments/${id}`, {
  method: 'POST',
  body: {
    ...formData,
    step,
    txId,
  },
})
  .then((links) => {
    links.result.forEach((link) => {
      downloadFile(`/attachments/${link}`, link);
    });

    return links;
  });
export const blank = () => {};
