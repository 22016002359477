import React from 'react';
import CatalogFormFillContainer from './components/CatalogFormFillContainer';

import './styles/catalog-form-fill.scss';

export default function CatalogFormFillPage({ location, history, match }) {
  return (
    <CatalogFormFillContainer location={location} history={history} match={match} />
  );
}
