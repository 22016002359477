import React from 'react';
import { CSSTransition } from 'react-transition-group';
import Modal from './Modal';
import Button from './Button';
import { connect } from 'react-redux';
import fetch from '../../utils/fetch';

import { loadTransaction } from '../../actions/transaction';
import AnimatedAlert from './AnimatedAlert';
import ClassicForm from './ClassicForm';



class SmartprotectStatusCheck extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      certId: '',
      comment: '',
      status: '',
      loading: false,
      showButtons: false,
      tx: {},
      error: '',
    };
  }

  applyTx = () => {
    const { history, lt } = this.props;
    const { tx } = this.state;


    lt({
      dst_extras: tx,
    });
    history.push('/form/smart_protect_check');
  }

  fetchDataFromBackend = (e) => {
    e.preventDefault();
    this.setState({ loading: true });

    fetch(`/smart_protect_check/${this.state.certId}`, { noDefaultNotification: true })
      .then((data) => {

        const statusMapper = {
          send: 'Отправлен запрос на обмен',
          approved: 'Обмен разрешен',
          rejected: 'Обмен отклонен',
          returned: 'Обмен возвращен для уточнения информации.',
          completed: 'Обмен разрешен',
          payed: 'Сертификат закрыт',
        };

        const showButtons = data.result.state === 'returned';


        this.setState({
          comment: data.result.text_return,
          status: statusMapper[data.result.state],
          loading: false,
          showButtons,
          tx: data.result.tx,
          error: '',
        });
      })
      .catch(error => {
        this.setState({
          loading: false,
          comment: '',
          status: '',
          showButtons: false,
          tx: {},
          error: 'Ошибка при обработке запроса, попробуйте позже',
        })
      })
    // setTimeout(() => {
    //   this.setState({
    //     comment: 'Где бекенд Алексей?????',
    //     status: 'Очень плохо',
    //     loading: false,
    //   })
    // }, 2000)
  }

  render() {

    const { modalOpened, switchModal } = this.props;
    const { error } = this.state;

    return (
      <Modal
        backdrop
        active={modalOpened}
        onClickOutside={() => switchModal()}
        contentClassName="ui-modals-wide"
        transitionEnterTimeout={300}
        transitionLeaveTimeout={300}
        transitionName="ui-modal"
      >
        <div className="modal-header">Уточнение статуса заявки</div>
        <div className="modal-body">Укажите номер сертификата для проверки статуса заявки</div>
        <div className="ui-input rutoken-modal-input" id="rutoken-modal-input">
          <form
            onSubmit={this.fetchDataFromBackend}
          >
            <label htmlFor="rutoken-modal-input">Номер сертификата</label>
            <input type="text" onChange={(e) => this.setState({ certId: e.target.value, error: '' })} value={this.state.certId} />
            <CSSTransition
              timeout={300}
              in={false}
              classNames="ui-input-msg"
              unmountOnExit
            >
              <div className="ui-input-msg" dangerouslySetInnerHTML={{ __html: 'Длина пинкода не менее 8-ми символов' }} />
            </CSSTransition>
          </form>
        </div>
        {this.state.status !== '' && (
          <>
            <div className="fields-group-item">
              <label className="tx-data-field-label">
                Статус заявки:
              </label>
              <span className="tx-data-field-value">
                {this.state.status}
              </span>
            </div>
            {this.state.comment !== '' && (
            <div className="fields-group-item">
              <label className="tx-data-field-label">
                Комментарий по заявке:
              </label>
              <span className="tx-data-field-value">
                {this.state.comment}
              </span>
            </div>
            )}
          </>
        )}
        <div style={{ paddingTop: '16px' }}>

        </div>
        <AnimatedAlert in={error !== ''} color={AnimatedAlert.Color.red}>
          {error}
        </AnimatedAlert>
        <div className="modal-footer rutoken-footer" style={{ paddingTop: '10px'}}>
          {(this.state.showButtons) && (
            <Button label="Повторить заявку" loading={this.state.loading} color={Button.Color.blue} onClick={this.applyTx} />
          )}
          <Button label="Подтвердить" loading={this.state.loading} color={Button.Color.blue} onClick={this.fetchDataFromBackend} />
        </div>
      </Modal>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  lt: params => dispatch(loadTransaction(params)),
})

export default connect(null, mapDispatchToProps)(SmartprotectStatusCheck);
