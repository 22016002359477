import React from 'react';
import ModalBlockErrorArrow from '../../assets/svg/modal-block-error-arrow.svg';

export default function AllowPopupsOverlay() {
  return (
    <div className="ui-allow-popups-overlay">
      <ModalBlockErrorArrow className="allow-popups-arrow" />
      <div className="content-header">
        Для продолжения работы в&nbsp;BoxRetail, необходимо разрешить всплывающие окна в браузере Google Chrome
        <ol className="tips-list">
          <li className="tips-list-item">Откройте новое окно в браузере Google Chrome.</li>
          <li className="tips-list-item">Перейдите в "Настройки браузера", в нижней части страницы выберите "Дополнительные".</li>
          <li className="tips-list-item">В разделе "Конфиденциальность и безопасность" выберите "Настройки сайта".</li>
          <li className="tips-list-item">Нажмите "Всплывающие окна и переадресация", установите переключатель в положение "Разрешено".</li>
        </ol>
      </div>
    </div>
  );
}
