import React from 'react';
import PropTypes from 'prop-types';
import * as classnames from 'classnames';
import accept from 'attr-accept';
import DefaultFileIcon from '../../assets/svg/file-icons/other.svg';
import DocFileIcon from '../../assets/svg/file-icons/doc.svg';
import ImageFileIcon from '../../assets/svg/file-icons/image.svg';
import PdfFileIcon from '../../assets/svg/file-icons/pdf.svg';
import PptFileIcon from '../../assets/svg/file-icons/ppt.svg';
import ExcelFileIcon from '../../assets/svg/file-icons/xls.svg';
import RemoveIcon from '../../assets/svg/close.svg';
import { formatFileSize } from '../../utils/format';

class FilesListItem extends React.Component {
  static propTypes = {
    file: PropTypes.object.isRequired,
    onFileRemove: PropTypes.func.isRequired,
    onTryAgainClick: PropTypes.func.isRequired,
    onFileStatusChanged: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.progressBar = React.createRef();
  }

  componentDidMount() {
    const { file, onFileStatusChanged } = this.props;

    if (!file.fileLoading) {
      if (this.progressBar && this.progressBar.current) {
        this.progressBar.current.style.width = '100%';
      }
      return;
    }

    // eslint-disable-next-line
    if (file instanceof File) {
      // eslint-disable-next-line
      this.xhr = new XMLHttpRequest();

      this.xhr.upload.onprogress = (event) => {
        this.progressBar.current.style.width = `${100 * (event.loaded / event.total)}%`;
      };

      // eslint-disable-next-line
      this.xhr.onload = this.xhr.onerror = function (e) {
        if (this.status === 200) {
          try {
            const responseJson = JSON.parse(this.responseText);
            onFileStatusChanged(file, 'fileId', responseJson.result.fileName);
          } catch (e) {
            console.log('Error in json: ', e, this.responseText);
          }
        } else {
          onFileStatusChanged(file, 'fileUploadError', true);
        }
        onFileStatusChanged(file, 'fileLoading', false);
      };

      this.xhr.open('POST', `${process.env.BACKEND_URL}/api/upload`, true);

      const token = window.localStorage.getItem('access_token') || '';

      this.xhr.setRequestHeader('x-auth-token', token);

      // eslint-disable-next-line
      const formData = new FormData();
      formData.append('file', file);

      this.xhr.send(formData);
    } else {
      onFileStatusChanged(file, 'fileLoading', false);
    }
  }

  // onFileListItemClick = () => {
  //   const { file } = this.props;
  //
  //   if (file.link) {
  //     window.open(file.link);
  //   }
  // };

  onActionClick = (e, state) => {
    e.stopPropagation();
    this.props.onFileRemove(this.props.file);
    if (state === 'loading') {
      this.xhr.abort();
    }
  };

  onTryAgainClick = (e) => {
    e.stopPropagation();
    this.props.onTryAgainClick(this.props.file);
  };

  getLabel = (state) => {
    const { file } = this.props;

    switch (state) {
      case 'loading':
        return (
          <div className="file-item-progress-bar">
            <div className="upload-indicator" ref={this.progressBar} />
          </div>
        );
      case 'attached':
        return (
          <div key={file.name} className="file-item-info-size" dangerouslySetInnerHTML={{ __html: formatFileSize(file.size) }} />
        );
      case 'upload-error':
        return (
          <div key={file.name} className="file-item-info-error">
            Ошибка загрузки
          </div>
        );
      case 'size-error':
        return (
          <div key={file.name} className="file-item-info-error">
            Недопустимый размер файла
          </div>
        );
      case 'type-error':
        return (
          <div key={file.name} className="file-item-info-error">
            Недопустимый формат файла
          </div>
        );
      default:
        return null;
    }
  };

  getFileIcon = (file) => {
    // const dotIndex = fileName.lastIndexOf('.');
    // fileName.substr(dotIndex + 1).toLowerCase()

    const isImage = accept(file, 'image/*');
    const isDoc = accept(file, '.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document');
    const isExcel = accept(file, '.xls,.xlsx,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    const isPpt = accept(file, '.ppt,.pptx,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.slideshow');
    const isPdf = accept(file, '.pdf,application/pdf');

    if (isImage) return <ImageFileIcon />;
    if (isDoc) return <DocFileIcon />;
    if (isExcel) return <ExcelFileIcon />;
    if (isPpt) return <PptFileIcon />;
    if (isPdf) return <PdfFileIcon />;

    return <DefaultFileIcon />;
  };

  render() {
    const { file } = this.props;
    const { fileLoading, fileSizeError, fileTypeError, fileUploadError } = file;
    const error = fileSizeError || fileTypeError || fileUploadError;
    const loading = fileLoading;
    const uploaded = !!file.link;
    const attached = uploaded || (!fileLoading && !error);
    const initial = !error && !loading && !attached;
    const state = (fileSizeError && 'size-error') || (fileTypeError && 'type-error') || (fileUploadError && 'upload-error') || (loading && 'loading') || (attached && 'attached') || 'init';
    const classname = classnames('file-list-item', {
      initial,
      loading,
      uploaded,
      attached,
      error,
    });

    return (
      <li className={classname}>
        <div className="file-list-item-container">
          <div className="file-item-icon-wrapper">
            {this.getFileIcon({ name: file.name, type: file.type })}
          </div>
          <div className="file-item-info-wrapper">
            <div className="file-item-info-title">
              {file.name}
            </div>
            <div className="file-item-info-data">
              {this.getLabel(state)}
            </div>
          </div>
          <div className="file-item-action-wrapper">
            {fileUploadError && (
              <div className="file-item-try-again-btn" onClick={this.onTryAgainClick}>
                Повторить
              </div>
            )}
            <span className="remove-ico" onClick={e => this.onActionClick(e, state)}>
              <RemoveIcon />
            </span>
          </div>
        </div>
      </li>
    );
  }
}

export default FilesListItem;
