import { ADD_MARKETPLACE_DATA, REMOVE_MARKETPLACE_DATA } from '../constants/actionTypes';

const initialState = {};

export default function t2marketplace(state = initialState, action) {
  switch (action.type) {
    case ADD_MARKETPLACE_DATA:
      return {
        ...state,
        ...action.payload,
      };

    case REMOVE_MARKETPLACE_DATA:
      return {
        ...initialState,
      };

    default:
      return { ...state };
  }
}
