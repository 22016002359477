import { connect } from 'react-redux';
import { getCats } from '../../../actions/catalog';
import {
  closeConfirmLeaveModal,
  openConfirmLeaveModal,
  updateOpenModalBeforeLeave,
  updatePageLoading,
} from '../../../actions/ui';
import { loadTransaction, removeTransaction } from '../../../actions/transaction';
import CatalogFormFill from './CatalogFormFill';
import { removeFormAndFormData } from '../../../actions/externalForm';
import { removeMarketplaceData } from '../../../actions/t2marketplace';
import { getCartFromTekoAsync, setActiveCartItem, updateCartData } from '../../../actions/cart';

const mapStateToProps = state => ({
  catalog: state.catalog,
  ui: state.ui,
  transaction: state.transaction,
  pageLoading: state.ui.pageLoading,
  externalForm: state.externalForm,
  t2marketplace: state.t2marketplace,
  user: state.user,
  tekoCart: state.cartData.tekoCart,
  deviceList: state.cartData.oneCItems.deviceList,
});

const mapDispatchToProps = {
  openConfirmLeaveModal,
  closeConfirmLeaveModal,
  loadTransaction,
  removeTransaction,
  updateOpenModalBeforeLeave,
  updatePageLoading,
  removeFormAndFormData,
  removeMarketplaceData,
  getCats,
  updateCartData,
  setActiveCartItem,
  getCartFromTekoAsync,
};

const CatalogFormFillContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CatalogFormFill);

export default CatalogFormFillContainer;
