import React from 'react';
import classnames from 'classnames';

import InProgress from '../../assets/svg/tx-statuses/inprocess.svg';
import InProgressInverted from '../../assets/svg/tx-statuses/inprocess-inverted.svg';
import Error from '../../assets/svg/tx-statuses/error.svg';
import ErrorInverted from '../../assets/svg/tx-statuses/error-inverted.svg';
import Cancel from '../../assets/svg/tx-statuses/cancel.svg';
import CancelInverted from '../../assets/svg/tx-statuses/cancel-inverted.svg';
import Redirect from '../../assets/svg/tx-statuses/redirect.svg';
import RedirectInverted from '../../assets/svg/tx-statuses/redirect-inverted.svg';
import Success from '../../assets/svg/tx-statuses/success.svg';
import SuccessInverted from '../../assets/svg/tx-statuses/success-inverted.svg';

export default function StatusIcon({ status, inverted }) {
  switch (status) {
    case 'inProgress':
      return inverted ? <InProgressInverted className="status-icon" /> : <InProgress className="status-icon" />;
    case 'ok':
    case 'success':
      return inverted ? <SuccessInverted className="status-icon" /> : <Success className="status-icon" />;
    case 'failed':
      return inverted ? <ErrorInverted className="status-icon" /> : <Error className="status-icon" />;
    case 'redirect':
      return inverted ? <RedirectInverted className="status-icon" /> : <Redirect className="status-icon" />;
    case 'rollback':
      return inverted ? <CancelInverted className="status-icon" /> : <Cancel className="status-icon" />;
    case 'loading':
      return <div className={classnames('status-icon status-loading-icon', { inverted })} />;
    default:
      console.warn(`[Status] Unknown status: ${status}`);
      return null;
  }
}
