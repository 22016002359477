import React from 'react';
import notification from 'antd/lib/notification';
import fetch from './fetch';

export default function closeWindow(windowName) {
  // http://localhost:10000/fr/CloseBrowser?WindowName=BoxRetail
  const driverUrl = window.localStorage.getItem('DRIVER_ADDRESS');

  const urlString = driverUrl ? `${driverUrl}/fr/CloseBrowser?WindowName=${windowName}` : `${process.env.DRIVER_ADDRESS}/fr/CloseBrowser?WindowName=${windowName}`;

  return fetch(urlString, {
    method: 'GET',
    notJson: true,
    noDefaultNotification: true,
    backendUrl: '',
    prefix: '',
  }).catch(() => {
    setTimeout(() => {
      notification.open({
        duration: 5,
        message: React.createElement('div', null, 'Ошибка закрытия страницы'),
        description: React.createElement('div', { dangerouslySetInnerHTML: { __html: 'Закройте данное окно самостоятельно' } }),
      });
    }, 1000); // don't know if it's needed
  });
}
