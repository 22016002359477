import React from 'react';
import { hot } from 'react-hot-loader';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { LocaleProvider } from 'antd';
import AppContainer from './AppContainer';
import WebFontLoader from './WebFontLoader';
import store from '../store/store';
import ru_RU from 'antd/lib/locale-provider/ru_RU';
ru_RU.Pagination.items_per_page = ' на странице';

const fontConfig = {
  google: {
    families: ['Rubik:400,500:latin,cyrillic'],
  },
};

function Root() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <LocaleProvider locale={ru_RU}>
          <WebFontLoader config={fontConfig}>
            <Switch>
              <Route path="/" component={AppContainer} />
            </Switch>
          </WebFontLoader>
        </LocaleProvider>
      </BrowserRouter>
    </Provider>
  );
}

export default hot(module)(Root);
