import { connect } from 'react-redux';
import { updatePageLoading } from '../../../actions/ui';
import { addMarketplaceData, removeMarketplaceData } from '../../../actions/t2marketplace';
import SmsAuth from './SmsAuth';

const mapDispatchToProps = {
  updatePageLoading,
  addMarketplaceData,
  removeMarketplaceData,
};

const mapStateToProps = state => ({
  user: state.user,
});

const SmsAuthContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SmsAuth);

export default SmsAuthContainer;
