import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';

import * as reducers from '../reducers';

const rootReducer = combineReducers({ ...reducers });
const composeEnhancers = (typeof window !== 'undefined' && process.env.NODE_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const store = createStore(rootReducer, composeEnhancers(
  applyMiddleware(thunk),
));

if (module.hot) {
  module.hot.accept('../reducers', () => {
    // eslint-disable-next-line
    const reducers = require('../reducers');
    store.replaceReducer(combineReducers(reducers));
  });
}

export default store;
