import React, { PureComponent } from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';
import Condition from './Condition';
import Checkbox from '../../../../components/ui/Checkbox';

export default class CheckboxFinalFieldWrap extends PureComponent {
  static propTypes = {
    item: PropTypes.object.isRequired,
    values: PropTypes.object,
    styleFromParent: PropTypes.object,
    visible: PropTypes.bool,
    validation: PropTypes.func,
    change: PropTypes.func,
    setResetParameter: PropTypes.func,
    updateDisabledFields: PropTypes.func,
  };

  static defaultProps = {
    values: {},
    styleFromParent: {},
    visible: false,
    validation: () => {},
    change: () => {},
    setResetParameter: () => {},
    updateDisabledFields: () => {},
  };

  checkIfNeedToDisable = () => {
    const { item, values, change, updateDisabledFields, setResetParameter } = this.props;
    if (item.actions && item.actions.length !== 0 && this.input && values[item.field_name]) {
      item.actions.forEach((actionObj) => {
        if (actionObj.action === 'copy') {
          const fieldNames = Object.keys(actionObj.srcToDstFieldNames);
          fieldNames.forEach((fieldName) => {
            const dstFieldName = actionObj.srcToDstFieldNames[fieldName];
            updateDisabledFields(dstFieldName, values[item.field_name]);
            setTimeout(() => {
              change(dstFieldName, values[fieldName]);
            }, 0);
          });
          setTimeout(() => {
            setResetParameter();
          }, 10);
        }
        if (actionObj.action === 'disable') {
          actionObj.dstFieldNamesWithDefaults.forEach((fieldToDisable) => {

            const fieldReference = fieldToDisable.fieldname || fieldToDisable.fieldName || fieldToDisable.field_name;

            updateDisabledFields(fieldReference, values[item.field_name]);
            setTimeout(() => {
              change(fieldReference, fieldToDisable.defaultValue);
            }, 0);
          });
        }
      });
    } else if (item.actions && item.actions.length !== 0 && this.input && !values[item.field_name]) {
      item.actions.forEach((actionObj) => {
        if (actionObj.action === 'copy') {
          setTimeout(() => {
            setResetParameter();
          }, 0);
        }
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.values[prevProps.item.field_name] !== this.props.values[this.props.item.field_name]) {
      this.checkIfNeedToDisable();
    }
  }


  input = null;

  render() {
    const {
      item,
      values,
      validation,
      styleFromParent,
      change,
      updateDisabledFields,
      visible,
    } = this.props;

    return (
      <Condition visible={visible}>
        <Field
          type="text"
          name={item.field_name}
          placeholder={item.placeholder}
          validate={value => validation(value, item)}
        >
          {({ input }) => {
            if (!this.input) {
              this.input = input;
              this.checkIfNeedToDisable();
            }
            return (
              <div className="form-item" style={styleFromParent} key={`${item.field_name}wrap_kek`}>
                <Checkbox
                  {...input}
                  label={item.caption}
                  value={input.value || false}
                  onChange={(val) => {
                    if (item.actions && item.actions.length !== 0) {
                      item.actions.forEach((actionObj) => {
                        if (actionObj.action === 'copy') {
                          const fieldNames = Object.keys(actionObj.srcToDstFieldNames);
                          fieldNames.forEach((fieldName) => {
                            const dstFieldName = actionObj.srcToDstFieldNames[fieldName];
                            updateDisabledFields(dstFieldName, val);
                            change(dstFieldName, val ? values[fieldName] : undefined);
                          });
                        }
                        if (actionObj.action === 'disable') {
                          actionObj.dstFieldNamesWithDefaults.forEach((fieldToDisable) => {

                            const fieldReference = fieldToDisable.fieldname || fieldToDisable.fieldName || fieldToDisable.field_name;

                            updateDisabledFields(fieldReference, val);
                            change(fieldReference, val ? fieldToDisable.defaultValue : undefined);
                          });
                        }
                      });
                    }
                    input.onChange(val);
                  }}
                />
              </div>
            );
          }}
        </Field>
      </Condition>
    );
  }
}
