import React, { Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';

const SmartProtectPass = React.lazy(() => import('./smart-protect-pass'));

const CustomSmartProtect = (props) => {
  return (
    <Suspense fallback="">
      <Switch>
        <Route exact path="/form/custom_smart_protect/pass" component={SmartProtectPass} />
      </Switch>
    </Suspense>
  );
};

export default CustomSmartProtect;
