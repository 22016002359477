import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import newid from '../../utils/newid';

export default class Checkbox extends React.PureComponent {
  static propTypes = {
    value: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    disabled: false,
  };

  constructor(props) {
    super(props);

    this.id = newid();

    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    this.props.onChange(e.target.checked);
  }

  render() {
    const { value, disabled, label } = this.props;
    const className = classnames('ui-checkbox', { disabled });

    return (
      <div className={className}>
        <div className="checkbox-wrap">
          <input
            type="checkbox"
            id={this.id}
            checked={value}
            disabled={disabled}
            onChange={this.onChange}
          />
          <label htmlFor={this.id}>
            <span className="label-text" dangerouslySetInnerHTML={{ __html: label }} />
          </label>
        </div>
      </div>
    );
  }
}
