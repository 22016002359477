/* eslint-disable react/no-unused-state */
import React from 'react';
import PropTypes from 'prop-types';

export const FormContext = React.createContext(null);

class ClassicForm extends React.PureComponent {
  static propTypes = {
    id: PropTypes.string,
    children: PropTypes.node,
    focusFirstInput: PropTypes.bool,
    onBeforeSubmit: PropTypes.func,
    onSubmit: PropTypes.func,
  };

  static defaultProps = {
    id: undefined,
    children: null,
    focusFirstInput: true,
    onBeforeSubmit: () => {},
    onSubmit: () => {},
  };

  constructor(props) {
    super(props);

    this.validateInputs = [];
    this.inputs = [];
    this.firstEnabledInputFocused = false;

    this.state = {
      formSubmitted: false,
      onValidateInputAddedToForm: this.onValidateInputAddedToForm,
      onInputAddedToForm: this.onInputAddedToForm,
    };
  }

  onValidateInputAddedToForm = (input) => {
    this.validateInputs = this.validateInputs.concat(input);
  };

  onInputAddedToForm = (input) => {
    this.inputs = this.inputs.concat(input);

    if (this.props.focusFirstInput && !this.firstEnabledInputFocused && !input.disabled) {
      input.focus();
      setTimeout(() => { // guarantees cursor position at the end
        const inputVal = input.value;
        input.value = '';
        input.value = inputVal;
      }, 10);
      this.firstEnabledInputFocused = true;
    }
  };

  onSubmit = (event) => {
    event.preventDefault();

    let isValid = true;

    this.setState({ formSubmitted: true });

    this.validateInputs.forEach((input) => {
      if (!input.state.valid || (input.input && input.input.input && input.props.validator(input.input.input.value) !== '')) {
        isValid = false;
        input.forceUpdate();
      }
    });

    this.props.onBeforeSubmit();

    if (isValid) {
      this.props.onSubmit();
    }
  };

  render() {
    return (
      <FormContext.Provider value={this.state}>
        <form onSubmit={this.onSubmit} noValidate id={this.props.id}>
          {this.props.children}
        </form>
      </FormContext.Provider>
    );
  }
}

export default ClassicForm;
