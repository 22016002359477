export default function detectCardType(cardNumber) {
  if (!cardNumber) return '';
  let result = '';

  const card = cardNumber.toString().replace(/[^\d]/g, '');
  const types = {
    electron: /^(4026|417500|4405|4508|4844|4913|4917)\d*$/,
    maestro: /^(5018|5020|5038|5612|5893|6304|6759|6761|6762|6763|0604|6390)\d*$/,
    dankort: /^(5019)\d*$/,
    interpayment: /^(636)\d*$/,
    unionpay: /^(62|88)\d*$/,
    visa: /^4\d*$/,
    mastercard: /^5[1-5]\d*$/,
    amex: /^3[47]\d*$/,
    diners: /^3(?:0[0-5]|[68][0-9])\d*$/,
    discover: /^6(?:011|5[0-9]{2})\d*$/,
    jcb: /^(?:2131|1800|35\d{3})\d*$/,
    forbrugsforeningen: /^(600)\d*$/,
    mir: /^220[0-4]\d*$/,
  };

  Object.keys(types).some((type) => {
    if (types[type].test(card)) {
      return result = type.toString();
    }
  });

  return result;
}
