import React, { Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
// import TraidInPass from './traid-in-pass';

const TraidInDetect = React.lazy(() => import('./traid-in-detect'));
const TraidInRealisation = React.lazy(() => import('./traid-in-realisation'));
const TraidInPass = React.lazy(() => import('./traid-in-pass'));

const TradeIn = (props) => {
  return (
    <Suspense fallback="">
      <Switch>
        <Route exact path="/form/traid-in/detect" component={TraidInDetect} />
        <Route exact path="/form/traid-in/realisation" component={TraidInRealisation} />
        <Route exact path="/form/traid-in/pass" component={TraidInPass} />
      </Switch>
    </Suspense>
  )
};

export default TradeIn;
