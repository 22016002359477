import React from 'react';

const LabelValue = ({ label: Label = '', value: Value = '', className = '' }) => (
  <div className={`label-value ${className}`}>
    <span className="label">{typeof Label === 'function' ? Label : Label}</span>
    <span className="value">{typeof Value === 'function' ? Value : Value}</span>
  </div>
);

export default LabelValue;
