/* eslint-disable jsx-a11y/label-has-associated-control */
import moment from 'moment/moment';
import PropTypes from 'prop-types';
import React from 'react';
import { ISO_VALUE } from '../../constants/currencies';
import { countryAlpha2ByAlpha3, formatMoneyWithCurrency, formatRawPhoneToMasked } from '../../utils/format';

// function convertToJsString(str, dst_extras) {
//   Object.keys(dst_extras).forEach((key) => {
//     if (typeof dst_extras[key] === 'number') {
//       str += `var ${key} = ${dst_extras[key]}; `;
//     } else if (typeof dst_extras[key] === 'string') {
//       str += `var ${key} = ${JSON.stringify(dst_extras[key])}; `;
//     } else if (typeof dst_extras[key] === 'object') {
//       // str += convertToJsString(str, dst_extras[key]);
//       str += `var ${key} = ${JSON.stringify(dst_extras[key])}; `;
//     }
//   });
//   return str;
// }

class TxDataField extends React.PureComponent {
  static propTypes = {
    field: PropTypes.object.isRequired,
    value: PropTypes.any.isRequired,
    amountCurrency: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    // visibleFields: PropTypes.object,
    // withConditionCheck: PropTypes.bool,
  };

  static defaultProps = {
    amountCurrency: 'RUB',
    // visibleFields: {},
    // withConditionCheck: true,
  };

  // checkCondition = (str) => {
  //   try {
  //     return new Function('', str)();
  //   } catch (e) {
  //     return false;
  //   }
  // };

  getFieldValue = () => {
    const { value, field, amountCurrency } = this.props;

    const currencyValue = typeof amountCurrency === 'object' ? amountCurrency.value : amountCurrency;
    const currency = ['full_amount', 'commission'].includes(field.field_name) ? '643' : ISO_VALUE[currencyValue];

    if (field.type === 'amount') return formatMoneyWithCurrency({ amount: value, currency, symbol: true });
    if (field.type === 'phone' && value.length === 11) return formatRawPhoneToMasked(value); // 79999999999 default format
    if (field.type === 'date') return moment(value).format('DD MMMM YYYY');
    if ((field.type === 'enum' || field.type === 'typeahead' || field.type === 'radio') && typeof value === 'object') {
      if (field.subtype === 'country' || (field.field_name === 'country' && value.alpha3)) {
        return (
          <>
            <img key="tx-data-country-icon" className="tx-data-country-icon" src={`/assets/svg/flags/1x1/${countryAlpha2ByAlpha3[value.alpha3].toLowerCase()}.svg`} alt={value.name} />
            <span key="tx-data-country-name" className="tx-data-country-name">{value.name}</span>
          </>
        );
      }
      return value.name;
    }

    return value;
  };

  getFieldStyle = () => {
    const { visual } = this.props.field;
    const style = {};

    if (visual) {
      if (visual.size) {
        style.width = `${visual.size}%`;
      }
      if (visual.off) {
        style.marginLeft = `${visual.off}%`;
      }
    }

    return style;
  };

  render() {
    const { field } = this.props;

    // const { withConditionCheck, visibleFields, field } = this.props;

    // if (withConditionCheck) {
    //   let str = convertToJsString('', dst_extras);
    //   str += ` return ${field.condition}; `;
    //
    //   if (!this.checkCondition(str)) return null;
    // }

    return (
      <div className="fields-group-item" style={this.getFieldStyle()}>
        <label className="tx-data-field-label">{field.caption}</label>
        <span className="tx-data-field-value">{this.getFieldValue()}</span>
      </div>
    );
  }
}

export default TxDataField;
