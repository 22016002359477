import { connect } from 'react-redux';
import { getAllCatalogItems, getCats, getStats } from '../../../actions/catalog';
import { updatePageLoading } from '../../../actions/ui';
import CatalogShowcase from './CatalogShowcase';

const mapStateToProps = state => ({
  catalog: state.catalog,
  user: state.user,
  pageLoading: state.ui.pageLoading,
});

const mapDispatchToProps = {
  getStats,
  getCats,
  getAllCatalogItems,
  updatePageLoading,
};

const CatalogShowcaseContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CatalogShowcase);

export default CatalogShowcaseContainer;
