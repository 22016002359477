import PropTypes from 'prop-types';
import useOnClickOutside from './useOnClickOutside';

function OnClickOutside(props) {
  const { children, onClickOutside, active, ...options } = props;
  const ref = useOnClickOutside(onClickOutside, active, options);

  return children(ref);
}

OnClickOutside.propTypes = {
  children: PropTypes.func.isRequired,
  onClickOutside: PropTypes.func.isRequired,
  active: PropTypes.bool,
  eventTypes: PropTypes.arrayOf(
    PropTypes.string.isRequired,
  ),
  ignoreClass: PropTypes.string,
};

export default OnClickOutside;
