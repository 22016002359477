import React, { Component, Suspense } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import queryString from 'query-string';
import { Switch, Route, Redirect } from 'react-router-dom';
import { isMsgLvlWithModal, ReadAlertsAndModalsAndReports } from '../utils/notifications';
import AppRoute from './AppRoute';
import AllowPopupsOverlay from './ui/AllowPopupsOverlay';
// const Cart = React.lazy(() => import('../pages/cart/Cart'));
import Cart from '../pages/cart/Cart';
// import queryString from 'query-string';
// import rutoken from 'rutoken';

import Header from './Header';
import CatalogShowcasePage from '../pages/catalog-showcase';
import IframesPage from '../pages/iframes';
import SmsAuth from '../pages/sms-auth';
import CatalogFormFillPageNew from '../pages/catalog-form-fill-new';
import TransactionsConfirmationPage from '../pages/transactions-confirmation';
import TransactionsHistoryPage from '../pages/transactions-history';
import NotFoundPage from '../pages/not-found';
import Footer from './Footer';
// import CmdFormatter from '../utils/CmdFormatter';
import AuthPage from '../pages/auth';
import FetchingOverlay from './ui/FetchingOverlay';
import ModalsMachine from './ui/ModalsMachine';
import UiKitPage from './UiKitPage';
import ReloadCatalogFormFill from '../pages/catalog-form-fill-new/components/reload-catalog-form-fill/ReloadCatalogFormFill';
import TradeIn from '../pages/trade-in';
import GoldCrown from '../pages/gold_crown';
import CustomSmartProtect from '../pages/custom-smart-protect';
import Addapter from '../pages/addappter';
import closeWindow from '../utils/windowClose';
import Complex_plus_discount from '../pages/complex_plus_discount';
import Sdalkupilbuyout from '../pages/sdalkupilbuyout/sdalkupilbuyout';


//test commit for new remote branch
if (process.env.NODE_ENV === 'production') {
  console.log = () => {
  };
}


export const ThunderBotContext = React.createContext();

let messagesPollingTimerId;
let fetchUserPollingTimerId;
let prevReadAlertsJson;
let prevReadModalsJson;
let firstTimeModalsShown = false;
// let lastZendeskTags;

class App extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    messages: PropTypes.array.isRequired,
    pageLoading: PropTypes.bool.isRequired,
    fetchUser: PropTypes.func.isRequired,
    fetchingOverlayActive: PropTypes.bool,
    fetchingOverlayMessage: PropTypes.string,
    fetchingOverlayMessageShowTime: PropTypes.number,
    fetchingOverlayMessageDefaultShown: PropTypes.bool,
    openModalBeforeLeave: PropTypes.bool.isRequired,
    openConfirmLeaveModal: PropTypes.func.isRequired,
    getMessages: PropTypes.func.isRequired,
    readMessage: PropTypes.func.isRequired,
    storeComplexSalesGuid: PropTypes.func.isRequired,
    updatePageLoading: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  static defaultProps = {
    fetchingOverlayActive: false,
    fetchingOverlayMessage: undefined,
    fetchingOverlayMessageShowTime: undefined,
    fetchingOverlayMessageDefaultShown: undefined,
  };

  constructor(props) {
    super(props);

    const Query = queryString.parse(props.location.search);
    if (Query.access_token) {
      window.localStorage.setItem('access_token', Query.access_token);
    }
    if (Query.complexSalesGuid) {
      this.props.storeComplexSalesGuid(Query.complexSalesGuid);
    }

    this.state = {
      appConfigLoaded: false,
      authorised: false,
      showAllowPopupsOverlay: false,
      tb: null,
    };

    this.thunderBot = null;
    this.thunderBotLoadPromise = null;
    // this.zendeskChatLoadPromise = new Promise(() => {});
  }

  componentDidMount() {

    const oldClose = window.close;

    window.close = function () {
      oldClose();
      closeWindow('BoxRetail');
    };

    // const cmd = new CmdFormatter(null, 40);//todo прикрутить данные о печати из юзера
    // cmd.center('test');
    // cmd.center('очень длинная строка для печати хрен пойми чего');


    //   if ($rootScope.onecurlport !== '') {
    //     SYSTEM.driverAddress = SYSTEM.driverAddress.replace(/:\d{2,}/, ":" + $rootScope.onecurlport)
    //   }
    // }
    try {
      const query = queryString.parse(location.search);
      Object.keys(queryString.parse(location.search)).forEach((item) => {
        sessionStorage.setItem(item, query[item]);
        if (item === 'port') {
          console.warn('port: ', query[item]);
          if (query[item] !== '') {
            const newOneCUrl = process.env.DRIVER_ADDRESS.replace(/:\d{2,}/, `:${query[item]}`);
            localStorage.setItem('DRIVER_ADDRESS', newOneCUrl);
          } else if (!localStorage.getItem('DRIVER_ADDRESS')) {
            localStorage.setItem('DRIVER_ADDRESS', process.env.DRIVER_ADDRESS);
          }
        }
      });

      // old close
      // if (location.href.indexOf('reopened') < 0 && sessionStorage.getItem('reopened') !== 'true') {
      //   const newHref = location.href.indexOf('?') < 0 ? `${location.href}?reopened=true` : `${location.href}&reopened=true`;
      //   // const a = window.open(newHref, 'newWindow', `toolbar=no, menubar=no, resizable=yes,width=${screen.availWidth},height=${screen.availHeight}`);
      //
      //   const retry = () => {
      //     const a = window.open(newHref, 'newWindow', `toolbar=no, menubar=no,fullscreen=yes, resizable=yes,width=${screen.availWidth},height=${screen.availHeight}`);
      //     if (a) {
      //       setTimeout(() => {
      //         window.close();
      //       }, 0);
      //     } else {
      //       this.setState({ showAllowPopupsOverlay: true });
      //       setTimeout(retry, 5000);
      //     }
      //     return a;
      //   };
      //   if (process.env.NODE_ENV === 'production') {
      //     retry();
      //   }
      // }
    } catch (e) {
      alert('У Вас заблокированы Cookie 🍪. Пожалуйста, включите их согласно следующей инструкции: Настройки -> Дополнительные (Конфиденциальность и безопасность) -> Настройки контента -> Файлы cookie -> снять флаг "Заблокировано"');
      console.warn(e);
    }

    // rutoken.ready.then(() => {
    //   if (window.chrome || typeof InstallTrigger !== 'undefined') {
    //     return rutoken.isExtensionInstalled();
    //   }
    //   return Promise.resolve(true);
    // }).then((result) => {
    //   if (result) {
    //     return rutoken.isPluginInstalled();
    //   }
    //   // eslint-disable-next-line
    //   throw "Rutoken Extension wasn't found";
    // }).then((result) => {
    //   if (result) {
    //     return rutoken.loadPlugin();
    //   }
    //   // eslint-disable-next-line
    //   throw "Rutoken Plugin wasn't found";
    // }).then((plugin) => {
    //   // signString(JSON.stringify({test: 'test'})).then(function(result) {
    //   //   console.log('result', result);
    //   // })
    //   const string = JSON.stringify({ test: 'test' });
    //   function firstStep() {
    //     return plugin.enumerateDevices()
    //       .then(devices => plugin.enumerateCertificates(devices[0], 1)
    //         .then(certIds => plugin.getCertificate(devices[0], certIds[0])
    //           .then(certificate => plugin.sign(devices[0], certIds[0], string, false, {
    //             addSignTime: true,
    //             useHardwareHash: true,
    //             detached: true,
    //             addUserCertificate: false,
    //           }).then(signature => ({
    //             certificate,
    //             signature,
    //             string,
    //           })))));
    //   }
    //   firstStep().then(result => console.log(result));
    //   //Можно начинать работать с плагином
    //
    //   //Только для работы через старый интерфейс плагина
    //   return plugin.wrapWithOldInterface();
    // })
    //   .then(() => {
    //   //(wrappedPlugin) => {}
    //   //Можно начинать работать через старый интерфейс плагина
    //   })
    //   .then(undefined, (reason) => {
    //     console.log(reason);
    //   });

    // this.zendeskChatLoadPromise =

    // this.loadZendeskChat()
    //   .then(this.initZendeskChat);

    if (process.env.THUNDERBOT_ENABLED) {
      this.thunderBotInitPromise = this.loadThunderbotChat()
        .then(this.initThunderbotChat)
        .catch(console.warn);
    }

    this.props.fetchUser({ noDefaultNotification: true })
      .then(() => {
        this.setState({ appConfigLoaded: true, authorised: true });
      }).catch(() => {
        this.setState({ appConfigLoaded: true, authorised: false });
      });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.authorised !== this.state.authorised) {
      if (!this.state.authorised) {
        firstTimeModalsShown = false;
        // if (process.env.GROUP_IB_ENABLED && window.gib) {
        //   window.gib.setAuthStatus(window.gib.IS_GUEST);
        //   window.gib.setAttribute('posId', null, { persistent: true, encryption: null });
        //   window.gib.setAttribute('rutoken', window.localStorage.getItem('rutoken_cert'), { persistent: true, encryption: null });
        // }
        ReadAlertsAndModalsAndReports.clear();
        clearTimeout(messagesPollingTimerId);
        clearTimeout(fetchUserPollingTimerId);
        // this.resetZendeskTags();
        if (process.env.THUNDERBOT_ENABLED) {
          // this.showZendeskChat();
          this.thunderBotInitPromise.then((tb) => {
            if (!tb) return;
            this.setState({ tb });
            this.resetThunderbotChat();
          }).catch(console.warn);
        }
      } else {
        const newNotifications = this.getNewNotificationsAlertsAndModals();
        firstTimeModalsShown = !!newNotifications.modals.length;
        // if (process.env.GROUP_IB_ENABLED && window.gib) {
        //   window.gib.setAuthStatus(window.gib.IS_AUTHORIZED);
        //   window.gib.setAttribute('posId', this.props.user.info.user.outletExtra['1c_point_code'], { persistent: true, encryption: null });
        //   window.gib.setAttribute('rutoken', window.localStorage.getItem('rutoken_cert'), { persistent: true, encryption: null });
        // }
        // if (process.env.THUNDERBOT_ENABLED && (process.env.NODE_ENV === 'development' || this.props.user.info.company.thunderbot)) {
        if (process.env.THUNDERBOT_ENABLED && this.props.user.info.user.extra.thunderbotTrustedId) {
          this.thunderBotInitPromise.then((tb) => {
            if (!tb) return;
            // this.hideZendeskChat();
            this.setState({ tb });
            const { user: { info: { user } } } = this.props;
            this.thunderBot.setUserInfo({
              authData: { trustedAuthId: user.extra.thunderbotTrustedId, userName: user.fullName },
              rawExtraData: { ...user },
              formattedExtraData: {
                outletName: { label: 'Имя точки', value: user.outletName },
                dealerId: { label: 'ID дилера', value: user.outletExtra.DLRID },
                posId: { label: 'POS ID', value: user.outletExtra['1c_point_code'] },
              },
            });
          }).catch(console.warn);
        }
        // this.setZendeskTags();
        this.startFetchUserPolling();
        this.startMessagesPolling();
      }
    }

    if (this.state.authorised) {
      const { messages } = this.props;

      try {
        const readAlertsJson = window.sessionStorage.getItem('readAlerts');
        const readModalsJson = window.sessionStorage.getItem('readModals');

        // eslint-disable-next-line max-len
        if (messages.length !== prevProps.messages.length || prevReadAlertsJson !== readAlertsJson || prevReadModalsJson !== readModalsJson) {
          const sessionReadAlerts = ReadAlertsAndModalsAndReports.get('readAlerts');
          const alerts = messages.filter(msg => !(msg.alertRead || sessionReadAlerts[msg.id]));

          const sessionReadModals = ReadAlertsAndModalsAndReports.get('readModals');
          const modals = alerts.filter(alert => isMsgLvlWithModal(alert.lvl) && !sessionReadModals[alert.id]);

          const modalShown = modals.length > 0;
          const alertShown = !modalShown && alerts.length > 0;

          if (alertShown) {
            window.document.body.classList.add('alert-shown');
          } else {
            window.document.body.classList.remove('alert-shown');
          }
        }

        prevReadAlertsJson = readAlertsJson;
        prevReadModalsJson = readModalsJson;
      } catch (e) {
        console.warn(e);
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(messagesPollingTimerId);
    clearTimeout(fetchUserPollingTimerId);
  }

  setUnAuthed = () => {
    window.localStorage.clear();
    this.setState({ authorised: false });
  };

  onPageDidMount = (page) => {
    this.setState({ page });

    const html = window.document.getElementsByTagName('html')[0];

    html.classList.add(`html-page-${page}`);
    html.classList.remove('page-in-transition');

    window.scrollTo(0, 0);
  };

  onPageWillUnmount = () => {
    const html = window.document.getElementsByTagName('html')[0];

    html.classList.remove(`html-page-${this.state.page}`);
  };

  onMinimizeMessageModalClick = (message) => {
    ReadAlertsAndModalsAndReports.set('readModals', message.id);

    const newNotifications = this.getNewNotificationsAlertsAndModals();

    if (!newNotifications.modals.length) {
      firstTimeModalsShown = false;
    }

    this.forceUpdate();
  };

  getNewNotificationsAlertsAndModals = () => {
    const { messages } = this.props;
    const sessionReadAlerts = ReadAlertsAndModalsAndReports.get('readAlerts');
    const alerts = messages.filter(msg => !(msg.alertRead || sessionReadAlerts[msg.id])).sort(this.sortAlertsByLvl);
    const sessionReadModals = ReadAlertsAndModalsAndReports.get('readModals');
    const modals = alerts.filter(alert => isMsgLvlWithModal(alert.lvl) && !sessionReadModals[alert.id]);

    return { alerts, modals };
  };

  setAuthed = () => {
    return this.props.fetchUser()
      .then(() => {
        this.setState({ appConfigLoaded: true, authorised: true });
      }).catch(() => {
        this.setState({ appConfigLoaded: true, authorised: false }, () => {
          this.props.updatePageLoading({ pageLoading: false });
        });
      });
  };

  loadThunderbotChat = () => new Promise((resolve, reject) => {
    const script = document.createElement('script');
    const firstScript = document.getElementsByTagName('script')[0];
    script.setAttribute('charset', 'utf-8');
    script.onload = () => { resolve(); };
    script.onerror = () => { reject('Thunderbot script loading failed'); };
    script.src = `${process.env.THUNDERBOT_URL}?no_cache=${Date.now()}`;
    script.type = 'text/javascript';
    firstScript.parentNode.insertBefore(script, firstScript);
  });

  initThunderbotChat = () => {
    if (!this.thunderBot) {
      if (window.ThunderBot) {
        this.thunderBot = new ThunderBot();
        return this.thunderBot.init({ stopScrollElement: '.footer' });
      } else {
        console.warn('Thunderbot load failed');
        return Promise.reject();
      }
    }
    return Promise.resolve();
  };

  resetThunderbotChat = () => {
    if (!this.thunderBot) {
      return;
    }
    // this.thunderBot.destroy();
    this.thunderBot.resetUserInfo();
  };

  // loadZendeskChat = () => new Promise((resolve) => {
  //   (function (d, s) {
  //     var z = window.$zopim = function (c) { z._.push(c); };
  //     const $ = z.s = d.createElement(s);
  //     const e = d.getElementsByTagName(s)[0];
  //     z.set = function (o) {
  //       z.set._.push(o);
  //     };
  //     z._ = [];
  //     z.set._ = [];
  //     $.async = !0;
  //     $.setAttribute('charset', 'utf-8');
  //     $.src = 'https://v2.zopim.com/?5YUWKWUV4UwtFjrBiEKYr3iU3wPEq7X1';
  //     $.onload = () => { resolve(); };
  //     z.t = +new Date();
  //     $.type = 'text/javascript';
  //     e.parentNode.insertBefore($, e);
  //   }(document, 'script'));
  // });
  //
  // initZendeskChat = () => {
  //   window.$zopim(() => {
  //     window.$zopim.livechat.setLanguage('ru');
  //
  //     // this.showZendeskChat();
  //   });
  // };
  //
  // setZendeskTags = () => {
  //   window.$zopim(() => {
  //     const { user: { info } } = this.props;
  //     const companyPointCode = info && info.user.outletExtra ? info.user.outletExtra['1c_point_code'] : '';
  //     const companyName = info && info.user.outletName;
  //     const userName = info && (info.user.FIO || info.user.fullName);
  //     const userEmail = info && info.user.email;
  //
  //     window.$zopim.livechat.set({
  //       email: userEmail,
  //       name: userName,
  //       organization: companyName,
  //     });
  //
  //     window.$zopim.livechat.setLanguage('ru');
  //     window.$zopim.livechat.addTags(`posid: ${companyPointCode}`, `org: ${companyName}`);
  //     // lastZendeskTags = [`posid: ${companyPointCode}`, `org: ${companyName}`];
  //   });
  // };
  //
  // resetZendeskTags = () => {
  //   window.$zopim(() => {
  //     window.$zopim.livechat.clearAll();
  //   });
  //   // window.$zopim.livechat.removeTags(...lastZendeskTags);
  // };
  //
  // showZendeskChat = () => {
  //   const zopimElements = window.document.getElementsByClassName('zopim');
  //   if (zopimElements.length) {
  //     for (let i = 0; i < zopimElements.length; i++) {
  //       zopimElements[i].classList.remove('totally-hidden');
  //     }
  //   }
  // };
  //
  // hideZendeskChat = () => {
  //   window.$zopim.livechat.clearAll();
  //   const zopimElements = window.document.getElementsByClassName('zopim');
  //   if (zopimElements.length) {
  //     for (let i = 0; i < zopimElements.length; i++) {
  //       zopimElements[i].classList.add('totally-hidden');
  //     }
  //   }
  // };

  startFetchUserPolling = () => {
    const pollInterval = 60000;

    const checkUser = () => {
      // if (!this.state.authorised) {
      //   fetchUserPollingTimerId = setTimeout(checkUser, pollInterval);
      //   return;
      // }
      this.props.fetchUser()
        .then(() => {
          if (!this.state.authorised) return;
          fetchUserPollingTimerId = setTimeout(checkUser, pollInterval);
        })
        .catch((err) => {
          this.setState({ authorised: false });
          // this.setState({ authorised: false });
        });
    };

    fetchUserPollingTimerId = setTimeout(checkUser, pollInterval);
  };

  startMessagesPolling = () => {
    const pollInterval = 30000;

    const checkMessages = () => {
      // if (!this.state.authorised) {
      //   messagesPollingTimerId = setTimeout(checkMessages, pollInterval);
      //   return;
      // }
      this.props.getMessages()
        .then(() => {
          if (!this.state.authorised) return;
          messagesPollingTimerId = setTimeout(checkMessages, pollInterval);
        })
        .catch((err) => {
          if (!this.state.authorised) {
            clearTimeout(messagesPollingTimerId);
            return;
          }
          console.log('getMessages request error:');
          console.log(err);
          messagesPollingTimerId = setTimeout(checkMessages, pollInterval);
        });
    };

    checkMessages();
  };

  sortAlertsByLvl = (a, b) => {
    if (isMsgLvlWithModal(a.lvl) && !isMsgLvlWithModal(b.lvl)) {
      return -1;
    }
    if (!isMsgLvlWithModal(a.lvl) && isMsgLvlWithModal(b.lvl)) {
      return 1;
    }
    return 0;
  };

  render() {
    const { appConfigLoaded, authorised, showAllowPopupsOverlay, tb } = this.state;
    const {
      user, history, location, messages, readMessage, pageLoading,
      fetchingOverlayActive, fetchingOverlayMessage, fetchingOverlayMessageShowTime,
      fetchingOverlayMessageDefaultShown, openModalBeforeLeave, openConfirmLeaveModal,
    } = this.props;
    const { info } = user;
    const companyPointCode = info && info.user.outletExtra ? info.user.outletExtra['1c_point_code'] : '';
    const companyName = info && info.user.outletName;
    const companyAddress = info && info.user.outletAddress;
    const userName = info && (info.user.FIO || info.user.fullName);

    const pageProps = {
      onPageDidMount: this.onPageDidMount,
      onPageWillUnmount: this.onPageWillUnmount,
    };

    const newNotifications = this.getNewNotificationsAlertsAndModals();
    const { alerts, modals } = newNotifications;

    const modalShown = authorised && modals.length > 0;
    const alertShown = authorised && !firstTimeModalsShown && alerts.length > 0;

    return (
      <div className="app-component">
        <ThunderBotContext.Provider value={tb}>
          {showAllowPopupsOverlay && (<AllowPopupsOverlay />)}
          {(!appConfigLoaded || pageLoading) && (
            <div>
              {/*<header className="header"/>*/}
              <div className="app-loading" key="app-loading-key" />
            </div>
          )}
          {appConfigLoaded && (
            <React.Fragment>
              <ModalsMachine active={modalShown} modals={modals} onMinimizeMessageModalClick={this.onMinimizeMessageModalClick} />
              {authorised && (
                <Header
                  alerts={alerts}
                  alertShown={alertShown}
                  companyPointCode={companyPointCode}
                  setUnAuthed={this.setUnAuthed}
                  companyName={companyName}
                  companyAddress={companyAddress}
                  userName={userName}
                  report={user.info.user.report}
                  messages={messages}
                  readMessage={readMessage}
                  openModalBeforeLeave={openModalBeforeLeave}
                  openConfirmLeaveModal={openConfirmLeaveModal}
                  location={location}
                  history={history}
                />
              )}
              <FetchingOverlay
                active={fetchingOverlayActive}
                message={fetchingOverlayMessage}
                messageShowTime={fetchingOverlayMessageShowTime}
                messageDefaultShown={fetchingOverlayMessageDefaultShown}
              />
              <div className={classnames('page-wrapper', { 'auth-page': !authorised, 'alert-shown': alertShown })}>
                {!authorised ? (
                  <AuthPage setAuthed={this.setAuthed} {...pageProps} />
                ) : (
                  <Switch>
                    {process.env.NODE_ENV === 'development' && <Route exact path="/ui-kit" component={UiKitPage} />}
                    <Route exact path="/catalog/:catalogType?" component={CatalogShowcasePage} />
                    <Route exact path="/iframes/:formId?" component={IframesPage} />
                    <Route exact path="/sms-auth/:type" component={SmsAuth} />
                    <Route path="/form/traid-in/" component={TradeIn} />
                    <Route path="/form/sdalkupilbuyout/" component={Sdalkupilbuyout} />
                    <Route path="/form/complex_plus_discount" component={Complex_plus_discount} />
                    <Route path="/form/addappter" component={Addapter} />
                    <Route path="/form/custom_smart_protect/" component={CustomSmartProtect} />
                    <Route path="/form/gold_crown_transfer/" component={GoldCrown} />
                    {/*<Suspense fallback="">*/}
                      <Route path="/cart" component={Cart} />
                    {/*</Suspense>*/}
                    <Redirect exact from="/form/tradein_courier" to="/form/traid-in/pass" />
                    <Redirect exact from="/form/sdalkupil" to="/form/traid-in/detect" />
                    <Redirect exact from="/form/applysdalkupil" to="/form/traid-in/realisation" />
                    <Redirect exact from="/form/smart_protect_courier" to="/form/custom_smart_protect/pass" />
                    <Route exact path="/form/:category/:step?" component={CatalogFormFillPageNew} />
                    <Route exact path="/reload-catalog-form-fill/:category/:step?" component={ReloadCatalogFormFill} />
                    <AppRoute exact path="/transactions/confirmation" component={TransactionsConfirmationPage} pageProps={pageProps} />
                    <AppRoute exact path="/transactions/history" component={TransactionsHistoryPage} pageProps={pageProps} />
                    <Redirect exact from="/" to="/catalog" />
                    <Route component={NotFoundPage} />
                  </Switch>
                )}
              </div>
            </React.Fragment>
          )}
          {(appConfigLoaded && authorised) && <Footer companyPointCode={companyPointCode} companyName={companyName} userName={userName} />}
        </ThunderBotContext.Provider>
      </div>
    );
  }
}

export default App;
