import ReactDOM from 'react-dom';
import React from 'react';
import RutokenPinModal from './RutokenPinModal';

const rutokenModalWrap = () => new Promise((resolve) => {
  const pinInput = (pin) => {
    resolve(pin);
  };
  const g = document.createElement('div');
  g.setAttribute('id', 'rutoken');
  document.body.appendChild(g);
  // ReactDOM.render(<RutokenPinModal fetch={Fetch}/>, document.getElementById('rutoken'));
  ReactDOM.render(
    <RutokenPinModal
      backdrop
      onClickOutside={() => {}}
      transitionEnterTimeout={300}
      transitionLeaveTimeout={300}
      transitionName="ui-modal"
      pinInput={pinInput}
    />, g,
  );
});

export default rutokenModalWrap;
