import React from 'react';
import moment from 'moment';
import classnames from 'classnames';
import { withRouter } from 'react-router-dom';
import AnimatedAlert from '../../../components/ui/AnimatedAlert';
import Button from '../../../components/ui/Button';
import ClassicForm from '../../../components/ui/ClassicForm';
import ClassicValidateInput from '../../../components/ui/ClassicValidateInput';
import Input from '../../../components/ui/Input';
import Select from '../../../components/ui/Select';
import rutokenWrappedFunctions from '../../../utils/rutoken/rutokenWrappedFunctions';
import fetch from '../../../utils/fetch';

// import DownloadIcon from '../../../assets/svg/download.svg';
import BoxRetailLogo from '../../../assets/img/box-retail-logo.svg';

function promisedParser(plugin, certId) {
  return new Promise(((resolve, reject) => {
    plugin.parseCertificate(0, certId).then((parsed) => {
      resolve([parsed, certId]);
    }, (error) => {
      reject(error);
    });
  }));
}


class Auth extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      username: '',
      password: '',
      FIO: '',
      companyId: '',
      posId: '',
      fingerprint: '',
      usernameDisabled: false,
      showError: false,
      errorMessage: '',
      crtIds: [],
    };
  }

  loginByType = {
    password: ({ username, password, companyId }) => fetch('/user/login/password', {
      method: 'POST',
      body: { ...{ username, password, projectId: companyId } },
      noDefaultNotification: true,
      returnError: true,
    })
      .then((result) => {
        if (result[1].success) {
          return Promise.resolve(result[1]);
        }

        if (result[0]) {
          return result[0]
            .response
            .json()
            .then(errorBody => Promise.reject(errorBody));
        }
        return Promise.reject(new Error('Проверьте логин или пароль'));
      })
      .catch((e) => {
        console.warn(e);
        if (e && e.error === 'Authorisation error - outlet not accepted') {
          return Promise.reject(new Error('Точка продаж не активирована, либо заблокирована.'));
        }
        return Promise.reject(new Error('Проверьте логин или пароль'));
      }),
    rutoken: (credentials) => {
      const { username, password, fio, posId, fingerprint } = credentials;
      let functions = {};
      return rutokenWrappedFunctions() //todo custom error handler for rutoken errors;
        .then((funcs) => {
          const { plugin } = funcs;
          return plugin
            .enumerateCertificates(0, 1)
            .then((certIds) => {
              window.localStorage.removeItem('rutoken_cert');
              const arrayOfPromises = [];
              for (const i in certIds) {
                arrayOfPromises.push(promisedParser(plugin, certIds[i]));
              }
              return Promise.all(arrayOfPromises).then((res) => {
                const filteredArray = res.sort((a, b) => new Date(a[0].validNotAfter) - new Date(b[0].validNotAfter)).filter(hmmm => moment(hmmm[0].validNotAfter).isAfter(new Date()));
                if (typeof filteredArray[0] !== 'undefined') {
                  window.localStorage.setItem('rutoken_cert', filteredArray[0][1]);
                  for (const k in filteredArray) {
                    //оставляем список из актуальных сертов
                    //Пишем в локалсторадж нужный сертификат (можно без цикла)
                    console.log('we look for it', moment(filteredArray[k][0].validNotAfter).format('DD.MM.YYYY'), '   ', moment(filteredArray[k][0].validNotAfter).isAfter(new Date()), filteredArray[k][1]);
                  }
                }
                return funcs;
              });
            });
        })
        .then((funcs) => {
          functions = funcs;
          return functions.removePin();
        })
        .then(() => functions.login(password))
        .then(null, (error) => {
          if (+error.message === 93) {
            return null;
          }
          if (+error.message === 17) {
            return Promise.reject(new Error('НЕКОРРЕКТНЫЙ PIN-КОД'));
          }
          return Promise.reject('Ошибка в работе рутокена, проверьте подключен ли токен к компьютеру.');
        })
        .then(() => functions.getCertificate())
        .then((certificate) => {
          credentials.params = certificate;
          return fetch('/user/login/rutoken', {
            method: 'POST',
            body: {
              username: fio || username,
              password,
              ...(fio && { fio }),
              ...(posId && { posId }),
              ...(fingerprint && { fingerprint }),
            },
            noDefaultNotification: true,
          });
        })
        .then((response) => {
          return rutokenWrappedFunctions().then(funcs => funcs.signStringByRutoken(response.result))
            .then(data => functions.getCertificateFingerPrint()
              .then(fingerprint => fetch('/user/login/rutoken/signature', {
                method: 'POST',
                body: {
                  username,
                  password,
                  signature: data.signature,
                  ...(fio && { fio }),
                  ...(fingerprint && { fingerprint }),
                },
                noDefaultNotification: true,
              })));
        });
    },
  };

  componentDidMount() {
    this.getUserParamsAndSetRutoken();
    this.props.onPageDidMount('auth');
    rutokenWrappedFunctions()
      .then(funcs => funcs.getCertificateFingerPrint()
        .then((fingerprint) => {
          this.setState({ fingerprint });
        }));
  }

  componentWillUnmount() {
    this.props.updatePageLoading({ pageLoading: true });
    this.props.onPageWillUnmount();
  }

  getCurrentUserFromOneC() {
    return new Promise((resolve) => {
      const driverUrl = window.localStorage.getItem('DRIVER_ADDRESS');
      const urlString = driverUrl || process.env.DRIVER_ADDRESS;

      fetch('/GetCurrentUser', {
        backendUrl: urlString,
        prefix: '/fr',
        noDefaultNotification: true,
      }).then((result) => {
        const { user, posid } = result.result;
        const userTrimmed = user.trim();
        this.setState({ username: '', FIO: userTrimmed, posId: posid, usernameDisabled: true });
        resolve();
      }).catch((e) => {
        console.log(e);
        resolve();
      });
    });
  }

  getUserParamsAndSetRutoken() {
    this.getCurrentUserFromOneC().then(() => {
      let functions = {};
      rutokenWrappedFunctions() //todo custom error handler for rutoken errors;
        .then((funcs) => {
          functions = funcs;
        })
        .then(() => functions.getArrayOfCertData())
        .then((crtIds) => {
          this.setState({ crtIds }, () => {
            this.props.updatePageLoading({ pageLoading: false });
          });
        })
        .catch((e) => {
          console.log(e);
          this.props.updatePageLoading({ pageLoading: false });
        });
    });
  }

  onCompanyIdChange = (companyId) => {
    this.setState({ companyId });
  };

  onLoginChange = (username) => {
    this.setState({ username });
  };

  onPasswordChange = (password) => {
    this.setState({ password });
  };

  // onFieldFocus = () => {
  //   let functions = {};
  //   rutokenWrappedFunctions() //todo custom error handler for rutoken errors;
  //     .then((funcs) => {
  //       functions = funcs;
  //     })
  //     .then(() => functions.getArrayOfCertData())
  //     .then((crtIds) => {
  //       if (crtIds.length > 1) {
  //         this.setState({
  //           crtIds,
  //           showError: false,
  //           errorMessage: '',
  //         });
  //       } else {
  //         this.setState({
  //           showError: false,
  //           errorMessage: '',
  //         });
  //       }
  //     });
  // };

  getLoginType = ({ username, password, fio, posId, fingerprint, companyId }) => fetch('/user/login', {
    method: 'POST',
    body: {
      username: fio || username,
      password,
      ...(fio && { fio }),
      ...(posId && { posId }),
      ...(fingerprint && { fingerprint }),
      ...(companyId && { projectId: companyId }),
    },
    noDefaultNotification: true,
  })
    .then(result => result.result.authType)
    .catch((err) => {
      if (err.response.status === 401) {
        return Promise.reject(new Error('Пользователь не найден'));
      }

      if (err.response.status === -1) {
        if (process.env.DRIVER_ADDRESS.indexOf('http://localhost') !== -1 || true) {
          console.warn('RestartStunnel on auth');

          const driverUrl = window.localStorage.getItem('DRIVER_ADDRESS');

          const urlString = driverUrl || process.env.DRIVER_ADDRESS;

          return fetch('/RestartStunnel', {
            backendUrl: urlString,
            prefix: '/fr',
            notJson: true,
          })
            .then(result => result.text())
            .then(result => new Promise((resolve, reject) => {
              if (result === '{ok}') {
                setTimeout(() => {
                  fetch('/user/login', {
                    method: 'POST',
                    body: { username, password },
                    noDefaultNotification: true,
                  })
                    .then(res => res.result.authType)
                    .then(resolve);
                }, 1000);
              } else {
                return reject(new Error('Ошибка в перезапуске станнела'));
              }
            }))
            .catch(err => Promise.reject(new Error('Возникли неполадки с сетевым соединением. Проверьте его и повторите попытку.')));
        }
        return Promise.reject(new Error('Возникли неполадки с сетевым соединением. Проверьте его и повторите попытку.'));
      }
      return Promise.reject(new Error('Внутренняя ошибка'));
    });

  sendAuth = () => {
    const { username, password, FIO: fio, posId, fingerprint, companyId } = this.state;
    if (username === '' || password === '') {
      this.setState({ showError: true, errorMessage: 'Введите логин и пароль' });
      return;
    }
    this.setState({ loading: true });
    this.getLoginType({ username, password, fio, posId, fingerprint, companyId })
      .then((loginType) => {
        const loginBySelectedType = this.loginByType[loginType];
        if (!loginBySelectedType) {
          return Promise.reject(new Error('Неизвестный тип аутентификации'));
        }
        return loginBySelectedType({ username, password, fio, fingerprint, posId, companyId });
      })
      .then((result) => {
        const access_token = result.result.access_token;
        window.localStorage.setItem('access_token', access_token);
        // if (process.env.GROUP_IB_ENABLED && window.gib) {
        //   window.gib.setLogin(window.sha1(username));
        // }
        this.props.updatePageLoading({ pageLoading: true });
        return this.props.setAuthed();
      })
      .then(() => {
        this.setState({ loading: false });
      })
      .catch(err => {
        this.setState({ showError: true, errorMessage: err.message || err, loading: false });
        this.props.updatePageLoading({ pageLoading: false });
      });
  };

  render() {
    const { pageLoading } = this.props;

    if (pageLoading) return null;

    const {
      username, usernameDisabled, password, loading, FIO, showError, errorMessage, companyId
    } = this.state;

    // const formatedSertItems = crtIds;
    //   .map(id => {
    //   return { label: id, value: id};
    // });
    // return null;

    return (
      <div className="auth-component">
        <div className="auth-form-wrap">
          <div className="auth-form">
            <BoxRetailLogo className="box-retail-logo" />
            <h3 className="auth-title">Авторизация</h3>
            <ClassicForm onSubmit={this.sendAuth} focusFirstInput>
              <div className="form-element">
                <div className="ui-input-with-right-icon auth-inputs">
                  {!FIO && (
                    <ClassicValidateInput
                      type="text"
                      className="company-id-input"
                      value={companyId}
                      label="ID или короткое имя проекта"
                      placeholder=""
                      name="companyId"
                      onChange={this.onCompanyIdChange}
                    />
                  )}
                  <ClassicValidateInput
                    type="text"
                    className={classnames({ 'custom-disabled': usernameDisabled })}
                    value={FIO || username}
                    label="Почта"
                    disabled={usernameDisabled || !!FIO}
                    placeholder=""
                    name="username"
                    onChange={this.onLoginChange}
                    // onFocus={this.onFieldFocus}
                  />
                  <ClassicValidateInput
                    className="auth-password-input"
                    type="text"
                    value={password}
                    label="Пароль"
                    placeholder=""
                    onChange={this.onPasswordChange}
                    // onFocus={this.onFieldFocus}
                  />
                  {/*{crtIds.length > 1 && (*/}
                  {/*  <Select*/}
                  {/*    returnObj*/}
                  {/*    label="Сертификат"*/}
                  {/*    value={localStorage.getItem('rutoken_cert') || ''}*/}
                  {/*    placeholder="Выберите сертифкат"*/}
                  {/*    onChange={(item) => {*/}
                  {/*      if (item.value === 'default') return;*/}
                  {/*      localStorage.setItem('rutoken_cert', item.value);*/}
                  {/*      this.forceUpdate();*/}
                  {/*    }}*/}
                  {/*    items={formatedSertItems}//label and value*/}
                  {/*  />*/}
                  {/*)}*/}
                </div>
              </div>

              <AnimatedAlert in={showError} color={AnimatedAlert.Color.red}>
                {errorMessage}
              </AnimatedAlert>

              <div className="form-footer">
                <Button
                  label="Войти"
                  color={Button.Color.blue}
                  size="wide"
                  loading={loading}
                  type="submit"
                  className="auth-button"
                />
              </div>
            </ClassicForm>
            {/*<div className="bottom-text">*/}
            {/*  <a className="download-link" href={process.env.DOWNLOAD_URL} target="_blank" rel="noopener noreferrer">*/}
            {/*    <span>Скачать дистрибутив</span>*/}
            {/*    <DownloadIcon />*/}
            {/*  </a>*/}
            {/*</div>*/}
            <div className="auth-problems-text">
              При проблемах с авторизацией обратитесь
              <br />
              в онлайн чат в правом нижнем углу экрана
            </div>
          </div>
        </div>
        <div className="auth-illustration-wrap" />
      </div>
    );
  }
}
export default withRouter(Auth);
