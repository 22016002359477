import React from 'react';
import classnames from 'classnames';
import ConditionalTooltip from '../../../../components/ui/ConditionalTooltip';
import Error from '../ErrorTooltip';

const MyComponent = React.memo(({ params, logicParams, component }) => {
  const Component = component;
  const { input, meta: { valid, touched } } = params;
  const { styleFromParent, item } = logicParams;
  const fileInputDefaultValue = item.fileInputConfig && item.fileInputConfig.multiple ? [] : '';

  return (
    <div className={classnames('form-item', { valid: touched && valid, invalid: touched && !valid })} style={styleFromParent} key={`${item.field_name}-wrap`}>
      <label className="input-label">{item.caption}</label>
      <ConditionalTooltip hint={item.hint}>
        {item.type === 'file'
            ? (<Component {...logicParams} {...input} value={input.value ? input.value : fileInputDefaultValue} />)
            : (<Component {...logicParams} {...input} />)
         }
      </ConditionalTooltip>
      <Error name={item.field_name} key={`${item.field_name}+key`} hint={item.fieldHint} />
    </div>
  );
});

export default function FinalFormPropsWrapper(Component, logicParams) {
  return (params) => {
    return (
      <MyComponent params={params} logicParams={logicParams} component={Component} />
    );
  };
}
