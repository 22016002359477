import React from 'react';
import classnames from 'classnames';
import AnimatedAlert from '../../../components/ui/AnimatedAlert';
import Form from '../../../components/ui/Form';
import Input from '../../../components/ui/Input';
import Button from '../../../components/ui/Button';
import MarketplaceApi from '../utils/marketplaceApi';
import MaskInput from '../../../components/ui/MaskInput';

class SmsAuth extends React.Component {
  constructor(props) {
    super(props);

    const { user } = props;

    const posId = {
      posId: (user.info.user && user.info.user.outletExtra && user.info.user.outletExtra['1c_point_code']) || '',
      userId: (user.info.user && user.info.user.fullName) || '',
      dealerId: (user.info.user && user.info.user.outletExtra && user.info.user.outletExtra.DLRID) || '',
    };

    this.marketplaceApi = new MarketplaceApi(posId);
    this.state = {
      phoneNumber: '',
      smsCode: '',
      error: false,
      errorMsg: '',
      smsCalled: false,
      counter: 10,
      reCallSms: false,
    };
  }

  componentDidMount() {
    this.props.updatePageLoading({ pageLoading: false });
  }

  requestSms = () => {
    const phone = this.state.phoneNumber.replace(/[^0-9]/g, '');

    if (phone.length !== 11) {
      this.setState({ error: true, errorMsg: 'Введен некорректный номер телефона' });
      return;
    }

    this.setState({ error: false });

    // console.log('OBJ KEYS ', Object.keys(this.marketplaceApi), typeof this.marketplaceApi.callForSmsCode);
    this.marketplaceApi.callForSmsCode(phone).then(() => {
      this.setState({ error: false, errorMsg: '', smsCalled: true, reCallSms: false });
      // console.log('this.marketplaceApi ', this.marketplaceApi);
      this.timer();
    }, () => {
      this.setState({ error: true, errorMsg: 'При запросе смс кода возникла ошибка, попробуйте позднее.', smsCalled: true, reCallSms: false }, () => {
      // this.setState({ error: false, errorMsg: '', smsCalled: true, reCallSms: false }, () => {
        this.timer();
      });
    });
  };

  smsAuth = () => {
    this.marketplaceApi.verifyCode(this.state.smsCode).then((result) => {
      this.setState({ error: false, errorMsg: '' }, () => {
        //TODO redux stuff+redirect
        // console.log('AAAAAAAAAA', result);
        this.props.addMarketplaceData({
          phone: this.marketplaceApi.phone,
          authToken: result.accessToken,
        });
        this.props.history.push('/form/drugie_pravila');
        // console.log('GOGO REDIRECT', this.marketplaceApi);
      });
    }).catch(() => {
      this.setState({ error: true, errorMsg: 'Некорректный код из смс' });
    });
  };

  timer = () => {
    // this.setState({ counter: 10 });
    if (this.state.counter === 0) {
      this.setState({ counter: 10 }, () => {
        this.countdown();
      });
    } else {
      this.countdown();
    }
  };

  countdown = () => {
    setTimeout(() => {
      const counter = this.state.counter - 1;
      this.setState({ counter }, () => {
        if (this.state.counter === 0) {
          this.setState({ reCallSms: true });
          // controller.counter = 10;
          // controller.isPhoneBlocked = false;
        } else {
          this.countdown();
        }
      });
    }, 1000);
  };

  onPhoneChange = phoneNumber => this.setState({ phoneNumber, smsCalled: false, reCallSms: false });

  onSmsCodeChange = smsCode => this.setState({ smsCode });

  hideError = () => this.setState({ error: false, errorMsg: '' });

  render() {
    const {
      phoneNumber, smsCode, error, errorMsg, smsCalled, reCallSms, counter,
    } = this.state;


    const timerShown = counter !== 0;

    return (
      <div className="auth-component">
        <div className="auth-form sms-auth">
          <h3 className="auth-title center-text-align">Для продолжения введите свой номер телефона Tele2</h3>
          <div className="separate" />
          <Form onSubmit={smsCalled ? this.smsAuth : this.requestSms} focusFirstInput>
            <div className="form-element">
              <div className="ui-input-with-right-icon auth-inputs">
                <MaskInput
                  className="custom-disabled"
                  autofocus
                  type="text"
                  label="Номер телефона"
                  mask="+7\ (999) 999-99-99"
                  value={phoneNumber}
                  placeholder=""
                  autocomplete="off"
                  name="username"
                  onChange={this.onPhoneChange}
                  onFocus={this.hideError}
                />
                {smsCalled && (
                  <Input
                    className="auth-password-input"
                    type="text"
                    label="Код подтверждения из смс"
                    value={smsCode}
                    placeholder=""
                    onChange={this.onSmsCodeChange}
                    onFocus={this.hideError}
                  />
                )}
                {smsCalled && (
                  <p
                    className={classnames('sms-timer', { active: reCallSms })}
                    onClick={timerShown ? () => {} : this.requestSms}
                  >
                    {`Повторно отправить код из смс ${timerShown ? `00:${counter < 10 ? '0' : ''}${counter}` : ''}`}
                  </p>
                )}
              </div>
            </div>
            <AnimatedAlert in={error} color={AnimatedAlert.Color.red}>
              {errorMsg}
            </AnimatedAlert>
            <div className="form-footer">
              <Button
                label={smsCalled ? 'Войти' : 'Получить смс код'}
                color={Button.Color.blue}
                size="wide"
                loading={false}
                type="submit"
                className="auth-button"
              />
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

export default SmsAuth;
