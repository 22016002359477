export const defaultProviderConfigByCat = {
  bank: {
    name: 'Банковские операции',
  },
  insurance: {
    name: 'Страховки',
  },
  transfer: {
    name: 'Денежные переводы',
  },
  other: {
    name: 'Другое',
  },
  mobile: {
    name: 'Мобильная связь',
  },
  "kommunal'nye_plategi": {
    name: 'Коммунальные платежи',
  },
  net_phones_tv: {
    name: 'Мобильная связь',
  },
  travel: {
    name: 'Туризм',
  },
  duty: {
    name: 'Штрафы и налоги',
  },
  tickets: {
    name: 'Билеты',
  },
  stoloto: {
    name: 'Лотерейные билеты',
  },
};

export const defaultProviderImg = '/assets/svg/txs-history-category-icons/other.svg';

export const getProviderConfig = (provider, cat, providersMap) => {
  if (provider === 'stoloto') return defaultProviderConfigByCat.stoloto;

  return providersMap[cat] || { logo: defaultProviderImg, name: 'Неизвестная категория' };
};
