import {
  LOAD_FORM_DATA, LOAD_FORM, REMOVE_FORM, REMOVE_FORM_DATA, REMOVE_FORM_AND_FORM_DATA,
} from '../constants/actionTypes';


export function loadFormData(formData) {
  return {
    type: LOAD_FORM_DATA,
    payload: formData,
  };
}

export function loadForm(formData) {
  return {
    type: LOAD_FORM,
    payload: formData,
  };
}

export function removeForm() {
  return {
    type: REMOVE_FORM,
  };
}

export function removeFormData() {
  return {
    type: REMOVE_FORM_DATA,
  };
}

export function removeFormAndFormData() {
  return {
    type: REMOVE_FORM_AND_FORM_DATA,
  };
}
