import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import RadioFinalField from './RadioFinalField';
import Condition from './Condition';

export default function RadioFinalFieldWrap(props) {
  const {
    item, values, styleFromParent, visible, disabled,
    validation, change, resetFieldState, updateRestPromise,
  } = props;

  useEffect(() => {
    if (!visible) {
      change(item.field_name, undefined);
    }
  }, [visible]);

  return (
    <Condition visible={visible}>
      <RadioFinalField
        item={item}
        rest={item.rest}
        values={values}
        stl={styleFromParent}
        visible={visible}
        disabled={disabled}
        validation={validation}
        changeValue={change}
        resetFieldState={resetFieldState}
        updateRestPromise={updateRestPromise}
      />
    </Condition>
  );
}

RadioFinalFieldWrap.propTypes = {
  item: PropTypes.object.isRequired,
  values: PropTypes.object,
  styleFromParent: PropTypes.object,
  visible: PropTypes.bool,
  disabled: PropTypes.bool,
  validation: PropTypes.func,
  change: PropTypes.func,
  resetFieldState: PropTypes.func,
  updateRestPromise: PropTypes.func,
};

RadioFinalFieldWrap.defaultProps = {
  values: {},
  styleFromParent: {},
  visible: false,
  disabled: false,
  validation: () => {},
  change: () => {},
  resetFieldState: () => {},
  updateRestPromise: () => {},
};
