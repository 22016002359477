import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export default class FormTooltip extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
  };

  static defaultProps = {
    className: '',
  };

  render() {
    const { children, className } = this.props;

    return (
      <div className={classnames('ui-form-tooltip', className)}>
        <div className="ui-form-tooltip-content-wrap">
          <div className="ui-form-tooltip-content">
            {children}
          </div>
        </div>
      </div>
    );
  }
}
