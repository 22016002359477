import React from 'react';
import PropTypes from 'prop-types';
import { ISO_VALUE } from '../../../constants/currencies';
import { formatMoneyWithCurrency } from '../../../utils/format';
import newid from '../../../utils/newid';
import Button from '../../../components/ui/Button';
import CmdFormatter from '../../../utils/CmdFormatter';
import { objectDotNotationGetter } from '../../../utils/objectDotNotationGetter';

// const infoItemsObject = {
//   selectedCountry: { label: 'Страна получения', value: '' },
//   priceToGive: { label: 'Сумма к выдаче', value: '' },
//   comission: { label: 'Комиссия', value: '' },
//   trnRate: { label: 'Курс', value: '' },
// };

function isEmpty(obj) {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
}

// const objectToArray = obj => Object.keys(obj).map(key => obj[key]);

class RightBillingInfo extends React.PureComponent {
  static propTypes = {
    catalogFormFillState: PropTypes.object.isRequired,
    trnRate: PropTypes.number,
    comission: PropTypes.number,
    full_amount: PropTypes.number,
    selectedCurrency: PropTypes.string,
    vskDiscount: PropTypes.string,
    realDiscount: PropTypes.string,
    location: PropTypes.object,
    itogo: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  };

  static defaultProps = {
    selectedCurrency: '₽',
    trnRate: undefined,
    comission: undefined,
    full_amount: undefined,
    itogo: undefined,
    vskDiscount: '',
    realDiscount: '',
  };

  constructor(props) {
    super(props);
    const { catalogFormFillState } = props;
    const infoBlocks = (typeof catalogFormFillState.infoBlocks !== 'undefined' && !isEmpty(catalogFormFillState.infoBlocks)) ? catalogFormFillState.infoBlocks[0] : {
      id: 'payment',
      label: 'Информация о платеже',
      fields: [
        {
          label: 'Курс',
          fieldReference: 'trnRate',
        },
        {
          label: 'Сумма без комиссии',
          currencyReference: 'values.currency',
          fieldReference: 'amount',
          type: 'amountWithCurrency',
        },
        {
          fieldReference: 'comission',
          label: 'Комиссия',
          type: 'amountWithCurrency',
        },
        {
          fieldReference: '"vskDiscount"',
          label: 'Скидка',
          type: 'amountWithCurrency',
        },
      ],
      resultField: {
        fieldReference: 'full_amount',
        label: 'Итого к оплате',
        type: 'amountWithCurrency',
      },
    };//todo default object

    const addBlocks = (catalogFormFillState.infoBlocks && catalogFormFillState.infoBlocks.length > 1)
      ? catalogFormFillState.infoBlocks[1]
      : null;

    this.state = { infoBlocks, addBlocks, loading: false };
  }


aliPrint = (url) => {
  const { outletExtra } = this.props;

  const urlToprint = outletExtra[url];

  if (!urlToprint) return;

  this.setState({ loading: true });

  const cmd = new CmdFormatter(null);

  cmd.command('QRCode', urlToprint);
  cmd.command('run', [2]);
  cmd.command('cut', [0]);

  const driverUrl = window.localStorage.getItem('DRIVER_ADDRESS');

  const urlString = driverUrl ? `${driverUrl}/fr` : `${process.env.DRIVER_ADDRESS}/fr`;

  cmd.callFiscal('shtrih-m', 'COM3', urlString, cmd.cmd).then((result) => {
    this.setState({ loading: false });
  })
    .catch(() => {
      this.setState({ loading: false });
    });

  setTimeout(() => {
    this.setState({ loading: false });
  }, 1000);
}

render() {
  const {
    catalogFormFillState, itogo, vskDiscount, realDiscount, location,
  } = this.props;

  const { infoBlocks, addBlocks, loading } = this.state;
  const blockLabel = infoBlocks.label;
  const total = typeof itogo !== 'undefined' ? formatMoneyWithCurrency({ amount: itogo, symbol: true }) : '-';
  const infoItemsArray = infoBlocks.fields
    .filter((block) => {
      const rateValue = objectDotNotationGetter(catalogFormFillState, block.fieldReference);
      return !((block.fieldReference === 'rate' || block.fieldReference === 'trnRate') && (typeof rateValue === 'undefined' || rateValue === 1));
    })
    .map((block) => {
      const value = objectDotNotationGetter(catalogFormFillState, block.fieldReference);

      let finalValue;

      if (typeof value === 'object') {
        finalValue = value.name;
      }

      if (typeof value === 'string' || typeof value === 'number') {
        finalValue = value;
      }

      if (block.type === 'amountWithCurrency') {
        let currency;

        if (typeof block.currencyReference === 'string') {
          currency = objectDotNotationGetter(catalogFormFillState, block.currencyReference);
        }

        if (block.fieldReference === 'amount' && finalValue == -1) {
          finalValue = total;
          return {
            label: block.label,
            value: finalValue,
          };
        }

        if (block.fieldReference === 'vskDiscount') {
          finalValue = vskDiscount;
        }

        finalValue = typeof finalValue !== 'undefined' ? formatMoneyWithCurrency({ amount: finalValue == -1 ? 0 : finalValue, currency: currency ? ISO_VALUE[currency.value] : 643, symbol: true }) : '-';
      }


      // risky business (TODO: move to backend)
      if (block.fieldReference === 'rate' || block.fieldReference === 'trnRate') {
        return {
          label: block.label,
          value: typeof finalValue !== 'undefined' ? formatMoneyWithCurrency({ amount: finalValue, exponent: 0, symbol: true }) : '-',
        };
      }

      return {
        label: block.label,
        value: typeof finalValue !== 'undefined' ? finalValue : '-',
      };
    });

  //legacy open
  // eslint-disable-next-line
    // infoItemsObject.selectedCountry.value = selectedCountry;
  //
  // // currency: 643, exponent: 2 (it's defaults, but need to pass there from back)
  // infoItemsObject.priceToGive.value = formatMoneyWithCurrency({ amount: full_amount });
  // infoItemsObject.comission.value = formatMoneyWithCurrency({ amount: comission || 0 });
  // infoItemsObject.trnRate.value = trnRate;
  // const infoItemsArray = objectToArray(infoItemsObject);

  // //legacy close
  //

  const showItogo = typeof infoBlocks.hidden === 'undefined' || infoBlocks.hidden;

  return (
    <React.Fragment>
      <div className="right-info-block right-billing-info">
        <div className="info-title">{blockLabel}</div>
        <ul className="info-list">
          {infoItemsArray.map(item => (
            <li className="info-item" key={newid()}>
              <span className="info-item-label" dangerouslySetInnerHTML={{ __html: item.label }} />
              <span className="info-item-value" dangerouslySetInnerHTML={{ __html: item.value }} />
            </li>
          ))}
        </ul>
        {showItogo && (
          <div className="info-summary info-item">
            <span className="info-item-label">{catalogFormFillState.form.id === 'complex_plus_barcode_discount' ? 'Итоговая скидка' : 'Итого к оплате'}</span>
            <span className="info-item-value">
              {catalogFormFillState.form.id === 'complex_plus_barcode_discount'
                ? formatMoneyWithCurrency({ amount: +realDiscount, symbol: true })
                : total}
            </span>
          </div>
        )}
      </div>
      {addBlocks && (
      <div className="right-info-block right-billing-info with-top-margin center-items">
        {addBlocks.label && (
          <div className="info-title">
            {`${addBlocks.label}`}
          </div>
        )}
        {addBlocks.fields.map(field => (
          <React.Fragment>
            {field.type === 'text' && (
              <span className="info-item-label full-width">{field.text}</span>
            )}
            {field.type === 'button' && field.action === 'print' && (
              <Button className="print-button" label="Печать" onClick={() => this.aliPrint(field.userKey)} color={Button.Color.blue} loading={loading} />
            )}
          </React.Fragment>
        ))}
      </div>
      )}
    </React.Fragment>
  );
}
}

export default RightBillingInfo;
