import React from 'react';
import TransactionsConfirmationContainer from './components/TransactionsConfirmationContainer';

import './styles/transactions-confirmation.scss';

export default function TransactionsConfirmationPage(props) {
  return (
    <TransactionsConfirmationContainer {...props} />
  );
}
