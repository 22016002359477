import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import onClickOutside from 'react-onclickoutside';
import { Scrollbars } from 'react-custom-scrollbars';
import { CSSTransition } from 'react-transition-group';

class Select extends React.PureComponent {
  static propTypes = {
    value: PropTypes.any.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })).isRequired,
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    returnObj: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    onToggle: PropTypes.func,
  };

  static defaultProps = {
    returnObj: false,
    placeholder: '',
    disabled: false,
    onChange: () => {},
    onToggle: () => {},
  };

  constructor(props) {
    super(props);

    this.state = {
      opened: false,
      focused: false,
      focusedItem: 0,
    };

    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    window.document.addEventListener('keydown', this.onKeyDown);
  }

  componentWillUnmount() {
    window.document.removeEventListener('keydown', this.onKeyDown);
  }

  onItemClick(item) {
    if (item.disabled) {
      return;
    }

    this.setState({ focusedItem: this.props.items.findIndex(i => item.value === i.value) });

    this.toggle();
    if (this.props.returnObj) {
      this.props.onChange(item);
    } else {
      this.props.onChange(item.value);
    }
  }

  onKeyDown = (e) => {
    const { items } = this.props;
    const { focused, opened, focusedItem } = this.state;

    if (e.keyCode === 38 && opened) {
      e.preventDefault();
      this.setState(prevState => ({ focusedItem: prevState.focusedItem > 0 ? prevState.focusedItem - 1 : 0 }), () => {
        const scrollWrap = this.scrollInner.parentElement;
        const focusEl = this.scrollInner.getElementsByClassName('select-item')[this.state.focusedItem];
        if (scrollWrap.scrollTop > focusEl.offsetTop) {
          scrollWrap.scrollTop = focusEl.offsetTop;
        }
      });
    }

    if (e.keyCode === 40 && opened) {
      e.preventDefault();
      this.setState(prevState => ({
        focusedItem: prevState.focusedItem + 1 >= items.length ? prevState.focusedItem : prevState.focusedItem + 1,
      }), () => {
        const scrollWrap = this.scrollInner.parentElement;
        const focusEl = this.scrollInner.getElementsByClassName('select-item')[this.state.focusedItem];
        if (focusEl.offsetTop - scrollWrap.scrollTop > scrollWrap.clientHeight - focusEl.clientHeight) {
          scrollWrap.scrollTop = (focusEl.offsetTop - scrollWrap.clientHeight) + focusEl.clientHeight;
        }
      });
    }

    if ((e.keyCode === 13 || e.keyCode === 32) && focused) {
      e.preventDefault();
      this.toggle();
      if (opened && !items[focusedItem].disabled) {
        this.props.onChange(items[focusedItem].value);
      }
    }
  };

  onFocus = () => {
    this.setState({ focused: true });
  };

  onBlur = () => {
    this.setState({ focused: false });
  };

  getLabelByValue(value) {
    const item = this.props.items.find(i => i.value === value);

    if (this.props.value === '') {
      return this.props.placeholder;
    }

    if (!item) {
      throw new Error(`Value not found: ${value}`);
    }

    return item.label;
  }

  getItems() {
    const { focusedItem } = this.state;
    return this.props.items.map((item, i) => (
      <div
        key={item.value}
        className={classnames('select-item', { active: item.value === this.props.value, disabled: item.disabled, focused: focusedItem === i })}
        onClick={() => this.onItemClick(item)}
        dangerouslySetInnerHTML={{ __html: item.label }}
      />
    ));
  }

  toggle() {
    if (this.props.disabled) return;
    this.setState({
      opened: !this.state.opened,
    }, () => {
      const scrollWrap = this.scrollInner.parentElement;
      const focusEl = this.scrollInner.getElementsByClassName('select-item')[this.state.focusedItem];

      if (scrollWrap.scrollTop > focusEl.offsetTop) {
        scrollWrap.scrollTop = focusEl.offsetTop;
      }

      if (focusEl.offsetTop - scrollWrap.scrollTop > scrollWrap.clientHeight - focusEl.clientHeight) {
        scrollWrap.scrollTop = (focusEl.offsetTop - scrollWrap.clientHeight) + focusEl.clientHeight;
      }
    });
  }

  handleClickOutside() {
    this.setState({
      opened: false,
    });
  }

  render() {
    const items = this.getItems();

    const className = classnames('ui-select', {
      disabled: this.props.disabled,
      notSelected: this.props.value === '',
      opened: this.state.opened && !this.props.disabled,
    });

    return (
      // eslint-disable-next-line
      <div className={className}>
        <p className="select-label">{this.props.label}</p>
        <div className="select-inner" tabIndex="0" onClick={this.toggle} onFocus={this.onFocus} onBlur={this.onBlur}>
          <div className="select-wrap">
            <p className="select-value" dangerouslySetInnerHTML={{ __html: this.getLabelByValue(this.props.value) }} />
          </div>
          <span className="select-arrow" />
        </div>
        <CSSTransition
          classNames="select-items"
          timeout={200}
          in={this.state.opened && !this.props.disabled}
          mountOnEnter
          unmountOnExit
        >
          <div className="select-items">
            <Scrollbars
              ref={ref => this.scroll = ref}
              autoHeight
              autoHeightMin={0}
              autoHeightMax={200}
              renderTrackVertical={props => <div {...props} className="ui-track-vertical" />}
              renderThumbVertical={props => <div {...props} className="ui-thumb-vertical" />}
            >
              <div className="select-items-inner" ref={ref => this.scrollInner = ref}>
                {items}
              </div>
            </Scrollbars>
          </div>
        </CSSTransition>
      </div>
    );
  }
}

export default onClickOutside(Select);
