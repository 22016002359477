import { connect } from 'react-redux';
import { getCats } from '../../../actions/catalog';
import { updatePageLoading } from '../../../actions/ui';
import TransactionsConfirmation from './TransactionsConfirmation';
import { loadTransaction, removeTransaction } from '../../../actions/transaction';

const mapStateToProps = state => ({
  transaction: state.transaction, //todo we'll probably need it later
  cats: state.catalog.cats,
  pageLoading: state.ui.pageLoading,
  user: state.user,
});

const mapDispatchToProps = {
  getCats,
  loadTransaction,
  removeTransaction,
  updatePageLoading,
};

const TransactionsConfirmationContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TransactionsConfirmation);

export default TransactionsConfirmationContainer;
