import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import CatalogCard from '../../../components/ui/CatalogCard';
import CategorySearchInput from '../../../components/ui/CategorySearchInput';
import { ISO_VALUE } from '../../../constants/currencies';
import { formatMoneyWithCurrency } from '../../../utils/format';

import ArrowDown from '../../../assets/img/icons/arrow-down.svg';

let statsPollingTimerId = 0;

class CatalogShowcase extends React.Component {
  static propTypes = {
    catalog: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    pageLoading: PropTypes.bool.isRequired,
    getStats: PropTypes.func.isRequired,
    getCats: PropTypes.func.isRequired,
    getAllCatalogItems: PropTypes.func.isRequired,
    updatePageLoading: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      fallbackLoading: true,
      currentCat: {},
    };

    this.rootCatsPromise = null;
  }

  componentDidMount() {
    this._isMounted = true;
    this.rootCatsPromise = Promise.all([this.props.getCats('root'), this.props.getAllCatalogItems()])
      .then(() => {
        if (typeof this.props.match.params.catalogType !== 'undefined') {
          this.fetchDataBasedOnUrl();
        } else {
          this.setState({ fallbackLoading: false });
          this.props.updatePageLoading({ pageLoading: false });
        }
      })
      .catch((err) => {
        console.log(err);
      });

    if (typeof this.props.match.params.catalogType === 'undefined') {
      this.startStatsPolling();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.catalogType !== this.props.match.params.catalogType) {
      clearTimeout(statsPollingTimerId);
      if (typeof this.props.match.params.catalogType !== 'undefined') {
        this.rootCatsPromise
          .then(() => {
            this.fetchDataBasedOnUrl();
          });
      } else {
        this.props.updatePageLoading({ pageLoading: false });
        this.startStatsPolling();
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearTimeout(statsPollingTimerId);
    this.props.updatePageLoading({ pageLoading: true });
  }

  onBreadClick = () => {
    const { currentCat } = this.state;

    this.props.updatePageLoading({ pageLoading: true });
    if (currentCat.cat) {
      this.props.history.push(`/catalog/${currentCat.cat}`);
    } else {
      this.props.history.push('/catalog');
    }
  };

  startStatsPolling = () => {
    const pollInterval = 30000;

    const checkStats = () => {
      this.props.getStats()
        .then(() => {
          if (this._isMounted) {
            statsPollingTimerId = setTimeout(checkStats, pollInterval);
          }
        })
        .catch((err) => {
          console.log('getStats request error:');
          console.log(err);
          if (this._isMounted) {
            statsPollingTimerId = setTimeout(checkStats, pollInterval);
          }
        });
    };

    checkStats();
  };

  fetchDataBasedOnUrl = () => {
    const { match: { params: { catalogType } }, catalog } = this.props;
    if (typeof catalogType !== 'undefined') {
      const currentCat = catalog.cats.root.byId[catalogType] || catalog.allItems.find(item => item.id === catalogType);

      if (currentCat) {
        this.setState({ currentCat });
      }
    }
    this.props.getCats(catalogType)
      .then(() => {
        this.setState({ fallbackLoading: false });
        this.props.updatePageLoading({ pageLoading: false });
        if (!this.props.catalog.cats[catalogType]) {
          this.props.history.replace('/404');
        }
      })
      .catch((e) => {
        console.warn(e);
        this.props.history.replace('/404');
      });
  };

  categoryName = () => {
    const { match, catalog } = this.props;
    if (typeof match.params.catalogType !== 'undefined') {
      const curCat = catalog.cats.root.byId[match.params.catalogType];
      if (typeof curCat !== 'undefined') {
        return curCat.name;
      }
    }
    return '';
  };

  onCatSelect = (value) => {
    this.props.history.push(`/form/${value}`);
  };

  renderSearchBar = () => {
    const { catalog: { allItems, cats }, match: { params: { catalogType } } } = this.props;

    let catsArr = [];

    if (allItems.length && cats.root) {
      const catsObj = {};

      allItems.filter(item => (!catalogType || item.cat === catalogType)
        && (cats.root.byId[item.cat] && cats.root.byId[item.cat].visible && !cats.root.byId[item.cat].disabled)).forEach((item) => {
        if (!catsObj[item.cat]) {
          catsObj[item.cat] = {
            id: item.cat,
            name: cats.root.byId[item.cat].name || 'Другое',
            logo: cats.root.byId[item.cat].logo || cats.root.byId.others.logo,
            items: [],
          };
        }
        catsObj[item.cat].items.push(item);
      });
      catsArr = Object.values(catsObj);
    }

    return (
      <div className="catalog-search-block">
        <div className="container">
          <CategorySearchInput categories={catsArr} onCategoryClick={this.onCatSelect} />
        </div>
      </div>
    );
  };

  render() {
    const { fallbackLoading, currentCat } = this.state;
    const {
      catalog, user, pageLoading, history,
      match: { params: { catalogType } },
      updatePageLoading,
    } = this.props;

    if (fallbackLoading || pageLoading) return null;

    const { stats } = catalog;
    const rubleStats = stats[ISO_VALUE.RUB];
    const { turnover, average, operations, exponent } = rubleStats; // TODO: design and develop other currencies output

    const rootCat = catalog.cats.root;
    // const defaultLogo = catalogType && rootCat && rootCat.byId[catalogType] ? `/assets/svg/category-icons/${catalogType}.svg` : '/assets/svg/product-icons/default.svg';
    const turnoverValue = formatMoneyWithCurrency({ amount: turnover, exponent, symbol: true });
    const averageValue = formatMoneyWithCurrency({ amount: average, exponent, symbol: true });

    const rootCats = catalog.cats.root ? catalog.cats.root.allIds.map(id => catalog.cats.root.byId[id]).filter(cat => typeof cat.visible === 'undefined' || cat.visible) : [];
    const subCats = catalog.cats[catalogType] ? catalog.cats[catalogType].allIds.map(id => catalog.cats[catalogType].byId[id]) : [];

    const cardArray = catalogType ? subCats : rootCats;

    const favouritesArray = user.info && (user.info.popular || []);

    const parentCat = currentCat && currentCat.cat && rootCat && rootCat.byId[currentCat.cat];

    return (
      <div className="catalog-showcase-page">
        {!catalogType && (
          <div className="user-stats-block">
            <div className="container">
              <div className="user-stats-list">
                <div className="user-stats-card">
                  <div className="user-stats-title">Оборот средств</div>
                  <div className="user-stats-money">{turnoverValue}</div>
                </div>
                <div className="user-stats-card">
                  <div className="user-stats-title">Средний чек</div>
                  <div className="user-stats-money">{averageValue}</div>
                </div>
                <div className="user-stats-card">
                  <div className="user-stats-title">Операций</div>
                  <div className="user-stats-money">{operations}</div>
                </div>
              </div>
            </div>
          </div>
        )}
        {catalogType && (//todo remove later
          <div className="breadcrumbs-block">
            <div className="container">
              <span className="breadcrumb-link" onClick={this.onBreadClick}>
                <ArrowDown className="breadcrumb-link-icon" />
                <span className="breadcrumb-link-text">{parentCat ? parentCat.name : 'Все категории'}</span>
              </span>
              <div className="page-title h1">{this.categoryName()}</div>
            </div>
          </div>
        )}
        {this.renderSearchBar()}
        {(!catalogType && !!favouritesArray.filter(item => rootCat.byId[item.cat]).length) && (
          <div className="favourites-block">
            <div className="container">
              <div className="favourites-title h3">Избранное</div>
              <div className="favourites-list">
                {favouritesArray.filter(item => rootCat.byId[item.cat]).map((card) => {
                  const cat = rootCat && rootCat.byId[card.cat];
                  // const defaultLogo = cat ? '/assets/svg/product-icons/default.svg' : '/assets/svg/category-icons/others.svg';

                  return (
                    <CatalogCard
                      key={card.id}
                      card={card}
                      size={CatalogCard.CardSizes.small}
                      disabled={cat.disabled}
                      history={history}
                      updatePageLoading={updatePageLoading}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        )}
        <div className={classnames('catalog-block', { 'root-catalog-block': !catalogType })}>
          <div className="container">
            {!catalogType && <div className="root-catalog-title h3">Все категории</div>}
            <div className="catalog-cards-list">
              {cardArray.map((card, i) => (
                <div key={card.id} className="catalog-cards-list-item">
                  <CatalogCard
                    card={card}
                    cardIndex={i + 1}
                    history={history}
                    updatePageLoading={updatePageLoading}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CatalogShowcase;
