import React, { Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';


const ComplexPlusDiscountPage = React.lazy(() => import('./complex_plus_discount'))

const Complex_plus_discount = (props) => {

  console.log('dadsada ', props)

  return (
    <Suspense fallback="loading">
      <Switch>
        <Route exact path="/form/complex_plus_discount" component={ComplexPlusDiscountPage} />
      </Switch>
    </Suspense>
  )
}


export default Complex_plus_discount;
