import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import classnames from 'classnames';
import moment from 'moment';
import newid from '../../utils/newid';

export default class DateInput extends React.PureComponent {
  static propTypes = {
    type: PropTypes.string,
    value: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    valid: PropTypes.bool,
    showValidity: PropTypes.bool,
    disabled: PropTypes.bool,
    message: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
  };

  static contextTypes = {
    formContext: PropTypes.object,
  };

  static defaultProps = {
    type: 'text',
    label: '',
    valid: undefined,
    showValidity: false,
    disabled: false,
    placeholder: '',
    message: '',
    value: undefined,
    onBlur: () => {},
    onFocus: () => {},
    onChange: () => {},
  };

  constructor(props) {
    super(props);

    this.state = {
      isEmptyAndFocused: false,
    };

    this.id = newid();
    this.minDate = moment().subtract(100, 'years').format('YYYY-MM-DD');
    this.today = moment().format('YYYY-MM-DD');
    this.maxDate = moment().add(100, 'years').format('YYYY-MM-DD');

    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onFocus = this.onFocus.bind(this);
  }

  // componentDidMount() {
  //   if (this.props.value && this.state.type === 'text') {
  //     this.setState({ type: 'date' });А
  //   }
  // }

  // static getDerivedStateFromProps(props, state) {
  //   if (props.value && state.type === 'text') {
  //     return { ...state, type: 'date' };
  //   }
  //   return null;
  // }

  onChange(e) {
    this.props.onChange(e.target.value);
  }

  onBlur(e) {
    if (!this.props.value) {
      this.setState({ isEmptyAndFocused: false });
    }
    this.props.onBlur(e);
  }

  onFocus(e) {
    if (!this.props.value) {
      this.setState({ isEmptyAndFocused: true });
    }
    this.props.onFocus(e);
  }

  render() {
    const {
      label, value, disabled, placeholder, message, valid, showValidity,
    } = this.props;
    const { isEmptyAndFocused } = this.state;
    const className = classnames('ui-date-input', {
      disabled,
      filled: value && value.length,
      isEmptyAndFocused,
      valid: showValidity && valid === true,
      invalid: showValidity && valid === false,
    });
    const messageShown = showValidity && !!message;

    return (
      <div className={className}>
        {!!label && <label className="input-label" htmlFor={this.id}>{label}</label>}
        <input
          id={this.id}
          placeholder="дд.мм.гггг"
          type="date"
          value={value}
          disabled={disabled}
          onChange={this.onChange}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          min={this.minDate}
          max={this.maxDate}
          autoComplete="off"
        />
        <CSSTransition
          timeout={300}
          in={messageShown}
          classNames="ui-input-msg"
          unmountOnExit
        >
          <div className="ui-input-msg">{message}</div>
        </CSSTransition>
      </div>
    );
  }
}
