import {
  LOAD_FORM_DATA, LOAD_FORM, REMOVE_FORM, REMOVE_FORM_DATA, REMOVE_FORM_AND_FORM_DATA,
} from '../constants/actionTypes';


const initialState = {
  form: {},
  formData: {},
};

export default function externalForm(state = initialState, action) {
  switch (action.type) {
    case LOAD_FORM_DATA:
      return {
        ...state,
        formData: {
          ...action.payload,
        },
      };
    case LOAD_FORM:
      return {
        ...state,
        form: { ...action.payload },
      };
    case REMOVE_FORM:
      return {
        ...state,
        form: { },
      };
    case REMOVE_FORM_DATA:
      return {
        ...state,
        formData: { },
      };
    case REMOVE_FORM_AND_FORM_DATA:
      return {
        ...state,
        formData: { },
        form: { },
      };
    default:
      return { ...state };
  }
}
