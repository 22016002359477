import { ADD_MARKETPLACE_DATA, REMOVE_MARKETPLACE_DATA } from '../constants/actionTypes';

export function addMarketplaceData(mpData) {
  return {
    type: ADD_MARKETPLACE_DATA,
    payload: mpData,
  };
}

export function removeMarketplaceData() {
  return dispatch => dispatch({ type: REMOVE_MARKETPLACE_DATA });
}
