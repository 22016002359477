import fetch from './fetch';

const getStackTrace = function(e = {}) {
  Error.captureStackTrace(e, getStackTrace);
  return e.stack;
};

const logger = function ({ message, data = {}, level = 'info', tag }) {
  const options = {
    level,
    msg: {
      tag,
      kassV: 'V3: 15.03.2019',
      message,
      data: data instanceof Error ? getStackTrace(data) : data,
      userAgent: window.navigator.userAgent,
      clientUrl: window.location.href,
      sessionStorage: JSON.stringify(window.sessionStorage),
    },
  };

  return fetch('/logging', {
    method: 'POST',
    body: options,
    noDefaultNotification: true,
  });
};

export default logger;
