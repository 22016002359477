import { LOAD_TRANSACTION, REMOVE_TRANSACTION } from '../constants/actionTypes';

const initialState = {};

export default function transaction(state = initialState, action) {
  switch (action.type) {
    case LOAD_TRANSACTION:
      return {
        ...state,
        ...action.payload,
      };

    case REMOVE_TRANSACTION:
      return {
        ...initialState,
      };

    default:
      return { ...state };
  }
}
