import React from 'react';
import classnames from 'classnames';
import ArrowDown from '../../../assets/img/icons/arrow-down.svg';
import Button from '../../../components/ui/Button';
import ErrorStatus from '../assets/svg/error-status.svg';

export default (props) => {
  const { onBreadcrumbBackToCatClick, currentCat, form, onCloseWindowClick, onErrorBackToFormClick } = props;

  const actualType = window.sessionStorage.getItem('complexSalesGuid') ? 'простой' : 'сложной';
  const currentType = window.sessionStorage.getItem('complexSalesGuid') ? 'сложной' : 'простой';
  return (
    <div className="catalog-form-fill-page form-page form-page-fill">
      <div className="container">
        <div className="left-fixed-block">
          <div className="breadcrumbs-block">
            <span className="breadcrumb-link" onClick={onBreadcrumbBackToCatClick}>
              <ArrowDown className="breadcrumb-link-icon" />
              <span className="breadcrumb-link-text">{currentCat}</span>
            </span>
            <span className="breadcrumb-link active">
              <span className="breadcrumb-link-text" dangerouslySetInnerHTML={{ __html: form.name || '' }} />
            </span>
          </div>
        </div>
        <div className={classnames('form-fill-content wizard-form')}>
          <div className="payments-phase payments-phase-error status-screen">
            <div className="picture-wrap">
              <ErrorStatus className="picture-svg" />
            </div>
            <div className="finish-step-title">Произошла ошибка</div>
            {/*<div className="error-text">Операция {`"${form.name}"` || ''} должна проводиться по {actualType} продаже, вы пытаетесь провести ее по {currentType}. Закройте данное окно и повторите попытку проведения из 1С.</div>*/}
            <div className="error-text">
              Данная услуга невозможна к оформлению в корзине.
              <pre />
              Закройте данное окно и повторите проведение из 1С
            </div>
            <div className="footer-button-wrap">
              <Button label="Перейти в 1С" color={Button.Color.white} onClick={onCloseWindowClick} />
              <Button label="Вернуться к каталогу" color={Button.Color.blue} onClick={onBreadcrumbBackToCatClick} />
            </div>
          </div>
        </div>
        <div className="right-fixed-block" />
      </div>
    </div>
  );
};
