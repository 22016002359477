const initialState = {
  result: {},
  fetchingUser: false,
  err: undefined,
};

export default function user(state = initialState, action) {
  switch (action.type) {
    case 'app.user.fetchingUser':
      return { ...state, fetchingUser: true };

    case 'app.user.fetchSuccess':
      return { ...state, fetchingUser: false, ...action.payload };

    case 'app.user.clear':
      return { ...state, fetchingUser: false, collection: [] };

    case 'app.user.fetchError':
      return { ...state, fetchingUser: false, result: {}, err: action.err };

    default:
      return state;
  }
}
