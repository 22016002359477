import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import notification from 'antd/lib/notification';
import ConditionalTooltip from '../../../../components/ui/ConditionalTooltip';
import MaskInput from '../../../../components/ui/MaskInput';
import useStateWithRef from '../../../../hooks/useStateWithRef';
import Error from '../ErrorTooltip';
import Condition from './Condition';
import fetch from '../../../../utils/fetch';

export default function PromocodeFinalField(props) {
  const {
    platId, item, value, styleFromParent, disabled, visible, change, setIsAsyncValidationInProgress,
  } = props;

  const [state, setState, stateRef] = useStateWithRef('initial');
  const validValuesCacheRef = useRef({});
  const lastErrorMessageRef = useRef('');

  const validate = useCallback((value) => {
    if ((typeof item.notRequired !== 'undefined' && item.notRequired === true) || !item.required) {
      return undefined;
    }
    if (!value || !value.trim()) {
      return 'Поле обязательно для заполнения';
    }
    if (stateRef.current === 'invalid' && validValuesCacheRef.current[value] === false) { //  && validValuesCacheRef.current[value] === false
      return lastErrorMessageRef.current || 'Промокод недействителен';
    }
    // if (stateRef.current === 'loading') {
    //   return 'Промокод проверяется';
    // }
    // if (stateRef.current === 'initial') {
    //   return 'Промокод еще не проверялся';
    // }
    return '';
  }, [item]);

  // finalFormFieldBlur чтобы зафорсить установку touched у поля после его перерегистрации с помощью измененного key (который подвязан на state)
  const validatePromocode = (value, finalFormFieldBlur) => {
    const promise = new Promise(async (resolve, reject) => {
      if (!value || !value.trim()) {
        finalFormFieldBlur();
        return;
      }

      if (value in validValuesCacheRef.current) {
        setState(validValuesCacheRef.current[value] ? 'valid' : 'invalid');
        finalFormFieldBlur();
        resolve();
        return;
      }

      try {
        setState('loading');
        // setIsAsyncValidationInProgress(true);

        await fetch('/validatePromoCode', { method: 'POST', body: { promoCodeId: `${platId}|${value}` }, noDefaultNotification: true, });

        validValuesCacheRef.current[value] = true;
        setState('valid');
        resolve();
      } catch (e) {
        validValuesCacheRef.current[value] = false;
        lastErrorMessageRef.current = e.message;
        setState('invalid');
        reject();
        notification.open({
          duration: 5,
          description: React.createElement('div', { dangerouslySetInnerHTML: { __html: e.message } }),
        });
      } finally {
        setIsAsyncValidationInProgress(false);
        finalFormFieldBlur();
      }
    });
    // updateRestPromise(promise);
  };

  // useEffect(() => () => {
  //   updateRestPromise(Promise.resolve());
  // }, []);

  return (
    <Condition visible={visible}>
      <Field
        key={state}
        type="text"
        name={item.field_name}
        placeholder={item.placeholder}
        validate={validate}
      >
        {({ input, meta }) => {
          const showValidationError = (state === 'invalid' && validValuesCacheRef.current[input.value] === false) || input.value.trim() === '';

          return (
            <div className="form-item" style={styleFromParent}>
              <label className="input-label">{item.caption}</label>
              <ConditionalTooltip hint={item.hint}>
                <MaskInput
                  value={input.value}
                  type={item.type}
                  mask={item.mask}
                  valid={meta.touched && showValidationError ? meta.valid : true}
                  message={item.comment}
                  placeholder={item.placeholder}
                  {...input}
                  // onKeyDown={(e) => {
                  //   if (e.key === 'Enter') {
                  //     e.persist();
                  //     validatePromocode(input.value, () => { input.onBlur(e); });
                  //   }
                  // }}
                  onChange={(value) => {
                    input.onChange({ target: { name: item.field_name, value } });
                  }}
                  onBlur={(e) => {
                    e.persist();
                    validatePromocode(input.value, () => { input.onBlur(e); });
                  }}
                />
              </ConditionalTooltip>
              {(showValidationError || item.hint) && <Error name={item.field_name} hint={item.fieldHint} />}
            </div>
          );
        }}
      </Field>
    </Condition>
  );
}

PromocodeFinalField.propTypes = {
  item: PropTypes.object.isRequired,
  value: PropTypes.string,
  styleFromParent: PropTypes.object,
  visible: PropTypes.bool,
  disabled: PropTypes.bool,
  validation: PropTypes.func,
  change: PropTypes.func,
  setIsAsyncValidationInProgress: PropTypes.func.isRequired,
};

PromocodeFinalField.defaultProps = {
  value: '',
  styleFromParent: {},
  visible: false,
  disabled: false,
  validation: () => {},
  change: () => {},
};
