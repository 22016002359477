import React, { Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';

const GoldCrownTransfer = React.lazy(() => import('./gold-crown-transfer'));

const GoldCrown = (props) => {
  return (
    <Suspense fallback="">
      <Switch>
        <Route exact path="/form/gold_crown_transfer" component={GoldCrownTransfer} />
      </Switch>
    </Suspense>
  );
};

export default GoldCrown;
