import fetch from '../../../utils/fetch';

export default class MarketplaceApi {
  constructor(POSID) {
    this.clientId = 'teko.test';
    this.pos = POSID;
    this.phone = '';
    this.accessToken = '';
  }

  callForSmsCode = phone => fetch('/eyeline/otpInit',
    {
      method: 'POST',
      body: {
        method: 'otpInit',
        value: {
          clientId: this.clientId,
          method: 'sms',
          msisdn: `${phone}`,
          pos: this.pos,
        },
      },
      noDefaultNotification: true,
      withOverlay: true,
    }).then((result) => {
    this.phone = `${phone}`;
    if (!result.success) {
      return Promise.reject();
    }
    return Promise.resolve();
  })

  verifyCode = code => fetch('/eyeline/otpVerify', {
    method: 'POST',
    body: {
      method: 'otpVerify',
      value: {
        clientId: this.clientId,
        method: 'sms',
        msisdn: this.phone,
        code: `${code}`,
      },
    },
    noDefaultNotification: true,
    withOverlay: true,
  }).then(result => {
    if (!result.success && result.result.code === 'code_invalid') {
      this.accessToken = result.result.accessToken;
      return Promise.reject();
    }
    return Promise.resolve(result.result);
  })

}
