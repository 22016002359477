import React from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';
import moment from 'moment';
import DateInput from '../../../../components/ui/DateInput';
import Condition from './Condition';
import FinalFormPropsWrapper from './FinalFormPropsWrapper';


class DateFinalFieldWrap extends React.Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    value: PropTypes.number,
    visible: PropTypes.bool,
    disabled: PropTypes.bool,
    styleFromParent: PropTypes.object,
    validation: PropTypes.func,
  };

  static defaultProps = {
    value: undefined,
    visible: false,
    disabled: false,
    styleFromParent: {},
    validation: () => {},
  };

  shouldComponentUpdate(nextProps) {
    const { item } = this.props;
    return nextProps.item !== item
      || nextProps.value !== this.props.value
      || nextProps.disabled !== this.props.disabled
      || nextProps.visible !== this.props.visible;
  }

  render() {
    const {
      item, visible, styleFromParent, validation, disabled = false,
    } = this.props;
    return (
      <Condition visible={visible}>
        <Field
          type="text"
          name={item.field_name}
          placeholder={item.placeholder}
          validate={value => validation(value, item)}
          format={(value) => {
            if (value) {
              return moment.utc(value).format('YYYY-MM-DD');
              // return moment(+value).format('YYYY-MM-DDTHH:mm:ss.SSSSZ');
            }
            return undefined;
          }}
          parse={value => +moment.utc(value).format('x')}
        >
          {FinalFormPropsWrapper(DateInput, { item, styleFromParent, disabled })}
        </Field>
      </Condition>
    );
  }
}

export default DateFinalFieldWrap;
