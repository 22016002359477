import { connect } from 'react-redux';
import { updatePageLoading } from '../../../actions/ui';
import NotFound from './NotFound';

const mapDispatchToProps = {
  updatePageLoading,
};

const NotFoundContainer = connect(
  null,
  mapDispatchToProps,
)(NotFound);

export default NotFoundContainer;
