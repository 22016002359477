import React, {
  useEffect, useCallback, useState, useMemo, useRef,
} from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Field, Form } from 'react-final-form';
import notification from 'antd/lib/notification';
import { usePageLoading, usePageLoadingStatus } from '../../components/hooks';
import ArrowDown from '../../assets/img/icons/arrow-down.svg';
import LeftFillSteps from '../catalog-form-fill-new/components/LeftFillSteps';
import newid from '../../utils/newid';
import ProductLogo from '../../components/ui/ProductLogo';
import Wizard from '../catalog-form-fill-new/components/Wizard';
import AutocompleteFinalFieldWrap from '../catalog-form-fill-new/components/final-form-inputs/AutocompleteFinalFieldWrap';
import CardnumberFinalFieldWrap from '../catalog-form-fill-new/components/final-form-inputs/CardnumberFinalFieldWrap';
import DateFinalFieldWrap from '../catalog-form-fill-new/components/final-form-inputs/DateFinalFieldWrap';
import PhoneFinalFieldWrap from '../catalog-form-fill-new/components/final-form-inputs/PhoneFinalFieldWrap';
import AmountFinalFieldWrap from '../catalog-form-fill-new/components/final-form-inputs/AmountFinalFieldWrap';
import RadioFinalFieldWrap from '../catalog-form-fill-new/components/final-form-inputs/RadioFinalFieldWrap';
import Condition from '../catalog-form-fill-new/components/final-form-inputs/Condition';
import Error from '../catalog-form-fill-new/components/ErrorTooltip';
import CheckboxFinalFieldWrap from '../catalog-form-fill-new/components/final-form-inputs/CheckboxFinalFieldWrap';
import DocumentFinalFieldWrap from '../catalog-form-fill-new/components/final-form-inputs/DocumentFinalFieldWrap';
import ConditionalTooltip from '../../components/ui/ConditionalTooltip';
import ValidateInput from '../../components/ui/ValidateInput';
import fetch from '../../utils/fetch';
import AnimatedAlert from '../../components/ui/AnimatedAlert';
import Button from '../../components/ui/Button';
import { backBtnLabel } from '../../constants/backBtnLabel';
import { formatMoneyWithCurrency } from '../../utils/format';
import { sendNotify } from '../../utils/processing';
import { merchantCheck, payment } from '../../utils/processingCalls';
import ErrorStatus from '../catalog-form-fill-new/assets/svg/error-status.svg';
import SuccessStatus from '../catalog-form-fill-new/assets/svg/success-status.svg';
import { downloadFiles } from '../../utils/downloadFiles';
import { clientForm, detectSteps } from './detect-steps';
import CodeVerificationInput from '../../components/ui/CodeVerificationInput';
import FileInput from '../../components/ui/FileInput';
import math from '../../utils/math';


const customMerchantCheck = (values) => {
  const submitDataKostil = {
    amount: 0,
    amountWithCommission: 0,
    currency: 643,
    formData: values,
    inner: '${100}',
    product: 'sdalkupilbuyout',
    srcCls: 'cash',
    tekoCustomerId: values.imei,
  };

  return merchantCheck(submitDataKostil);
};

const getQuestions = requestId => fetch(`/trade_in/get_visual_test_questions/${requestId}?flow=buyout`);
const getSmsCode = (txId, requestId) => fetch(`/sdalkupilbuyout_send_sms_code?txId=${txId}&requestId=${requestId}`);

const Sdalkupilbuyout = (props) => {
  const router = useParams();
  // up({ values, stepValid: valid, pageIndex });

  const [values, setValues] = useState({});
  const [loading, setLoading] = useState(false);
  const updatePage = usePageLoading();
  const pageLoadingStatus = usePageLoadingStatus();
  const [stepValid, setStepValid] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);
  const [change, setChange] = useState(null);
  const [visibleFields, setVisibleFields] = useState([true, false, false, false]);
  const [listOfDevices, setListOfDevices] = useState({});
  const [showAllert, setShowAllert] = useState(false);
  const [txData, setTxData] = useState({});
  const [questionData, setQuestionData] = useState(null);
  const [visualTestPrice, setVisualTestPrice] = useState(null);
  const [photoTestPrice, setPhotoTestPrice] = useState(null);
  const [photoTestConfirmed, setPhotoTestConfirmed] = useState(false);
  const [teclTestPrice, setTeclTestPrice] = useState(null);
  const [teclTestConfirmed, setTeclTestConfirmed] = useState(false);
  const [currentFormState, setCurrentFormState] = useState('fill'); //fill, visual_test, tec_test
  const [formErrorMessage, setFormErrorMessage] = useState('');
  const [letBlockStep, setLeftBlockStep] = useState(0);
  const [formError, setFormError] = useState(false);
  const [successFullPay, setSuccessFullPay] = useState(false);
  const [codeValue, setCodeValue] = useState('');
  const [isCodeValid, setIsCodeValid] = useState('default');
  const [secondsLeft, setSecondsLeft] = useState(60);
  const [fileName, setFileName] = useState([]);
  const [readyForExchange, setReadyForExchange] = useState(false);
  const [submitButtonLoading, setSubmitButtonLoading] = useState(false);
  const timerId = useRef();


  const handleCodeChange = useCallback((e) => {
    setIsCodeValid('default');
    setCodeValue(e.target.value);
  }, []);
  const handleCodeSubmit = useCallback(() => {
    fetch(`/sdalkupilbuyout_check_sms_code?txId=${txData.tx.id}&requestId=${txData.dst.extra.request_id}&smsCode=${codeValue}`)
      .then(() => setIsCodeValid('valid'))
      .catch(() => setIsCodeValid('invalid'));
  }, [codeValue]);
  const startTimer = useCallback(() => {
    if (secondsLeft < 60) {
      setSecondsLeft(60);
    }

    timerId.current = setInterval(() => {
      setSecondsLeft((prev) => {
        if (prev === 1) {
          clearInterval(timerId.current);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  }, [secondsLeft]);

  const handleCodeResendClick = useCallback(() => {
    setCodeValue('');
    setIsCodeValid('default');
    startTimer();
    fetch(`/sdalkupilbuyout_send_sms_code?txId=${txData.tx.id}&requestId=${txData.dst.extra.request_id}`);
  }, [startTimer]);

  // let change = () => {};
  useEffect(() => {
    updatePage(true);
    fetch('/trade_in')
      .then((list) => {
        const { result } = list;
        setListOfDevices(result);
        const filteredData = Object.keys(result).map(key => result[key]).reduce((acc, item) => {
          acc[item.brandAlias] = {
            name: item.brandName,
            value: item.brandAlias,
          };
          return acc;
        }, {});

        detectSteps[0].fields[0].items = Object.keys(filteredData).map(key => filteredData[key]);
        updatePage(false);
      });
  }, []);

  const resetPriceAndTestsResults = useCallback(() => {
    setVisualTestPrice(null);
    setTeclTestPrice(null);
    setTeclTestConfirmed(false);
    setPhotoTestPrice(null);
    setPhotoTestConfirmed(false);
  }, []);

  useEffect(() => {
    // letBlockStep
    // fill, visual_test, tec_test
    switch (currentFormState) {
      case 'fill':
        if (pageIndex === 0) {
          setLeftBlockStep(0);
        } else {
          setLeftBlockStep(1);
        }
        break;
      case 'visual_test':
        setLeftBlockStep(1);
        setPageIndex(1);
        break;
      case 'tec_test':
        setLeftBlockStep(2);
        break;
      case 'client_fill':
        setLeftBlockStep(3);
        break;
      case 'sms_confirmation':
        setLeftBlockStep(4);
        setPageIndex(1);
        break;
      default:
        break;
    }

    if (pageIndex === 0) {
      setFormError(false);
    }
  }, [currentFormState, pageIndex]);

  useEffect(() => {
    const imei = window.sessionStorage.getItem('IMEI');
    if (imei) {
      if (change) {
        change('imei', imei);
      }
    }
  }, [change]);

  useEffect(() => {
    if (values.brand) {
      setVisibleFields([true, true, false, false]);
    } else {
      setVisibleFields([true, false, false, false]);
    }

    if (values.brand && values.brand.value) {
      const filteredModels = Object.keys(listOfDevices).map(key => listOfDevices[key]).filter(item => (item.brandAlias === values.brand.value) && item.combined_name).reduce((acc, item) => {
        acc[item.combined_name] = {
          name: item.combined_name,
          value: item.combined_name,
          id: item.id,
        };
        return acc;
      }, {});
      detectSteps[0].fields[1].items = Object.keys(filteredModels).map(key => filteredModels[key]);
    } else {
      detectSteps[0].fields[1].items = [];
      if (change) {
        change('model', null);
      }
    }
  }, [values.brand]);

  useEffect(() => {
    if (values.model) {
      setVisibleFields([true, true, true, false]);

      const filteredModels = Object.keys(listOfDevices)
        .map(key => listOfDevices[key])
        .filter(item => (item.combined_name === values.model.value) && item.combined_name && item.memory_size)
        .reduce((acc, item) => {
          acc[item.id] = {
            name: `${item.memory_size} ГБ`,
            value: item.memory_size,
            id: item.id,
          };
          return acc;
        }, {});
      detectSteps[0].fields[2].items = Object.keys(filteredModels).map(key => filteredModels[key]).length === 0 ? [{ name: '-', value: '-', id: values.model.id }] : Object.keys(filteredModels).map(key => filteredModels[key]);
    } else {
      setVisibleFields([true, true, false, false]);
      setVisibleFields(visibleFields.map((status, i) => ((i === visibleFields.length - 1) ? false : status)));
      detectSteps[0].fields[2].items = [];
      if (change) {
        change('memory', null);
      }
    }
  }, [values.model]);

  useEffect(() => {
    console.log('change in mem');
    if (values.memory) {
      setVisibleFields([true, true, true, true, true, true]);
      // найти телефон, если ок то поставить значение, если нет то показать банер
      if (change) {
        change('sostoyanie', null);
      }
    } else {
      // setVisibleFields([true, true, true, false]);
      // очистить + убрать банер
      setShowAllert(false);
      if (change) {
        change('sostoyanie', null);
      }
    }
  }, [values.memory]);

  useEffect(() => {
    console.log(' sost ', values.sostoyanie);
    if (values.sostoyanie) {
      const { id } = values.memory;
      const currentPhone = listOfDevices[id];
      console.log('values.sostoyanie ', values.sostoyanie, currentPhone, id, listOfDevices[id]);
      // найти телефон, если ок то поставить значение, если нет то показать банер
      if (!currentPhone) {
        change('preprice', null);
        setShowAllert(true);
        return;
      }
      let preprice;
      // console.log('values.sostoyanie ', values.sostoyanie, currentPhone)
      switch (values.sostoyanie.value) {
        case '1':
          preprice = currentPhone.price_b;
          break;
        case '2':
          preprice = currentPhone.price_c;
          break;
        case '3':
          preprice = currentPhone.price_d;
          break;
        default:
          preprice = null;
          break;
      }

      console.log('preprice ', preprice);
      if (preprice) {
        setVisibleFields([true, true, true, true, true, true, true]);
        change('preprice', Math.round(preprice * 100));
        change('device_id', id);
        setShowAllert(false);
      } else {
        setVisibleFields([true, true, true, true, true, true, false]);
        change('preprice', null);
        change('device_id', null);
        setShowAllert(true);
      }
      // values.sostoyanie
    } else {
      // очистить + убрать банер
      setShowAllert(false);
    }
  }, [values.sostoyanie]);

  const up = useCallback(({ values: valuesFromWizard, stepValid: stepValidFromWizard, pageIndex: pageIndexFromWizard, change: changeFromWizard }) => {
    setValues({ ...values, ...valuesFromWizard });

    setStepValid(stepValidFromWizard);
    setPageIndex(pageIndexFromWizard);
    if (changeFromWizard) {
      setChange(smth => changeFromWizard);
    }
    // change = changeFromWizard;
  }, []);

  const goToPage = useCallback((page) => {
    setCurrentFormState(page);
  }, []);

  const cancelTX = useCallback(() => {
    fetch('/merchant/cancel', { method: 'POST', body: { tx: txData.tx.id, formData: { } }, noDefaultNotification: true })
      .then(() => {
        setCodeValue('');
        resetPriceAndTestsResults();
        setIsCodeValid('default');
        setFormError(true);
        setSuccessFullPay(false);
        setCurrentFormState('finish');
        setSubmitButtonLoading(false);
      })
      .catch(() => {
        setCodeValue('');
        resetPriceAndTestsResults();
        setIsCodeValid('default');
        setFormError(true);
        setSuccessFullPay(false);
        setCurrentFormState('finish');
        setSubmitButtonLoading(false);
      });
  }, [txData]);

  const city = useSelector(state => state.user.info.user.outletAddress.split(',')[0]);

  const handleSubmit = useCallback((values) => {
    customMerchantCheck({ ...values, city })
      .then((data) => {
        setTxData(data.result);
        getQuestions(data.result.dst.extra.request_id)
          .then((questions) => {
            setQuestionData(questions.result);
            // const [txData, setTxData] = useState({});
            // const [questionData, setQuestionData] = useState(null);
            setCurrentFormState('visual_test');
          });
      });
  }, []);

  const validatePartnerForm = useCallback((values) => {
    console.log(' !!!!!!!!!!!!!');
    const keys = Object.keys(questionData.questions)
      .reduce((acc, value) => {
        acc = [...acc, ...Object.keys(questionData.questions[value].child)];
        return acc;
      }, []);

    const errors = {};
    keys.forEach((key) => {
      !values[key] ? errors[key] = '* Обязательно' : null;
    });

    console.log('?????? ', errors, showAllert);
    if (showAllert) return { ...errors, sostoyanie: 'Ошибка' };

    return errors;

    // .map(segmentKey => {
    // return Object.keys(questionData.questions[segmentKey].child);
    // })
  }, [questionData, showAllert]);

  const onParterFormSubmit = useCallback((values) => {
    const answers = Object.keys(questionData.questions).reduce((acc, segment) => {
      acc[segment] = Object.keys(questionData.questions[segment].child).reduce((subAcc, questionKey) => {
        subAcc[questionKey] = values[questionKey].split('-')[1];
        return subAcc;
      }, {});

      return acc;
    }, {});

    const dataToSend = {
      answers,
      requestId: txData.dst.extra.request_id,
    };

    fetch('/trade_in/send_visual_test_answers?flow=buyout', {
      method: 'POST',
      body: dataToSend,
      withOverlay: true,
      noDefaultNotification: true,
    }).then(((result) => {
      const lcGrade = (result.result.grade || '').toLowerCase();
      if (lcGrade === 'отказ') {
        cancelTX();
        notification.open({
          duration: 5,
          description: React.createElement('div', { dangerouslySetInnerHTML: { __html: 'В данном состоянии устройство не выкупается' } }),
        });
      } else {
        setVisualTestPrice(Math.round(result.result.price * 100));
        setCurrentFormState(lcGrade === 'b' ? 'photo_test' : 'tec_test');
      }
    }))
      .catch((error) => {
        cancelTX();
        error.response.json().then((body) => {
          notification.open({
            duration: 5,
            description: React.createElement('div', { dangerouslySetInnerHTML: { __html: body.error } }),
          });
        });
      });
  }, [questionData, txData]);

  const handleSubmitPhotoTest = useCallback(() => {
    setLoading(true);

    fetch('/trade_in/send_photo_testing_result?flow=buyout', {
      method: 'POST',
      body: { requestId: txData.dst.extra.request_id },
      noDefaultNotification: true,
    }).then((result) => {
      setPhotoTestPrice(Math.round(result.result.price * 100)); // to display in step
      setVisualTestPrice(Math.round(result.result.price * 100)); // to display in right info block
      setPhotoTestConfirmed(true);
      setLoading(false);
    }).catch((error) => {
      console.log(error);
      setLoading(false);
      if (error.response) {
        error.response.json().then((body) => {
          console.log(body);
          notification.open({
            duration: 5,
            description: React.createElement('div', { dangerouslySetInnerHTML: { __html: body.error } }),
          });
        });
      }
    });
  }, [txData]);

  const acceptTecTest = useCallback(() => {
    // () => setCurrentFormState('applyTx')
    // fetch(`/sdalkupil_tech_testing_result/${txData.dst.extra.request_id}`, {
    setLoading(true);

    fetch(`/sdalkupilbuyout_tech_testing_result?txId=${txData.tx.id}&requestId=${txData.dst.extra.request_id}`, {
      noDefaultNotification: true,
    })
      .then((result) => {
        setTeclTestPrice(Math.round(result.result.price * 100));
        setTeclTestConfirmed(true);
        setLoading(false);
        // setCurrentFormState('applyTx');
      })
      .catch((error) => {
        // в экран ошибки
        setLoading(false);
        error.response.json().then((body) => {
          notification.open({
            duration: 5,
            description: React.createElement('div', { dangerouslySetInnerHTML: { __html: body.error } }),
          });
          setFormErrorMessage(body.error);
        });
        setFormError(true);
      });
  }, [txData]);

  const proceedToTechTest = useCallback(() => {
    setCurrentFormState('tec_test');
  }, []);

  const proceedToCustomerForm = useCallback(() => {
    setCurrentFormState('client_fill');
  }, []);

  const handleClientFormSubmit = useCallback((values) => {
    fetch('/sdalkupilbuyout_send_customer_data', {
      method: 'PUT',
      body: {
        ...values,
        tx_id: txData.tx.id,
        request_id: txData.dst.extra.request_id,
      },
    })
      .then(() => {
        setSecondsLeft(60);
        startTimer();
        if (!readyForExchange) {
          setReadyForExchange(true);
          fetch(`/sdalkupilbuyout_set_request_ready_for_exchange?txId=${txData.tx.id}&requestId=${txData.dst.extra.request_id}`, {
            noDefaultNotification: true,
          })
            .then(() => getSmsCode(txData.tx.id, txData.dst.extra.request_id))
            .catch((error) => {
              error.response.json().then((body) => {
                notification.open({
                  duration: 5,
                  description: React.createElement('div', { dangerouslySetInnerHTML: { __html: body.error } }),
                });
                setFormErrorMessage(body.error);
              });
              setFormError(true);
            });
        } else {
          getSmsCode(txData.tx.id, txData.dst.extra.request_id);
        }

        downloadFiles({
          id: 'sdalkupilbuyout',
          txId: txData.tx.id,
          formData: { ...txData.dst.extra, price: teclTestPrice / 100 },
          step: 'cheque',
        });
        setCurrentFormState('sms_confirmation');
      })
      .catch((error) => {
        error.response.json().then((body) => {
          notification.open({
            duration: 5,
            description: React.createElement('div', { dangerouslySetInnerHTML: { __html: body.error } }),
          });
          setFormErrorMessage(body.error);
        });
        setFormError(true);
      });
  }, [txData, teclTestPrice, readyForExchange]);

  const handleConfirmationStepBackClick = useCallback(() => {
    clearInterval(timerId.current);
    goToPage('client_fill');
    setCodeValue('');
    setIsCodeValid('default');
  }, []);

  const confirmTransaction = useCallback(() => {
    setSubmitButtonLoading(true);
    const driverUrl = window.localStorage.getItem('DRIVER_ADDRESS');
    const urlString = driverUrl ? `${driverUrl}/fr` : `${process.env.DRIVER_ADDRESS}/fr`;

    sendNotify({
      price: undefined,
      GoodId: undefined,
      product: undefined,
      txId: undefined,
      PhoneNumber: undefined,
      Type: undefined,
    }, {
      TEKOGUID: txData.tx.id,
      GoodsAccepted: '0100-485798',
      IMEI: values.imei,
      actnumber: txData.dst.extra.request_id,
      modelBuy: txData.dst.extra.model.name,
      brendtBuy: txData.dst.extra.brand.name,
      costDevice: teclTestPrice / 100,
      comment: txData.tx.id,
    }, 'sdalkupilbuyout')
      .then((response) => {
        if (response.Message === 'Оприходование произведено успешно.') {
          payment({
            amount: 0,
            amountWithCommission: 0,
            inner: '${100}',
            srcCls: 'cash',
            currency: 643,
            partnerTx: txData.tx.id,
            formData: { ...txData.dst.extra, price: teclTestPrice / 100 },
            product: 'sdalkupilbuyout',
          }, { withOverlay: true })
            .then(() => {
              setSubmitButtonLoading(false);
              setSuccessFullPay(true);
              setCurrentFormState('finish');
            })
            .catch((error) => {
              fetch(`${urlString}/InvokeFunction1C?func=Transfer`, {
                method: 'POST',
                backendUrl: '',
                prefix: '',
                body: {
                  Товары: [
                    {
                      КодТовара: '0100-485798',
                      НаименованиеТовара: 'Товар Trade-In Сдал-Выкуп',
                      IMEI: values.imei,
                    },
                  ],
                  TEKOGUID: txData.tx.id,
                },
              });
              setSubmitButtonLoading(false);
              setCodeValue('');
              resetPriceAndTestsResults();
              setIsCodeValid('default');
              setFormErrorMessage(error.error);
              setFormError(true);
              setSuccessFullPay(false);
              setCurrentFormState('finish');
            });
        } else {
          cancelTX();
          notification.open({
            duration: 5,
            description: React.createElement('div', { dangerouslySetInnerHTML: { __html: response.Message } }),
          });
        }
      })
      .catch(() => {
        cancelTX();
      });
  }, [values, txData, teclTestPrice]);


  // useEffect(() => {
  //   setTimeout(() => {
  //     updatePage();
  //   }, 1000);
  // }, []);

  const header = useMemo(() => {
    switch (currentFormState) {
      case 'fill':
        return '1. Предварительная оценка';
        // return `${pageIndex + 1 || 1}. ${detectSteps[pageIndex || 0].name}`;
      case 'visual_test':
        return '2. Визуальное тестирование';
      case 'photo_test':
        return '2.1 Оценка по фотографиям';
      case 'tec_test':
        return '3. Техническое тестирование';
      case 'client_fill':
        return '4. Данные клиента';
      case 'sms_confirmation':
        return '5. Подтверждение операции';
      default:
        return '';
    }
  }, [currentFormState]);

  const filteredQuestions = useMemo(() => {
    if (!questionData) return [];
    return Object.keys(questionData.questions).filter((key) => Object.keys(questionData.questions[key].child).length > 0);
  }, [questionData]);

  // currentFormState == 'visual_test'`${pageIndex + 1 || 1}. ${detectSteps[pageIndex || 0].name}`

  return (
    <div className="catalog-form-fill-page form-page form-page-fill">
      <div className="container">
        <div className="left-fixed-block">
          <div className="breadcrumbs-block">
            <span className="breadcrumb-link" onClick={() => {}}>
              <ArrowDown className="breadcrumb-link-icon" />
              <span className="breadcrumb-link-text">Назад</span>
            </span>
            <span className="breadcrumb-link active">
              <span className="breadcrumb-link-text">
                Сдал-Купил Выкуп
              </span>
            </span>
          </div>
          <LeftFillSteps
            ignoreLastEasySalesSteps
            step={letBlockStep}
            complexSalesGuid={1234}
            formSteps={[
              {
                name: 'Предварительная оценка',
              },
              {
                name: 'Визуальное тестирование',
              },
              {
                name: 'Техническое тестирование',
              },
              {
                name: 'Данные клиента',
              },
              {
                name: 'Подтверждение операции',
              },
            ]}
            parentState={null}
            formError={false}
          />
        </div>
        <div style={{ display: pageLoadingStatus ? 'none' : 'block' }} className={classnames('form-fill-content wizard-form', { 'precheck-content': false })}>
          <div className="form-title">
            <div className="form-logo">
              <ProductLogo productConfig={{}} />
            </div>
            <div className="form-desc">
              <div className="form-name">Сдал-Купил Выкуп</div>
            </div>
          </div>
          <div className={classnames('form-fill-info', { 'hidden-by-error': false })}>
            <h3 key="step-1-title" className="page-title h3">{header}</h3>
          </div>
          {currentFormState === 'fill' && (
          <Wizard
            calcSuccess // calcSuccess
            dataFromRedux={false}
            stepValid={stepValid}
            initialValues={values}
            blockNext={showAllert}
            up={up}
            validate={console.log}
            onSubmit={handleSubmit}
            forceCalc={() => {}}
            location={props.location}
          >
            {detectSteps.map((group, groupIndex) => {
              // const fileInputs = group.fields.filter(field => field.type === 'file');
              // const amountInputs = group.fields.filter(field => ['amount', 'comission', 'full_amount'].includes(field.field_name));
              // const regularInputs = group.fields.filter(field => field.type !== 'file');
              const fileInputs = [];
              const amountInputs = [];
              const regularInputs = [];

              for (let i = 0; i < group.fields.length; i++) {
                const field = group.fields[i];
                regularInputs.push(field);
              }
              return (
                <>
                  <Wizard.Page key={group.name}>
                    {regularInputs.map((item, fieldIndex) => {
                      const fieldKey = `${item.field_name}-wrap-${fieldIndex}`;
                      const styleFromParent = {
                        marginLeft: `${item.visual.off}%`,
                        marginRight: `${item.visual.post}%`,
                        maxWidth: `${item.visual.size}%`,
                      };
                      const commonFieldProps = {
                        item,
                        styleFromParent,
                        // visible: item.visible,
                        disabled: item.disabled,
                        validation: item.validation || (item => (!item && 'Поле обязательно для заполнения!!')),
                        change: change || (() => {}),
                        visible: groupIndex === 0 ? visibleFields[fieldIndex] : true,
                        // visible: true,
                      };


                      if (item.type === 'cardnumber') {
                        return <CardnumberFinalFieldWrap key={fieldKey} {...commonFieldProps} />;
                      }
                      if (item.type === 'date') {
                        return <DateFinalFieldWrap key={fieldKey} {...commonFieldProps} />;
                      }
                      if (item.type === 'enum') {
                        return (
                          <AutocompleteFinalFieldWrap
                            key={`${fieldKey}-${this.resetIndex}`}
                            {...commonFieldProps}
                            values={values}
                          />
                        );
                      }
                      if (item.type === 'typeahead') {
                        return (
                          <AutocompleteFinalFieldWrap
                            key={`${fieldKey}`}
                            {...commonFieldProps}
                            values={values}
                          />
                        );
                      }
                      if (item.type === 'phone') {
                        return (
                          <PhoneFinalFieldWrap
                            key={fieldKey}
                            {...commonFieldProps}
                          />
                        );
                      }
                      if (item.type === 'amount') {
                        return (
                          <AmountFinalFieldWrap
                            key={fieldKey}
                            {...commonFieldProps}
                            value={values[item.field_name]}
                            currencyValue="RUB"
                          />
                        );
                      }
                      if (item.type === 'radio') {
                        return (
                          <RadioFinalFieldWrap
                            key={fieldKey}
                            {...commonFieldProps}
                            values={values}
                          />
                        );
                      }
                      if (item.type === 'checkbox') {
                        return (
                          <CheckboxFinalFieldWrap
                            key={item.field_name}
                            {...commonFieldProps}
                            values={values}
                            setResetParameter={this.setResetParameter}
                            updateDisabledFields={this.updateDisabledFields}
                          />
                        );
                      }
                      return (
                        <Condition key={fieldKey} visible={commonFieldProps.visible}>
                          <Field
                            type="text"
                            name={item.field_name}
                            placeholder={item.placeholder}
                            validate={(val) => {
                              if (item.reg_ex && item.reg_ex !== '') {
                                const reg = new RegExp(item.reg_ex);
                                if (!reg.test(val)) return item.comment;
                              }
                              return (!val && ((item.comment !== '-' && item.comment) || 'Поле обязательно для заполнения'));
                            }}
                          >
                            {({ input, meta }) => (
                              <div className="form-item" style={styleFromParent}>
                                <label className="input-label">{item.caption}</label>
                                <ConditionalTooltip hint={item.hint}>
                                  <ValidateInput
                                    value={input.value}
                                    type={item.type}
                                    mask={item.mask}
                                    valid={meta.touched ? meta.valid : true}
                                    message={item.comment}
                                    placeholder={item.placeholder}
                                    disabled={item.disabled}
                                    {...input}
                                  />
                                </ConditionalTooltip>
                                <Error name={item.field_name} />
                              </div>
                            )}
                          </Field>
                        </Condition>
                      );
                    })}
                  </Wizard.Page>
                  {pageIndex === 0 && (
                    <AnimatedAlert
                      in={showAllert}
                      color={AnimatedAlert.Color.red}
                      style={{
                        marginLeft: '10px',
                        width: '100%',
                      }}
                    >
                      В данном состоянии устройство не выкупается
                    </AnimatedAlert>
                  )}
                </>
              );
            })}
          </Wizard>
          )}
          {currentFormState === 'visual_test' && (
            <Form
              onSubmit={onParterFormSubmit}
              validate={validatePartnerForm}
              render={({ handleSubmit, form, submitting, pristine, values }) => (
                <form onSubmit={handleSubmit}>
                  <div className="form-inner">
                    {filteredQuestions.map(questionSegmentKey => (
                      <div key={questionSegmentKey}>
                        <h4 className="fields-group-title">{questionData.questions[questionSegmentKey].title}</h4>
                        {Object.keys(questionData.questions[questionSegmentKey].child).map(key => (
                          <div className="form-item" key={`${questionSegmentKey}-${key}`}>
                            <label className="input-label">{questionData.questions[questionSegmentKey].child[key].title}</label>
                            <div className="radio-group vertical">
                              {questionData.questions[questionSegmentKey].child[key].child.map((quest, i) => (
                                <div className="ui-radio-button-wrap ui-default-radio" key={`${questionSegmentKey}-${key}-${quest.grade}-${i}`}>
                                  <Field
                                    name={key}
                                    component="input"
                                    className="radio-input default-radio"
                                    type="radio"
                                    value={`${quest.grade}-${i}`}
                                    id={`${questionSegmentKey}-${key}-${quest.grade}-${i}`}
                                  />
                                  <label htmlFor={`${questionSegmentKey}-${key}-${quest.grade}-${i}`} className="ui-default-radio-label">
                                    {quest.title}
                                  </label>
                                </div>
                              ))}
                              <Error name={key} />
                              {/*<Field*/}
                              {/*  name={key}*/}
                              {/*  subscription={{ touched: true, error: true }}*/}
                              {/*  render={({ meta: { touched, error } }) =>*/}
                              {/*    touched && error ? <span>{error}</span> : null*/}
                              {/*  }*/}
                              {/*/>*/}
                            </div>
                          </div>
                        ))}
                      </div>
                    ))}
                    <div className="buttons-wrap">
                      <Button appearance={Button.Appearance.link} label={backBtnLabel} onClick={() => goToPage('fill')} />
                      <Button appearance={Button.Appearance.link} label="Отмена" onClick={cancelTX} />
                      <Button type="submit" label="Продолжить" color={Button.Color.blue} />
                    </div>
                  </div>
                </form>
              )}
            />
          )}
          {(currentFormState === 'photo_test' && (
            <>
              {!photoTestConfirmed ? (
                <div className="form-inner">
                  <h4 className="fields-group-title">На данном этапе необходимо сделать фотографии на устройстве сотрудника с установленным приложением NSYS Autograding. Для загрузки фотографий следуйте инструкциям в приложении. После загрузки фотографий нажмите продолжить.</h4>
                  <div className="buttons-wrap">
                    <Button appearance={Button.Appearance.link} label={backBtnLabel} onClick={() => goToPage('visual_test')} />
                    <Button label="Продолжить" color={Button.Color.blue} loading={loading} onClick={handleSubmitPhotoTest} />
                  </div>
                </div>
              ) : (
                <div className="form-inner">
                  <h4 className="fields-group-title">
                    {`Цена по результатам визуального тестирования:
                    ${formatMoneyWithCurrency({ amount: photoTestPrice, currency: 643, symbol: true })}
                    рублей`}
                  </h4>
                  <h3 className="fields-group-title">Если вы согласны, нажмите подтвердить</h3>
                  <div className="buttons-wrap">
                    <Button label="Отмена" appearance={Button.Appearance.link} onClick={cancelTX} />
                    <Button label="Подтвердить" color={Button.Color.blue} onClick={proceedToTechTest} />
                  </div>
                </div>
              )}
            </>
          ))}
          {(currentFormState === 'tec_test' && (
            <>
              {!teclTestConfirmed ? (
                <div className="form-inner">
                  <h4 className="fields-group-title">На данном этапе клиент должен провести техническое тестирование на своем устройстве с помощью программы NSYS. Дождитесь результатов тестирования, и нажмите продолжить</h4>
                  <div className="buttons-wrap">
                    <Button appearance={Button.Appearance.link} label={backBtnLabel} onClick={() => goToPage('visual_test')} />
                    <Button label="Продолжить" color={Button.Color.blue} loading={loading} onClick={acceptTecTest} />
                  </div>
                </div>
              ) : (
                <div className="form-inner">
                  <h4 className="fields-group-title">
                    {`Итоговая цена выкупа:
                    ${formatMoneyWithCurrency({ amount: teclTestPrice, currency: 643, symbol: true })}
                    рублей`}
                  </h4>
                  <h3 className="fields-group-title">Если вы согласны, нажмите подтвердить</h3>
                  <div className="buttons-wrap">
                    <Button appearance={Button.Appearance.link} label="Отмена" onClick={cancelTX} />
                    <Button onClick={proceedToCustomerForm} label="Подтвердить" color={Button.Color.blue} />
                  </div>
                </div>
              )}
            </>
          ))}
          {currentFormState === 'client_fill' && (
            <Wizard
              calcSuccess // calcSuccess
              dataFromRedux={false}
              stepValid={stepValid}
              initialValues={values}
              blockNext={showAllert}
              up={up}
              validate={console.log}
              onSubmit={handleClientFormSubmit}
              forceCalc={() => {}}
              location={props.location}
            >
              {clientForm.map((group, groupIndex) => {
                // const fileInputs = group.fields.filter(field => field.type === 'file');
                // const amountInputs = group.fields.filter(field => ['amount', 'comission', 'full_amount'].includes(field.field_name));
                // const regularInputs = group.fields.filter(field => field.type !== 'file');
                const fileInputs = [];
                const amountInputs = [];
                const regularInputs = [];

                for (let i = 0; i < group.fields.length; i++) {
                  const field = group.fields[i];
                  regularInputs.push(field);
                }
                return (
                  <>
                    <Wizard.Page key={group.name}>
                      {regularInputs.map((item, fieldIndex) => {
                        const fieldKey = `${item.field_name}-wrap-${fieldIndex}`;
                        const styleFromParent = {
                          marginLeft: `${item.visual.off}%`,
                          marginRight: `${item.visual.post}%`,
                          maxWidth: `${item.visual.size}%`,
                        };
                        const commonFieldProps = {
                          item,
                          styleFromParent,
                          // visible: item.visible,
                          disabled: item.disabled,
                          validation: item.validation || (item => (!item && 'Поле обязательно для заполнения!!')),
                          change: change || (() => {}),
                          visible: groupIndex === 0 ? visibleFields[fieldIndex] : true,
                          // visible: true,
                        };


                        if (item.type === 'cardnumber') {
                          return (
                            <>
                              <CardnumberFinalFieldWrap key={fieldKey} {...commonFieldProps} />
                              <p className="file-input-text card-note">Денежные средства поступят на указанную карту. Внесите данные со слов клиента и предложите клиенту повторно проверить номер карты в поступившем ему СМС-сообщении во избежание ошибочного платежа.</p>
                            </>
                          );
                        }
                        if (item.type === 'date') {
                          return <DateFinalFieldWrap key={fieldKey} {...commonFieldProps} />;
                        }
                        if (item.type === 'enum') {
                          return (
                            <AutocompleteFinalFieldWrap
                              key={`${fieldKey}-${this.resetIndex}`}
                              {...commonFieldProps}
                              values={values}
                            />
                          );
                        }
                        if (item.type === 'typeahead') {
                          return (
                            <AutocompleteFinalFieldWrap
                              key={`${fieldKey}`}
                              {...commonFieldProps}
                              values={values}
                            />
                          );
                        }
                        if (item.type === 'phone') {
                          return (
                            <PhoneFinalFieldWrap
                              key={fieldKey}
                              {...commonFieldProps}
                            />
                          );
                        }
                        if (item.type === 'amount') {
                          return (
                            <AmountFinalFieldWrap
                              key={fieldKey}
                              {...commonFieldProps}
                              value={values[item.field_name]}
                              currencyValue="RUB"
                            />
                          );
                        }
                        if (item.type === 'radio') {
                          return (
                            <RadioFinalFieldWrap
                              key={fieldKey}
                              {...commonFieldProps}
                              values={values}
                            />
                          );
                        }
                        if (item.type === 'checkbox') {
                          return (
                            <CheckboxFinalFieldWrap
                              key={item.field_name}
                              {...commonFieldProps}
                              values={values}
                              setResetParameter={this.setResetParameter}
                              updateDisabledFields={this.updateDisabledFields}
                            />
                          );
                        }
                        return (
                          <Condition key={fieldKey} visible={commonFieldProps.visible}>
                            <Field
                              type="text"
                              name={item.field_name}
                              placeholder={item.placeholder}
                              validate={(val) => {
                                if (item.reg_ex && item.reg_ex !== '') {
                                  const reg = new RegExp(item.reg_ex);
                                  if (!reg.test(val)) return item.comment;
                                }
                                return (!val && ((item.comment !== '-' && item.comment) || 'Поле обязательно для заполнения'));
                              }}
                            >
                              {({ input, meta }) => (
                                <div className="form-item" style={styleFromParent}>
                                  <label className="input-label">{item.caption}</label>
                                  <ConditionalTooltip hint={item.hint}>
                                    <ValidateInput
                                      value={input.value}
                                      type={item.type}
                                      mask={item.mask}
                                      valid={meta.touched ? meta.valid : true}
                                      message={item.comment}
                                      placeholder={item.placeholder}
                                      disabled={item.disabled}
                                      {...input}
                                    />
                                  </ConditionalTooltip>
                                  <Error name={item.field_name} />
                                </div>
                              )}
                            </Field>
                          </Condition>
                        );
                      })}
                    </Wizard.Page>
                    {pageIndex === 0 && (
                      <AnimatedAlert
                        in={showAllert}
                        color={AnimatedAlert.Color.red}
                        style={{
                          marginLeft: '10px',
                          width: '100%',
                        }}
                      >
                        В данном состоянии устройство не выкупается
                      </AnimatedAlert>
                    )}
                  </>
                );
              })}
            </Wizard>
          )}
          {currentFormState === 'sms_confirmation' && (
            <>
              <div className="form-inner">
                <div className="code-block">
                  <h4 className="fields-group-title">{`На указанный номер телефона ${values.phone} отправлено СМС-сообщение с кодом подтверждения.`}</h4>
                  <CodeVerificationInput
                    className="code-input"
                    value={codeValue}
                    onChange={handleCodeChange}
                    onBlur={() => {}}
                    submitForm={handleCodeSubmit}
                    state={isCodeValid}
                    disabled={isCodeValid === 'valid'}
                  />
                  {secondsLeft ? (
                    <div className="resend-text">
                      До повторной отправки кода
                      {' '}
                      {secondsLeft}
                      {' '}
                      сек.
                    </div>
                  ) : (
                    <Button className="resend-button" onClick={handleCodeResendClick} label="Повторная отправка" color={Button.Color.white} />
                  )}
                </div>

                <div className="file-inputs-group">
                  <div className="file-inputs-title">Загрузка документов</div>
                  <p className="file-input-text">Если СМС-подписание недоступно, загрузите подписанную скан-копию клиентского соглашения.</p>
                  <div className="file-inputs-list" style={{ paddingLeft: '10px' }}>
                    <FileInput
                      // acceptMimeTypes="image/*"
                      acceptFileExtensions="docx, doc, pdf"
                      values={{}}
                      onChange={setFileName}
                      value={fileName}
                      item={{
                        field_name: 'test',
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="buttons-wrap">
                <Button appearance={Button.Appearance.link} label={backBtnLabel} onClick={handleConfirmationStepBackClick} />
                <Button onClick={confirmTransaction} label="Подтвердить" color={Button.Color.blue} loading={submitButtonLoading} disabled={isCodeValid !== 'valid' && fileName.length === 0} />
              </div>
            </>
          )}
          {currentFormState === 'finish' && formError && (
            <div className="payments-phase payments-phase-error status-screen">
              <div className="picture-wrap">
                <ErrorStatus className="picture-svg" />
              </div>
              <div className="finish-step-title">Произошла ошибка</div>
              <div className="error-text">
                "
                {formErrorMessage || 'При проведении операции возникла ошибка'}
                "
              </div>
              <div className="footer-button-wrap">
                <Button label="Перейти в 1С" color={Button.Color.white} onClick={() => window.close()} />
                <Button label="Вернуться к форме" color={Button.Color.blue} onClick={() => goToPage('fill')} />
              </div>
            </div>
          )}
          {currentFormState === 'finish' && successFullPay && (
            <div className={classnames('payments-phase payments-phase-success status-screen', { 'hidden-by-error': formError })}>
              <div className="picture-wrap">
                <SuccessStatus className="picture-svg" />
              </div>
              <div className="finish-step-title">Операция успешно завершена</div>
              <div className="footer-button-wrap">
                <Button label="Перейти в 1С" color={Button.Color.white} onClick={() => window.close()} />
              </div>
            </div>
          )}
        </div>

        <div className="right-fixed-block">
          <div className="right-info-block right-billing-info">
            <div className="info-title">Стоимость устройства</div>
            <ul className="info-list">
              <li className="info-item" key={newid()}>
                <span className="info-item-label">Предварительная цена</span>
                <span className="info-item-value">{`${values.preprice ? formatMoneyWithCurrency({ amount: values.preprice, currency: 643, symbol: true }) : '-'}`}</span>
              </li>
              <li className="info-item" key={newid()}>
                <span className="info-item-label">Цена по результатам визуального тестирования</span>
                <span className="info-item-value">{`${visualTestPrice ? formatMoneyWithCurrency({ amount: visualTestPrice, currency: 643, symbol: true }) : '-'}`}</span>
              </li>
              <li className="info-item" key={newid()}>
                <span className="info-item-label">Цена по результатам технического тестирования</span>
                <span className="info-item-value">{`${teclTestPrice ? formatMoneyWithCurrency({ amount: teclTestPrice, currency: 643, symbol: true }) : '-'}`}</span>
              </li>
            </ul>
            <div className="info-summary info-item">
              <span className="info-item-label">Итоговая цена выкупа</span>
              <span className="info-item-value">{`${teclTestPrice ? formatMoneyWithCurrency({ amount: teclTestPrice, currency: 643, symbol: true }) : '-'}`}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sdalkupilbuyout;
