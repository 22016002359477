import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Field } from 'react-final-form';
import { AutoComplete } from 'antd';
import AnimatedFormTooltip from '../../../../components/ui/AnimatedFormTooltip';
import ValidateInput from '../../../../components/ui/ValidateInput';
import fetch from '../../../../utils/fetch';
import Error from "../ErrorTooltip";

function formatValueFromProps({ socr, name = '' }) {
  const prefix = socr ? `${socr}. ` : '';
  return prefix + name;
}

const addressOptions = [
  {
    name: 'region',
    level: 1,
    methodName: 'getRegion',
  },
  {
    name: 'district',
    level: 2,
    methodName: 'getDistrict',
  },
  {
    name: 'city',
    level: 3,
    methodName: 'getCity',
  },
  {
    name: 'street',
    level: 4,
    methodName: 'getStreet',
  },
];

const fullAddressOptions = [
  ...addressOptions,
  {
    name: 'house',
    level: 5,
  },
  {
    name: 'building',
    level: 6,
  },
  {
    name: 'flat',
    level: 7,
  },
];

class Address extends React.PureComponent {
  static propTypes = {
    changeValue: PropTypes.func,
    values: PropTypes.object.isRequired,
  };

  static defaultProps ={
    changeValue: () => {},
  };

  state = {
    region: [],
    district: [],
    city: [],
    street: [],
    regionVal: '',
    districtVal: '',
    cityVal: '',
    streetVal: '',
  };


  componentDidUpdate(prevProps) {
    const addressPrefix = this.props.item.field_name;
    const { values } = this.props;
    const addressJson = values[`${addressPrefix}_json`];

    const addressCopy = { ...addressJson };

    if (prevProps.values[`${addressPrefix}_json`] && addressJson) {
      Object.keys(prevProps.values[`${addressPrefix}_json`]).forEach((key) => {
        const addressOption = addressOptions.find(item => key === item.name);
        if (!addressJson[key] && addressOption) {
          const newVals = { ...this.state };
          const removedItemLvl = addressOption.level;
          Object.keys(addressJson).forEach((k) => {
            const addrOpt = addressOptions.find(it => k === it.name);
            if (addrOpt) {
              const lvl = addrOpt.level;
              if (lvl > removedItemLvl) {
                delete addressCopy[k];
                newVals[`${k}Val`] = '';
              }
            }
          });
          this.setState(newVals);
          this.props.changeValue(`${addressPrefix}_json`, addressCopy);
        }
      });

      //   const nameToLvl = addressOptions.reduce((obj, current) => { obj[current.name] = current.level; return obj; }, {});
      //   const keys = Object.keys(prevProps.values.id_address_json).sort((a, b) => nameToLvl[a] - nameToLvl[b]);
      //
      //   for (let i = 0; i < keys.length; i++) {
      //     if (id_address_json[keys[i]] !== prevProps.values.id_address_json[keys[i]]) {
      //       for (let j = i + 1; j < keys.length; j++) {
      //         delete addressCopy[keys[j]];
      //       }
      //       break;
      //     }
      //   }
    }


    const kladr = this.addressFormatter(addressCopy);
    this.props.changeValue(addressPrefix, kladr);
  }

  handleSearch = (input, collectionName, lvl) => (value) => {

    const addressPrefix = this.props.item.field_name;
    const addressJson = `${addressPrefix}_json`;

    input.onChange(undefined);
    let parentString = '';

    const parent = addressOptions.filter(i => i.level < lvl && this.props.values[addressJson][i.name]).sort((a, b) => b.level - a.level)[0];

    if (parent && typeof this.props.values[addressJson] !== 'undefined' && typeof this.props.values[addressJson][parent.name] !== 'undefined') {
      parentString += `&parentCode=${this.props.values[addressJson][parent.name].id}`;
    }
    fetch(`/kuadr?collectionName=${collectionName}${parentString}&prefix=${value}`)
      .then((result) => {
        this.setState({ [collectionName]: result.result });
      })
      .catch(e => console.warn(e));
  };

  addressFormatter = (model = {}) => {
    let address = '';
    let kladrId = '';
    if (model.region) {
      if (!model.region.socr) {
        address += `${model.region.name}, `;
      } else {
        address += `${model.region.socr}. ${model.region.name}, `;
      }

      if (model.region.id) {
        kladrId = model.region.id;
      }
    }

    address += '\u200B';
    if (model.district) {
      if (!model.district.socr) {
        address += `${model.district.name}, `;
      } else {
        address += `${model.district.socr}. ${model.district.name}, `;
      }
      if (model.district.id) {
        kladrId = model.district.id;
      }
    }
    address += '\u200B';
    if (model.city) {
      if (!model.city.socr) {
        address += `${model.city.name}, `;
      } else {
        address += `${model.city.socr}. ${model.city.name}, `;
      }
      if (model.city.id) {
        kladrId = model.city.id;
      }
    }

    address += '\u200B';
    if (model.street && model.street.name && model.street.name.replace(/-*/, '')) {
      if (!model.street.socr) {
        address += `${model.street.name}, `;
      } else {
        address += `${model.street.socr}. ${model.street.name}, `;
      }

      if (model.street.id) {
        kladrId = model.street.id;
      }
    }

    address += '\u200B';
    if (model.house && model.house.replace(/-*/, '')) {
      address += `д.${model.house}. `;
    }

    address += '\u200B';
    if (model.building && model.building.replace(/-*/, '')) {
      address += `стр.${model.building}. `;
    }

    address += '\u200B';
    if (model.flat && model.flat.replace(/-*/, '')) {
      address += `кв.${model.flat}`;
    }
    // return kladrId !== '' ? `${kladrId}, ${address.replace(/,\s$/, '')}` : address.replace(/,\s$/, '');
    return address.replace(/,\s$/, '');
  }


  render() {
    const { regionVal, districtVal, cityVal, streetVal } = this.state;
    const { values, item: masterItem } = this.props;

    const addressPrefix = this.props.item.field_name;
    const addressJson = `${addressPrefix}_json`;
    return (
      <React.Fragment>
        {/*<div className="form-item hint">*/}
        {/*  <p className="input-label">Адрес регистрации</p>*/}
        {/*</div>*/}

        <AnimatedFormTooltip key="form-tooltip" in={!!values[addressPrefix] && values[addressPrefix].length > 6} className="address-tooltip">
          Адрес: {values[addressPrefix]}
        </AnimatedFormTooltip>

        <Field
          key="region-field"
          type="text"
          name={`${addressJson}.region`}
          validate={(value) => masterItem.full_address_required ? (value ? '' : 'Обязательно') : ''}
        >
          {({ input, meta }) => {
            const className = classnames('ui-input', {
              valid: meta.valid === true,
              // meta.touched ? meta.valid : true
              // invalid: data.meta.valid === false && data.meta.touched,
              invalid: !(meta.touched ? meta.valid : true),
            });

            return (
              <div className="form-item" style={{ maxWidth: '100%' }}>
                <label className="input-label">Регион</label>
                <AutoComplete
                  value={regionVal || formatValueFromProps(input.value)}
                  className={className}
                  defaultValue={input.value && input.value.name}
                  placeholder={this.props.placeholder}
                  onSearch={this.handleSearch(input, 'region', 1)}
                  onSelect={(value, option) => {
                    input.onChange(option.props.data);
                  }}
                  onChange={value => this.setState({ regionVal: value })}
                  dataSource={this.state.region.map((item) => {
                    const a = `${item.socr}. ${item.name}`;
                    return (
                      <AutoComplete.Option key={item.id} data={item}>
                        {a}
                      </AutoComplete.Option>
                    );
                  })}
                />
              </div>
            );
          }}
        </Field>
        <Field
          key="district-field"
          type="text"
          name={`${addressJson}.district`}
        >
          {({ input, meta }) => {
            const className = classnames('ui-input', {
              valid: meta.valid === true,
              // meta.touched ? meta.valid : true
              // invalid: data.meta.valid === false && data.meta.touched,
              invalid: !(meta.touched ? meta.valid : true),
            });
            return (
              <div className="form-item" style={{ maxWidth: '100%' }}>
                <label className="input-label">Район</label>
                <AutoComplete
                  value={districtVal || formatValueFromProps(input.value)}
                  className={className}
                  defaultValue={input.value && input.value.name}
                  placeholder={this.props.placeholder}
                  onSearch={this.handleSearch(input, 'district', 2)}
                  onSelect={(value, option) => {
                    input.onChange(option.props.data);
                  }}
                  onChange={value => this.setState({ districtVal: value })}
                  dataSource={this.state.district.map((item) => {
                    const a = `${item.socr}. ${item.name}`;
                    return (
                      <AutoComplete.Option key={item.id} data={item}>
                        {a}
                      </AutoComplete.Option>
                    );
                  })}
                />
              </div>
            );
          }}
        </Field>
        <Field
          key="city-field"
          type="text"
          name={`${addressJson}.city`}
        >
          {({ input, meta }) => {
            const className = classnames('ui-input', {
              valid: meta.valid === true,
              // meta.touched ? meta.valid : true
              // invalid: data.meta.valid === false && data.meta.touched,
              invalid: !(meta.touched ? meta.valid : true),
            });
            return (
              <div className="form-item" style={{ maxWidth: '100%' }}>
                <label className="input-label">Город (Поселение)</label>
                <AutoComplete
                  value={cityVal || formatValueFromProps(input.value)}
                  className={className}
                  defaultValue={input.value && input.value.name}
                  placeholder={this.props.placeholder}
                  onSearch={this.handleSearch(input, 'city', 3)}
                  onSelect={(value, option) => {
                    input.onChange(option.props.data);
                  }}
                  onChange={value => this.setState({ cityVal: value })}
                  dataSource={this.state.city.map((item) => {
                    const a = `${item.socr}. ${item.name}`;
                    return (
                      <AutoComplete.Option key={item.id} data={item}>
                        {a}
                      </AutoComplete.Option>
                    );
                  })}
                />
              </div>
            );
          }}
        </Field>
        <Field
          key="street-field"
          type="text"
          name={`${addressJson}.street`}
          validate={(value) => masterItem.full_address_required ? (value || streetVal === '-' ? '' : 'Обязательно') : ''}
        >
          {({ input, meta }) => {
            const className = classnames('ui-input', {
              valid: meta.valid === true,
              // meta.touched ? meta.valid : true
              // invalid: data.meta.valid === false && data.meta.touched,
              invalid: !(meta.touched ? meta.valid : true),
            });
            return (
              <div className="form-item" style={{ maxWidth: '50%' }}>
                <label className="input-label">Улица</label>
                <AutoComplete
                  value={streetVal  || formatValueFromProps(input.value)}
                  className={className}
                  defaultValue={input.value && input.value.name}
                  placeholder={this.props.placeholder}
                  onSearch={this.handleSearch(input, 'street', 4)}
                  onSelect={(value, option) => {
                    input.onChange(option.props.data);
                  }}
                  onChange={(value) => {
                    this.setState({ streetVal: value })
                    if (value === '-') {
                      input.onChange('-');
                    }
                  }}
                  dataSource={this.state.street.map((item) => {
                    const a = `${item.socr}. ${item.name}`;
                    return (
                      <AutoComplete.Option key={item.id} data={item}>
                        {a}
                      </AutoComplete.Option>
                    );
                  })}
                />
                <Error name={`${addressJson}.street`} hint={masterItem.fieldHint} />
              </div>
            );
          }}
        </Field>
        <Field
          key="house-field"
          type="text"
          name={`${addressJson}.house`}
          validate={(value) => masterItem.full_address_required ? (value ? '' : 'Обязательно') : ''}
        >
          {({ input, meta }) => (
            <div className="form-item" style={{ maxWidth: 'calc(50% / 3)' }}>
              <label className="input-label">Дом</label>
              <ValidateInput
                type="text"
                value={input.value}
                valid={meta.touched ? meta.valid : true}
                {...input}
              />
              <Error name={`${addressJson}.house`} hint={masterItem.fieldHint} />
            </div>
          )}
        </Field>
        <Field
          key="building-field"
          type="text"
          name={`${addressJson}.building`}
        >
          {({ input, meta }) => (
            <div className="form-item" style={{ maxWidth: 'calc(50% / 3)' }}>
              <label className="input-label">Строение</label>
              <ValidateInput
                type="text"
                value={input.value}
                valid={meta.touched ? meta.valid : true}
                {...input}
              />
            </div>
          )}
        </Field>
        <Field
          key="flat-field"
          type="text"
          name={`${addressJson}.flat`}
        >
          {({ input, meta }) => (
            <div className="form-item" style={{maxWidth: 'calc(50% / 3)'}}>
              <label className="input-label">Квартира</label>
              <ValidateInput
                type="text"
                value={input.value}
                valid={meta.touched ? meta.valid : true}
                {...input}
              />
            </div>
          )}
        </Field>
      </React.Fragment>
    );
  }
}

export default Address;
