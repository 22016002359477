import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Field } from 'react-final-form';
import { AutoComplete, Input, Select } from 'antd';
import ConditionalTooltip from '../../../../components/ui/ConditionalTooltip';
import fetch from '../../../../utils/fetch';
import { countryAlpha2ByAlpha3 } from '../../../../utils/format';
import { objectDotNotationGetter } from '../../../../utils/objectDotNotationGetter';


const serialize = function (obj) {
  const str = [];
  for (const p in obj) {
    if (obj.hasOwnProperty(p)) {
      str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
    }
  }
  return str.join('&');
};

const dropdownAlign = { offset: [0, 8] };

const { Option } = Select;

class AutoCompleteFinalField extends React.PureComponent {
  static propTypes = {
    item: PropTypes.object,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    values: PropTypes.object,
    validate: PropTypes.func,
    changeValue: PropTypes.func,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    item: {},
    placeholder: '',
    disabled: false,
    values: {},
    validate: () => {},
    changeValue: () => {},
    onChange: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      dataSource: this.props.item.items || [],
      // selectedItem: null,
    };
  }

  componentDidMount() {
    this.handleSearch('', false);
  }

  handleSearch = (input = '', clear = true, onChange) => {
    if (clear) {
      this.props.changeValue(this.props.item.field_name, undefined);
    }

    if (this.props.item.rest) {
      this.getItems(input, this.props.values, this.props.item)
        .then((result) => {
          let dataSource = result.result;
          const { responseKeyValueMap } = this.props.item;
          if (this.props.item.responseKeyValueMap) {
            dataSource = result.result.map(item => ({
              ...item,
              name: item[responseKeyValueMap.name],
              value: item[responseKeyValueMap.value],
            }));
          }
          //todo ignore
          this.setState({ dataSource }, () => {
            const item = this.state.dataSource.find(dataItem => dataItem.name === input);
            if (item && onChange) {
              onChange(item);
              this.setState({
                dataSource: [],
                // selectedItem: item,
              });
            }
            // if (!item) {
            //   this.setState({ selectedItem: null });
            // }
          });
        })
        .catch(console.log);
    } else {
      this.setState({
        dataSource: this.props.item.items ? this.props.item.items.filter((item) => {
          const nameKey = item.name ? 'name' : 'label';
          return item[nameKey].toUpperCase().indexOf(input.toUpperCase()) !== -1;
        }) : [],
      }, () => {
        const item = this.state.dataSource.find(dataItem => dataItem.name === input);
        if (item && onChange) {
          onChange(item);
          this.setState({
            dataSource: [],
            // selectedItem: item,
          });
        }
        // if (!item) {
        //   this.setState({ selectedItem: null });
        // }
      });
    }
  };

  getItems = (inputValue, values, item) => {
    const matching = item.rest.match(/(.*)(\{.*\})|.*/);
    const rest = (matching[1] || matching[0]).trim();
    const params = matching[2] ? matching[2].replace(/[{}\s]/g, '').split(',') : [];
    let actDeep = this.props.user.info;
    let query = inputValue;
    if (item.restUserKey) {
      const param = item.restUserKey;

      param.split('.').forEach((i) => {
        actDeep = actDeep[i];
      });
      query = actDeep;
    }
    const p = item.restKey ? { [item.restKey]: query } : { query };

    params.forEach((key) => {
      const value = objectDotNotationGetter(values, key);
      const splittedKey = key.split('.');
      const lastKey = splittedKey[splittedKey.length - 1];
      p[lastKey] = typeof value === 'object' ? value.value : value;
    });

    return fetch(`${rest}?${serialize(p)}`);
  };

  getAutocompleteOptions = () => {
    const { dataSource } = this.state;
    const { item, item: { field_name } } = this.props;
    const isCountrySubtype = item.subtype === 'country';

    return dataSource.map(it => (
      <AutoComplete.Option key={`${field_name}-${it.value}`} data={it}>
        {(isCountrySubtype && it.alpha3 && countryAlpha2ByAlpha3[it.alpha3]) && <img className="ui-autocomplete-option-icon" src={`/assets/svg/flags/1x1/${countryAlpha2ByAlpha3[it.alpha3].toLowerCase()}.svg`} alt={it.name || it.label} />}
        <span className="ui-autocomplete-option-text">{it.name ? it.name : it.label}</span>
      </AutoComplete.Option>
    ));
  };

  getSelectOptions = () => {
    const { item, item: { field_name } } = this.props;

    return item.items.map(it => (
      <Option key={`${field_name}-${it.value}`} data={it}>
        {it.name ? it.name : it.label}
      </Option>
    ));
  };

  fieldRender = (data) => {
    // const { selectedItem } = this.state;
    const { meta: { touched, valid }, input } = data;
    const className = classnames('ui-input', { valid: touched && valid, invalid: touched && !valid });
    const valueObj = (input && input.value) ? { value: input.value } : { };
    const { values, item, item: { field_name }, disabled, placeholder } = this.props;
    const selectedItem = values[field_name];
    const isCountrySelector = field_name.includes('country'); // TODO: smth more reliable
    const isCountryWithAlpha3Selected = selectedItem && selectedItem.alpha3 && countryAlpha2ByAlpha3[selectedItem.alpha3];

    // try to override onFocus autocomplete prop in tooltip wrap to make tooltip work (and save prev functionality)
    return (
      <ConditionalTooltip hint={item.hint} onFocus={() => this.handleSearch(input.value, false)} onBlur={input.onBlur}>
        {(item.rest || item.items.length > 5) ? (
          <AutoComplete
            dropdownAlign={dropdownAlign}
            className={className}
            disabled={disabled}
            defaultValue={input.value}
            placeholder={placeholder}
            dataSource={this.getAutocompleteOptions()}
            onSearch={inputVal => this.handleSearch(inputVal, true, input.onChange)}
            onSelect={(value, option) => {
              //changeValue если есть флаг
              if (item.appendResultToForm) {
                const actualObject = option.props.data;
                Object.keys(actualObject).forEach((dataKey) => {
                  let actualValue = actualObject[dataKey];
                  // if (dataKey === 'cost') {
                  //   actualValue = 1245;
                  // }
                  if (['updatedAt', 'createdAt'].indexOf(dataKey) >= 0) {
                    actualValue = actualObject[dataKey] * 1000;
                  }
                  this.props.changeValue(dataKey, actualValue);
                });
              }
              // this.setState({ selectedItem: option.props.data });

              input.onChange(item.returnValueOnly ? option.props.data.value : option.props.data);
            }}
            onFocus={() => this.handleSearch(input.value, false)}
            onBlur={input.onBlur}
            {...valueObj}
          >
            <Input
              prefix={
                (isCountrySelector && isCountryWithAlpha3Selected) && <img className="ui-autocomplete-option-icon" src={`/assets/svg/flags/1x1/${countryAlpha2ByAlpha3[selectedItem.alpha3].toLowerCase()}.svg`} alt={selectedItem.name || selectedItem.label} />
              }
            />
          </AutoComplete>
        ) : (
          <Select
            dropdownAlign={dropdownAlign}
            className={className}
            disabled={disabled}
            defaultValue={input.value}
            placeholder={placeholder}
            onSelect={(value, option) => {
              input.onChange(option.props.data);
            }}
            onFocus={() => this.handleSearch(input.value, false)}
            onBlur={input.onBlur}
            {...valueObj}
          >
            {this.getSelectOptions()}
          </Select>
        )}

      </ConditionalTooltip>
    );
  };

  fieldFormat = value => value && value.name;

  fieldParse = val => val;

  render() {
    const { item: { field_name }, validate } = this.props;

    return (
      <Field
        name={field_name}
        validate={validate}
        validateOnBlur
        render={this.fieldRender}
        format={this.fieldFormat}
        parse={this.fieldParse}
      />
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
});

export default connect(mapStateToProps)(AutoCompleteFinalField);
