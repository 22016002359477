import { LOAD_TRANSACTION, REMOVE_TRANSACTION } from '../constants/actionTypes';

export function loadTransaction(txData) {
  return {
    type: LOAD_TRANSACTION,
    payload: txData,
  };
}

export function removeTransaction() {
  return dispatch => dispatch({ type: REMOVE_TRANSACTION });
}
