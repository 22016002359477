/* eslint-disable */
import moment from 'moment';
import { ISO_VALUE } from '../constants/currencies';

export const formatJackpot = (jackpot) => {
  if (typeof jackpot === 'undefined') return 0;
  const decreasedJackpot = jackpot / 100;
  return decreasedJackpot.toLocaleString().replace(/,/g, ' ');
};

export const formatPrice = (price, exp = 2) => ((+price) / 10 ** exp).toFixed(2) * 1;

export const formatPhoneForTransactions = phone => phone.replace(/[^0-9]/g, '');

export const formatRawPhoneToMasked = phone => phone.replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, '+7 ($2) $3-$4-$5');

export const momentCalendarFormat = {
  sameDay: '[Сегодня, в&nbsp;]LT',
  nextDay: '[Завтра, в&nbsp;]LT',
  lastDay: '[Вчера, в&nbsp;]LT',
  nextWeek(now) {
    if (now.week() !== this.week()) {
      switch (this.day()) {
        case 0:
          return now.day() <= this.day() ? 'dddd, [в&nbsp;]LT' : '[След.] dddd, [в&nbsp;]LT';
        case 1:
        case 2:
        case 4:
          return now.day() <= this.day() ? 'dddd, [в&nbsp;]LT' : '[След.] dddd, [в&nbsp;]LT';
        case 3:
        case 5:
        case 6:
          return now.day() <= this.day() ? 'dddd, [в&nbsp;]LT' : '[След.] dddd, [в&nbsp;]LT';
      }
    } else if (this.day() === 2) {
      return 'dddd, [в&nbsp;]LT';
    } else {
      return 'dddd, [в&nbsp;]LT';
    }
  },
  lastWeek(now) {
    if (now.week() !== this.week()) {
      switch (this.day()) {
        case 0:
          return '[В прошлое] dddd, [в] LT';
        case 1:
        case 2:
        case 4:
          return '[В прошлый] dddd, [в] LT';
        case 3:
        case 5:
        case 6:
          return '[В прошлую] dddd, [в] LT';
      }
    } else if (this.day() === 2) {
      return '[Во] dddd, [в] LT';
    } else {
      return '[В] dddd, [в] LT';
    }
  },
  sameElse: 'L',
};

export const formatDateAndTime = dateAndTime => (moment(dateAndTime, moment.ISO_8601).calendar(null, momentCalendarFormat)).capitalize();

const currencyISO = {
  971: { name: 'AFGHANISTAN	Afghani', value: 'AFN' },
  '008': { name: 'ALBANIA	Lek', value: 'ALL' },
  '012': { name: 'ALGERIA	Algerian Dinar', value: 'DZD' },
  978: { name: 'ANDORRA	Euro', value: 'EUR', symbol: '€' },
  973: { name: 'ANGOLA	Kwanza', value: 'AOA' },
  '032': { name: 'ARGENTINA	Argentine Peso', value: 'ARS' },
  '051': { name: 'ARMENIA	Armenian Dram', value: 'AMD' },
  533: { name: 'ARUBA	Aruban Florin', value: 'AWG' },
  944: { name: 'AZERBAIJAN	Azerbaijanian Manat', value: 'AZN' },
  '044': { name: 'BAHAMAS (THE)	Bahamian Dollar', value: 'BSD' },
  '048': { name: 'BAHRAIN	Bahraini Dinar', value: 'BHD' },
  '050': { name: 'BANGLADESH	Taka', value: 'BDT' },
  '052': { name: 'BARBADOS	Barbados Dollar', value: 'BBD' },
  974: { name: 'BELARUS	Belarussian Ruble', value: 'BYR' },
  '084': { name: 'BELIZE	Belize Dollar', value: 'BZD' },
  '060': { name: 'BERMUDA	Bermudian Dollar', value: 'BMD' },
  '064': { name: 'BHUTAN	Ngultrum', value: 'BTN' },
  '068': { name: 'BOLIVIA (PLURINATIONAL STATE OF)	Boliviano', value: 'BOB' },
  984: { name: 'BOLIVIA (PLURINATIONAL STATE OF)	Mvdol', value: 'BOV' },
  977: { name: 'BOSNIA AND HERZEGOVINA	Convertible Mark', value: 'BAM' },
  '072': { name: 'BOTSWANA	Pula', value: 'BWP' },
  986: { name: 'BRAZIL	Brazilian Real', value: 'BRL' },
  '096': { name: 'BRUNEI DARUSSALAM	Brunei Dollar', value: 'BND' },
  975: { name: 'BULGARIA	Bulgarian Lev', value: 'BGN' },
  108: { name: 'BURUNDI	Burundi Franc', value: 'BIF' },
  132: { name: 'CABO VERDE	Cabo Verde Escudo', value: 'CVE' },
  116: { name: 'CAMBODIA	Riel', value: 'KHR' },
  124: { name: 'CANADA	Canadian Dollar', value: 'CAD' },
  136: { name: 'CAYMAN ISLANDS (THE)	Cayman Islands Dollar', value: 'KYD' },
  990: { name: 'CHILE	Unidad de Fomento', value: 'CLF' },
  152: { name: 'CHILE	Chilean Peso', value: 'CLP' },
  156: { name: 'CHINA	Yuan Renminbi', value: 'CNY' },
  170: { name: 'COLOMBIA	Colombian Peso', value: 'COP' },
  970: { name: 'COLOMBIA	Unidad de Valor Real', value: 'COU' },
  174: { name: 'COMOROS (THE)	Comoro Franc', value: 'KMF' },
  976: { name: 'CONGO (THE DEMOCRATIC REPUBLIC OF THE)	Congolese Franc', value: 'CDF' },
  188: { name: 'COSTA RICA	Costa Rican Colon', value: 'CRC' },
  191: { name: 'CROATIA	Kuna', value: 'HRK' },
  931: { name: 'CUBA	Peso Convertible', value: 'CUC' },
  192: { name: 'CUBA	Cuban Peso', value: 'CUP' },
  203: { name: 'CZECH REPUBLIC (THE)	Czech Koruna', value: 'CZK' },
  262: { name: 'DJIBOUTI	Djibouti Franc', value: 'DJF' },
  214: { name: 'DOMINICAN REPUBLIC (THE)	Dominican Peso', value: 'DOP' },
  818: { name: 'EGYPT	Egyptian Pound', value: 'EGP' },
  222: { name: 'EL SALVADOR	El Salvador Colon', value: 'SVC' },
  232: { name: 'ERITREA	Nakfa', value: 'ERN' },
  230: { name: 'ETHIOPIA	Ethiopian Birr', value: 'ETB' },
  238: { name: 'FALKLAND ISLANDS (THE) [MALVINAS]	Falkland Islands Pound', value: 'FKP' },
  242: { name: 'FIJI	Fiji Dollar', value: 'FJD' },
  950: { name: 'GABON	CFA Franc BEAC', value: 'XAF' },
  270: { name: 'GAMBIA (THE)	Dalasi', value: 'GMD' },
  981: { name: 'GEORGIA	Lari', value: 'GEL' },
  936: { name: 'GHANA	Ghana Cedi', value: 'GHS' },
  292: { name: 'GIBRALTAR	Gibraltar Pound', value: 'GIP' },
  208: { name: 'GREENLAND	Danish Krone', value: 'DKK' },
  320: { name: 'GUATEMALA	Quetzal', value: 'GTQ' },
  324: { name: 'GUINEA	Guinea Franc', value: 'GNF' },
  328: { name: 'GUYANA	Guyana Dollar', value: 'GYD' },
  332: { name: 'HAITI	Gourde', value: 'HTG' },
  340: { name: 'HONDURAS	Lempira', value: 'HNL' },
  344: { name: 'HONG KONG	Hong Kong Dollar', value: 'HKD' },
  348: { name: 'HUNGARY	Forint', value: 'HUF' },
  352: { name: 'ICELAND	Iceland Krona', value: 'ISK' },
  356: { name: 'INDIA	Indian Rupee', value: 'INR' },
  360: { name: 'INDONESIA	Rupiah', value: 'IDR' },
  960: { name: 'INTERNATIONAL MONETARY FUND (IMF) 	SDR (Special Drawing Right)', value: 'XDR' },
  364: { name: 'IRAN (ISLAMIC REPUBLIC OF)	Iranian Rial', value: 'IRR' },
  368: { name: 'IRAQ	Iraqi Dinar', value: 'IQD' },
  376: { name: 'ISRAEL	New Israeli Sheqel', value: 'ILS' },
  388: { name: 'JAMAICA	Jamaican Dollar', value: 'JMD' },
  392: { name: 'JAPAN	Yen', value: 'JPY' },
  400: { name: 'JORDAN	Jordanian Dinar', value: 'JOD' },
  398: { name: 'KAZAKHSTAN	Tenge', value: 'KZT' },
  404: { name: 'KENYA	Kenyan Shilling', value: 'KES' },
  408: { name: 'KOREA (THE DEMOCRATIC PEOPLE’S REPUBLIC OF)	North Korean Won', value: 'KPW' },
  410: { name: 'KOREA (THE REPUBLIC OF)	Won', value: 'KRW' },
  414: { name: 'KUWAIT	Kuwaiti Dinar', value: 'KWD' },
  417: { name: 'KYRGYZSTAN	Som', value: 'KGS' },
  418: { name: 'LAO PEOPLE’S DEMOCRATIC REPUBLIC (THE)	Kip', value: 'LAK' },
  422: { name: 'LEBANON	Lebanese Pound', value: 'LBP' },
  426: { name: 'LESOTHO	Loti', value: 'LSL' },
  430: { name: 'LIBERIA	Liberian Dollar', value: 'LRD' },
  434: { name: 'LIBYA	Libyan Dinar', value: 'LYD' },
  446: { name: 'MACAO	Pataca', value: 'MOP' },
  807: { name: 'MACEDONIA (THE FORMER YUGOSLAV REPUBLIC OF)	Denar', value: 'MKD' },
  969: { name: 'MADAGASCAR	Malagasy Ariary', value: 'MGA' },
  454: { name: 'MALAWI	Kwacha', value: 'MWK' },
  458: { name: 'MALAYSIA	Malaysian Ringgit', value: 'MYR' },
  462: { name: 'MALDIVES	Rufiyaa', value: 'MVR' },
  478: { name: 'MAURITANIA	Ouguiya', value: 'MRO' },
  480: { name: 'MAURITIUS	Mauritius Rupee', value: 'MUR' },
  965: { name: 'MEMBER COUNTRIES OF THE AFRICAN DEVELOPMENT BANK GROUP	ADB Unit of Account', value: 'XUA' },
  484: { name: 'MEXICO	Mexican Peso', value: 'MXN' },
  979: { name: 'MEXICO	Mexican Unidad de Inversion (UDI)', value: 'MXV' },
  498: { name: 'MOLDOVA (THE REPUBLIC OF)	Moldovan Leu', value: 'MDL' },
  496: { name: 'MONGOLIA	Tugrik', value: 'MNT' },
  943: { name: 'MOZAMBIQUE	Mozambique Metical', value: 'MZN' },
  104: { name: 'MYANMAR	Kyat', value: 'MMK' },
  516: { name: 'NAMIBIA	Namibia Dollar', value: 'NAD' },
  524: { name: 'NEPAL	Nepalese Rupee', value: 'NPR' },
  558: { name: 'NICARAGUA	Cordoba Oro', value: 'NIO' },
  566: { name: 'NIGERIA	Naira', value: 'NGN' },
  512: { name: 'OMAN	Rial Omani', value: 'OMR' },
  586: { name: 'PAKISTAN	Pakistan Rupee', value: 'PKR' },
  590: { name: 'PANAMA	Balboa', value: 'PAB' },
  598: { name: 'PAPUA NEW GUINEA	Kina', value: 'PGK' },
  600: { name: 'PARAGUAY	Guarani', value: 'PYG' },
  604: { name: 'PERU	Nuevo Sol', value: 'PEN' },
  608: { name: 'PHILIPPINES (THE)	Philippine Peso', value: 'PHP' },
  985: { name: 'POLAND	Zloty', value: 'PLN' },
  634: { name: 'QATAR	Qatari Rial', value: 'QAR' },
  946: { name: 'ROMANIA	Romanian Leu', value: 'RON' },
  643: { name: 'RUSSIAN FEDERATION (THE)	Russian Ruble', value: 'RUB', symbol: '₽' },
  646: { name: 'RWANDA	Rwanda Franc', value: 'RWF' },
  654: { name: 'SAINT HELENA, ASCENSION AND TRISTAN DA CUNHA	Saint Helena Pound', value: 'SHP' },
  951: { name: 'SAINT VINCENT AND THE GRENADINES	East Caribbean Dollar', value: 'XCD' },
  882: { name: 'SAMOA	Tala', value: 'WST' },
  678: { name: 'SAO TOME AND PRINCIPE	Dobra', value: 'STD' },
  682: { name: 'SAUDI ARABIA	Saudi Riyal', value: 'SAR' },
  941: { name: 'SERBIA	Serbian Dinar', value: 'RSD' },
  690: { name: 'SEYCHELLES	Seychelles Rupee', value: 'SCR' },
  694: { name: 'SIERRA LEONE	Leone', value: 'SLL' },
  702: { name: 'SINGAPORE	Singapore Dollar', value: 'SGD' },
  532: { name: 'SINT MAARTEN (DUTCH PART)	Netherlands Antillean Guilder', value: 'ANG' },
  '090': { name: 'SOLOMON ISLANDS	Solomon Islands Dollar', value: 'SBD' },
  706: { name: 'SOMALIA	Somali Shilling', value: 'SOS' },
  710: { name: 'SOUTH AFRICA	Rand', value: 'ZAR' },
  728: { name: 'SOUTH SUDAN	South Sudanese Pound', value: 'SSP' },
  144: { name: 'SRI LANKA	Sri Lanka Rupee', value: 'LKR' },
  938: { name: 'SUDAN (THE)	Sudanese Pound', value: 'SDG' },
  968: { name: 'SURINAME	Surinam Dollar', value: 'SRD' },
  578: { name: 'SVALBARD AND JAN MAYEN	Norwegian Krone', value: 'NOK' },
  748: { name: 'SWAZILAND	Lilangeni', value: 'SZL' },
  752: { name: 'SWEDEN	Swedish Krona', value: 'SEK' },
  947: { name: 'SWITZERLAND	WIR Euro', value: 'CHE' },
  756: { name: 'SWITZERLAND	Swiss Franc', value: 'CHF' },
  948: { name: 'SWITZERLAND	WIR Franc', value: 'CHW' },
  760: { name: 'SYRIAN ARAB REPUBLIC	Syrian Pound', value: 'SYP' },
  901: { name: 'TAIWAN (PROVINCE OF CHINA)	New Taiwan Dollar', value: 'TWD' },
  972: { name: 'TAJIKISTAN	Somoni', value: 'TJS' },
  834: { name: 'TANZANIA, UNITED REPUBLIC OF	Tanzanian Shilling', value: 'TZS' },
  764: { name: 'THAILAND	Baht', value: 'THB' },
  952: { name: 'TOGO	CFA Franc BCEAO', value: 'XOF' },
  554: { name: 'TOKELAU	New Zealand Dollar', value: 'NZD' },
  776: { name: 'TONGA	Pa’anga', value: 'TOP' },
  780: { name: 'TRINIDAD AND TOBAGO	Trinidad and Tobago Dollar', value: 'TTD' },
  788: { name: 'TUNISIA	Tunisian Dinar', value: 'TND' },
  949: { name: 'TURKEY	Turkish Lira', value: 'TRY', symbol: '₺' },
  934: { name: 'TURKMENISTAN	Turkmenistan New Manat', value: 'TMT' },
  '036': { name: 'TUVALU	Australian Dollar', value: 'AUD' },
  800: { name: 'UGANDA	Uganda Shilling', value: 'UGX' },
  980: { name: 'UKRAINE	Hryvnia', value: 'UAH' },
  784: { name: 'UNITED ARAB EMIRATES (THE)	UAE Dirham', value: 'AED' },
  826: { name: 'UNITED KINGDOM OF GREAT BRITAIN AND NORTHERN IRELAND (THE)	Pound Sterling', value: 'GBP' },
  997: { name: 'UNITED STATES OF AMERICA (THE)	US Dollar (Next day)', value: 'USN' },
  940: { name: 'URUGUAY	Uruguay Peso en Unidades Indexadas (URUIURUI)', value: 'UYI' },
  858: { name: 'URUGUAY	Peso Uruguayo', value: 'UYU' },
  860: { name: 'UZBEKISTAN	Uzbekistan Sum', value: 'UZS' },
  548: { name: 'VANUATU	Vatu', value: 'VUV' },
  937: { name: 'VENEZUELA (BOLIVARIAN REPUBLIC OF)	Bolivar', value: 'VEF' },
  704: { name: 'VIET NAM	Dong', value: 'VND' },
  840: { name: 'VIRGIN ISLANDS (U.S.)	US Dollar', value: 'USD', symbol: '$' },
  953: { name: 'WALLIS AND FUTUNA	CFP Franc', value: 'XPF' },
  504: { name: 'WESTERN SAHARA	Moroccan Dirham', value: 'MAD' },
  886: { name: 'YEMEN	Yemeni Rial', value: 'YER' },
  967: { name: 'ZAMBIA	Zambian Kwacha', value: 'ZMW' },
  932: { name: 'ZIMBABWE	Zimbabwe Dollar', value: 'ZWL' },
  955: { name: 'ZZ01_Bond Markets Unit European_EURCO	Bond Markets Unit European Composite Unit (EURCO)', value: 'XBA' },
  956: { name: 'ZZ02_Bond Markets Unit European_EMU-6	Bond Markets Unit European Monetary Unit (E.M.U.-6)', value: 'XBB' },
  957: { name: 'ZZ03_Bond Markets Unit European_EUA-9	Bond Markets Unit European Unit of Account 9 (E.U.A.-9)', value: 'XBC' },
  958: { name: 'ZZ04_Bond Markets Unit European_EUA-17	Bond Markets Unit European Unit of Account 17 (E.U.A.-17)', value: 'XBD' },
  963: { name: 'ZZ06_Testing_Code	Codes specifically reserved for testing purposes', value: 'XTS' },
  999: { name: 'ZZ07_No_Currency	The codes assigned for transactions where no currency is involved', value: 'XXX' },
  959: { name: 'ZZ08_Gold	Gold', value: 'XAU' },
  964: { name: 'ZZ09_Palladium	Palladium', value: 'XPD' },
  962: { name: 'ZZ10_Platinum	Platinum', value: 'XPT' },
  961: { name: 'ZZ11_Silver	Silver', value: 'XAG' },
};

export function currencyIntToIso(input, symbol) {
  return currencyISO[input] ? ((symbol && currencyISO[input].symbol) ? currencyISO[input].symbol : currencyISO[input].value) : input;
}

export function formatMoneyWithCurrency({
  amount, currency = ISO_VALUE.RUB, exponent = 2, digitsAfterComma = 2, withSpaces = true, symbol = true, withDivision = true,
}) {
  const padStartedCurrency = `${currency}`.padStart(3, '0');
  const currencyConfig = currencyISO[padStartedCurrency] ? {
    style: 'currency',
    currency: currencyISO[padStartedCurrency].value,
    currencyDisplay: symbol ? 'symbol' : 'code',
  } : {};

  const formatter = new Intl.NumberFormat('ru', { // TODO: make 'ru' changeable by config
    ...currencyConfig,
    minimumFractionDigits: digitsAfterComma,
    useGrouping: withSpaces,
  });

  const divider = Math.pow(10, exponent);
  const finalAmount = withDivision ? (amount / divider) : amount;

  return formatter.format(finalAmount);
}

export function formatFileSize(bytes) {
  if (typeof bytes !== 'number') {
    return '';
  }
  if (bytes >= 1000000) {
    return `${(bytes / 1000000).toFixed(bytes % 1000000 === 0 ? 0 : 1)}&nbsp;МБ`;
  }

  return `${(bytes / 1000).toFixed(0)}&nbsp;КБ`;
}


export const countryAlpha2ByAlpha3 = {
  ABH: 'AB',
  AFG: 'AF',
  ALA: 'AX',
  ALB: 'AL',
  DZA: 'DZ',
  ASM: 'AS',
  AND: 'AD',
  AGO: 'AO',
  AIA: 'AI',
  ATA: 'AQ',
  ATG: 'AG',
  ARG: 'AR',
  ARM: 'AM',
  ABW: 'AW',
  AUS: 'AU',
  AUT: 'AT',
  AZE: 'AZ',
  BHS: 'BS',
  BHR: 'BH',
  BGD: 'BD',
  BRB: 'BB',
  BLR: 'BY',
  BEL: 'BE',
  BLZ: 'BZ',
  BEN: 'BJ',
  BMU: 'BM',
  BTN: 'BT',
  BOL: 'BO',
  BES: 'BQ',
  BIH: 'BA',
  BWA: 'BW',
  BVT: 'BV',
  BRA: 'BR',
  IOT: 'IO',
  BRN: 'BN',
  BGR: 'BG',
  BFA: 'BF',
  BDI: 'BI',
  KHM: 'KH',
  CMR: 'CM',
  CAN: 'CA',
  CPV: 'CV',
  CYM: 'KY',
  CAF: 'CF',
  TCD: 'TD',
  CHL: 'CL',
  CHN: 'CN',
  CXR: 'CX',
  CCK: 'CC',
  COL: 'CO',
  COM: 'KM',
  COG: 'CG',
  COD: 'CD',
  COK: 'CK',
  CRI: 'CR',
  CIV: 'CI',
  HRV: 'HR',
  CUB: 'CU',
  CUW: 'CW',
  CYP: 'CY',
  CZE: 'CZ',
  DNK: 'DK',
  DJI: 'DJ',
  DMA: 'DM',
  DOM: 'DO',
  ECU: 'EC',
  EGY: 'EG',
  SLV: 'SV',
  GNQ: 'GQ',
  ERI: 'ER',
  EST: 'EE',
  ETH: 'ET',
  FLK: 'FK',
  FRO: 'FO',
  FJI: 'FJ',
  FIN: 'FI',
  FRA: 'FR',
  GUF: 'GF',
  PYF: 'PF',
  ATF: 'TF',
  GAB: 'GA',
  GMB: 'GM',
  GEO: 'GE',
  DEU: 'DE',
  GHA: 'GH',
  GIB: 'GI',
  GRC: 'GR',
  GRL: 'GL',
  GRD: 'GD',
  GLP: 'GP',
  GUM: 'GU',
  GTM: 'GT',
  GGY: 'GG',
  GIN: 'GN',
  GNB: 'GW',
  GUY: 'GY',
  HTI: 'HT',
  HMD: 'HM',
  VAT: 'VA',
  HND: 'HN',
  HKG: 'HK',
  HUN: 'HU',
  ISL: 'IS',
  IND: 'IN',
  IDN: 'ID',
  IRN: 'IR',
  IRQ: 'IQ',
  IRL: 'IE',
  IMN: 'IM',
  ISR: 'IL',
  ITA: 'IT',
  JAM: 'JM',
  JPN: 'JP',
  JEY: 'JE',
  JOR: 'JO',
  KAZ: 'KZ',
  KEN: 'KE',
  KIR: 'KI',
  PRK: 'KP',
  KOR: 'KR',
  KWT: 'KW',
  KGZ: 'KG',
  LAO: 'LA',
  LVA: 'LV',
  LBN: 'LB',
  LSO: 'LS',
  LBR: 'LR',
  LBY: 'LY',
  LIE: 'LI',
  LTU: 'LT',
  LUX: 'LU',
  MAC: 'MO',
  MKD: 'MK',
  MDG: 'MG',
  MWI: 'MW',
  MYS: 'MY',
  MDV: 'MV',
  MLI: 'ML',
  MLT: 'MT',
  MHL: 'MH',
  MTQ: 'MQ',
  MRT: 'MR',
  MUS: 'MU',
  MYT: 'YT',
  MEX: 'MX',
  FSM: 'FM',
  MDA: 'MD',
  MCO: 'MC',
  MNG: 'MN',
  MNE: 'ME',
  MSR: 'MS',
  MAR: 'MA',
  MOZ: 'MZ',
  MMR: 'MM',
  NAM: 'NA',
  NRU: 'NR',
  NPL: 'NP',
  NLD: 'NL',
  NCL: 'NC',
  NZL: 'NZ',
  NIC: 'NI',
  NER: 'NE',
  NGA: 'NG',
  NIU: 'NU',
  NFK: 'NF',
  MNP: 'MP',
  NOR: 'NO',
  OMN: 'OM',
  PAK: 'PK',
  PLW: 'PW',
  PSE: 'PS',
  PAN: 'PA',
  PNG: 'PG',
  PRY: 'PY',
  PER: 'PE',
  PHL: 'PH',
  PCN: 'PN',
  POL: 'PL',
  PRT: 'PT',
  PRI: 'PR',
  QAT: 'QA',
  REU: 'RE',
  ROU: 'RO',
  RUS: 'RU',
  RWA: 'RW',
  BLM: 'BL',
  SHN: 'SH',
  KNA: 'KN',
  LCA: 'LC',
  MAF: 'MF',
  SPM: 'PM',
  VCT: 'VC',
  WSM: 'WS',
  SMR: 'SM',
  STP: 'ST',
  SAU: 'SA',
  SEN: 'SN',
  SRB: 'RS',
  SYC: 'SC',
  SLE: 'SL',
  SGP: 'SG',
  SXM: 'SX',
  SVK: 'SK',
  SVN: 'SI',
  SLB: 'SB',
  SOM: 'SO',
  ZAF: 'ZA',
  SGS: 'GS',
  SSD: 'SS',
  ESP: 'ES',
  LKA: 'LK',
  SDN: 'SD',
  SUR: 'SR',
  SJM: 'SJ',
  SWZ: 'SZ',
  SWE: 'SE',
  CHE: 'CH',
  SYR: 'SY',
  TWN: 'TW',
  TJK: 'TJ',
  TZA: 'TZ',
  THA: 'TH',
  TLS: 'TL',
  TGO: 'TG',
  TKL: 'TK',
  TON: 'TO',
  TTO: 'TT',
  TUN: 'TN',
  TUR: 'TR',
  TKM: 'TM',
  TCA: 'TC',
  TUV: 'TV',
  UGA: 'UG',
  UKR: 'UA',
  ARE: 'AE',
  GBR: 'GB',
  USA: 'US',
  UMI: 'UM',
  URY: 'UY',
  UZB: 'UZ',
  VUT: 'VU',
  VEN: 'VE',
  VNM: 'VN',
  VGB: 'VG',
  VIR: 'VI',
  WLF: 'WF',
  ESH: 'EH',
  YEM: 'YE',
  ZMB: 'ZM',
  ZWE: 'ZW',
  XKX: 'XK',
  RKS: 'XK',
};
