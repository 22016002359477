import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import notification from 'antd/lib/notification';
import WizardTheFormItselfComponent from './WizardTheFormItselfComponent';

export default class Wizard extends React.PureComponent {
  static Page = ({ children }) => children;

  static propTypes = {
    calcSuccess: PropTypes.bool,
    dataFromRedux: PropTypes.bool,
    initialValues: PropTypes.object,
    up: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
  };

  static defaultProps = {
    calcSuccess: false,
    dataFromRedux: false,
    initialValues: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      pageIndex: 0,
      values: props.initialValues || {},
    };
  }

  next = values => this.setState(state => ({
    pageIndex: Math.min(state.pageIndex + 1, this.props.children.length - 1),
    values,
  }));

  resetPageIndex() {
    this.setState({
      pageIndex: 0,
    });
    // this.props.resetPageIndex()
  }

  previous = () => {
    this.setState(state => ({
      pageIndex: Math.max(state.pageIndex - 1, 0),
    }));
  };

  /**
   * NOTE: Both validate and handleSubmit switching are implemented
   * here because 🏁 Redux Final Form does not accept changes to those
   * functions once the form has been defined.
   */

  validate = (values) => {
    const activePage = React.Children.toArray(this.props.children)[
      this.state.pageIndex
    ];

    // return '{EEEQ'; //todo сделай что нибудь с первоначальной валидацией
    if (!activePage || !activePage.props) return {};
    return activePage.props.validate ? activePage.props.validate(values) : {};
  };

  handleSubmit = (values, form) => {
    const {
      children, onSubmit, calcSuccess, forceCalc, location, complexSalesOnBarCodeSubmit,
    } = this.props;

    const currentLocation = location.pathname.split('/')[2];
    const { pageIndex } = this.state;
    const isLastPage = pageIndex === React.Children.count(children) - 1;

    if (!calcSuccess) {
      return forceCalc().then((newValues) => {
        if (!this.props.stepValid) return null;
        if (currentLocation && currentLocation === 'complex_plus_barcode_discount') {
          return complexSalesOnBarCodeSubmit();
        }
        if (isLastPage) {
          return onSubmit(newValues, form);
        }
        return this.next(newValues);
      })
        .catch((e) => {
          console.error(e);
          notification.open({
            duration: 5,
            description: 'При расчете комиссии возникла ошибка, попробуйте изменить данные и повторить операцию.',
          });
        });
      // return;
    }

    if (isLastPage) {
      return onSubmit(values, form);
    }

    if (currentLocation && currentLocation === 'complex_plus_barcode_discount') {
      return complexSalesOnBarCodeSubmit();
    }

    return this.next(values);
  };

  render() {
    const { children, calcSuccess, dataFromRedux, up, blockNext = false, loading } = this.props;
    const { pageIndex, values } = this.state;
    const activePage = React.Children.toArray(children)[pageIndex];
    const isLastPage = pageIndex === React.Children.count(children) - 1;
    return (
      <Form
        initialValues={values}
        validate={this.validate}
        onSubmit={this.handleSubmit}
      >
        {formProps => (
          <WizardTheFormItselfComponent
            {...formProps}
            calcSuccess={calcSuccess}
            dataFromRedux={dataFromRedux}
            isLastPage={isLastPage}
            activePage={activePage}
            previous={this.previous}
            blockNext={blockNext}
            pageIndex={pageIndex}
            up={up}
            loading={loading}
          />
        )}
      </Form>
    );
  }
}
