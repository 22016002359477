import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import classnames from 'classnames';
import newid from '../../utils/newid';

export default class Input extends React.PureComponent {
  static propTypes = {
    type: PropTypes.string,
    value: PropTypes.string,
    label: PropTypes.string,
    className: PropTypes.string,
    autocomplete: PropTypes.string,
    placeholder: PropTypes.string,
    name: PropTypes.string,
    valid: PropTypes.bool,
    showValidity: PropTypes.bool,
    disabled: PropTypes.bool,
    message: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
  };

  static contextTypes = {
    formContext: PropTypes.object,
  };

  static defaultProps = {
    type: 'text',
    label: '',
    className: '',
    name: undefined,
    autocomplete: 'off',
    valid: undefined,
    showValidity: false,
    disabled: false,
    placeholder: '',
    message: '',
    value: '',
    onBlur: () => {},
    onFocus: () => {},
    onChange: () => {},
  };

  constructor(props) {
    super(props);

    this.id = newid();

    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onFocus = this.onFocus.bind(this);
  }

  onChange(e) {
    this.props.onChange(e.target.value);
  }

  onBlur(e) {
    this.props.onBlur(e);
  }

  onFocus(e) {
    this.props.onFocus(e);
  }

  render() {
    const {
      label, type, value, disabled, placeholder, message, valid, showValidity, name, autocomplete, className: classNameFromProps,
    } = this.props;
    const className = classnames('ui-input', classNameFromProps, {
      disabled,
      filled: value && value.length,
      valid: showValidity && valid === true,
      invalid: showValidity && valid === false,
    });
    const messageShown = showValidity && !!message;

    return (
      <div className={className}>
        {!!label && <label htmlFor={this.id}>{label}</label>}
        <input
          id={this.id}
          type={type}
          value={value}
          name={name}
          disabled={disabled}
          onChange={this.onChange}
          onBlur={this.onBlur}
          onFocus={this.onFocus}
          placeholder={placeholder}
          autoComplete={autocomplete}
        />
        <CSSTransition
          timeout={300}
          in={messageShown}
          classNames="ui-input-msg"
          unmountOnExit
        >
          <div className="ui-input-msg">{message}</div>
        </CSSTransition>
      </div>
    );
  }
}
