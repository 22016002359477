import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import TxControlDropdown from './TxControlDropdown';

class TxControlDropdownWrap extends React.PureComponent {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    parentDomElem: PropTypes.object,
    loadTransaction: PropTypes.func.isRequired,
    refreshTransactionStatus: PropTypes.func.isRequired,
    row: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  static defaultProps = {
    parentDomElem: null,
  };

  componentDidMount = () => {
    window.addEventListener('resize', this.handleResize);
  };

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.handleResize);
  };

  getCoords = (elem) => {
    if (!elem) return;

    const box = elem.getBoundingClientRect();
    const { top } = box;

    return {
      top: top + window.scrollY + box.height - 5,
      left: Math.round(box.left + box.width / 2) - 233,
    };
  };

  handleResize = () => {
    this.forceUpdate();
  };

  handleClickOutside = () => {
    this.props.onClose();
  };

  render() {
    const { loadTransaction, refreshTransactionStatus, row, history } = this.props;

    return ReactDOM.createPortal((
      <div className="absolute-dropdown-layer">
        <TxControlDropdown
          coords={this.getCoords(this.props.parentDomElem)}
          onClickOutside={this.handleClickOutside}
          loadTransaction={loadTransaction}
          refreshTransactionStatus={refreshTransactionStatus}
          row={row}
          history={history}
        />
      </div>), window.document.body);
  }
}

export default TxControlDropdownWrap;
