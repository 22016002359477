import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import NewMaskInput from '../../../../components/ui/NewMaskInput';
import Condition from './Condition';
import FinalFormPropsWrapper from './FinalFormPropsWrapper';

export default class DocumentFinalFieldWrap extends React.Component {
  shouldComponentUpdate(nextProps) {
    return nextProps.documentCountry !== this.props.documentCountry
      || nextProps.documentType !== this.props.documentType
      || nextProps.value !== this.props.value
      || nextProps.disabled !== this.props.disabled
      || nextProps.visible !== this.props.visible;
  }

  getMaskPropsByCountryAndType() {
    const { item, documentCountry, documentType } = this.props;

    // Маски серий
    if (item.field_name === 'id_series') {
      switch (documentCountry) {
        case 'RU':
          switch (documentType) {
            case '21': // Паспорт гражданина РФ
              return { mask: '00 00' };
            case '22': // Загран паспорт гражданина РФ
              return { mask: '00' };
            case '23': // Паспорт моряка
            case '24': // Удостоверение личности военнослужащего
            case '25': // Военный билет
              return { mask: 'ЯЯ', definitions: { 'Я': /[А-ЯЁ]/ } };
            default:
              return { mask: /^[A-Z0-9]{0,36}$/ };
          }
        case 'TJ':
          return documentType === '21' || documentType === '31' ? { mask: /.*/ } : { mask: /^[A-Z0-9]{0,36}$/ };
        case 'UZ':
          return documentType === '21' || documentType === '31' ? { mask: /^[A-Z]{0,2}$/ } : { mask: /^[A-Z0-9]{0,36}$/ };
        default:
          return { mask: /^[A-Z0-9]{0,36}$/ };
      }
    }

    // Маски номеров
    if (item.field_name === 'id_number') {
      switch (documentCountry) {
        case 'RU':
          switch (documentType) {
            case '21': // Паспорт гражданина РФ
              return { mask: '000000' };
            case '22': // Загран паспорт гражданина РФ
              return { mask: '0000000' };
            case '23': // Паспорт моряка
            case '25': // Военный билет
              return { mask: '[0]000000' };
            case '24': // Удостоверение личности военнослужащего
              return { mask: '000000' };
            default:
              return { mask: /^[A-Z0-9]{0,36}$/ };
          }
        case 'TJ':
          return documentType === '21' || documentType === '31' ? { mask: '[000]000000' } : { mask: /^[A-Z0-9]{0,36}$/ };
        case 'UZ':
          return documentType === '21' || documentType === '31' ? { mask: '0000000' } : { mask: /^[A-Z0-9]{0,36}$/ };
        default:
          return { mask: /^[A-Z0-9]{0,36}$/ };
      }
    }

    return {};
  }

  validate = (value, item) => {
    const { documentCountry, documentType } = this.props;
    let regex = new RegExp();
    let msg = 'Не все обязательные символы заполнены';

    // Валидация серий
    if (item.field_name === 'id_series') {
      switch (documentCountry) {
        case 'RU':
          switch (documentType) {
            case '21': // Паспорт гражданина РФ
              regex = /^\d{4}$/;
              break;
            case '22': // Загран паспорт гражданина РФ
              regex = /^\d{2}$/;
              break;
            case '23': // Паспорт моряка
            case '24': // Удостоверение личности военнослужащего
            case '25': // Военный билет
              regex = /^[А-ЯЁ]{2}$/;
              msg = 'Две заглавные русские буквы';
              break;
            default:
              regex = /^[A-Z0-9]{0,36}$/;
              break;
          }
          break;
        case 'TJ':
          if (documentType !== '21' && documentType !== '31') {
            regex = /^[A-Z0-9]{0,36}$/;
          }
          break;
        case 'UZ':
          if (documentType === '21' || documentType === '31') {
            regex = /^[A-Z]{2}$/;
            msg = 'Две заглавные буквы';
          }
          break;
        default:
          regex = /^[A-Z0-9]{0,36}$/;
          break;
      }
    }

    // Валидация номеров
    if (item.field_name === 'id_number') {
      switch (documentCountry) {
        case 'RU':
          switch (documentType) {
            case '21': // Паспорт гражданина РФ
              regex = /^\d{6}$/;
              break;
            case '22': // Загран паспорт гражданина РФ
              regex = /^\d{7}$/;
              break;
            case '23': // Паспорт моряка
            case '25': // Военный билет
              regex = /^\d?\d{6}$/;
              break;
            case '24': // Удостоверение личности военнослужащего
              regex = /^\d{6}$/;
              break;
            default:
              regex = /^[A-Z0-9]{0,36}$/;
              break;
          }
          break;
        case 'TJ':
          regex = documentType === '21' || documentType === '31' ? /^\d{6,9}$/ : /^[A-Z0-9]{0,36}$/;
          break;
        case 'UZ':
          regex = documentType === '21' || documentType === '31' ? /^\d{7}$/ : /^[A-Z0-9]{0,36}$/;
          break;
        default:
          regex = /^[A-Z0-9]{0,36}$/;
          break;
      }
    }

    if (!value && item.required && !item.notRequired) {
      return 'Поле обязательно для заполнения';
    }

    if (!regex.test(value)) {
      return msg;
    }

    return '';
  };

  render() {
    const {
      item, visible, styleFromParent, disabled, value,
    } = this.props;

    return (
      <Condition visible={visible}>
        <Field
          type="string"
          name={item.field_name}
          placeholder={item.placeholder}
          validate={v => this.validate(v, item)}
        >
          {FinalFormPropsWrapper(NewMaskInput, {
            value,
            item,
            disabled,
            styleFromParent,
            type: 'text',
            uppercase: true,
            maskProps: this.getMaskPropsByCountryAndType(),
          })}
        </Field>
      </Condition>
    );
  }
}

DocumentFinalFieldWrap.propTypes = {
  item: PropTypes.object.isRequired,
  value: PropTypes.string,
  documentCountry: PropTypes.string,
  documentType: PropTypes.string,
  styleFromParent: PropTypes.object,
  visible: PropTypes.bool,
  disabled: PropTypes.bool,
  validation: PropTypes.func,
};

DocumentFinalFieldWrap.defaultProps = {
  value: undefined,
  documentCountry: '',
  documentType: '',
  styleFromParent: {},
  visible: false,
  disabled: false,
  validation: () => {},
};
