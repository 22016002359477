import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import classnames from 'classnames';
import Button from '../../components/ui/Button';

class RutokenPinModal extends React.PureComponent {
  static propTypes = {
    active: PropTypes.bool.isRequired,
    transitionName: PropTypes.string.isRequired,
    transitionEnterTimeout: PropTypes.number.isRequired,
    transitionLeaveTimeout: PropTypes.number.isRequired,
    pinInput: PropTypes.func.isRequired,
    className: PropTypes.string,
    backdrop: PropTypes.bool,
    onClickOutside: PropTypes.func,
  };

  static defaultProps = {
    className: undefined,
    backdrop: true,
    onClickOutside: () => {},
  };

  constructor(props) {
    super(props);

    this.el = window.document.createElement('div');
    this.scrollTop = 0;
    this.state = {
      active: true,
      pincode: '',
      error: false,
    };
  }

  componentDidMount() {
    window.document.body.appendChild(this.el);
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (this.props.active !== nextProps.active) {
      const { body } = window.document;

      if (nextProps.active) {
        body.classList.add('contains-modal');
      } else {
        body.classList.remove('contains-modal');
      }
    }
  }

  componentWillUnmount() {
    window.document.body.removeChild(this.el);
    window.document.body.classList.remove('contains-modal');
  }

  handleOnclick = (e) => {
    e.preventDefault();
    const { pincode } = this.state;
    if (!pincode || pincode.length < 8) {
      this.setState({ error: true });
      return;
    }
    this.setState({ active: false }, () => {
      this.props.pinInput(pincode);
    });
  };

  onPincodeChange = pincode => this.setState({ pincode: pincode.target.value, error: false });

  render() {
    const {
      backdrop,
      transitionName,
      transitionEnterTimeout,
      transitionLeaveTimeout,
    } = this.props;
    const { active, error } = this.state;

    return (
      <CSSTransition
        classNames={transitionName}
        timeout={{ enter: transitionEnterTimeout, exit: transitionLeaveTimeout }}
        in={active}
        mountOnEnter
        unmountOnExit
      >
        <div className={classnames('ui-modal', this.props.className)}>
          {backdrop && (<div className="ui-modal-backdrop" onClick={this.props.onClickOutside} />)}
          <div className="ui-modal-content rutoken-modal">
            <div className="modal-header">Ввод пинкода</div>
            <div className="modal-text">
              Для подтверждения операции введите
              <pre />
              пинкод рутокена
            </div>
            <div className="ui-input rutoken-modal-input" id="rutoken-modal-input">
              <form
                onSubmit={this.handleOnclick}
              >
                <label htmlFor="rutoken-modal-input">Пинкод</label>
                <input type="password" onChange={this.onPincodeChange} />
                <CSSTransition
                  timeout={300}
                  in={error}
                  classNames="ui-input-msg"
                  unmountOnExit
                >
                  <div className="ui-input-msg" dangerouslySetInnerHTML={{ __html: 'Длина пинкода не менее 8-ми символов' }} />
                </CSSTransition>
              </form>
            </div>
            <div className="modal-footer rutoken-footer">
              <Button label="Подтвердить" color={Button.Color.blue} onClick={this.handleOnclick} />
            </div>
          </div>
        </div>
      </CSSTransition>
    );
  }
}

export default RutokenPinModal;
