import React from 'react';
import PropTypes from 'prop-types';

// function escapeRegExp(string) {
//   return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
// }
//
// function convertToJsString(str, dst_extras, mapka) {
//   // console.log(str, dst_extras, mapka)
//   let string = str;
//   mapka.forEach((element) => {
//     string += ` var ${element.field_name}; `;
//   });
//
//   Object.keys(dst_extras).forEach((key) => {
//     // console.log('dst_extras[key]', dst_extras[key])
//     if (typeof dst_extras[key] === 'number') {
//       string += `var ${key} = ${dst_extras[key]}; `;
//     } else if (typeof dst_extras[key] === 'string') {
//       string += `var ${key} = ${JSON.stringify(dst_extras[key])}; `;
//     } else if (typeof dst_extras[key] === 'object') {
//       // string += convertToJsString(string, dst_extras[key]);
//       string += `var ${key} = ${JSON.stringify(dst_extras[key])}; `;
//     }
//   });
//   return string;
// }
//
// const omg = (str) => {
//   try {
//     return new Function('', str)();
//   } catch (e) {
//     return false;
//   }
// };


class Condition extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    visible: PropTypes.bool,
  };

  static defaultProps = {
    visible: false,
  };

  // state = {
  //   visible: true,
  // };

  // componentDidMount() {
  //   const { values, field, mapka, name } = this.props;
  //   let str = convertToJsString('', values, mapka);
  //   str += ` return ${field.condition}; `;
  //   // console.log(str)
  //   if (!omg(str)) {
  //     // console.log('WE SHOULD REMOVE VALUE', this.props.name, this.props.changeValue);
  //     this.props.changeValue(name, undefined);
  //     this.setState({ visible: false });
  //   } else {
  //     this.setState({ visible: true });
  //   }
  // }
  //
  // componentDidUpdate(prevProps, prevState) {
  //   return; //todo удалить по ненадобности
  //   if (prevState.visible !== this.state.visible) return;
  //   const { values, field, mapka } = this.props;
  //   if (field.condition === 'true' || field.condition === '' || typeof field.condition === 'undefined') return;
  //
  //   if (field.condition === 'false') {
  //     if (this.state.visible) {
  //       this.setState({ visible: false });
  //     }
  //     return;
  //   }
  //   const valEqual = JSON.stringify(prevProps.values) === JSON.stringify(values);
  //   if (valEqual) return;
  //   let str = convertToJsString('', values, mapka);
  //
  //   str += ` return ${field.condition}; `;
  //
  //   // console.log(str)
  //   if (!omg(str)) {
  //     // console.log('WE SHOULD REMOVE VALUE', this.props.name, this.props.changeValue);
  //     this.props.changeValue(this.props.name, undefined);
  //     this.setState({ visible: false });
  //   } else {
  //     this.setState({ visible: true });
  //   }
  // }


  render() {
    const { children, visible } = this.props;

    if (visible) {
      return children;
    }
    return null;
  }
}

export default Condition;
