import React from 'react';
import Viewport from './Viewport';

function withViewport(sizes = undefined) {
  function Wrapper(Component) {
    const WithViewport = props => (
      <Viewport render={viewportProps => (<Component {...props} {...viewportProps} />)} sizes={sizes} />
    );

    WithViewport.displayName = `WithViewport(${getDisplayName(Component)})`;
    WithViewport.WrappedComponent = Component;

    return WithViewport;
  }

  return Wrapper;
}

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export default withViewport;
