import notification from 'antd/lib/notification';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import onClickOutside from 'react-onclickoutside';
import { CSSTransition } from 'react-transition-group';
import Close from '../../../assets/svg/close-black.svg';
import Button from '../../../components/ui/Button';
import ClassicForm from '../../../components/ui/ClassicForm';
import ClassicValidateInput from '../../../components/ui/ClassicValidateInput';
import Modal from '../../../components/ui/Modal';
import Spinner from '../../../components/ui/Spinner';
import CmdFormatter, { printFiscalCheque } from '../../../utils/CmdFormatter';
import { downloadFile, downloadFiles } from '../../../utils/downloadFiles';
import fetch from '../../../utils/fetch';
import logger from '../../../utils/logger';
import newid from '../../../utils/newid';
import { sendNotify } from '../../../utils/processing';
import { getTxExtra } from '../../../utils/processingCalls';
import transformToConfirmForm from '../../../utils/transformToConfirmForm';

import Resume from '../assets/svg/resume.svg';
import Refresh from '../assets/svg/refresh.svg';
import Printer from '../assets/svg/printer.svg';
import Download from '../assets/svg/download.svg';
import Cancel from '../assets/svg/cancel.svg';

// only 4 steps of check - more isn't needed for this case
function customClosest(thisEl, selector) {
  let counter = 0;

  function recursiveCheck(el) {
    counter++;
    if (!el) return null;
    if (el.matches(selector)) return el;
    if (counter === 4) { return null; }

    return recursiveCheck(el.parentElement);
  }

  return recursiveCheck(thisEl);
}

class TxControlDropdown extends PureComponent {
  static propTypes = {
    coords: PropTypes.object,
  };

  static defaultProps = {
    coords: {},
  };

  constructor(props) {
    super(props);

    this.state = {
      loadingIdsArr: [],
      modalShown: false,
      modalForResume: false,
      typedTxId: '',
      printCheckLoading: false,
    };

    this.uid = newid();
  }

  onResumeClick = () => {
    this.setState({ modalShown: true, modalForResume: true });
  };

  onRestartClick = () => {
    const { row: { txData, txData: { paymentId } }, loadTransaction, history } = this.props;

    loadTransaction({ ...txData });
    history.push(`/form/${paymentId}`);
  };

  onPrecheckClick = () => {
    const { row: { txData, paymentData } } = this.props;

    this.setActionLoading('precheck');

    const someShit = transformToConfirmForm(paymentData.paymentGroup, txData.dst_extras);
    //todo место для печати
    const cmd = new CmdFormatter(someShit);

    cmd.command('run', [2]);

    for (const property in cmd.p) {
      const value = cmd.p[property];
      cmd.string(value[0], value[1]);
    }

    const delay = new Promise(resolve => setTimeout(resolve, 600)); // min requset duration
    const driverUrl = localStorage.getItem('DRIVER_ADDRESS');
    const urlString = driverUrl ? `${driverUrl}/fr` : `${process.env.DRIVER_ADDRESS}/fr`;

    // const url = new URL(urlString);

    Promise.all([
      delay,
      cmd.callFiscal('shtrih-m', 'COM3', urlString, cmd.cmd),
    ]).then(() => {
      this.resetActionLoading('precheck');
    }, () => {
      notification.open({
        duration: 5,
        description: 'Фискальный накопитель не отвечает',
      });
      this.resetActionLoading('precheck');
    });
  };

  onStolotoClick = () => {
    const driverUrl = localStorage.getItem('DRIVER_ADDRESS');
    const urlString = driverUrl ? `${driverUrl}/fr` : `${process.env.DRIVER_ADDRESS}/fr`;
    const { row: { txData } } = this.props;

    if (typeof txData.dst_extras !== 'undefined' && typeof txData.dst_extras.RECEIPT === 'object') {
      this.setActionLoading('stolotoPrint');

      const { RECEIPT } = txData.dst_extras;
      const cmd = new CmdFormatter({});
      // cmd.command('run', [2]);
      // cmd.command('logo',''); //urltoimage
      for (let i = 0; i < RECEIPT.length; i++) {
        if (RECEIPT[i].indexOf('barcode') !== -1) {
          const probablyBarcode = RECEIPT[i].match(/\d+/);
          if (probablyBarcode) {
            // cmd.barcode(probablyBarcode[0]);
          }
        } else {
          cmd.center(RECEIPT[i]);
        }
      }
      // cmd.center('aaaaa');
      // cmd.command('run', [5]);
      // cmd.command('cut', [0]);
      // processing.execKvit(cmd.cmd).then(function (res) {
      cmd.callFiscal('shtrih-m', 'COM3', urlString, cmd.cmd).then((result) => {
        this.resetActionLoading('stolotoPrint');
      });
      // return execKvit('kvitancia', cmd.cmd);
    } else {
      notification.open({
        duration: 5,
        description: 'Нет возможности распечатать квитанцию для данной транзакции.',
      });
    }
  };

  onTypedTxIdChange = typedTxId => this.setState({ typedTxId });

  onModalConfirm = () => {
    const { typedTxId, modalForResume } = this.state;
    const { row: { txData, txData: { tx }, paymentData } } = this.props;

    if (typedTxId === tx.id) {
      if (modalForResume) {
        const { loadTransaction, history } = this.props;

        loadTransaction({ ...txData });
        history.push('/transactions/confirmation');
      } else {
        this.setState({ printCheckLoading: true });

        const txExtras = getTxExtra(txData);
        const driverUrl = localStorage.getItem('DRIVER_ADDRESS');
        const urlString = driverUrl ? `${driverUrl}/fr` : `${process.env.DRIVER_ADDRESS}/fr`;
        const cmd = printFiscalCheque(paymentData.paymentGroup, txData.dst_extras);

        const coreObjectToOneC = {
          price: txExtras.full_amount,
          GoodId: txExtras.insuranceNomenKey || paymentData.paymentExtras.nomen,
          product: txData.paymentId,
          txId: tx.id,
        };

        // TODO: additional object to one c ???

        cmd.callFiscal('shtrih-m', 'COM3', urlString, cmd.cmd).then(() => sendNotify(coreObjectToOneC))
          .then(() => this.setState({ printCheckLoading: false, modalShown: false }))
          .catch(() => {
            this.setState({ printCheckLoading: false, modalShown: false });
            Promise.reject('fiscal');
          });
      }
    } else {
      notification.open({
        duration: 5,
        description: React.createElement('div', { dangerouslySetInnerHTML: { __html: 'Неверный идентификатор' } }),
      });
    }
  };

  onPrecheckDocumentsClick = () => {
    const { row: { txData: { paymentId: id, gateTx: txId, dst_extras: formData } } } = this.props;

    this.setActionLoading('precheckDocuments');

    downloadFiles({ id, txId, formData })
      .then(() => this.resetActionLoading('precheckDocuments'));
  };

  onCheckDocumentsClick = () => {
    const { row: { txData: { paymentId: id, gateTx: txId, dst_extras: formData } } } = this.props;

    this.setActionLoading('checkDocuments');

    downloadFiles({ id, txId, formData, step: 'cheque' })
      .then(() => this.resetActionLoading('checkDocuments'));
  };

  onCancelClick = () => {
    this.setActionLoading('cancel');

    const { row: { txData: { gateTx: tx } } } = this.props;

    const delay = new Promise(resolve => setTimeout(resolve, 600)); // min requset duration

    Promise.all([
      delay,
      fetch('/merchant/cancel', { method: 'POST', body: { tx, formData: { } }, noDefaultNotification: true }),
    ]).then(() => {
      logger({ message: 'Отмена транзакции из истории транзакций', data: { tx }, level: 'info', tag: 'cancelTx' });
      this.props.refreshTransactionStatus(tx, 'failed');
      this.resetActionLoading('cancel');
    }).catch(() => {
      this.resetActionLoading('cancel');
      notification.open({
        duration: 5,
        description: React.createElement('div', { dangerouslySetInnerHTML: { __html: 'Ошибка отмены, попробуйте еще раз или обратитесь в поддержку' } }),
      });
    });
  };

  onCancelInstructionClick = () => {
    const { row: { paymentData: { paymentCat } } } = this.props;

    // downloadFile(`/assets/zip/refund_instructions_${paymentCat}.zip`, paymentCat, false);

    switch (paymentCat) {
      case 'insurance':
        downloadFile('/assets/zip/refund_instructions.docx', 'Инструкция по возврату (Страховки)', false);
        break;
      case 'transfer':
      default:
        downloadFile('/assets/zip/refund_instructions_transfer.zip', 'Инструкция по возврату (ДП)', false);
    }
  };

  onModalClose = () => this.setState({ modalShown: false });

  setActionLoading = actionId => this.setState(prevState => ({ loadingIdsArr: prevState.loadingIdsArr.concat(actionId) }));

  resetActionLoading = actionId => this.setState(prevState => ({ loadingIdsArr: prevState.loadingIdsArr.filter(id => id !== actionId) }));

  validateRequired = value => (value ? '' : 'Так ничего не выйдет ¯\\_(ツ)_/¯');

  render() {
    const { coords, row: { txData: { status, paymentId, dst_extras: { complexSalesGuid } }, paymentData: { paymentCat } } } = this.props;
    const { loadingIdsArr, modalShown, modalForResume, typedTxId, printCheckLoading } = this.state;
    const actions = [
      { id: 'resume', label: 'Допровести', icon: Resume, handler: this.onResumeClick, disabled: !['inProgress', 'pending'].includes(status) || (['inProgress', 'pending'].includes(status) && paymentId === 'sdalkupilbuyout') },
      { id: 'restart', label: 'Повторить', icon: Refresh, handler: this.onRestartClick, disabled: (status === 'rollback' || (complexSalesGuid !== '0')) },
      { id: 'precheck', label: 'Предчек', icon: Printer, handler: this.onPrecheckClick, disabled: status === 'rollback' },
      { id: 'stolotoPrint', label: 'Печать квитанции', icon: Printer, handler: this.onStolotoClick, disabled: !(['stoloto_rusloto', 'stoloto_4x20', 'stoloto_5x36', 'stoloto_6x45', 'stoloto_gzhl', 'stoloto_zp'].indexOf(paymentId) !== -1 && ['ok', 'success'].includes(status)) },
      { id: 'precheckDocuments', label: 'Документы предчека', icon: Download, handler: this.onPrecheckDocumentsClick, disabled: !['rollback', 'success', 'ok'].includes(status) },
      { id: 'checkDocuments', label: 'Документы чека', icon: Download, handler: this.onCheckDocumentsClick, disabled: !['rollback', 'success', 'ok'].includes(status) },
      { id: 'cancel', label: 'Отменить', icon: Cancel, handler: this.onCancelClick, disabled: !['inProgress', 'pending'].includes(status) || (['inProgress', 'pending'].includes(status) && paymentId === 'sdalkupilbuyout') },
      { id: 'cancelInstruction', label: 'Инструкция по возврату', icon: Download, handler: this.onCancelInstructionClick, disabled: !['ok', 'success'].includes(status) || !['insurance', 'transfer'].includes(paymentCat) },
    ];

    return (
      <div className="tx-control-dropdown" style={{ ...coords }}>
        <ul className="transaction-actions-list">
          {actions.filter(action => !action.disabled).map((action) => {
            const Icon = action.icon;
            const loading = loadingIdsArr.indexOf(action.id) > -1;
            return (
              <li className="transaction-action" key={action.id}>
                <button type="button" className="transaction-action-button" onClick={action.handler} disabled={loading || action.disabled}>
                  <Icon />
                  <div>{action.label}</div>
                  <CSSTransition
                    classNames="ui-button-spinner"
                    timeout={300}
                    in={loading}
                    mountOnEnter
                    unmountOnExit
                  >
                    <Spinner />
                  </CSSTransition>
                </button>
              </li>
            );
          })}
        </ul>
        <Modal
          backdrop
          active={modalShown}
          contentClassName="ui-print-modal"
          onClickOutside={this.onModalClose}
          transitionEnterTimeout={300}
          transitionLeaveTimeout={300}
          transitionName="ui-modal"
        >
          <div className="modal-close-btn" onClick={this.onModalClose}><Close /></div>
          <div className="modal-header">
Подтверждение
            {modalForResume ? 'допроведения транзакции' : 'печати чека'}
          </div>
          <div className="modal-body">
            {modalForResume ? 'Для допроведения транзакции, запросите ее идентификатор у ответственного лица дилера' : 'Для печати чека запросите идентификатор транзакции в тех. поддержке'}
            <ClassicForm id={`${this.uid}-check-print`} onSubmit={this.onModalConfirm}>
              <ClassicValidateInput label="Идентификатор" value={typedTxId} onChange={this.onTypedTxIdChange} validator={this.validateRequired} />
            </ClassicForm>
          </div>
          <div className="modal-footer">
            <Button label="Отменить" color={Button.Color.white} onClick={this.onModalClose} />
            <Button label={modalForResume ? 'Подтвердить и допровести' : 'Подтвердить и распечатать'} color={Button.Color.blue} type="submit" form={`${this.uid}-check-print`} loading={printCheckLoading} />
          </div>
        </Modal>
      </div>
    );
  }
}


export default onClickOutside(TxControlDropdown, {
  handleClickOutside(instance) {
    return function (e) {
      if (!customClosest(e.target, '.tx-control-btn') && !instance.state.modalShown && !instance.state.loadingIdsArr.length) {
        instance.props.onClickOutside();
      }
    };
  },
});
