import { createReducer } from 'typesafe-actions';
import { combineReducers } from 'redux';
import * as actions from '../actions/cart';

const initialOneCState = {
  loading: false,
  error: null,
  isLoaded: false,
  deviceList: [],
};


const initialSuggestedItemsState = {
  loading: false,
  error: null,
  isLoaded: false,
  suggestedItemsList: [],
};

const initialTekoCart = {
  loading: false,
  error: null,
  isLoaded: false,
  cartList: [],
  txs: [],
};


const oneCItemsReducer = createReducer(initialOneCState)
  .handleAction(actions.getCartFromOneC.success, (state, { payload }) => {
    return {
      ...state,
      deviceList: [
        // ...state.deviceList,
        ...payload,
      ],
    };
  });

const suggestedItemsReducer = createReducer(initialSuggestedItemsState)
  .handleAction(actions.getSuggestedItems.success, (state, { payload }) => {

    return {
      ...state,
      isLoaded: true,
      suggestedItemsList: [
        // ...state.suggestedItemsList,
        ...payload,
      ],
    };
  })
  .handleAction(actions.getCalcData.success, (state, {payload: { index, calcResult}}) => {
    const newSuggestedItemsList = state.suggestedItemsList.map((item, i) => {
      return index === i ? { ...item, newCost: calcResult, calcedSuccess: true, price: calcResult.result.src_payment.amount } : { ...item }
    })


    return {
      ...state,
      isLoaded: true,
      suggestedItemsList: newSuggestedItemsList,
    };
  })

const tekoCartReducer = createReducer(initialTekoCart)
  .handleAction(actions.getCartFromTeko.success, (state, { payload }) => {
    return {
      ...state,
      isLoaded: true,
      loading: false,
      cartList: payload.items,
      txs: payload.txs,
    };
  })
  .handleAction(actions.addToCart.request, (state) => {
    return {
      ...state,
      loading: true,
      error: null,
      isLoaded: false,
    };
  })
  .handleAction(actions.addToCart.failure, (state) => {
    return {
      ...state,
      loading: false,
      error: 'Ошибка',
      isLoaded: true,
    };
  })
  .handleAction(actions.addToCart.success, (state, { payload }) => {
    return {
      ...state,
      isLoaded: true,
      loading: false,
      cartList: [
        ...payload.arrayOfItems,
      ],
    };
  })
  .handleAction(actions.removeFromCart.request, (state) => {
    return {
      ...state,
      loading: true,
    };
  })
  .handleAction(actions.removeFromCart.failure, (state) => {
    return {
      ...state,
      loading: false,
      error: 'Ошибка',
      isLoaded: true,
    };
  })
  .handleAction(actions.removeFromCart.success, (state, { payload }) => {

    const newCartList = state.cartList.filter(obj => {
      return obj.cat !== payload.cat && obj.good_id !== payload.good_id;
    })
    return {
      ...state,
      isLoaded: true,
      loading: false,
      cartList: [
        // ...newCartList,
        ...payload.arrayOfItems,
      ],
      txs: payload.txs,
    };
  })
  .handleAction(actions.editCartItem.success, (state, { payload }) => {

    const { coreObjectToOneC, additionalObjectToOneC } = payload;

    const editedCartList = state.cartList.map((cartItem) => {
      if (cartItem.isActive) {
        return {
          ...cartItem,
          status: 'checked',
          coreObjectToOneC,
          additionalObjectToOneC,
        };
      } else {
        return cartItem;
      }
    });

    return {
      ...state,
      isLoaded: true,
      loading: false,
      cartList: [
        ...editedCartList,
      ],
    };
  })
  .handleAction(actions.setCartItemStatus.success, (state, { payload }) => {

    const { index, status = true } = payload;


    const newCardList = state.cartList.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          isActive: status,
        };
      } else {
        return {
          ...item,
          isActive: false,
        };
      }
    });

    return {
      ...state,
      cartList: [
        ...newCardList,
      ],
    };
  });

export default combineReducers({
  oneCItems: oneCItemsReducer,
  suggestedItems: suggestedItemsReducer,
  tekoCart: tekoCartReducer,
});
