import React from 'react';
import PropTypes from 'prop-types';
import onClickOutside from 'react-onclickoutside';
import classnames from 'classnames';

import Lupa from '../../assets/img/search-copy-3.svg';

// const onImgError = (e, errorSrc) => {
//   const src = `/${errorSrc}`;
//   if (!e.target.src.includes(src)) e.target.src = src;
// };

const onImgLoadError = (el, fallbackSrc, defaultSrc) => {
  if (fallbackSrc && !el.src.includes(fallbackSrc)) {
    el.src = `${fallbackSrc[0] === '/' ? '' : '/'}${fallbackSrc}`;
    return;
  }
  if (!el.src.includes(defaultSrc)) el.src = defaultSrc;
};

class CategorySearchInput extends React.PureComponent {
  static propTypes = {
    categories: PropTypes.array.isRequired,
    onCategoryClick: PropTypes.func.isRequired,
  };

  static defaultProps = {};

  state = {
    value: '',
    active: '',
    ddVisible: false,
    foundItems: [],
  };

  componentWillUnmount() {
    window.document.removeEventListener('keydown', this.onKeyDown);
  }

  getFiltered(value) {
    const { categories } = this.props;

    return categories
      .reduce((result, category) => {
        const methods = category.items
          .filter(item => item.name.toLowerCase().includes(value.toLowerCase()));
        return [...result, ...methods];
      }, [])
      // eslint-disable-next-line no-nested-ternary
      .sort((x, y) => ((x.isActive === y.isActive) ? 0 : x.isActive ? -1 : 1))
      .slice(0, 6); // 6 items maximum
  }

  onFocus = () => {
    this.setState({ ddVisible: true });
    window.document.addEventListener('keydown', this.onKeyDown);
  };

  onBlur = () => {
    window.document.removeEventListener('keydown', this.onKeyDown);
  };

  onDropdownContentClick = () => {
    this.setState({ ddVisible: false });
  };

  onChange = ({ target: { value } }) => {
    if (this.state.value !== value) {
      const newState = { value, active: '' };
      if (value) {
        const foundItems = this.getFiltered(value);
        newState.foundItems = foundItems;
        newState.active = foundItems[0] ? foundItems[0].id : '';
      }
      this.setState(newState);
    }
  };

  onKeyDown = (e) => {
    const { value, active, foundItems } = this.state;

    if (!value) {
      return;
    }

    // arrowup || arrowdown || esc || enter || space
    if (e.keyCode === 38 || e.keyCode === 40 || e.keyCode === 27 || e.keyCode === 13 || e.keyCode === 32) {
      const activeIndex = active ? foundItems.findIndex(cat => cat.id === active) : -1;

      switch (e.keyCode) {
        case 27:
          e.preventDefault();
          this.setState({ value: '', active: '' });
          break;

        case 38:
          e.preventDefault();

          if (activeIndex > 0) {
            this.setState({ active: foundItems[activeIndex - 1].id });
          }
          break;

        case 40:
          e.preventDefault();

          if (activeIndex < foundItems.length - 1) {
            this.setState({ active: foundItems[activeIndex + 1].id });
          }
          break;

        case 13:
          const cat = foundItems[activeIndex];

          if (cat) {
            this.onItemClick(cat.id, !cat.isActive);
          }
          break;

        default:
          break;
      }
    }
  };

  onItemClick(id, disabled) {
    const { onCategoryClick } = this.props;

    if (!disabled) {
      onCategoryClick(id);
    }
  }

  handleClickOutside() {
    this.setState({ ddVisible: false });
  }

  renderContent() {
    const { categories } = this.props;
    const { value, active, foundItems } = this.state;

    if (!value) {
      return null;
    }

    if (foundItems.length === 0) {
      return (
        <div className="dropdown-empty">
          Ничего не найдено
        </div>
      );
    }

    return categories
      .filter(category => foundItems.some(item => item.cat === category.id))
      .map((category) => {
        const items = foundItems
          .filter(item => item.cat === category.id)
          .map(item => (
            <div
              key={`${item.id}`}
              className={classnames('dropdown-item', { active: active === item.id, disabled: !item.isActive })}
              onClick={() => this.onItemClick(item.id, !item.isActive)}
              onMouseEnter={() => this.setState({ active: item.id })}
              onMouseLeave={() => this.setState({ active: '' })}
            >
              <div className="provider-logo-container">
                <img
                  src={`/assets/svg/product-icons/${item.id}.svg`}
                  className="provider-icon"
                  alt={item.name}
                  onError={e => onImgLoadError(e.target, item.logo, '/assets/svg/product-icons/default.svg')}
                />
              </div>
              <span className="provider-name">{item.name}</span>
            </div>
          ));

        return (
          <div key={category.id} className="dropdown-group">
            <div className="dropdown-category-title">{category.name}</div>
            {items}
          </div>
        );
      });
  }

  render() {
    const { value, ddVisible, foundItems } = this.state;

    const showDropdown = ddVisible && !!value && !!foundItems.length;

    return (
      <div className={classnames('ui-input-with-icon', { withDropdown: showDropdown })}>
        <Lupa className="ui-input-icon" />
        <input
          className="ui-input-element"
          type="search"
          value={value}
          placeholder="Введите название услуги, платежа, перевода"
          onChange={this.onChange}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
        />
        {
          showDropdown && (
            <div className="categories-dropdown">
              <div className="dropdown-content" onClick={this.onDropdownContentClick}>
                {this.renderContent()}
              </div>
            </div>
          )
        }
      </div>
    );
  }
}

export default onClickOutside(CategorySearchInput);
