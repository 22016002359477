import React from 'react';
import PropTypes from 'prop-types';
import { countryAlpha2ByAlpha3 } from '../../utils/format';

class PopularOptions extends React.PureComponent {
  static propTypes = {
    label: PropTypes.string,
    options: PropTypes.array.isRequired,
    format: PropTypes.oneOf(['country', 'plain']),
    onClick: PropTypes.func.isRequired,
  };

  // if format: country, options must have next shape: { alpha3, name, value, bankId }

  static defaultProps = {
    label: 'Популярные:',
    format: 'plain',
  };

  getListItems() {
    const { options, format, onClick } = this.props;

    return options.map((item) => {
      const isCountry = format === 'country';
      const keyAndAlt = isCountry ? (item.name || item.label) : item;

      return (
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
        <li className="popular-options-list-item" key={keyAndAlt} onClick={() => onClick(item)}>
          {(isCountry && item.alpha3 && countryAlpha2ByAlpha3[item.alpha3]) && (
            <img className="popular-options-list-item-icon country-icon" src={`/assets/svg/flags/1x1/${countryAlpha2ByAlpha3[item.alpha3].toLowerCase()}.svg`} alt={keyAndAlt} />
          )}
          <span className="popular-options-list-item-text">{isCountry ? item.name : item}</span>
        </li>
      );
    });
  }

  render() {
    const { label } = this.props;

    return (
      <div className="popular-options">
        <div className="popular-options-title">{label}</div>
        <ul className="popular-options-list">
          {this.getListItems()}
        </ul>
      </div>
    );
  }
}

export default PopularOptions;
