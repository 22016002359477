import React from 'react';
import clonedeep from 'lodash.clonedeep';

import fetch from '../../../utils/fetch';

// import TxControlDropdownWrap from '../../transactions-history/components/TxControlDropdownWrap';

class Iframes extends React.PureComponent {
  state = {
    partnerUrl: null,
    partnerId: null,
  };

  pollingStatusTimerId = 0;
  _isMounted = false;

  componentDidMount() {

    this._isMounted = true;
    const { match: { params: { formId } } } = this.props;

    this.props.removeFormAndFormData();

    if (this.props.tb) {
      this.props.tb.hideWidget();
    }

    if (['airs', 'buses', 'trains'].indexOf(formId) >= 0) {
      this.fetchForm()
        .then(() => this.generateUrl(formId))
        .then((data) => {
          this.setState({ partnerUrl: data.url, partnerId: data.id }, () => {
            this.pollStatus();
            this.props.updatePageLoading({ pageLoading: false });
          });
        });
      return;
    }
    if (formId === 'eyeline') {
      const url = `http://127.0.0.1:1444/eyeline/?teko_session=${this.props.user.access_token}`;
      this.setState({ partnerUrl: url }, () => {
        this.props.updatePageLoading({ pageLoading: false });
      });
      return;
    }
    this.props.history.replace('/404');
  }


  componentDidUpdate(prevProps) {
    if (this.props.tb !== prevProps.tb && this.props.tb) {
      this.props.tb.hideWidget();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearTimeout(this.pollingStatusTimerId);
    if (this.props.tb) {
      this.props.tb.showWidget();
    }
  }

  fetchForm() {
    return fetch(`/categories/${this.props.match.params.formId}`)
      .then(originalFieldsResponse => this.props.loadForm(originalFieldsResponse.result));
  }

  generateUrl(id) {
    return fetch(`/bilet/url/${id}`).then((result) => {
      const baseUrlObject = result.result;
      const POSID = this.props.user.info && this.props.user.info.user.outletExtra && this.props.user.info.user.outletExtra['1c_point_code'];
      if (POSID) {
        const url = `${baseUrlObject.url}&POS_ID=${POSID}`;
        return {
          id: baseUrlObject.id,
          url,
        };
      }
      return baseUrlObject;
    });
  }

  pollStatus() {
    clearTimeout(this.pollingStatusTimerId);
    const { partnerId } = this.state;
    this.getStatus(partnerId)
      .then((partnerStatus) => {
        if (partnerStatus.billing) {
          return partnerStatus;
        }
        return Promise.reject(partnerStatus);

        //Пока тестовая среда лежит использовать для тестов хардкод ниже

        // return {
        //   email: 'derkunskyi@gmail.com',
        //   url: 'http://storage.bilet-on-line.ru/et/eticket_7152012789_34894166-35842536.pdf',
        //   passengers: [{
        //     firstname: 'Михаил',
        //     middlename: 'Михайлович',
        //     citizen: 'RU',
        //     doctype: 'ПН',
        //     lastname: 'Деркунский',
        //     birthdate: '1993-07-10 00:00:00',
        //     type: 'adt',
        //     docNumber: '0000262973',
        //     gender: 'M',
        //   }],
        //   total: 501,
        //   currency: 'RUR',
        //   billing: '7152012789',
        //   phone: '89168683667',
        //   fee: 412,
        // };
      })
      .then((result) => {
        if (!this._isMounted) return;
        const {
          phone, email, total, fee, currency, billing, url,
        } = result;

        const payerData = {
          phone,
          email,
          total,
          fee,
          currency,
          billing,
          url,
        };

        const passengersData = {};
        result.passengers.forEach((passenger, i) => {
          const j = i + 1;
          passengersData[`lastname${j}`] = passenger.lastname;
          passengersData[`firstname${j}`] = passenger.firstname;
          passengersData[`middlename${j}`] = passenger.middlename ? passenger.middlename : '-';
        });

        const payer = {
          name: 'Плательщик',
          fields: [
            {
              field_name: 'total',
              disabled: true,
              required: true,
              caption: 'Стоимость билета с комиссией',
              type: 'text',
              visual: { size: '50' },
            },
            {
              field_name: 'fee',
              disabled: true,
              required: true,
              notRequired: true,
              caption: 'Комиссия',
              type: 'text',
              visual: { size: '30' },
            },
            {
              field_name: 'currency',
              disabled: true,
              required: true,
              caption: 'Валюта',
              type: 'text',
              visual: { size: '20' },
            },
            {
              field_name: 'phone',
              disabled: true,
              required: true,
              caption: 'Телефон плательщика',
              type: 'phone',
              visual: { size: '50' },
            },
            {
              field_name: 'email',
              disabled: true,
              required: true,
              caption: 'Email плательщика',
              type: 'text',
              visual: { size: '50' },
            },
          ],
        };

        const passengers = {
          name: 'Пассажиры',
          fields: result.passengers.map((passenger, i) => {
            const j = i + 1;
            return [
              {
                field_name: `lastname${j}`,
                caption: `Фамилия пассажира ${j}`,
                disabled: true,
                required: true,
                type: 'text',
                visual: { size: '50' },
              },
              {
                field_name: `firstname${j}`,
                caption: `Имя пассажира ${j}`,
                disabled: true,
                required: true,
                type: 'text',
                visual: { size: '50' },
              },
              {
                field_name: `middlename${j}`,
                caption: `Отчество пассажира ${j}`,
                disabled: true,
                required: true,
                type: 'text',
                visual: { size: '50' },
              },
            ];
          }).reduce((a, b) => a.concat(b), []),
        };

        const { form } = this.props.externalForm;
        const formCopy = clonedeep(form);
        const group = formCopy.group.filter(group => group.name !== 'Пассажиры' && group.name !== 'Плательщик');
        formCopy.group = group.concat([passengers]).concat([payer]);

        this.props.loadForm(formCopy);

        const submitData = {
          product: formCopy.id,
          inner: formCopy.inner,
          srcCls: 'cash',
          amount: payerData.total * 100,
          amountWithCommission: payerData.total * 100,
          formData: {
            ...payerData,
            ...passengersData,
            amount: payerData.total * 100,
            amountWithCommission: payerData.total * 100,
            tAttr1: billing,
          },
          currency: 643, //TODO CHECK DIS IN ALT CURRENCIES, probably swap to +ISO_VALUE[currency]
          id: result.id,
        };
        this.props.loadFormData(submitData);
        this.props.history.push(`/form/${formCopy.id}`);
      })
      .catch(() => {
        if (!this._isMounted) return;
        this.pollingStatusTimerId = setTimeout(() => {
          this.pollStatus();
        }, 5000);
      });
  }

  getStatus(id) {
    return fetch(`/bilet/status/${id}`).then(result => result.result);
  }

  render() {
    const { partnerUrl } = this.state;
    return (
      <React.Fragment>
        {partnerUrl && <iframe src={partnerUrl} title="PartnerScreen" width="100%" style={{
          height: "calc(100vh - 190px)",
          display: "block",
        }} />}
      </React.Fragment>
    );
  }
}

export default Iframes;
