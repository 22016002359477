import React from 'react';
import SmsAuthContainer from './components/SmsAuthContainer';

import './styles/sms-auth.scss';

export default function SmsAuthPage({ location, history, match }) {
  return (
    <SmsAuthContainer location={location} history={history} match={match} />
  );
}
