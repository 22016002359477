import React from 'react';
import Button from '../../../components/ui/Button';

import { backBtnLabel } from '../../../constants/backBtnLabel';

export default class WizardTheFormItselfComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { upperFormIsInformedAboutValidity: false };
  }

  componentDidMount() {
    const { values = {}, form: { change, reset, resetFieldState, getState }, pageIndex } = this.props;
    this.props.up({
      values,
      pageIndex,
      change,
      reset,
      resetFieldState,
      getState,
    });
  }

  componentDidUpdate(prevProps) {
    const {
      values = {}, dataFromRedux, valid, pageIndex, pristine, up,
    } = this.props;
    const { values: preValues = {}, valid: preValid, pageIndex: prevPageIndex } = prevProps;
    const { upperFormIsInformedAboutValidity } = this.state;

    const currentStringToDeterminateParentUpdate = JSON.stringify({ values, valid, pageIndex });
    const prevStringToDeterminateParentUpdate = JSON.stringify({ values: preValues, valid: preValid, pageIndex: prevPageIndex });

    if (currentStringToDeterminateParentUpdate !== prevStringToDeterminateParentUpdate
      || (upperFormIsInformedAboutValidity === false && valid && (pristine === false || dataFromRedux))) {
      if (upperFormIsInformedAboutValidity === false) {
        this.setState({ upperFormIsInformedAboutValidity: true });
      }
      up({ values, stepValid: valid, pageIndex });
    }
  }

  render() {
    const {
      handleSubmit, submitting, values, isLastPage, activePage, pageIndex, blockNext = false, loading,
    } = this.props;

    // const activePageWithFormProps = React.cloneElement(activePage, {
    //   setValue: this.props.mutators.setValue,
    // });

    return (
      <form onSubmit={handleSubmit}>
        <div className="form-inner">
          {activePage}
        </div>
        <div className="buttons-wrap">
          {pageIndex > 0 && (
            <Button disabled={submitting} appearance={Button.Appearance.link} label={backBtnLabel} onClick={this.props.previous} />
          )}
          {!isLastPage && <Button type="submit" loading={loading} disabled={submitting || blockNext} label="Продолжить" color={Button.Color.blue} />}
          {isLastPage && (
            <Button type="submit" disabled={submitting} label="Продолжить" color={Button.Color.blue} />
            // <button type="submit" disabled={submitting}>
            //   Submit
            // </button>
          )}
        </div>
        {/*<button type="kek" onClick={() => this.props.form.change('amount', '123')}>*/}
        {/*setAm*/}
        {/*</button>*/}
        {process.env.NODE_ENV === 'development' && (<pre>{JSON.stringify(values, 0, 2)}</pre>)}
      </form>
    );
  }
}
