import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import SimpleBar from 'simplebar-react';
import CloseIcon from '../../assets/svg/close.svg';
import Spinner from '../ui/Spinner';
// import LoaderIcon from '../../assets/svg/loader-light.svg';
import DomHolder from './DomHolder';

function Sidebar(props) {
  const { children, title, opened, loading, onClose } = props;

  useEffect(
    () => {
      const handler = (e) => {
        if (e.key === 'Escape') {
          onClose();
        }
      };

      if (opened) {
        document.addEventListener('keydown', handler);
      }

      return () => document.removeEventListener('keydown', handler);
    },
    [opened, onClose],
  );

  return (
    <CSSTransition
      classNames="sidebar"
      timeout={300}
      in={opened}
      mountOnEnter
      unmountOnExit
    >
      <div className="sidebar">
        <button className="sidebar-close" type="button" onClick={onClose}>
          <CloseIcon />
        </button>

        {title && <h2>{title}</h2>}

        <SimpleBar className="sidebar-scroll">
          <div className="sidebar-content">
            <DomHolder>
              {children}
            </DomHolder>
          </div>
        </SimpleBar>

        <CSSTransition
          classNames="overlay"
          timeout={150}
          in={loading}
          mountOnEnter
          unmountOnExit
        >
          <div className="overlay">
            <Spinner />
          </div>
        </CSSTransition>
      </div>
    </CSSTransition>
  );
}

Sidebar.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  opened: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

Sidebar.defaultProps = {
  children: null,
  title: null,
  loading: false,
};

export default Sidebar;
