import {
  READ_MESSAGE,
  GET_MESSAGES,
  ENABLE_FETCHING_OVERLAY,
  DISABLE_FETCHING_OVERLAY, UPDATE_OPEN_MODAL_BEFORE_LEAVE, UPDATE_PAGE_LOADING,
} from '../constants/actionTypes';

const initialState = {
  modalOpened: false,
  openModalBeforeLeave: false,
  fetchingOverlayActive: false,
  fetchingOverlayMessage: undefined,
  fetchingOverlayMessageShowTime: undefined, // 3000
  fetchingOverlayMessageDefaultShown: undefined, // false
  url: '',
  messages: [],
  pageLoading: true,
};

export default function ui(state = initialState, action) {
  switch (action.type) {
    case 'app.ui.openConfirmLeaveModal':
      return {
        ...state,
        modalOpened: true,
        url: action.payload.url,
      };

    case 'app.ui.closeConfirmLeaveModal':
      return {
        ...state,
        modalOpened: false,
        url: '',
      };

    case UPDATE_OPEN_MODAL_BEFORE_LEAVE:
      return {
        ...state,
        openModalBeforeLeave: action.payload,
      };

    case GET_MESSAGES:
      return {
        ...state,
        messages: action.payload.sort((a, b) => b.createdT - a.createdT),
      };

    case READ_MESSAGE:
      const newMessages = state.messages.slice(0);
      const readMsgIndex = newMessages.findIndex(msg => msg.id === action.meta.id);
      newMessages[readMsgIndex] = { ...newMessages[readMsgIndex], ...action.meta.data };

      return {
        ...state,
        messages: newMessages,
      };

    case ENABLE_FETCHING_OVERLAY:
      return {
        ...state,
        fetchingOverlayActive: true,
        fetchingOverlayMessage: action.payload.message,
        fetchingOverlayMessageShowTime: action.payload.messageShowTime,
        fetchingOverlayMessageDefaultShown: action.payload.messageDefaultShown,
      };

    case DISABLE_FETCHING_OVERLAY:
      return {
        ...state,
        fetchingOverlayActive: false,
        fetchingOverlayMessage: undefined,
        fetchingOverlayMessageDefaultShown: undefined,
      };

    case UPDATE_PAGE_LOADING:
      return {
        ...state,
        pageLoading: action.payload.pageLoading,
      };

    default:
      return state;
  }
}
