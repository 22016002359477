import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import classnames from 'classnames';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import OnClickOutside from './click-outside/OnClickOutside';

class Modal extends React.PureComponent {
  static propTypes = {
    active: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
    transitionName: PropTypes.string.isRequired,
    transitionEnterTimeout: PropTypes.number.isRequired,
    transitionLeaveTimeout: PropTypes.number.isRequired,
    className: PropTypes.string,
    contentClassName: PropTypes.string,
    backdrop: PropTypes.bool,
    onClickOutside: PropTypes.func,
  };

  static defaultProps = {
    className: undefined,
    contentClassName: undefined,
    backdrop: true,
    onClickOutside: () => {},
  };

  constructor(props) {
    super(props);

    this.el = window.document.createElement('div');
    this.removeBodyClassTimerId = 0;
  }

  componentDidMount() {
    window.document.body.appendChild(this.el);
    if (this.props.active) {
      window.document.body.classList.add('contains-modal');
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.active !== prevProps.active) {
      const { body } = window.document;
      clearTimeout(this.removeBodyClassTimerId);

      if (this.props.active) {
        body.classList.add('contains-modal');
      } else {
        this.removeBodyClassTimerId = setTimeout(() => {
          body.classList.remove('contains-modal');
        }, this.props.transitionLeaveTimeout);
      }
    }
  }

  componentWillUnmount() {
    window.document.body.removeChild(this.el);
    window.document.body.classList.remove('contains-modal');
  }

  render() {
    const {
      children,
      className,
      contentClassName,
      active,
      backdrop,
      transitionName,
      transitionEnterTimeout,
      transitionLeaveTimeout,
    } = this.props;

    const wrapper = (
      <TransitionGroup component={null}>
        {active && (
          <CSSTransition
            classNames={transitionName}
            timeout={{ enter: transitionEnterTimeout, exit: transitionLeaveTimeout }}
            mountOnEnter
            unmountOnExit
          >
            <div className={classnames('ui-modal', className)}>
              {backdrop && (<div className="ui-modal-backdrop" />)}
              <OnClickOutside onClickOutside={this.props.onClickOutside} active={backdrop}>
                {ref => (
                  <div ref={ref} className={classnames('ui-modal-content', contentClassName)}>
                    {children}
                  </div>
                )}
              </OnClickOutside>
            </div>
          </CSSTransition>
        )}
      </TransitionGroup>
    );

    return ReactDOM.createPortal(
      wrapper,
      this.el,
    );
  }
}

export default Modal;
