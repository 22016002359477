import React from 'react';
import CatalogShowcaseContainer from './components/CatalogShowcaseContainer';

import './styles/catalog-showcase.scss';

export default function CatalogShowcasePage({ location, history, match }) {
  return (
    <CatalogShowcaseContainer location={location} history={history} match={match} />
  );
}
