import React from 'react';
import PropTypes from 'prop-types';

export default class NotFound extends React.Component {
  static propTypes = {
    updatePageLoading: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
  };

  componentDidMount() {
    this.props.updatePageLoading({ pageLoading: false });
  }

  componentWillUnmount() {
    this.props.updatePageLoading({ pageLoading: true });
  }

  goBack = () => {
    this.props.history.goBack();
  };

  render() {
    return (
      <div className="page-not-found">
        <div className="container page-not-found-container">
          <h1>404</h1>
          <h2>Страница не найдена</h2>
          {/*<div className="back-link" onClick={this.goBack}>Назад</div>*/}
        </div>
      </div>
    );
  }
}
