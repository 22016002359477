import React from 'react';
import PropTypes from 'prop-types';
import PopularOptions from '../../../../components/ui/PopularOptions';
import AutoCompleteFinalField from './AutocompleteFinalField';
import Error from '../ErrorTooltip';
import Condition from './Condition';

export default function AutocompleteFinalFieldWrap(props) {
  const {
    item, values, styleFromParent, disabled, visible, validation, change,
  } = props;

  return (
    <Condition visible={visible}>
      <div className="form-item" style={styleFromParent}>
        <label className="input-label">{item.caption}</label>
        <AutoCompleteFinalField
          item={item}
          values={values}
          name={item.field_name}
          placeholder="Выберите"
          disabled={disabled}
          validate={value => validation(value, item)}
          changeValue={change}
        />
        <Error name={item.field_name} hint={item.fieldHint} />
        {item.popular && (
          <PopularOptions
            label={item.popular.label}
            format={item.popular.format}
            options={item.popular.items}
            onClick={value => change(item.field_name, value)}
          />
        )}
      </div>
    </Condition>
  );
}

AutocompleteFinalFieldWrap.propTypes = {
  item: PropTypes.object.isRequired,
  values: PropTypes.object,
  styleFromParent: PropTypes.object,
  visible: PropTypes.bool,
  disabled: PropTypes.bool,
  validation: PropTypes.func,
  change: PropTypes.func,
};

AutocompleteFinalFieldWrap.defaultProps = {
  values: {},
  styleFromParent: {},
  visible: false,
  disabled: false,
  validation: () => {},
  change: () => {},
};
