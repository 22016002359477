import * as classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { formatFileSize } from '../../utils/format';

import AttachIcon from '../../assets/svg/attach.svg';

import FilesListItem from './FilesListItem';

class FileInput extends React.PureComponent {
  static propTypes = {
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.array,
    ]).isRequired,
    comment: PropTypes.string,
    acceptMimeTypes: PropTypes.string,
    acceptFileExtensions: PropTypes.string,
    multiple: PropTypes.bool,
    maxFileSize: PropTypes.number,
    onChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    comment: '',
    // comment: '<p>Пожалуйста, загрузите следующие документы:</p><ul><li>- паспорт</li><li>- СНИЛС</li><li>- ИНН</li></ul>',
    acceptMimeTypes: 'application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, image/*, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.presentationml.slideshow',
    acceptFileExtensions: 'PDF, PNG, JPG',
    multiple: false,
    maxFileSize: 1000000,
  };

  constructor(props) {
    super(props);

    const files = [];
    if (props.values[props.item.field_name]) {
      files.push({
        name: props.values[props.item.field_name]
      })
    }

    this.state = {
      dropzoneActive: false,
      files,
    };

    this.dropzoneRef = React.createRef();
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps) {
  }

  componentWillUnmount() {
  }

  onTryAgainClick = (tryAgainFile) => {
    this.dropzoneRef.current.open();
    // const { files } = this.state;
    // const newFiles = files.filter(file => file.name !== tryAgainFile.name);
    //
    // this.setState({ files: newFiles });
  };

  onFileStatusChanged = (changedFile, statusKey, statusValue) => {
    const { files } = this.state;
    const newFiles = files.slice(0);
    const fullFileObject = newFiles.find(file => file.name === changedFile.name);
    fullFileObject[statusKey] = statusValue;

    const { fileSizeError, fileTypeError, fileUploadError } = changedFile;
    const { value, multiple } = this.props;

    if (!(fileSizeError || fileTypeError || fileUploadError) && statusKey === 'fileId') {
      this.props.onChange(multiple ? [...value, statusValue] : statusValue);
    }

    this.setState({ files: newFiles });
  };

  onFileRemove = (removingFile) => {
    const { files } = this.state;
    const { value, multiple } = this.props;
    const newFiles = files.filter(file => file.name !== removingFile.name);

    this.props.onChange(multiple ? value.filter(fileId => fileId !== removingFile.fileId) : '');

    this.setState({ files: newFiles });
  };

  onDrop = (acceptedFiles, rejectedFiles) => {
    const { multiple, maxFileSize } = this.props;
    const { files } = this.state;
    const attachedFiles = [];

    acceptedFiles.forEach((file) => {
      const prevFile = files.find(docFile => docFile.name === file.name);

      if (prevFile && prevFile.size === file.size) return;

      const fileSizeError = file.size > maxFileSize;

      const modifiedFile = !fileSizeError ? file : { name: file.name, size: file.size, type: file.type };

      modifiedFile.fileSizeError = fileSizeError;
      modifiedFile.fileTypeError = false;
      modifiedFile.fileUploadError = false;
      modifiedFile.fileLoading = true;

      attachedFiles.push(modifiedFile);
    });

    rejectedFiles.forEach((file) => {
      const fileSizeError = file.size > maxFileSize;
      const fileTypeError = true; // TODO: xls check!!! mb need to be specific checked
      const fileUploadError = false;
      const fileLoading = false;

      attachedFiles.push({
        name: file.name,
        size: file.size,
        type: file.type,
        fileSizeError,
        fileTypeError,
        fileUploadError,
        fileLoading,
      });
    });

    this.setState(prevState => ({ dropzoneActive: false, files: multiple ? [...prevState.files, ...attachedFiles] : [...attachedFiles] }));
  };

  onDragEnter = () => {
    this.setState({
      dropzoneActive: true,
    });
  };

  onDragLeave = () => {
    this.setState({
      dropzoneActive: false,
    });
  };

  render() {
    const { comment, acceptMimeTypes, acceptFileExtensions, multiple, maxFileSize } = this.props;
    const { dropzoneActive, files } = this.state;
    // const atLeastOneIsLoading = !!files.find(file => file.fileLoading);
    const firstFileError = (files[0] && ((files[0].fileSizeError && 'Недопустимый размер файла') || (files[0].fileTypeError && 'Недопустимый формат файла'))) || ''; // (files[0].fileUploadError && 'Не удалось загрузить')

    return (
      <div className="ui-file-input">
        <Dropzone
          multiple={multiple}
          style={{}}
          accept={acceptMimeTypes}
          onDrop={this.onDrop}
          onDragEnter={this.onDragEnter}
          onDragLeave={this.onDragLeave}
          ref={this.dropzoneRef}
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()} className={classnames('file-drop-block', { active: dropzoneActive, multiple })}>
              {comment && <div className="file-drop-comment" dangerouslySetInnerHTML={{ __html: comment }} />}
              <div className="file-drop-container">
                <ul className="file-drop-info">
                  <li className="file-drop-info-item">
                    <span>Возможные форматы: </span>
                    <b>{acceptFileExtensions}</b>
                  </li>
                  <li className="file-drop-info-item">
                    <span>Максимальный размер файла: </span>
                    <b dangerouslySetInnerHTML={{ __html: formatFileSize(maxFileSize) }} />
                  </li>
                  {!multiple && firstFileError ? (
                    <li className="file-drop-info-item file-drop-info-error">
                      <b>Ошибка: </b>
                      <span>{firstFileError}</span>
                    </li>
                  ) : null}
                </ul>
                <div className="file-drop-button">
                  <button className="ui-button ui-button-white ui-button-wide" type="button">
                    <AttachIcon className="file-input-button-ico" />
                    <span className="file-input-button-text">{`Выбрать файл${multiple ? 'ы' : ''}`}</span>
                  </button>
                </div>
                <input type="file" className="hidden-file-input" name="doc-hidden" {...getInputProps()} />
              </div>
            </div>
          )}
        </Dropzone>

        <CSSTransition
          classNames="files-list"
          timeout={450}
          in={(multiple || !firstFileError) && !!files.length}
          mountOnEnter
          unmountOnExit
        >
          <TransitionGroup component="ul" className="files-list">
            {files.map(file => (
              <CSSTransition
                key={file.name}
                timeout={450}
                classNames="file-list-item"
              >
                <FilesListItem
                  key={file.name}
                  file={file}
                  onFileRemove={this.onFileRemove}
                  onTryAgainClick={this.onTryAgainClick}
                  onFileStatusChanged={this.onFileStatusChanged}
                />
              </CSSTransition>
            ))}
          </TransitionGroup>
        </CSSTransition>
      </div>
    );
  }
}

export default FileInput;
