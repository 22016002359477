import React from 'react';
import { Field } from 'react-final-form';
import classnames from 'classnames';
import { CSSTransition } from 'react-transition-group';
import Radio from '../../../../components/ui/Radio';
import fetch from '../../../../utils/fetch';
import { objectDotNotationGetter } from '../../../../utils/objectDotNotationGetter';

const serialize = function (obj) {
  const str = [];
  for (const p in obj) {
    if (obj.hasOwnProperty(p)) {
      str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
    }
  }
  return str.join('&');
};

class RadioFinalField extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      dataSource: !props.item.rest ? props.item.items : [],
    };

    const { item } = this.props;

    if (item.rest) {
      const matching = item.rest.match(/(.*)(\{.*\})|.*/);
      const rest = (matching[1] || matching[0]).trim();
      const params = matching[2] ? matching[2].replace(/[{}\s]/g, '').split(',') : [];

      this.rest = rest;
      this.trimmedParams = params;
    }
  }

  // componentWillUnmount() {
  //   if (this.props.changeValue && !this.props.visible) {
  //     // this.props.changeValue(this.props.item.field_name, undefined);
  //   }
  // }

  componentDidMount() {
    if (this.props.item.rest) {
      this.props.updateRestPromise(this.getItems('', this.props.values, this.props.item));
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.item.rest) {
      const { values, item } = this.props;
      if (this.trimmedParams.some(key => {
        const val = objectDotNotationGetter(values, key);
        return objectDotNotationGetter(prevProps.values, key) !== val && typeof val !== 'undefined';
      })) {
        this.props.updateRestPromise(this.getItems('', values, item));
      }
    }
  }

  getItems = (query, values, item) => {
    const p = { query };
    this.trimmedParams.forEach((key) => {
      const value = objectDotNotationGetter(values, key);
      const splittedKey = key.split('.');
      const lastKey = splittedKey[splittedKey.length - 1];
      p[lastKey] = typeof value === 'object' ? value.value : value;
    });

    return fetch(`${this.rest}?${serialize(p)}`).then((result) => {
      this.setState({ dataSource: result.result });

      if (
        values[item.field_name]
        && values[item.field_name].value
        && !result.result.find(it => it.value === values[item.field_name].value)
      ) {
        try {
          // this.props.resetFieldState(item.field_name);
          this.props.changeValue(item.field_name, undefined);
        } catch (e) {
          console.log(item.field_name, e);
        }
        if (result.result.length > 1) {
          this.props.changeValue(item.field_name, undefined);
        }
      }
      if (result.result.length === 1) {
        this.props.changeValue(item.field_name, result.result[0]);
      }
    }).catch((error) => {
      if (this.props.item.field_name === 'currency') {
        this.setState({
          dataSource: [
            { name: 'Рубли', value: 'RUB' },
            { name: 'Доллары', value: 'USD' },
            { name: 'Евро', value: 'EUR' },
          ],
        });
      } else {
        console.error(error);
      }
    });
  };

  render() {
    const { item, stl, values, validation, disabled = false } = this.props;
    const { dataSource } = this.state;

    // if (!dataSource.length) return null;

    return (
      <div className="form-item" style={stl} key={`${item.field_name}wrap`}>
        <label className="input-label">{item.caption}</label>
        <div className="radio-group">
          {dataSource.map(currency => (
            <Field
              key={`${currency.value}_field`}
              name={item.field_name}
              // component="input"
              validate={value => validation(value, item)}
              type="text"
              placeholder={item.placeholder}
            >
              {({ input, meta }) => (
                <Radio
                  {...input}
                  key={currency.value}
                  name={item.field_name}
                  tabLike={item.tabLike}
                  className={classnames({ active: currency.value === (values[item.field_name] && values[item.field_name].value) })}
                  labelClassName={classnames('ui-button ui-button-white ui-radio-button', { active: currency.value === (values[item.field_name] && values[item.field_name].value) })}
                  value={currency}
                  valid={meta.visited || meta.submitFailed ? meta.valid : true}
                  // label={item.field_name === 'currency' ? currency.value : currency.name}
                  label={currency.name}
                  checkedValue={values[item.field_name] && values[item.field_name].value}
                  disabled={disabled}
                />
              )}
            </Field>
          ))}
        </div>
        <Field
          name={item.field_name}
          key={`${item.field_name}+error`}
          validate={value => validation(value, item)}
          subscribe={{ touched: true, error: true }}
          render={({ meta: { error, active, submitFailed, visited } }) => (
            <CSSTransition
              timeout={300}
              in={((visited || submitFailed) && error && !active) || item.fieldHint}
              classNames="ui-input-msg"
              unmountOnExit
            >
              <div className="ui-input-msg">{((visited || submitFailed) && error && !active) || !item.fieldHint ? error : item.fieldHint}</div>
            </CSSTransition>
          )}
        />
      </div>
    );
  }
}

export default RadioFinalField;
