const bpaContact = [
  'alfa_bank',
  'nsb_bank',
  'credite_bank',
  'pochta_bank',
  'mts_bank_contact',
  'plus_bank',
  'renes_bank',
  'russtan_bank',
  'ural_bank',
  'homecredit_bank',
  'otkr_bank',
  'bank_vozrozhdenie_oao',
  'gazprombank_oao',
  'oranzhevyj_bank_ooo',
  'ros_bank',
  'sovkombank_pao_byvsh_dzhii_mani_bank',
  'setelem_bank_bankovskie_uslugi_pogashenie_kredita',
  'soyuz',
  'skb',
  'bystrobank',
  'toyota_bank',
  'webbankir',
  'mk_mobail',
  'rrs_pay_easy_contact',
  'rrs_mobile_contact',
  'ntv_plyus',
  'trikolor_tv',
  'dom_ru_internet',
  'dom_ru_tv',
  'dom_ru_complex',
  'moscow_zku',
  'mosenergosbit',
  'zku_moscow_pikkomfort',
  'gibdd',
  'mos_parking',
  'ufk',
  'oriflame',
  'avon',
  'faberlic',
  'mary_kay',
  'world_of_tanks',
  'contact_raif',
  'beeline_topup',
  'megafon_topup',
  'mts_topup',
  'yota_internet',
  'yota_topup',
  'beeline_arm',
  'beeline_krg',
  'beeline_uzb',
  'vivacell_mts_arm',
  'matrix_mobile',
  'motiv_account_topup',
  'vtb_bank_ru',
  'money_man',
  'strelka',
  'lyca_mobile_pay',
  'ms_bank',
  'tinkoff_mobile_topup',
  'otp_bank',
  'sber_mobile',
  'tinkoff_contact',
  'contact_patent',
  'wellcom_topup',
  'skylink_topup',
  'tele2_topup_contact',
  'contact_mt',
  'vostok_bank',
];

export default function isBpaContactPlat(id) {
  return bpaContact.includes(id);
}
