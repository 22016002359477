import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import newid from '../../utils/newid';

export default class Radio extends React.PureComponent {
  static propTypes = {
    value: PropTypes.any.isRequired,
    name: PropTypes.string.isRequired,
    tabLike: PropTypes.bool,
    className: PropTypes.string,
    labelClassName: PropTypes.string,
    label: PropTypes.string.isRequired,
    checkedValue: PropTypes.any,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
  };

  static defaultProps = {
    checkedValue: undefined,
    tabLike: false,
    className: '',
    labelClassName: '',
    disabled: false,
    required: false,
    onFocus: () => {},
    onBlur: () => {},
  };

  constructor(props) {
    super(props);

    this.state = {
      focused: false,
    };

    this.id = newid();
    this.onChange = this.onChange.bind(this);
  }

  componentWillUnmount() {
    if (this.props.changeValue) {
    }
  }

  onChange(e) {
    this.props.onChange(this.props.value);
  }

  onFocus = () => {
    this.setState({ focused: true });
    this.props.onFocus();
  };

  onBlur = () => {
    this.setState({ focused: false });
    this.props.onBlur();
  };

  render() {
    const {
      value, name, label, checkedValue, disabled, className, tabLike, labelClassName,
    } = this.props;
    const { focused } = this.state;
    return (
      <div className={classnames('ui-radio-button-wrap', className, { 'ui-tab-like-radio': tabLike, focused })}>
        <input
          type="radio"
          className="radio-input"
          id={this.id}
          value={value}
          required={this.props.required}
          name={name}
          checked={value === checkedValue}
          disabled={disabled}
          onChange={this.onChange}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
        />
        <label
          htmlFor={this.id}
          className={
          classnames(labelClassName, { disabled })}
        >
          {label}
        </label>
      </div>
    );
  }
}

