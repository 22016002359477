import React, { memo } from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import classnames from 'classnames';
import moment from 'moment';
import { Pagination, Tooltip } from 'antd';
import Button from '../../../components/ui/Button';
import ProductLogo from '../../../components/ui/ProductLogo';
import { arrayToObject } from '../../../utils/arrayToObject';
import { downloadFiles } from '../../../utils/downloadFiles';
import { formatMoneyWithCurrency } from '../../../utils/format';
import { defaultProviderImg, getProviderConfig } from '../../../utils/getProviderConfig';
import { getTxExtra, merchantCheck, payment } from '../../../utils/processingCalls';
import StatusIcon from '../../../components/common/StatusIcon';
import Modal from '../../../components/ui/Modal';
import statusLabels from '../../../constants/statusLabels';

import makeCancelable from '../../../utils/makeCancelable';
import fetch from '../../../utils/fetch';

import Loupe from '../../../assets/svg/magnifier.svg';
import ArrowDown from '../../../assets/img/icons/arrow-down.svg';
import DoubleArrowRight from '../../../assets/img/icons/double-arrow-right.svg';
import logger from '../../../utils/logger';
import CmdFormatter from '../../../utils/CmdFormatter';
import { ISO_VALUE } from '../../../constants/currencies';
import FileInput from '../../../components/ui/FileInput';
import { transaction } from '../../../reducers';

const colNameById = {
  providerConfig: 'Наименование',
  sum: 'Сумма',
  commission: 'Комиссия',
  toCashbox: 'В кассу',
  startTime: 'Дата',
  status: 'Статус',
};

const callFiscal = (frModel = '', frPort = '10000', url1c, commands) => fetch(`?model=${frModel}&port=${frPort}`, {
  backendUrl: `${url1c}`,
  prefix: '/execute',
  method: 'POST',
  notJson: true,
  body: [...commands],
  noDefaultNotification: true,
});

const defaultHeaderRender = row => (
  <div className={classnames('rt-header-item', { 'rt-header-sortable': row.column.sortable !== false })}>
    <span>{colNameById[row.column.id]}</span>
  </div>
);

const Provider = memo(props => (
  <React.Fragment>
    <div className="tx-label-logo">
      <ProductLogo productConfig={props} />
    </div>
    <div className="tx-label-text">
      <div className="tx-provider-name">{props.name}</div>
      <div className="tx-provider-category">{props.categoryName}</div>
    </div>
  </React.Fragment>
));

const moneyCellRender = row => row.value;

const startTimeCellRender = row => row.value;

class TransactionsConfirmation extends React.PureComponent {
  static propTypes = {
    transaction: PropTypes.object.isRequired,
    cats: PropTypes.object.isRequired,
    getCats: PropTypes.func.isRequired,
    pageLoading: PropTypes.bool.isRequired,
    onPageDidMount: PropTypes.func.isRequired,
    onPageWillUnmount: PropTypes.func.isRequired,
    loadTransaction: PropTypes.func.isRequired,
    removeTransaction: PropTypes.func.isRequired,
    updatePageLoading: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    const { transaction, transaction: { gateTx } } = props;

    this.state = {
      currentPage: 1,
      transactions: gateTx ? [{ ...transaction }] : [],
      transactionsToupdate: [],
      providerIdToConfigMap: {},
      catsLoading: true,
      errorCode: null,
      errorDescription: null,
      showModal: false,
      youLoose: false,
      stolotoPrintError: false,
      stolotoReceipt: [],
      acceptingTx: '',
      filterStatus: 'all',
      allQueryTxsExistsInTxsArr: true,
      additionalModalOpened: false,
      act: null,
    };

    this.fetchTxsWrap = null;
    this.columns = [
      {
        Header: defaultHeaderRender,
        accessor: 'providerConfig',
        headerClassName: 'name-col',
        className: 'name-col',
        minWidth: 320,
        maxWidth: 440,
        sortMethod: (a, b) => (a.name > b.name ? 1 : -1),
        Cell: cell => <Provider {...cell.value} />,
      },
      {
        Header: defaultHeaderRender,
        accessor: 'sum',
        headerClassName: 'sum-col',
        className: 'sum-col',
        minWidth: 110,
        maxWidth: 165,
        Cell: moneyCellRender,
      },
      {
        Header: defaultHeaderRender,
        accessor: 'commission',
        headerClassName: 'commission-col',
        className: 'commission-col',
        minWidth: 106,
        maxWidth: 165,
        Cell: moneyCellRender,
      },
      {
        Header: defaultHeaderRender,
        accessor: 'toCashbox',
        headerClassName: 'to-cashbox-col',
        className: 'to-cashbox-col',
        minWidth: 110,
        maxWidth: 165,
        Cell: moneyCellRender,
      },
      {
        Header: defaultHeaderRender,
        accessor: 'startTime',
        headerClassName: 'start-time-col',
        className: 'start-time-col',
        minWidth: 144,
        maxWidth: 205,
        Cell: startTimeCellRender,
      },
      {
        Header: defaultHeaderRender,
        accessor: 'status',
        sortMethod: (a, b) => (statusLabels[a] > statusLabels[b] ? 1 : -1),
        headerClassName: 'status-col',
        className: 'status-col',
        width: 90,
        resizable: false,
        Cell: this.statusCellRender,
      },
    ];
  }

  componentDidMount() {
    this.props.onPageDidMount('transactions-confirmation');
    const { transaction, getCats } = this.props;

    if (transaction.gateTx) {
      this.fetchTxsWrap = makeCancelable(Promise.all([this.fetchCat(transaction.paymentId), getCats('root')]));
      this.fetchTxsWrap.promise
        .then((res) => {
          const cat = res[0];
          this.setState({ providerIdToConfigMap: arrayToObject([cat], ['id', 'cat', 'name', 'logo', 'multiLogo'], true), catsLoading: false }, () => {
            if (this.props.cats.root) {
              this.props.updatePageLoading({ pageLoading: false });
              this.startTransactionsAccepting();
            } else {
              console.warn('cats.root missing');
            }
          });
        });
    } else {
      const startTime = moment().subtract(1, 'd').format('x');
      const finishTime = moment().add(1, 'd').format('x');
      const complexSalesGuid = window.sessionStorage.getItem('complexSalesGuid');

      this.fetchTxsWrap = makeCancelable(Promise.all([this.fetchTxs(startTime, finishTime, complexSalesGuid), getCats('root')]));
      this.fetchTxsWrap.promise
        .then((res) => {
          const txs = res[0];
          const providerIdToConfigMap = arrayToObject(txs.arrayOfCats, ['id', 'cat', 'name', 'logo', 'multiLogo'], true);
          this.setState({
            currentPage: 1, transactions: txs.transactions, providerIdToConfigMap, catsLoading: false, transactionsToUpdate: txs.transactionsToUpdate,
          }, () => {
            if (this.props.cats.root) {
              this.props.updatePageLoading({ pageLoading: false });
            } else {
              console.warn('cats.root missing');
            }
          });

          const queryTxsString = window.sessionStorage.getItem('tekotxs');
          const queryTxsArrUnfiltered = queryTxsString ? queryTxsString.split(',') : [];
          const queryTxsArr = queryTxsArrUnfiltered.filter((item,index) => queryTxsArrUnfiltered.indexOf(item) === index);
          const allQueryTxsExistsInTxsArr = queryTxsArr.every(id => txs.transactions.find(tx => tx.gateTx === id) || txs.transactionsToUpdate.find(tx => tx.gateTx === id));
          const transactions = txs.transactions.filter(tx => queryTxsArr.indexOf(tx.gateTx) > -1); // фильтруем те, которые были в URL из 1C
          const transactionsToUpdate = txs.transactionsToUpdate.filter(tx => queryTxsArr.indexOf(tx.gateTx) > -1); // фильтруем те, которые были в URL из 1C

          if (queryTxsArr.length === 0) {
            console.warn('Нет транзакций в URL из 1C');
          } else if (!allQueryTxsExistsInTxsArr) {
            console.warn('В ответе сервера не найдены некоторые транзакции для подтверждения, пришедшие в URL из 1C');
          }

          logger({
            message: 'В ответе сервера не найдены некоторые транзакции для подтверждения, пришедшие в URL из 1C',
            data: {
              queryTxsArr,
            },
            level: 'info',
            tag: 'txs_missmatch',
          });
          if (!allQueryTxsExistsInTxsArr) {
            const arrayOfGetTxPromises = [];
            queryTxsArr.forEach((txId) => {
              arrayOfGetTxPromises.push(this.fetchTxById(txId));
            })

            Promise.all(arrayOfGetTxPromises)
              .then((txesById) => {
                logger({
                  message: 'После запроса всех пропущенных из запроса по времени',
                  data: {
                    txesById,
                  },
                  level: 'info',
                  tag: 'txs_search',
                });
                console.log('txesById ', txesById)
                const allTxesAreAllowed = txesById.every(tx => tx.transaction.dst_extras.alowedForDelegatedConfirmation === 'true' || complexSalesGuid === tx.transaction.dst_extras.complexSalesGuid);

                if (allTxesAreAllowed) {
                  // const formatedTxes = allTxesAreAllowed.map(txObj => txObj.tx);
                  const newArrayOfCats = txesById.map(item => item.arrayOfCats[0]);
                  const providerIdToConfigMapNew = arrayToObject(newArrayOfCats, ['id', 'cat', 'name', 'logo', 'multiLogo'], true);
                  this.setState({ transactions: txesById.map(tx => tx.transaction), transactionsToUpdate, allQueryTxsExistsInTxsArr: true, providerIdToConfigMap: providerIdToConfigMapNew }, () => {
                    this.startTransactionsUpdating();
                    if (txesById.map(tx => tx.transaction).length > 0 ) {
                      this.startTransactionsAccepting();
                    }
                  });
                } else {
                  this.setState({ transactions, transactionsToUpdate, allQueryTxsExistsInTxsArr }, () => {
                    this.startTransactionsUpdating();
                    if (transactions.length > 0) {
                      this.startTransactionsAccepting()
                    }
                  });
                }
              });
          } else {
            this.setState({ transactions, transactionsToUpdate, allQueryTxsExistsInTxsArr }, () => {
              this.startTransactionsUpdating();

              if(transactions.length > 0) {
                this.startTransactionsAccepting();
              }
            });
          }

        });
    }
  }

  componentWillUnmount() {
    if (this.fetchTxsWrap) {
      this.fetchTxsWrap.cancel();
    }
    this.props.removeTransaction();
    this.props.onPageWillUnmount();
    this.props.updatePageLoading({ pageLoading: true });
  }

  onPageChange = currentPage => this.setState({ currentPage });

  onModalOkClick = (e) => {
    e.stopPropagation();
    const { transaction: { gateTx }, history } = this.props;
    const isResume = !!gateTx;
    if (isResume) {
      history.push('/catalog');
    } else {
      window.close();
    }
  };

  retryStolotoPrint = (e) => {
    e.stopPropagation();
    this.setState({ showModal: false }, () => {
      this.stolotoPrint();
    });
  };

  stolotoPrint = () => {
    if (this.state.stolotoReceipt.length !== 0) {
      const { stolotoReceipt } = this.state;
      const driverUrl = localStorage.getItem('DRIVER_ADDRESS');

      const urlString = driverUrl ? `${driverUrl}/fr` : `${process.env.DRIVER_ADDRESS}/fr`;

      callFiscal('shtrih-m', 'COM3', urlString, stolotoReceipt).then((result) => {
        this.setState({ showModal: true, youLoose: false, stolotoPrintError: false });
      })
        .catch(() => {
          this.setState({ showModal: true, youLoose: true, stolotoPrintError: true });
        });
    }
  };

  fetchTxById = txId => new Promise((resolve) => {
    logger({
      message: 'До запроса траназкции по id',
      data: {
        txId,
      },
      level: 'info',
      tag: 'txs_search',
    });
    fetch(`/get_tx_by_id/${txId}`)
      .then((response) => {
        // const paymentIds = response.result.map(item => item.paymentId).filter((item, i, arr) => arr.indexOf(item) === i);
        const arrayOfPromises = [this.fetchCat(response.result.paymentId)];
        logger({
          message: 'до запроса категорий',
          data: {
            response,
          },
          level: 'info',
          tag: 'txs_search',
        });
        Promise.all(arrayOfPromises)
          .then((arrayOfCats) => {
            logger({
              message: 'после запроса категорий',
              data: {
                response,
                arrayOfCats
              },
              level: 'info',
              tag: 'txs_search',
            });
            resolve({ arrayOfCats, transaction: response.result });
          });
      })
      .catch((err) => {
        logger({
          message: 'Ошибка поиска транзакции по id',
          data: {
            err,
          },
          level: 'error',
          tag: 'txs_search',
        });
        console.error(err);
        resolve(null);
      });
  })

  // fetchTxs = (start, finish, complexSalesGuid) => fetch(`/complex/sales/txs?finish_t=${finish}&start_t=${start}&complexSalesGuid=${complexSalesGuid}`)
  fetchTxs = (start, finish, complexSalesGuid) => fetch(`/complex/sales/parted/txs?finish_t=${finish}&start_t=${start}&complexSalesGuid=${complexSalesGuid}`)
    .then((response) => {


      logger({
        message: 'Список транзакций до фильтра по гуиду',
        data: {
          txs_all: response,
          filter: {
            finish,
            start,
            complexSalesGuid,
          },
        },
        level: 'info',
        tag: 'txs_unfiltered',
      });
      const paymentIds = response.result.toResume.map(item => item.paymentId).filter((item, i, arr) => arr.indexOf(item) === i);
      const paymentIdsUpdated = response.result.toUpdate.map(item => item.paymentId).filter((item, i, arr) => arr.indexOf(item) === i);
      const arrayOfPromises = [...paymentIds.map(item => this.fetchCat(item)), ...paymentIdsUpdated.map(item => this.fetchCat(item))]

      return new Promise((resolve) => {
        Promise.all(arrayOfPromises)
          .then((arrayOfCats) => {
            resolve({ arrayOfCats, transactions: response.result.toResume, transactionsToUpdate: response.result.toUpdate });
          });
      });
    });

  fetchCat = cat => new Promise((resolve) => {
    fetch(`/categories/${cat}`, { noDefaultNotification: true })
      .then((result) => {
        resolve(result);
      })
      .catch((e) => {
        resolve();
      });
  });

  startTransactionsUpdating = () => {

    // alert('!!!')

    const { transactionsToUpdate } = this.state;
    const arrayToUpdate = [];


    console.log('transactionsToUpdate ', transactionsToUpdate)

    transactionsToUpdate.forEach(tx => {
      arrayToUpdate.push(new Promise(resolve => {
        fetch('/merchant/update', {
          method: 'POST',
          body: {
            // ...tx,
            // dst_extras: {
            //   ...tx.dst_extras,
            //   checknumber: window.sessionStorage.getItem('checknumber') || ''
            // }
            txId: tx.partnerTx.id,
            checknumber: window.sessionStorage.getItem('checknumber') || '',
          },
        })
          .then(resolve)
          .catch(resolve)
      }))
    })

    Promise.all(arrayToUpdate)
      .then(() => {
        if (arrayToUpdate.length > 0 && this.state.transactions.length === 0) {
          this.setState({ showModal: true, youLoose: false });
        }
      })
  }

  startTransactionsAccepting = () => {
    const { transaction } = this.props;
    const { transactions, allQueryTxsExistsInTxsArr } = this.state;
    const txsArr = transactions.slice(0);
    const checkStatusCounter = {};
    const acceptTryCounter = {};
    const checknumber = window.sessionStorage.getItem('checknumber') || '';
    const tag_discount = window.sessionStorage.getItem('tag_discount') || '';

    // if doprovedenie mode on (one tx from tx history page is dropped into redux store)
    // we use transaction from props to detect if it's ok and we can doprovesti:
    const allReadyToStartAccepting = (!!transaction.gateTx || transactions.length > 0) && allQueryTxsExistsInTxsArr;

    if (allReadyToStartAccepting) {
      const recursiveTxAccept = () => {
        const txToAccept = txsArr[0];

        this.setState({ acceptingTx: txToAccept.gateTx });

        const recursiveCheckStatus = () => {
          fetch(`/get_transaction_status_by_id/${txToAccept.gateTx}`)
            .then((response) => {
              const { status, code, description } = response.result;
              const newTransactions = this.state.transactions.slice(0);
              const txToAcceptIndex = newTransactions.findIndex(tx => tx.gateTx === txToAccept.gateTx);
              newTransactions[txToAcceptIndex] = { ...newTransactions[txToAcceptIndex], status };

              switch (status) {
                case 'success':
                case 'ok':
                  txsArr.shift();
                  this.setState({ acceptingTx: '', transactions: newTransactions }, () => {
                    if (txsArr.length) {
                      recursiveTxAccept();
                    } else {
                      const downloadPromisesArr = transactions.map((tx, i) => new Promise((resolve) => {
                        const { paymentId: id, gateTx: txId, dst_extras: formData } = tx;

                        setTimeout(() => {
                          if (id === 'complex_plus_discount') {
                            resolve();
                          } else {
                            downloadFiles({ id, txId, formData, step: 'cheque' })
                              .then(resolve, resolve);
                          }
                        }, i * 1000);
                      }));

                      const isAdditionalTxNeeded = transactions.findIndex(tx => tx.paymentId === 'smart_protect_confirm') !== -1; //todo заменить на смарт
                      Promise.all(downloadPromisesArr)
                        .then(() => {
                          // stolotoReceipt
                          if (this.state.stolotoReceipt.length !== 0) {
                            const { stolotoReceipt } = this.state;
                            const driverUrl = localStorage.getItem('DRIVER_ADDRESS');

                            const urlString = driverUrl ? `${driverUrl}/fr` : `${process.env.DRIVER_ADDRESS}/fr`;

                            callFiscal('shtrih-m', 'COM3', urlString, stolotoReceipt).then((result) => {
                              this.setState({ showModal: true });
                            })
                              .catch(() => {
                                this.setState({ showModal: true, youLoose: true, stolotoPrintError: true });
                              });
                          } else if (isAdditionalTxNeeded) { //todo место под костыль с новым тх
                            this.setState({ additionalModalOpened: true });
                          } else {
                            this.setState({ showModal: true, youLoose: false });
                          }
                        });
                    }
                  });
                  break;
                case 'failed':

                  if (txToAccept.paymentId === 'vsk_accident') {
                    this.setState({ showModal: true, youLoose: true, acceptingTx: '', transactions: newTransactions, errorCode: 500, errorDescription: 'Произошла ошибка при проведении платежа. Обратитесь в чат технической поддержки ТЕКО для допроведения платежа.' });
                    return;
                  }
                  if (code && description) {
                    this.setState({ showModal: true, youLoose: true, acceptingTx: '', transactions: newTransactions, errorCode: code, errorDescription: description });
                  } else {
                    this.setState({ showModal: true, youLoose: true, acceptingTx: '', transactions: newTransactions });
                  }
                  break;
                case 'inProgress':
                default:
                  if (!checkStatusCounter[txToAccept.gateTx]) {
                    checkStatusCounter[txToAccept.gateTx] = 0;
                  }
                  if (checkStatusCounter[txToAccept.gateTx] < 4) {
                    checkStatusCounter[txToAccept.gateTx]++;
                    setTimeout(recursiveCheckStatus, 5000);
                  } else {
                    this.setState({ showModal: true, youLoose: true, acceptingTx: '', transactions: newTransactions });
                  }
                  break;
              }
            })
            .catch(() => {
              this.setState({ showModal: true, youLoose: true, acceptingTx: '' });
            });
        };

        let currency = 643;

        if (['unistream_mt'].indexOf(txToAccept.paymentId) !== -1) {
          currency = (txToAccept.dst_extras && txToAccept.dst_extras.currency && ISO_VALUE[txToAccept.dst_extras.currency.value]) || 643;
        }

        const additionalData = {};
        if (['vsk_accident'].indexOf(txToAccept.paymentId) !== -1 && this.props.user.info) {
          const POSID = this.props.user.info && this.props.user.info.user.outletExtra && this.props.user.info.user.outletExtra['1c_point_code'];
          additionalData.companyName = this.props.user.info.company.name;
          additionalData.POSID = POSID;
        }

        payment({
          currency,
          partnerTx: txToAccept.gateTx,
          product: txToAccept.paymentId,
          inner: txToAccept.inner_id,
          amount: txToAccept.amount.amount,
          amountWithCommission: txToAccept.full_amount.amount,
          formData: { ...getTxExtra(txToAccept, checknumber), checknumber, tag_discount, complexSalesGuid: window.sessionStorage.getItem('complexSalesGuid'), ...additionalData },
          initiator_id: txToAccept.dst_extras.alowedForDelegatedConfirmation === 'true' ? txToAccept.gateTx : undefined, // <- если отвалится, то виноват Леха
        }).then((txData) => {
          logger({
            message: 'Удачная оплата',
            data: {
              partnerTx: txToAccept.gateTx,
              product: txToAccept.paymentId,
              inner: txToAccept.inner_id,
              amount: txToAccept.amount.amount,
              amountWithCommission: txToAccept.full_amount.amount,
              formData: { ...getTxExtra(txToAccept, checknumber), checknumber, tag_discount },
            },
            level: 'info',
            tag: 'success_payment',
          });
          if (txData.result.merchant_tx.extra.RECEIPT) {
            const receipt = txData.result.merchant_tx.extra.RECEIPT;
            const { stolotoReceipt } = this.state;
            const cmd = new CmdFormatter(null);

            cmd.command('run', [2]);

            for (let i = 0; i < receipt.length; i++) {
              if (receipt[i].indexOf('barcode') !== -1) {
                //some day we will probably need this
                // const probablyBarcode = receipt[i].match(/\d+/);
                // if (probablyBarcode) {
                //   cmd.barcode(probablyBarcode[0]);
                // }
              } else {
                cmd.center(receipt[i]);
              }
            }

            cmd.command('run', [5]);
            cmd.command('cut', [0]);

            this.setState({
              stolotoReceipt: [...stolotoReceipt, ...cmd.cmd],
            });
          }
          recursiveCheckStatus();
        }).catch((err) => {
          console.warn(err);
          logger({
            message: 'Ошибка при оплате',
            data: {
              txData: {
                partnerTx: txToAccept.gateTx,
                product: txToAccept.paymentId,
                inner: txToAccept.inner_id,
                amount: txToAccept.amount.amount,
                amountWithCommission: txToAccept.full_amount.amount,
                formData: { ...getTxExtra(txToAccept, checknumber), checknumber, tag_discount },
              },
              Error: err,
            },
            level: 'warn',
            tag: 'error_payment',
          });
          // no internet
          if (err.noConnection) {
            if (!acceptTryCounter[txToAccept.gateTx]) {
              acceptTryCounter[txToAccept.gateTx] = 0;
            }
            if (acceptTryCounter[txToAccept.gateTx] < 4) {
              acceptTryCounter[txToAccept.gateTx]++;
              setTimeout(recursiveTxAccept, 5000);
            } else {
              this.setState({ showModal: true, youLoose: true, acceptingTx: '' });
            }
          } else {
            // just error recieved from payment endpoint
            recursiveCheckStatus();
          }
        });
      };

      recursiveTxAccept();
    } else {
      this.setState({ showModal: true, youLoose: true });
    }
  };

  uploadAct = () => {
    const submitDataKostil = {
      amount: 0,
      amountWithCommission: 0,
      currency: 643,
      formData: {
        txId: this.state.transactions.find(tx => tx.paymentId === 'smart_protect_confirm').tx.id,
        actScan: this.state.act,
      },
      inner: '${100}',
      product: 'smart_protect_act',
      srcCls: 'cash',
    };

    merchantCheck(submitDataKostil).then((response) => {
      const { id } = response.result.tx;

      payment({
        currency: 643,
        partnerTx: id,
        product: 'smart_protect_act',
        inner: '${100}',
        amount: 0,
        amountWithCommission: 0,
        formData: {
          txId: this.state.transactions.find(tx => tx.paymentId === 'smart_protect_confirm').tx.id,
          actScan: this.state.act,
        },
        initiator_id: id, // <- если отвалится, то виноват Леха
      })
      .then(() => {
        this.setState({ showModal: true, youLoose: false, additionalModalOpened: false });
      })

    })
  }

  // type: 'page' | 'prev' | 'next'
  paginationItemRender = (page, type, originalElement) => {
    if (type === 'prev' || type === 'next') {
      return (<a className={`ui-pagination-link ${type}-link`}><ArrowDown /></a>);
    } if (type === 'jump-next' || type === 'jump-prev') {
      return (<a className={`ui-pagination-link ${type}-link`}><DoubleArrowRight /></a>);
    }

    return originalElement;
  };

  normalizeTransactions = (transactions) => {
    const { providerIdToConfigMap, acceptingTx } = this.state;
    const { cats } = this.props;

    return transactions.map((transaction) => {
      const {
        provider, paymentId, amount, commission: rawCommission,
        full_amount, start_t, gateTx,
      } = transaction;
      const paymentCat = providerIdToConfigMap[paymentId] ? providerIdToConfigMap[paymentId].cat : '';
      const initialProviderConfig = getProviderConfig(provider, paymentCat, cats.root.byId);
      const providerConfig = {
        ...providerIdToConfigMap[paymentId],
        cat: paymentCat || 'others',
        name: providerIdToConfigMap[paymentId].name || 'Неизвестный продукт',
        categoryName: initialProviderConfig.name || 'Неизвестная категория',
        gateTx,
      };
      const sum = formatMoneyWithCurrency({ amount: amount.amount, exponent: amount.exp, symbol: true });
      const commission = formatMoneyWithCurrency({ amount: rawCommission, exponent: full_amount.exp, symbol: true });
      const toCashbox = formatMoneyWithCurrency({ amount: full_amount.amount, exponent: full_amount.exp, symbol: true });
      const startTime = moment(start_t).format('DD.MM.YYYY HH:mm');
      const status = gateTx === acceptingTx ? 'loading' : transaction.status;

      return {
        providerConfig, sum, commission, toCashbox, startTime, status, gateTx,
      };
    });
  };

  statusCellRender = row => (
    <div className="status-with-controls">
      <Tooltip title={statusLabels[row.value]} overlayClassName="ui-tooltip" align={{ offset: [0, -2] }}>
        <div className="status-icon-wrap">
          <StatusIcon status={row.value} />
        </div>
      </Tooltip>
    </div>
  );

  render() {
    const { transaction, pageLoading } = this.props;

    if (pageLoading || this.state.catsLoading) return null;

    const {
      currentPage, showModal, transactions, transactionsToUpdate, filterStatus, youLoose, stolotoPrintError, errorCode, errorDescription, additionalModalOpened
    } = this.state;
    const isResume = !!transaction.gateTx;
    const modalBtnLabel = isResume ? 'Вернуться в каталог' : 'ОК';
    const filteredTransactions = filterStatus === 'all' ? [ ...transactions, ...transactionsToUpdate] : transactions.filter(tx => tx.status === filterStatus);
    const normalizedTransactions = this.normalizeTransactions(filteredTransactions);
    //eslint-disable-next-line
    const errorText = (errorCode && errorDescription) ?
      `Произошла ошибка при проведении платежа. Описание ошибки: "${errorDescription}". Для получения подтверждения на возврат денежных средств и чека в 1С возврат чека в 1С необходимо обратиться в службу поддержки ТЕКО. Для связи со службой поддержки используйте Онлайн чат. При проведении возврата без подтверждения со стороны Teko сотрудник берет на себя ответственность за возможную финансовую потерю.` : 'Произошла ошибка при проведении платежа. Для получения подтверждения на возврат денежных средств и чека в 1С возврат чека в 1С необходимо обратиться в службу поддержки ТЕКО. Для связи со службой поддержки используйте Онлайн чат. При проведении возврата без подтверждения со стороны Teko сотрудник берет на себя ответственность за возможную финансовую потерю.'
    const modalMessage = youLoose ? stolotoPrintError ? 'Произошла ошибка при печати квитанции. Исправьте проблему фискального регистратора и повторите печать.' : errorText : `Идет скачивание документов, по завершению загрузки файлов, нажмите кнопку ${isResume ? 'Вернуться в каталог' : 'Ок'}.`
    const buttonLabel = youLoose ? 'Перейти в 1С' : modalBtnLabel;
    const onModalButtonClick = stolotoPrintError ? this.retryStolotoPrint : this.onModalOkClick;

    return (
      <div className="transactions-confirmation-page">
        <div className="container">
          {/*<div className="breadcrumbs-block">*/}
          {/*<span className="breadcrumb-link" onClick={() => history.goBack()}>*/}
          {/*<ArrowDown className="breadcrumb-link-icon" />*/}
          {/*<span className="breadcrumb-link-text">Назад</span>*/}
          {/*</span>*/}
          {/*</div>*/}
          <h1 className="page-title h1">Ожидают подтверждения</h1>
          <div className="transaction-table-wrapper">
            {normalizedTransactions.length === 0 ? (
              <div className="no-txs-block">
                <Loupe className="no-txs-icon" />
                <div className="no-txs-title">Операций пока нет</div>
                <div className="no-txs-desc">На заданный период операции отсутствуют</div>
              </div>
            ) : (
              <div className="table">
                <ReactTable
                  PaginationComponent={() => (
                    <Pagination
                      onChange={this.onPageChange}
                      current={currentPage}
                      defaultCurrent={1}
                      pageSize={10}
                      total={normalizedTransactions.length}
                      hideOnSinglePage
                      itemRender={this.paginationItemRender}
                    />
                  )}
                  page={currentPage - 1}
                  data={normalizedTransactions}
                  columns={this.columns}
                  defaultPageSize={10}
                  pageSize={10}
                  minRows={0}
                />
              </div>
            )}

            <Modal
              backdrop
              active={additionalModalOpened}
              transitionEnterTimeout={300}
              transitionLeaveTimeout={300}
              transitionName="ui-modal"
            >
              <div className="modal-header">Отсканируйте и загрузите скан договора</div>
              <div className="modal-body">
                <div className="file-inputs-group">
                  <div className="file-inputs-title">Загрузка скана акта</div>
                  <div className="file-inputs-list" style={{ paddingLeft: '10px' }}>
                    <FileInput
                      item={{
                        field_name: 'test',
                      }}
                      acceptMimeTypes="application/pdf, image/*"
                      acceptFileExtensions="jpeg, jpg, png, pdf"
                      values={{}}
                      value={this.state.act}
                      onChange={file => this.setState({ act: file })}
                    />
                  </div>
                </div>
                <div className="modal-footer">
                  <Button label="Загрузить" disabled={!this.state.act} onClick={this.uploadAct} color={Button.Color.blue} />
                </div>
              </div>
            </Modal>

            <Modal
              backdrop
              active={showModal}
              transitionEnterTimeout={300}
              transitionLeaveTimeout={300}
              transitionName="ui-modal"
            >
              <div className="modal-header">{youLoose ? 'Ошибка подтверждения' : 'Подтверждение прошло успешно'}</div>
              <div className="modal-body">
                {modalMessage}
              </div>
              <div className="modal-footer">
                <Button label={buttonLabel} onClick={onModalButtonClick} color={Button.Color.blue} />
              </div>
            </Modal>

          </div>
        </div>
      </div>
    );
  }
}

export default TransactionsConfirmation;
