import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import ErrorIcon from '../../assets/svg/notifications/error-alert.svg';
import ImportantIcon from '../../assets/svg/notifications/important-alert.svg';
import InfoIcon from '../../assets/svg/notifications/info-alert.svg';
import WarningIcon from '../../assets/svg/notifications/warning-alert.svg';
import CloseIcon from '../../assets/svg/close-white.svg';
import { isMsgLvlWithModal, ReadAlertsAndModalsAndReports } from '../../utils/notifications';

const lvlToIconAndClass = {
  ERROR: { icon: ErrorIcon, classname: 'error' },
  IMPORTANT: { icon: ImportantIcon, classname: 'important' },
  INFO: { icon: InfoIcon, classname: 'info' },
  WARNING: { icon: WarningIcon, classname: 'warning' },
};

export default class AlertsMachine extends React.PureComponent {
  static propTypes = {
    alerts: PropTypes.array.isRequired,
    readMessage: PropTypes.func.isRequired,
    // enterTimeout: PropTypes.number,
    // leaveTimeout: PropTypes.number,
  };

  // static defaultProps = {
  //   enterTimeout: 300,
  //   leaveTimeout: 300,
  // };

  // constructor(props) {
  //   super(props);
  //
  //   this.enterTimer = 0;
  //   this.leaveTimer = 0;
  // }

  onAlertCloseClick = ({ id, lvl, messageRead }) => {
    const data = {};
    // const sessionReadAlerts = ReadAlertsAndModalsAndReports.get('readAlerts');

    if (!isMsgLvlWithModal(lvl)) {
      data.alertRead = true;
      data.messageRead = true;
    } else if (messageRead) {
      data.alertRead = true;
    }

    ReadAlertsAndModalsAndReports.set('readAlerts', id);
    this.props.readMessage({ id, data });

    // this.setState((prevState) => {
    //   const newAlerts = prevState.alerts.slice(0);
    //   newAlerts.push(newAlerts.shift());
    //
    //   return { alerts: newAlerts };
    // });
  };

  // componentWillEnter(callback) {
  //   clearTimeout(this.leaveTimer);
  //
  //   this.alertMachine.style.height = '0px';
  //   this.alertMachine.getBoundingClientRect();
  //   this.alertMachine.style.height = `${this.alertMachineContent.clientHeight}px`;
  //
  //   this.enterTimer = setTimeout(() => {
  //     callback();
  //   }, this.props.enterTimeout);
  // }
  //
  // componentDidEnter() {
  //   this.alertMachine.style.height = '';
  // }
  //
  // componentWillLeave(callback) {
  //   clearTimeout(this.enterTimer);
  //
  //   this.alertMachine.style.height = `${this.alertMachineContent.clientHeight}px`;
  //   this.alertMachine.getBoundingClientRect();
  //   this.alertMachine.style.height = '0px';
  //
  //   this.leaveTimer = setTimeout(() => {
  //     callback();
  //   }, this.props.leaveTimeout);
  // }
  //
  // componentDidLeave() {
  //   this.alertMachine.style.height = '';
  // }

  render() {
    const { alerts } = this.props;

    // messages already sorted by createdTime in reducer
    // const lastAlert = messages.find(message => message.lvl === 'alert' && !message.alertRead);

    return (
      <div className="ui-alert-machine">
        <div className="ui-alert-machine-content">
          <TransitionGroup component={null}>
            {alerts.slice(0, 1).map((alert) => {
              const lvlConf = lvlToIconAndClass[alert.lvl];
              const lvlClass = lvlConf.classname;
              const Icon = lvlConf.icon;

              return (
                <CSSTransition
                  key={alert.id}
                  timeout={450}
                  classNames="ui-alert-machine-item"
                >
                  <div className={classnames('ui-alert-machine-item', `ui-alert-machine-item-${lvlClass}`)}>
                    <div className="container">
                      <Icon className="ui-alert-machine-icon" />
                      <div className="ui-alert-machine-text" dangerouslySetInnerHTML={{ __html: alert.msg }} />
                      <div className="ui-alert-machine-close" onClick={() => this.onAlertCloseClick(alert)}><CloseIcon /></div>
                    </div>
                  </div>
                </CSSTransition>
              );
            })}
          </TransitionGroup>
        </div>
      </div>
    );
  }
}
