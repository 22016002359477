import React from 'react';
import { Route } from 'react-router-dom';

function AppRoute({ component: Component, pageProps, ...rest }) {
  return (
    <Route {...rest} render={props => (<Component {...pageProps} {...props} />)} />
  );
}

export default AppRoute;
