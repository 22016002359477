import React from 'react';

import TekoLogo from '../assets/svg/teko-logo.svg';
import EmailIcon from '../assets/svg/footer-email.svg';

const Footer = ({ companyPointCode, companyName, userName }) => (
  <footer className="footer">
    <div className="container">
      <div className="footer-blocks">
        <div className="copyright-block">
          <div className="works-on-teko">
            {/*<span className="works-text">Hand made by</span>*/}
            <a href="https://teko.io" target="_blank" rel="noopener noreferrer" className="teko-link"><TekoLogo className="teko-logo-svg" /></a>
          </div>
          <div className="copyright-text">© 2014-{new Date().getFullYear()} ТЕКО.</div>
        </div>
        <div className="contacts-block">
          {/*<a href="tel:88005557025" className="phone-link">8 (800) 555-70-25</a>*/}
          <div className="support-label">техподдержка</div>
          <a href={`mailto:support@teko.io?subject=Проблема%20на%20кассе:%20${companyPointCode}%20|%20${companyName}&body=Номер%20Точки:%20${companyPointCode};%0D%0AКомпания:%20${companyName};%0D%0AФИО:%20${userName};%0D%0A%0D%0A`} className="email-link">
            <EmailIcon className="email-link-icon" />
            <span className="email-link-text">support@teko.io</span>
          </a>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
