import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import InputMask from 'react-input-mask';
import newid from '../../utils/newid';
import { FormContext } from './ClassicForm';

export default class ClassicMaskInput extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.string,
    tabIndex: PropTypes.string,
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    valid: PropTypes.bool,
    disabled: PropTypes.bool,
    message: PropTypes.string,
    mask: PropTypes.string.isRequired,
    maskChar: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
  };

  static defaultProps = {
    className: '',
    type: 'text',
    valid: undefined,
    tabIndex: '0',
    disabled: false,
    message: 'Field is required',
    maskChar: null,
    placeholder: '',
    value: '',
    onBlur: () => {},
    onFocus: () => {},
    onChange: () => {},
  };

  static contextType = FormContext;

  constructor(props) {
    super(props);

    this.id = newid();
    this.input = null;

    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onFocus = this.onFocus.bind(this);
  }

  componentDidMount() {
    if (this.context) {
      this.context.onInputAddedToForm(this.input);
    }
  }

  onChange(e) {
    this.props.onChange(e.target.value);
  }

  onBlur(e) {
    this.props.onBlur(e);
  }

  onFocus(e) {
    this.props.onFocus(e);
  }

  render() {
    const className = classnames('ui-input', 'ui-classic-input', this.props.className, {
      disabled: this.props.disabled,
      filled: this.props.value && this.props.value.length,
      valid: this.props.valid === true,
      invalid: this.props.valid === false,
    });

    return (
      <div className={className}>
        <label htmlFor={this.id}>{this.props.label}</label>
        <InputMask
          id={this.id}
          inputRef={ref => this.input = ref}
          type={this.props.type}
          value={this.props.value}
          tabIndex={this.props.tabIndex}
          placeholder={this.props.placeholder}
          disabled={this.props.disabled}
          onChange={this.onChange}
          onBlur={this.onBlur}
          onFocus={this.onFocus}
          mask={this.props.mask}
          maskChar={this.props.maskChar}
        />
        <div className="ui-input-msg">{this.props.message}&nbsp;</div>
      </div>
    );
  }
}

