import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import classnames from 'classnames';

// import SearchIcon from '../assets/img/icons/search.svg';
// import BasketIcon from '../assets/img/icons/basket.svg';
import ArrowDownIcon from '../assets/img/icons/arrow-down.svg';
import AnimatedAlertsMachine from './ui/AnimatedAlertsMachine';
import Messages from './ui/HeaderMessages';

const Header = (props) => {
  const { alertShown, alerts, readMessage, report } = props;
  const isConfirmationPage = props.location.pathname === '/transactions/confirmation';
  const onClick = (e) => {
    if (isConfirmationPage || props.openModalBeforeLeave) {
      e.preventDefault();
    }

    if (props.openModalBeforeLeave) {
      props.openConfirmLeaveModal(e.target.getAttribute('href'));
    }
  };

  return (
    <div className="header-and-alerts-wrap">
      <AnimatedAlertsMachine in={alertShown} alerts={alerts} readMessage={readMessage} />
      <header className="header">
        <div className="container">
          <div className="company-info-with-nav">
            <div className="company-info">
              {props.companyPointCode && <div className="company-point-code">{props.companyPointCode}</div>}
              <div className="company-desc">
                <div className="company-name">{props.companyName}</div>
                <div className="company-address" dangerouslySetInnerHTML={{ __html: props.companyAddress }} />
              </div>
            </div>
            <nav className="header-nav">
              <ul className="header-nav-list">
                <li className="header-nav-list-item">
                  <NavLink to="/" exact className="header-nav-link" activeClassName="active">Платежи и переводы</NavLink>
                </li>
                {/*<li className="header-nav-list-item">*/}
                {/*  <NavLink to="/" className={classnames('header-nav-link', { disabled: isConfirmationPage })} activeClassName="active" onClick={onClick}>Обзор</NavLink>*/}
                {/*</li>*/}
                {/*<li className="header-nav-list-item">*/}
                {/*  <NavLink to="/catalog/insurance" className={classnames('header-nav-link', { disabled: isConfirmationPage })} activeClassName="active" onClick={onClick}>Страхование</NavLink>*/}
                {/*</li>*/}
                <li className="header-nav-list-item">
                  <NavLink to="/transactions/history" className={classnames('header-nav-link', { disabled: isConfirmationPage })} activeClassName="active" onClick={onClick}>История транзакций</NavLink>
                </li>
              </ul>
            </nav>
          </div>
          <div className="user-menu">
            {/*<div className="user-menu-icon search-icon">*/}
            {/*<SearchIcon className="search-icon-svg" />*/}
            {/*</div>*/}

            {/*<div className="user-menu-item">*/}
            {/*<div className="user-menu-icon">*/}
            {/*/!*onClick={() => props.history.push('/loto/cart')}*!/*/}
            {/*<div className="icon-with-badge">*/}
            {/*<BasketIcon className="basket-icon-svg" />*/}
            {/*{!!props.basketItemsCount && <span className="count-badge">{props.basketItemsCount}</span>}*/}
            {/*</div>*/}
            {/*</div>*/}
            {/*</div>*/}
            <Messages messages={props.messages} readMessage={props.readMessage} report={report} />
            <div className="ui-hover-dropdown user-name">
              <div className="dropdown-title">
                <span className="dropdown-title-text">{props.userName}</span>
                <ArrowDownIcon className="arrow-down-icon-svg" />
              </div>
              <div className="dropdown-body">
                <div className={classnames('dropdown-item logout-btn', { disabled: isConfirmationPage })} onClick={() => { if (!isConfirmationPage) props.setUnAuthed(); }}>Выйти</div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

Header.propTypes = {
  alertShown: PropTypes.bool,
  alerts: PropTypes.array,
  companyPointCode: PropTypes.string,
  userName: PropTypes.string,
  setUnAuthed: PropTypes.func,
  companyName: PropTypes.string,
  companyAddress: PropTypes.string,
  report: PropTypes.object,
  messages: PropTypes.array,
  readMessage: PropTypes.func.isRequired,
  openModalBeforeLeave: PropTypes.bool.isRequired,
  openConfirmLeaveModal: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

Header.defaultProps = {
  alertShown: false,
  alerts: [
    { msg: 'Работа сервиса приостановлена, о дополнительной информации обращайтесь в тех. поддержку', id: 'alert-1', lvl: 'ERROR', messageRead: false, alertRead: false },
    { msg: 'Для вашей точки добавлена услуга пополнения карты через телефон', id: 'alert-2', lvl: 'IMPORTANT', messageRead: false, alertRead: false  },
    { msg: 'По техническим причинам приостановлены платежи и переводы для банка Тинькоф', id: 'alert-3', lvl: 'INFO', messageRead: false, alertRead: false  },
    { msg: 'Для вашей точки добавлена услуга пополнения карты через телефон', id: 'alert-4', lvl: 'WARNING', messageRead: false, alertRead: false  },
  ],
  companyPointCode: '',
  setUnAuthed: () => {},
  companyName: 'ООО "АЛЛО"',
  companyAddress: 'г. Санкт-Петербург<br />ул. Комсомольская 23а',
  userName: 'Фамилия Имя',
  report: undefined,
  messages: [],
};

export default Header;
