import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import FinalFormPropsWrapper from './FinalFormPropsWrapper';
import Condition from './Condition';
import CardnumberInput from '../../../../components/ui/CardnumberInput';

function isValidCard(number) {
  let digit;
  let sum = 0;

  const splittedNumber = number.split('').reverse();

  for (let i = 0; i < splittedNumber.length; i++) {
    digit = splittedNumber[i];
    digit = +digit;
    if (i % 2) {
      digit *= 2;
      if (digit < 10) {
        sum += digit;
      } else {
        sum += digit - 9;
      }
    } else {
      sum += digit;
    }
  }
  return sum % 10 === 0;
}

export default class CardnumberFinalFieldWrap extends React.PureComponent {
  static propTypes = {
    item: PropTypes.object.isRequired,
    visible: PropTypes.bool,
    disabled: PropTypes.bool,
    styleFromParent: PropTypes.object,
    validation: PropTypes.func,
  };

  static defaultProps = {
    visible: false,
    disabled: false,
    styleFromParent: {},
    validation: () => {},
  };

  customValidation = (value) => {
    if (!value) return 'Поле обязательно для заполнения';
    if (!isValidCard(value)) return 'Некорректный номер карты';
    return this.props.validation(value, this.props.item);
  };

  render() {
    const { item, styleFromParent, disabled = false, visible } = this.props;

    return (
      <Condition visible={visible}>
        <Field
          type="text"
          name={item.field_name}
          placeholder={item.placeholder}
          validate={this.customValidation}
          parse={value => value.replace(/\D/g, '')}
        >
          {FinalFormPropsWrapper(CardnumberInput, { item, styleFromParent, disabled })}
        </Field>
      </Condition>
    );
  }
}
