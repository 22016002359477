import fetch from './fetch';
import logger from './logger';
import rutokenWrappedFunctions from './rutoken/rutokenWrappedFunctions';

export const getTxExtra = tx => JSON.parse(tx.extras).dst.extra;

export const merchantCheck = (data, { withOverlay = true, overlayMessageShowTime = 6000, noDefaultNotification = false, log = false } = {}) => {
  function check(body) {
    if (log) {
      logger({ message: 'Проверка доступности платежа', data: body, level: 'info', tag: 'isPaymentPossible' });
    }

    return fetch('/merchant/check', {
      method: 'POST',
      body,
      withOverlay,
      overlayMessageShowTime,
      noDefaultNotification,
    }).then((result) => {
      if (log) {
        logger({message: 'Успешный /merchant/check', data: { submitData: body, result: result.result }, level: 'info', tag: 'isPaymentPossible_complete' });
      }
      return result;
    });
  }

  // if (process.env.USE_RUTOKEN) {
    return new Promise((resolve, reject) => {
      rutokenWrappedFunctions().then((funcs) => {
        resolve(funcs);
      }, (error) => {
        reject(error);
      });
    })
      .then((funcs) => funcs.getCertificateFingerPrint())
      .catch((err) => {
        console.warn('Rutoken plugin error:', err);
      })
      .then((fingerPrint) => {
        const _data = {
          ...data,
          formData: {
            ...data.formData,
            ...(fingerPrint ? { rutokenCertificateFingerprint: fingerPrint } : null),
          },
        };

        return check(_data);
      });
  // }

  return check(data);
};

export const payment = ({
  partnerTx, product, inner, srcCls = 'cash', amount, amountWithCommission, formData, currency = 643, initiator_id,
}, {
  withOverlay = false,
  overlayMessageShowTim = 3000,
} = {}) => {
  const txBody = {
    amount,
    amountWithCommission,
    inner,
    srcCls,
    currency,
    partnerTx,
    formData,
    product,
    initiator_id,
  };

  //todo fix this later
  // if (process.env.NODE_ENV === 'production') {
  if (!(!process.env.USE_RUTOKEN || ['vsk_dms', 'tradein_courier', 'display_protection', 'vsk_accident', 'alfa_luggage', 'ali-express', 'vtb_test', 'vtb_test_warranty', 'alfa_allin', 'vtb_hello_neighbor', 'alfas', 'alfa_tur', 'vsk_extended_guaranty', 'eset_1_year', 'sdalkupil'].indexOf(product) !== -1)) {
    return new Promise((resolve, reject) => {
      rutokenWrappedFunctions().then((funcs) => {
        resolve(funcs);
      }, (error) => {
        reject(error);
      });
    })
      .then((funcs) => {
        return new Promise((res, rej) => {
          funcs.signStringByRutoken(JSON.stringify(txBody)).then((data) => {
            console.log('data,', data);
            return res(data);
          }, (error) => {
            console.log('error', error);
            return rej(error);
            // throw new Error(error);
          })
        })
      })
      .then((data) => {
        return data;
      })
      .then(certSignedData => ({ ...txBody, formData: { ...txBody.formData, ...certSignedData } }))
      .then(dataToSend => fetch('/merchant/payment', {
        method: 'POST',
        body: dataToSend,
        withOverlay,
        overlayMessageShowTim,
      }));
  }
  // }
  //eslint-disable-next-line
  return fetch('/merchant/payment', {
    method: 'POST',
    body: txBody,
    withOverlay,
    overlayMessageShowTim,
  });
};

window.payment = payment;
export const functionFromTheFuture = () => {};
