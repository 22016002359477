import moment from 'moment';


const convertValue = (type, value) => {
  switch (type) {
    case 'amount':
      return (value / 100).toString();
    case 'date':
      // return new Date(new Date(value).getTime() - 60000 * new Date().getTimezoneOffset()).format('dd-mm-yyyy');
      return moment.utc(+value).format('DD.MM.YYYY');
    // return new Date(value).format('dd-mm-yyyy', 'UTC');
    case 'enum':
    case 'radio':
    case 'typeahead':
      return (value && typeof value === 'object' ? value.name : value).toString();
    default:
      return value.toString();
  }
};

const transformToConfirmForm = (form = [], formdata = {}) => {
  const confirmData = {};

  form
    .flatMap(g => g.fields)
    .forEach((item) => {
      if (formdata[item.field_name] && item.hideFromPrecheque !== true) {
        confirmData[item.field_name] = [
          item.caption || item.field_name,
          convertValue(item.type, formdata[item.field_name]),
        ];
      }
    });
  return confirmData;
};

export default transformToConfirmForm;
