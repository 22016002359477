import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Transition } from 'react-transition-group';

export default class Alert extends React.PureComponent {
  static Color = {
    blue: 'blue',
    green: 'green',
    orange: 'orange',
    red: 'red',
    gray: 'gray',
  };

  static propTypes = {
    children: PropTypes.node.isRequired,
    color: PropTypes.string,
    className: PropTypes.string,
    // enterTimeout: PropTypes.number,
    // leaveTimeout: PropTypes.number,
  };

  static defaultProps = {
    color: 'blue',
    className: '',
    // enterTimeout: 300,
    // leaveTimeout: 300,
  };

  // constructor(props) {
  //   super(props);
  //
  //   this.enterTimer = 0;
  //   this.leaveTimer = 0;
  // }

  // componentWillEnter(callback) {
  //   clearTimeout(this.leaveTimer);
  //
  //   this.alert.style.marginBottom = '0px';
  //   this.alert.style.height = '0px';
  //   this.alert.getBoundingClientRect();
  //   this.alert.style.height = `${this.alertContent.clientHeight}px`;
  //   this.alert.style.marginBottom = '20px';
  //
  //   this.enterTimer = setTimeout(() => {
  //     callback();
  //   }, this.props.enterTimeout);
  // }
  //
  // componentDidEnter() {
  //   this.alert.style.height = '';
  //   this.alert.style.marginBottom = '';
  // }
  //
  // componentWillLeave(callback) {
  //   clearTimeout(this.enterTimer);
  //
  //   this.alert.style.marginBottom = '20px';
  //   this.alert.style.height = `${this.alertContent.clientHeight}px`;
  //   this.alert.getBoundingClientRect();
  //   this.alert.style.height = '0px';
  //   this.alert.style.marginBottom = '0px';
  //
  //   this.leaveTimer = setTimeout(() => {
  //     callback();
  //   }, this.props.leaveTimeout);
  // }
  //
  // componentDidLeave() {
  //   this.alert.style.height = '';
  //   this.alert.style.marginBottom = '';
  // }

  render() {
    const { children, color, className, style = {} } = this.props;

    return (
      <div className={classnames(`ui-alert ui-alert-${color}`, className)} style={style}>
        <div className="ui-alert-content">
          {children}
        </div>
      </div>
    );
  }
}
