import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import classnames from 'classnames';
import { CSSTransition } from 'react-transition-group';
import ProgressRing from './ProgressRing';

class FetchingOverlay extends React.PureComponent {
  static propTypes = {
    message: PropTypes.string,
    active: PropTypes.bool.isRequired,
    messageShowTime: PropTypes.number,
    messageDefaultShown: PropTypes.bool,
    transitionName: PropTypes.string,
    transitionEnterTimeout: PropTypes.number,
    transitionLeaveTimeout: PropTypes.number,
  };

  static defaultProps = {
    message: 'Ваша операция заняла больше времени, <br/>чем ожидалось. Осталось совсем немного!',
    messageShowTime: 3000,
    messageDefaultShown: false,
    transitionName: 'ui-fetching-overlay',
    transitionEnterTimeout: 300,
    transitionLeaveTimeout: 300,
  };

  static getDerivedStateFromProps(props, state) {
    if (!props.active) {
      return { ...state, longTimePassed: false };
    }
    return state;
  }

  constructor(props) {
    super(props);

    this.state = {
      longTimePassed: false,
    };

    this.lastFocused = null;
    this.el = window.document.createElement('div');
  }

  componentDidMount() {
    window.document.body.appendChild(this.el);
  }

  componentDidUpdate(prevProps) {
    if (this.props.active !== prevProps.active) {
      const { body } = window.document;

      if (!prevProps.active) {
        body.classList.add('contains-overlay');
        this.lastFocused = window.document.activeElement;
        if (this.lastFocused.type !== 'date') {
          this.lastFocused.blur();
        }
        window.document.addEventListener('keydown', this.onKeyDown);
      } else {
        if (this.lastFocused && this.lastFocused.type !== 'date') {
          this.lastFocused.focus();
        }
        body.classList.remove('contains-overlay');
        window.document.removeEventListener('keydown', this.onKeyDown);
      }
    }
  }

  componentWillUnmount() {
    window.document.body.removeChild(this.el);
    window.document.body.classList.remove('contains-overlay');
    window.document.removeEventListener('keydown', this.onKeyDown);
  }

  onKeyDown = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  onAnimationFinished = () => this.setState({ longTimePassed: true });

  render() {
    const {
      active,
      message,
      messageShowTime,
      messageDefaultShown,
      transitionName,
      transitionEnterTimeout,
      transitionLeaveTimeout,
    } = this.props;

    const { longTimePassed } = this.state;

    const wrapper = (
      <CSSTransition
        classNames={transitionName}
        timeout={{ enter: transitionEnterTimeout, exit: transitionLeaveTimeout }}
        in={active}
        mountOnEnter
        unmountOnExit
      >
        <div className={classnames('ui-fetching-overlay', { longTimePassed: longTimePassed || messageDefaultShown })}>
          <div className="ui-fetching-overlay-content">
            <div className="overlay-tooltip" dangerouslySetInnerHTML={{ __html: message }} />
            <div className="ring-wrap">
              <ProgressRing onAnimationFinished={this.onAnimationFinished} animationDuration={messageShowTime} />
            </div>
          </div>
        </div>
      </CSSTransition>
    );

    return ReactDOM.createPortal(
      wrapper,
      this.el,
    );
  }
}

export default FetchingOverlay;
