import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import InputMask from 'react-input-mask';
import newid from '../../utils/newid';

import ResetIcon from '../../assets/img/icons/close-input-black.svg';
import { FormContext } from './Form';

export default class MaskInput extends React.PureComponent {
  static propTypes = {
    type: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    tabIndex: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    valid: PropTypes.bool,
    fullFilled: PropTypes.bool,
    disabled: PropTypes.bool,
    withResetIcon: PropTypes.bool,
    message: PropTypes.string,
    mask: PropTypes.string,
    maskChar: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
  };

  static contextType = FormContext;

  static defaultProps = {
    type: 'text',
    label: '',
    valid: undefined,
    tabIndex: '0',
    fullFilled: false,
    disabled: false,
    withResetIcon: true,
    message: '',
    placeholder: '',
    value: '',
    mask: '',
    maskChar: null,
    onBlur: () => {},
    onFocus: () => {},
    onChange: () => {},
  };

  constructor(props) {
    super(props);

    this.id = newid();
    this.input = null;
  }

  componentDidMount() {
    if (this.context) {
      this.context.onInputAddedToForm(this.input);
    }
  }

  onChange = (e) => {
    this.props.onChange(e.target.value);
  };

  onBlur = (e) => {
    this.props.onBlur(e);
  };

  onFocus = (e) => {
    this.props.onFocus(e);
  };

  onReset = () => {
    this.props.onChange('');
    this.input.setSelectionRange(4, 5);
    this.input.focus();
  };

  render() {
    const {
      label, type, value, disabled, placeholder, message, valid, fullFilled, mask, maskChar, tabIndex, withResetIcon, onKeyDown,
    } = this.props;
    const className = classnames('ui-input', {
      disabled,
      filled: value && value.length,
      fullFilled,
      valid: valid === true,
      invalid: valid === false,
    });

    return (
      <div className={className}>
        {!!label && <label htmlFor={this.id}>{label}</label>}
        <div className="input-wrap">
          <InputMask
            id={this.id}
            inputRef={ref => this.input = ref}
            type={type}
            value={value}
            tabIndex={tabIndex}
            placeholder={placeholder}
            autoComplete="chrome-off"
            disabled={disabled}
            onChange={this.onChange}
            onBlur={this.onBlur}
            onFocus={this.onFocus}
            onKeyDown={onKeyDown}
            mask={mask}
            maskChar={maskChar}
          />
          {withResetIcon && (
            <div className="reset-icon" onClick={this.onReset}>
              <ResetIcon className="reset-icon-svg" />
            </div>
          )}
        </div>
        {/*<div className="ui-input-msg">{message}</div>*/}
      </div>
    );
  }
}
