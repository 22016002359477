import moment from 'moment';
import pluginPromise from './rutoken';
import rutokenModalWrap from './rutokenModalWrap';

export default function rutokenWrappedFunctions() {
  return pluginPromise.then((plugin) => {
    function error(string) {
      return function (code) {
        return new Promise((resolve, reject) => {
          code = typeof code === 'object' ? code.message : code;
          if (+code === 19 || +code === 17 || +code === 9) {
            return rutokenModalWrap().then(pin => login(pin).then(() => resolve(signStringByRutoken(string)), newCode => resolve(error(string)(newCode))));
            // return new Promise(resolve => rutokenModalWrap().then(pin => login(pin).then(() => resolve(signStringByRutoken(string)), code => resolve(error(string)(code)))));
            // return rutokenModalWrap().then(pin => login(pin).then(signStringByRutoken(string), error(string)));
          }
          reject(new Error(code));
        });
      };
    }

    function login(password) {
      return plugin.enumerateDevices().then(devices => plugin.login(devices[0], password));
    }


    function signStringByRutoken(string) {
      // return new Promise((resolve) => {
      // return new Promise((resolve) => {
      let cert;
      return plugin.enumerateDevices()
        .then(devices => plugin.enumerateCertificates(devices[0], 1)
          .then((certIds) => {
            const foundCert = certIds.find(element => element === localStorage.getItem('rutoken_cert'));
            cert = foundCert || certIds[0];
            return plugin.getCertificate(devices[0], cert);
          })
          .then(certificate => plugin.sign(devices[0], cert, string, false, {
            addSignTime: true,
            useHardwareHash: true,
            detached: true,
            addUserCertificate: false,
          })
            .then(signature => Promise.resolve({
              certificate,
              signature,
              jsonString: string,
            }), error(string))));
      // });
      // })
    }

    function getCertificate() {
      return plugin.enumerateDevices().then(devices => plugin.enumerateCertificates(devices[0], 1).then((certIds) => {
        const foundCert = certIds.find(element => element === localStorage.getItem('rutoken_cert'));
        return plugin.getCertificate(devices[0], foundCert || certIds[0]).then(certificate => certificate);
      }));
    }

    function getCertificateFingerPrint() {
      return plugin.enumerateDevices().then(devices => plugin.enumerateCertificates(devices[0], 1).then((certIds) => {
        const foundCert = certIds.find(element => element === localStorage.getItem('rutoken_cert'));
        return foundCert || certIds[0];
      }));
    }

    function getAllCertificateFingerPrintsFromToken() {
      return plugin.enumerateDevices().then(devices => plugin.enumerateCertificates(devices[0], 1)).then(crtIds => crtIds);
    }

    function removePin() {
      return plugin.removePin(0);
    }

    function getArrayOfCertData() {
      return getAllCertificateFingerPrintsFromToken().then((certIds) => {
        const regex = /Signature Algorithm: ([^\n]*)/;
        const arrayOfPromises = [];
        certIds.forEach((certId) => {
          arrayOfPromises.push(plugin.parseCertificate(0, certId).then(data => ({
            value: certId,
            label: `${data.text.match(regex)['1']} | Валидность до ${moment(data.validNotAfter).format('DD.MM.YYYY')}`,
          })));
        });
        return Promise.all(arrayOfPromises).then(wtf => wtf);
      });
    }

    return Promise.resolve({
      getArrayOfCertData,
      removePin,
      getAllCertificateFingerPrintsFromToken,
      signStringByRutoken,
      getCertificateFingerPrint,
      getCertificate,
      login,
      plugin,
    });
  });
}
