import classnames from 'classnames';
import React from 'react';

// eslint-disable-next-line
function LeftFillSteps ({ step, formSteps, formError, values, parentState, ignoreLastEasySalesSteps = false }) {
  const complexSalesGuid = window.sessionStorage.getItem('complexSalesGuid') || '0';

  return (
    <div className="progress-indicator">
      {formSteps.map((formStep, i) => {
        const active = i === 0 ? true : step >= i;
        let currentFields;

        if (parentState !== null) {
          currentFields = Object.keys(parentState.touched);
        } else {
          currentFields = formSteps[i].fields ? formSteps[i].fields.map(field => field.field_name) : [];
        }
        const stepKeys = currentFields;
        const stepTrueKeys = stepKeys.filter(key => values[key]);
        const stepProgress = ((stepTrueKeys.length + 1) / (stepKeys.length + 1)) * 100;

        let actualStepProgress;
        if (step === i) {
          actualStepProgress = formError ? 100 : stepProgress;
        } else if (step > i) {
          actualStepProgress = 100;
        } else {
          actualStepProgress = 0;
        }
        // alert(JSON.stringify({ stepKeys, stepTrueKeys, stepProgress }))
        return (
          <div className={classnames('progress-point', { active, error: formError && (complexSalesGuid !== '0' ? step === i : false ) })} key={formStep.name}>
            <div className="progress-point-index-wrap"><span className="progress-point-index">{i + 1}</span></div>
            <div className="progress-point-name" dangerouslySetInnerHTML={{ __html: formStep.name }} lang="ru" />
            {((complexSalesGuid === '0' && !ignoreLastEasySalesSteps) || !((complexSalesGuid !== '0' || ignoreLastEasySalesSteps) && i === formSteps.length - 1)) && (
              <div className="progress-line-wrap">
                <div className="progress-line" style={{ height: `${(formError && (complexSalesGuid !== '0' ? step === i : false )) ? 0 : actualStepProgress}%` }} />
                {/*<div className="progress-line" style={{ height: `${actualStepProgress}%` }} />*/}
              </div>
            )}
          </div>
        );
      })}
      {/*<div className={classnames('progress-point', { active: true })}>*/}
      {/*<div className="progress-point-index-wrap">1</div>*/}
      {/*<div className="progress-point-name">Общая информация</div>*/}
      {/*<div className="progress-line-wrap">*/}
      {/*<div className="progress-line" style={{ height: step >= 1 ? `${progress}%` : '100%' }} />*/}
      {/*</div>*/}
      {/*</div>*/}
      {/*<div className={classnames('progress-point', { active: step >= 2 })}>*/}
      {/*<div className="progress-point-index-wrap">2</div>*/}
      {/*<div className="progress-point-name">Информация об&nbsp;отправителе</div>*/}
      {/*<div className="progress-line-wrap">*/}
      {/*<div className="progress-line" style={{ height: step >= 2 ? `${progress}%` : '0%' }} />*/}
      {/*</div>*/}
      {/*</div>*/}
      {complexSalesGuid === '0' && !ignoreLastEasySalesSteps && (
        <React.Fragment>
          <div className={classnames('progress-point', { active: step >= formSteps.length, error: formError && step === formSteps.length - 1 })}>
            <div className="progress-point-index-wrap"><span className="progress-point-index">{formSteps.length + 1}</span></div>
            <div className="progress-point-name">Предварительный чек</div>
            <div className="progress-line-wrap">
              <div className="progress-line" style={{ height: step >= formSteps.length + 1 ? '100%' : '0%' }} />
            </div>
          </div>
          <div className={classnames('progress-point', { active: step >= formSteps.length + 1, error: formError && step === formSteps.length })}>
            <div className="progress-point-index-wrap"><span className="progress-point-index">{formSteps.length + 2}</span></div>
            <div className="progress-point-name">Подтверждение платежа</div>
            {/*<div className="progress-line-wrap">*/}
            {/*<div className="progress-line" style={{ height: `${progress}%` }} />*/}
            {/*</div>*/}
          </div>
        </React.Fragment>
      )}
    </div>
  );
}

export default LeftFillSteps;

//
// return (
//   <div className="progress-indicator">
//     <div className={classnames('progress-point', { active: true })}>
//       <div className="progress-point-index-wrap">1</div>
//       <div className="progress-point-name">Общая информация</div>
//       <div className="progress-line-wrap">
//         <div className="progress-line" style={{ height: step >= 1 ? `${progress}%` : '100%' }} />
//       </div>
//     </div>
//     <div className={classnames('progress-point', { active: step >= 2 })}>
//       <div className="progress-point-index-wrap">2</div>
//       <div className="progress-point-name">Информация об&nbsp;отправителе</div>
//       <div className="progress-line-wrap">
//         <div className="progress-line" style={{ height: step >= 2 ? `${progress}%` : '0%' }} />
//       </div>
//     </div>
//     <div className={classnames('progress-point', { active: step >= 3 })}>
//       <div className="progress-point-index-wrap">3</div>
//       <div className="progress-point-name">Предварительный чек</div>
//       <div className="progress-line-wrap">
//         <div className="progress-line" style={{ height: step >= 3 ? `${progress}%` : '0%' }} />
//       </div>
//     </div>
//     <div className={classnames('progress-point', { active: step === 4 })}>
//       <div className="progress-point-index-wrap">4</div>
//       <div className="progress-point-name">Успешный платеж</div>
//       {/*<div className="progress-line-wrap">*/}
//       {/*<div className="progress-line" style={{ height: `${progress}%` }} />*/}
//       {/*</div>*/}
//     </div>
//   </div>
// );
