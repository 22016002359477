import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import formatString from 'format-string-by-pattern';
import makeCancelable from '../../../../utils/makeCancelable';
import { getMobileOperator } from '../../../../utils/processing';
import FinalFormPropsWrapper from './FinalFormPropsWrapper';
import Condition from './Condition';
import MaskInput from '../../../../components/ui/MaskInput';

// const supportedOperators = ['tele2', 'mts', 'beeline', 'yota', 'megafon'];
// const getOperatorFormId = operator => `${supportedOperators.find(operatorId => operator.indexOf(operatorId) !== -1)}_ru` || '';

const simpleMemoize = (fn) => {
  let lastArg;
  let lastResult;
  let isThisWasCalled = false;
  return (arg) => {
    if (arg !== lastArg || !isThisWasCalled) {
      isThisWasCalled = true;
      lastArg = arg;
      lastResult = fn(arg);
    }
    return lastResult;
  };
};

class PhoneFinalFieldWrap extends React.PureComponent {
  static propTypes = {
    item: PropTypes.object.isRequired,
    visible: PropTypes.bool,
    disabled: PropTypes.bool,
    formConfing: PropTypes.object,
    styleFromParent: PropTypes.object,
    validation: PropTypes.func,
    onFormConfigUpdate: PropTypes.func,
  };

  static defaultProps = {
    visible: false,
    disabled: false,
    formConfing: {},
    styleFromParent: {},
    validation: () => {},
    onFormConfigUpdate: () => {},
  };

  constructor(props) {
    super(props);

    this.state = {
      phone: '',
    };

    this.cancelableGetOperator = null;
  }

  componentDidUpdate(prevProps, prevState) {
    // const isPhoneWithAction = true;
    // if (!isPhoneWithAction) return;
    //
    // const { phone } = this.state;
    //
    // if (prevState.phone !== phone) {
    //   if (phone.length === 11) {
    //     // this.getOperator(phone);
    //   } else {
    //
    //   }
    // }
  }

  getOperator = (phoneNumber) => {
    if (this.cancelableGetOperator) {
      this.cancelableGetOperator.cancel();
    }

    this.cancelableGetOperator = makeCancelable(getMobileOperator({ phoneNumber }));

    const { item } = this.props;

    const isPhoneWithGetFormAction = item.actions.find(act => act.action === 'getOperatorToGetForm');

    return new Promise((resolve, reject) => {
      this.cancelableGetOperator
        .promise
        .then((result) => {
          const { result: { id: rawId, name, logo } } = result;
          const { formConfing, onFormConfigUpdate } = this.props;

          const isTele2 = typeof rawId === 'string' && rawId.indexOf('tele2|') === 0;
          const id = isTele2 ? 'tele2_ru' : rawId;

          if (isPhoneWithGetFormAction) {
            onFormConfigUpdate({ form: { ...formConfing, id, name, logo }, platId: id });
          }

          resolve({
            validationResult: (id === formConfing.id || isPhoneWithGetFormAction) ? undefined : 'Введенный телефон привязан к другому оператору связи',
          });
        })
        .catch((e) => {
          if (!e.isCanceled) {
            resolve({ validationResult: 'Введенный телефон не существует или не поддерживается' });
          } else {
            reject();
          }
        });
    });
  };

  validatePhone = simpleMemoize((value) => {
    const { validation, item, onFormConfigUpdate } = this.props;
    const defaultValidation = validation(value, item);
    if (defaultValidation) return defaultValidation;

    const isPhoneWithAction = item.actions && item.actions.find(act => ['getOperatorToValidate', 'getOperatorToGetForm'].includes(act.action));

    if (isPhoneWithAction) {
      if (value !== this.state.phone) {
        return this.getOperator(value)
          .then(result => result.validationResult);
      }
    }

    return undefined;
  });

  render() {
    const { item, visible, styleFromParent, disabled = false } = this.props;

    return (
      <Condition visible={visible}>
        <Field
          type="text"
          name={item.field_name}
          placeholder={item.placeholder}
          validate={this.validatePhone}
          format={(value) => {
            if (typeof value === 'string' && value !== '') {
              return formatString('+7 (999) 999-99-99')(value.replaceAt(0, '7'));
            }
            return formatString('+7 (999) 999-99-99')(value);
          }}
          parse={(value) => {
            if (value.replace(/\D/g, '').length <= 11) {
              this.setState({ phone: value.replace(/\D/g, '') });
            }
            return value.replace(/\D/g, '');
          }}
          // format={(value) => {
          //   const stringPhone = `${value}`;
          //   if (stringPhone.length === 11 && stringPhone[0] == 8) {
          //     return stringPhone.replaceAt(0, '7');
          //   }
          //   return value;
          // }}
          // parse={value => value}
        >
          {FinalFormPropsWrapper(MaskInput, {
            item,
            mask: '+7(999)-999-99-99',
            type: 'text',
            style: styleFromParent,
            disabled,
          })}
        </Field>
      </Condition>
    );
  }
}

export default PhoneFinalFieldWrap;
