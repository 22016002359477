import React from 'react';
import AuthContainer from './components/AuthContainer';

import './styles/auth.scss';

export default function AuthPage({ location, history, match, setAuthed, ...pageProps }) {
  return (
    <AuthContainer location={location} history={history} match={match} setAuthed={setAuthed} {...pageProps} />
  );
}
