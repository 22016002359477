import React from 'react';
import PropTypes from 'prop-types';
import notification from 'antd/lib/notification';
import classnames from 'classnames';
import Button from './ui/Button';
import Checkbox from './ui/Checkbox';
import DateInput from './ui/DateInput';
import Form from './ui/Form';
import Input from './ui/Input';
import Select from './ui/Select';
import ValidateInput from './ui/ValidateInput';

const selectItems = [
  { label: 'Да', value: 'Да' }, { label: 'Нет', value: 'Нет' },
];

export default class UiKitPage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      inputVal: '',
      inputValValid: false,
      checkboxValue: false,
      selectValue: '',
      dateInputValue: '',
    };
  }

  onSubmit = () => {
    notification.open({
      duration: 5,
      description: React.createElement('div', { dangerouslySetInnerHTML: { __html: 'Угараешь что ли, некуда сабмитить' } }),
    });
  };

  validateNotEmptyInput = value => (value === '' ? 'Поле обязательно для заполнения' : '');

  onInputChange = (value) => {
    this.setState({ inputVal: value, inputValValid: !this.validateNotEmptyInput(value) });
    console.log('Input value: ', value);
  };

  onCheckboxChange = (value) => {
    this.setState({ checkboxValue: value });
  };

  onSelectChange = (value) => {
    this.setState({ selectValue: value });
  };

  onDateInputChange = (value) => {
    this.setState({ dateInputValue: value });
  };

  render() {
    const { inputVal, inputValValid, checkboxValue, selectValue, dateInputValue } = this.state;

    return (
      <div className="ui-kit-page">
        <div className="container">
          <Form onSubmit={this.onSubmit} focusFirstInput>
            <div className="form-control">
              <Input
                label="Input"
                value={inputVal}
                valid={inputValValid}
                showValidity={!inputValValid}
                message={this.validateNotEmptyInput(inputVal)}
                onChange={this.onInputChange}
              />
            </div>
            <div className="form-control">
              <Checkbox label="Checkbox" value={checkboxValue} onChange={this.onCheckboxChange} />
            </div>
            <div className="form-control">
              <Select
                label="Select"
                placeholder="Choose your destiny"
                items={selectItems}
                value={selectValue}
                onChange={this.onSelectChange}
              />
            </div>
            <div className="form-control">
              <DateInput
                label="DateInput"
                valid
                showValidity={false}
                value={dateInputValue}
                onChange={this.onDateInputChange}
              />
            </div>
            <div className="form-control">
              <Button label="Отправить" type="submit" />
            </div>
          </Form>
        </div>
      </div>
    );
  }
}
