import React from 'react';
import PropTypes from 'prop-types';
import ClassicMaskInput from './ClassicMaskInput';

import { FormContext } from './ClassicForm';

export default class ClassicValidateInput extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    type: PropTypes.string,
    disabled: PropTypes.bool,
    value: PropTypes.string,
    tabIndex: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    validator: PropTypes.func,
    mask: PropTypes.string,
    maskChar: PropTypes.string,
    blockBlur: PropTypes.bool,
    resetValidationOnFocus: PropTypes.bool,
  };

  static defaultProps = {
    className: '',
    disabled: false,
    type: undefined,
    mask: '',
    maskChar: '',
    value: '',
    tabIndex: '0',
    blockBlur: false,
    placeholder: '',
    resetValidationOnFocus: true,
    validator: () => '',
    onBlur: () => {},
    onFocus: () => {},
    onChange: () => {},
  };

  static contextType = FormContext;

  constructor(props) {
    super(props);

    this.state = {
      value: '',
      message: '',
      valid: true,
      touched: false,
    };

    this.input = null;

    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onFocus = this.onFocus.bind(this);
  }

  componentDidMount() {
    if (this.context) {
      this.context.onValidateInputAddedToForm(this);
    }
    const startVal = this.props.value !== null ? this.props.value : '';
    this.onChange(startVal);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      if (this.props.value === '-') {
        const message = this.props.validator(this.state.value) || '';
        this.setState({
          message,
          valid: !message,
        });
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { value } = nextProps;

    if (nextProps.value !== null && value !== this.state.value) {
      this.onChange(value);
    }
  }

  onChange(value) {
    if (this.props.resetValidationOnFocus && this.context && this.context.formSubmitted) {
      this.setState({ value });
      this.props.onChange(value);
    } else {
      const message = this.props.validator(value) || '';
      this.setState({
        value,
        message,
        valid: !message,
      });
      this.props.onChange(message ? null : value, value);
    }
  }

  onBlur(e) {
    if (this.props.resetValidationOnFocus && this.context && this.context.formSubmitted) {
      const message = this.props.validator(this.state.value) || '';
      this.setState({
        message,
        valid: !message,
      });
    }
    this.props.onBlur(e);
    if (!this.props.blockBlur) {
      this.setState({
        touched: true,
      });
    }
  }

  onFocus(e) {
    if (this.props.resetValidationOnFocus && this.context && this.context.formSubmitted) {
      this.setState({
        message: '',
        valid: true,
      });
    }
    this.props.onFocus(e);
  }

  setErrorMessage(message) {
    this.setState({
      message,
      valid: false,
      touched: true,
    });
  }

  render() {
    let valid = null;
    let message = '';

    if ((!this.context && this.state.touched) || (this.context && this.context.formSubmitted)) {
      // eslint-disable-next-line
      valid = this.state.valid;
    }

    if (valid === false) {
      // eslint-disable-next-line
      message = this.state.message;
    }

    // const InputComponent = this.props.mask ? MaskInput : Input;

    return (
      <ClassicMaskInput
        ref={ref => this.input = ref}
        className={this.props.className}
        type={this.props.type}
        disabled={this.props.disabled}
        label={this.props.label}
        placeholder={this.props.placeholder}
        value={this.state.value}
        tabIndex={this.props.tabIndex}
        message={message}
        valid={valid}
        onChange={this.onChange}
        onBlur={this.onBlur}
        onFocus={this.onFocus}
        mask={this.props.mask}
        maskChar={this.props.maskChar}
      />
    );
  }
}

// export default function ClassicValidateInputWithContext(props) {
//   return (
//     <FormContext.Consumer>
//       {context => <ClassicValidateInput {...props} {...context} />}
//     </FormContext.Consumer>
//   );
// };
