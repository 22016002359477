export function isMsgLvlWithModal(lvl) {
  const lvlToModal = {
    ERROR: true,
    IMPORTANT: true,
    WARNING: false,
    INFO: false,
  };

  return lvlToModal[lvl];
}

export const ReadAlertsAndModalsAndReports = {
  get: (key) => {
    try {
      const readItems = window.sessionStorage.getItem(key);

      if (!readItems) {
        return {};
      }

      return JSON.parse(readItems);
    } catch (e) {
      console.warn(e);
      return {};
    }
  },
  set: (key, id) => {
    try {
      const readItems = ReadAlertsAndModalsAndReports.get(key);

      readItems[id] = true;
      window.sessionStorage.setItem(key, JSON.stringify(readItems));
    } catch (e) {
      console.warn(e);
    }
  },
  clear: () => {
    try {
      ['readAlerts', 'readModals', 'readReports'].forEach(key => window.sessionStorage.removeItem(key));
    } catch (e) {
      console.warn(e);
    }
  },
};
