import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classnames from 'classnames';
import { CSSTransition } from 'react-transition-group';

const CODE_LENGTH = new Array(6).fill(0);

const CodeVerificationInput = (props) => {
  const {
    className, state, name, disabled, value, onChange, onBlur, submitForm,
  } = props;
  const values = useMemo(() => value.split(''), [value]);
  const selectedIndex = values.length < CODE_LENGTH.length ? values.length : CODE_LENGTH.length - 1;
  const hideInput = !(values.length < CODE_LENGTH.length);
  const [focused, setFocused] = useState(false);
  const inputRef = useRef();

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  useEffect(() => {
    if (value.length === CODE_LENGTH.length && !disabled) {
      setFocused(false);
      submitForm();
    }
  }, [value, disabled, submitForm]);

  const handleClick = useCallback(() => inputRef.current.focus(), [inputRef.current]);

  const handleChange = useCallback((e) => {
    const newValue = e.target.value;
    if (value.length >= CODE_LENGTH.length) return;
    onChange({ target: { value: (value + newValue).slice(0, CODE_LENGTH.length), name } });
  }, [value, onChange]);

  const handleKeyUp = useCallback((e) => {
    if (e.key === 'Backspace') {
      onChange({ target: { value: value.slice(0, value.length - 1), name } });
    }
  }, [value, onChange]);

  const handleFocus = useCallback(() => setFocused(true), []);

  const handleBlur = useCallback((e) => {
    setFocused(false);
    onBlur(e);
  }, []);

  const getInputLeft = useCallback(
    () => values.slice(0, selectedIndex).reduce((sum, v, i) => sum + (i === 1 || i === 4 ? 58 : 55), 0) + (selectedIndex > 2 ? 22 : 0),
    [values, selectedIndex],
  );

  const renderCellsPart = useCallback((part) => {
    const arrayPart = part === 1 ? CODE_LENGTH.slice(0, 3) : CODE_LENGTH.slice(3);

    return arrayPart.map((v, i) => {
      const index = ((part - 1) * arrayPart.length) + i;
      const selected = values.length === index;
      const filled = values.length === CODE_LENGTH.length && index === CODE_LENGTH.length - 1;

      return (
        <div className="code-verification-cell" key={`${index}-${v}`}>
          {values[index]}
          <CSSTransition
            classNames="code-verification-cell-shadows"
            timeout={150}
            in={(selected || filled) && focused}
            mountOnEnter
            unmountOnExit
          >
            <div className="code-verification-cell-shadows" />
          </CSSTransition>
        </div>
      );
    });
  }, [values, focused]);

  const cls = classnames('ui-code-verification-input', `state-${state}`, className, {
    focused,
    focusedLeft: focused && value.length < 3,
    focusedRight: focused && value.length > 2,
  });

  return (
    <div className={cls} onClick={handleClick}>
      <input
        value=""
        name={name}
        ref={inputRef}
        disabled={disabled}
        onChange={handleChange}
        onKeyUp={handleKeyUp}
        onFocus={handleFocus}
        onBlur={handleBlur}
        className="input"
        style={{
          width: `${(selectedIndex === 1 || selectedIndex === 4) ? 58 : 55}px`,
          top: '0px',
          bottom: '0px',
          left: `${getInputLeft()}px`,
          opacity: hideInput ? 0 : 1,
        }}
      />
      <div className="left-cells">
        {renderCellsPart(1)}
      </div>
      <div className="code-verification-separator" />
      <div className="right-cells">
        {renderCellsPart(2)}
      </div>
    </div>
  );
};

CodeVerificationInput.displayName = 'CodeVerificationInput';

CodeVerificationInput.propTypes = {
  className: PropTypes.string,
  state: PropTypes.oneOf(['default', 'valid', 'invalid']),
  value: PropTypes.string,
  onChange: PropTypes.func,
};

CodeVerificationInput.defaultProps = {
  state: 'default',
  value: '',
};

export default CodeVerificationInput;
