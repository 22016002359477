import React from 'react';
import PropTypes from 'prop-types';
import { animate } from '../../utils/animations';
import makeCancelable from '../../utils/makeCancelable';

export default class ProgressRing extends React.PureComponent {
  static propTypes = {
    animationDuration: PropTypes.number,
    radius: PropTypes.number,
    stroke: PropTypes.number,
    onAnimationFinished: PropTypes.func,
  };

  static defaultProps = {
    animationDuration: 3000,
    radius: 30,
    stroke: 6,
    onAnimationFinished: () => {},
  };

  constructor(props) {
    super(props);

    this.state = {
      progress: 0,
    };

    const { radius, stroke } = this.props;

    this.normalizedRadius = radius - stroke * 2;
    this.circumference = this.normalizedRadius * 2 * Math.PI;
  }

  componentDidMount() {
    this._isMounted = true;
    const { animationDuration, onAnimationFinished } = this.props;

    this.cancelablePromise = makeCancelable(animate({
      duration: animationDuration,
      timing: x => x,
      draw: (progress) => {
        if (this._isMounted) {
          this.setState({ progress });
        }
      },
    }));

    this.cancelablePromise.promise
      .then(onAnimationFinished);
  }

  componentWillUnmount() {
    if (this.cancelablePromise) {
      this.cancelablePromise.cancel();
    }
    this._isMounted = false;
  }

  render() {
    const { radius, stroke } = this.props;
    const { progress } = this.state;

    const strokeDashoffset = this.circumference - progress * this.circumference;

    return (
      <svg
        height={radius * 2}
        width={radius * 2}
        className="ui-progress-ring"
      >
        <circle
          className="background-circle"
          fill="transparent"
          strokeWidth={stroke}
          r={this.normalizedRadius}
          cx={radius}
          cy={radius}
        />
        <circle
          className="primary-colored-arc"
          fill="transparent"
          strokeWidth={stroke}
          strokeDasharray={`${this.circumference} ${this.circumference}`}
          style={{ strokeDashoffset }}
          r={this.normalizedRadius}
          cx={radius}
          cy={radius}
        />
      </svg>
    );
  }
}
