import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import onClickOutside from 'react-onclickoutside';
import { Scrollbars } from 'react-custom-scrollbars';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Close from '../../assets/svg/close-black.svg';
import Button from './Button';
import Modal from './Modal';

import RingIcon from '../../assets/img/icons/ring.svg';
import CheckIcon from '../../assets/svg/notifications/check-icon.svg';
import DownloadIcon from '../../assets/svg/download.svg';

import { isMsgLvlWithModal, ReadAlertsAndModalsAndReports } from '../../utils/notifications';


class HeaderMessages extends React.PureComponent {
  static propTypes = {
    report: PropTypes.object,
    messages: PropTypes.arrayOf(PropTypes.object).isRequired,
    readMessage: PropTypes.func.isRequired,
  };

  static defaultProps = {
    report: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      opened: false,
      modalShown: false,
      modalMsg: null,
    };
  }

  componentDidMount() {}

  componentWillUnmount() {}

  onIconClick = () => {
    this.setState(prevState => ({ opened: !prevState.opened }));
  };

  onMessageClick = (msg) => {
    this.setState({ modalMsg: msg, modalShown: true });
    if (!msg.messageRead) {
      this.onReadClick(msg);
    }
  };

  onReadClick = (msg) => {
    const data = { messageRead: true };
    const sessionReadAlerts = ReadAlertsAndModalsAndReports.get('readAlerts');

    if (!isMsgLvlWithModal(msg.lvl) || sessionReadAlerts[msg.id]) {
      data.alertRead = true;
    }

    this.props.readMessage({ id: msg.id, data });
  };

  onReportClick = (url) => {
    ReadAlertsAndModalsAndReports.set('readReports', url);
    window.open(`${process.env.BACKEND_URL}/${url}`);
    this.forceUpdate();
  };

  onMessageModalClose = () => this.setState({ modalShown: false });

  handleClickOutside() {
    if (!this.state.modalShown) {
      this.setState({ opened: false });
    }
  }

  render() {
    const { opened, modalShown, modalMsg } = this.state;
    const { messages, report } = this.props;
    const unreadMessages = messages.filter(msg => !msg.messageRead);

    const className = classnames('user-menu-item ui-messages', { opened });
    const showReport = report && !ReadAlertsAndModalsAndReports.get('readReports')[report.url];

    return (
      <div className={className}>
        <div className="user-menu-icon" onClick={this.onIconClick}>
          <div className="icon-with-badge">
            <RingIcon className="ring-icon-svg" />
            {!!(unreadMessages.length || showReport) && <span className="count-badge">{unreadMessages.length + (showReport ? 1 : 0)}</span>}
          </div>
        </div>
        <CSSTransition
          classNames="ui-messages-dropdown"
          timeout={300}
          in={opened}
          mountOnEnter
          unmountOnExit
        >
          <div className="ui-messages-dropdown">
            <div className="messages-title">Уведомления</div>
            <Scrollbars
              autoHeight
              autoHeightMin={0}
              autoHeightMax={240}
              renderTrackVertical={props => <div {...props} className="ui-track-vertical" />}
              renderThumbVertical={props => <div {...props} className="ui-thumb-vertical" />}
            >
              <div className="messages-dropdown-inner">
                {(messages.length === 0 && !showReport) && <div className="no-messages">Нет новых уведомлений.</div>}
                <CSSTransition
                  classNames="report-item"
                  timeout={450}
                  in={showReport}
                  mountOnEnter
                  unmountOnExit
                >
                  <div className={classnames('report-item active')} onClick={() => this.onReportClick(report && report.url)}>
                    <div className="report-item-content">
                      <div className="report-icon-wrap">
                        <div className="report-icon">
                          <div className="white-circle"><DownloadIcon /></div>
                        </div>
                      </div>
                      <div className="report-text-wrap">
                        <div className="report-text" dangerouslySetInnerHTML={{ __html: report && report.name }} />
                      </div>
                    </div>
                  </div>
                </CSSTransition>
                {messages.length > 0 && (
                  <TransitionGroup className="message-items" component={null} enter={false}>
                    {messages.map(msg => (
                      <CSSTransition
                        key={msg.id}
                        timeout={450}
                        classNames="message-item"
                      >
                        <div className={classnames('message-item', { active: !msg.messageRead })} key={msg.id} onClick={() => this.onMessageClick(msg)}>
                          <div className="message-item-content">
                            <div className="message-icon-wrap">
                              <div className={classnames('message-icon', `message-icon-${msg.lvl.toLowerCase()}`)} />
                            </div>
                            <div className="message-text-wrap">
                              <div className="message-text" dangerouslySetInnerHTML={{ __html: msg.msg }} />
                              <div className="message-date">{moment(msg.createdT, 'x').calendar()}</div>
                            </div>
                            <div className="message-controls" onClick={(e) => { e.stopPropagation(); this.onReadClick(msg); }}>
                              <div className="check-icon-wrap">
                                <CheckIcon />
                              </div>
                            </div>
                          </div>
                        </div>
                      </CSSTransition>
                    ))}
                  </TransitionGroup>
                )}
              </div>
            </Scrollbars>
            {/*{messages.length > 0 && (*/}
              {/*<div className="messages-actions">*/}
            {/*<Button label="Отметить все как прочитанное" color={Button.Color.white} />*/}
              {/*</div>*/}
            {/*)}*/}
          </div>
        </CSSTransition>
        <Modal
          backdrop
          active={modalShown}
          contentClassName="ui-modals-machine-item"
          onClickOutside={this.onMessageModalClose}
          transitionEnterTimeout={300}
          transitionLeaveTimeout={300}
          transitionName="ui-modal"
        >
          <div className="modal-close-btn" onClick={this.onMessageModalClose}>
            <Close />
          </div>
          <div className="modal-header">Уведомление</div>
          <div className="modal-body">
            <div className="message-icon-wrap">
              <div className={classnames('message-icon', `message-icon-${modalMsg ? modalMsg.lvl.toLowerCase() : 'info'}`)} />
            </div>
            <div className="message-content-wrap">
              <div className="message-content" dangerouslySetInnerHTML={{ __html: modalMsg ? modalMsg.msg : '' }} />
              <div className="message-date">{modalMsg && moment(modalMsg.createdT, 'x').calendar()}</div>
            </div>
          </div>
          <div className="modal-footer">
            <Button label="Закрыть" color={Button.Color.blue} onClick={this.onMessageModalClose} />
          </div>
        </Modal>
      </div>
    );
  }
}

export default onClickOutside(HeaderMessages);
