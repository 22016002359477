import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

class ProductLogo extends React.PureComponent {
  static propTypes = {
    productConfig: PropTypes.object.isRequired,
  };

  static FallbackLogo = '/assets/svg/product-icons/default.svg';

  constructor(props) {
    super(props);

    const { productConfig } = this.props;
    this.defaultLogo = productConfig.cat ? '/assets/svg/product-icons/default.svg' : '/assets/svg/category-icons/others.svg';
    this.onceLogoLoaded = false;
    this.onImgLoadError = this.onImgLoadError.bind(this);
  }

  onImgLoadError(e) {
    const { productConfig } = this.props;
    if (productConfig.logo
      && !e.target.src.includes(productConfig.logo)
      && !this.onceLogoLoaded
      && (!productConfig.multiLogo || !productConfig.multiLogo.length)
    ) {
      e.target.src = `${productConfig.logo[0] === '/' || productConfig.logo.substr(0, 4).toLowerCase() === 'http' ? '' : '/'}${productConfig.logo}`;
      this.onceLogoLoaded = true;
      return;
    }

    if (!e.target.src.includes(this.defaultLogo)) {
      e.target.src = this.defaultLogo;
      return;
    }

    e.target.src = ProductLogo.FallbackLogo;
  }

  render() {
    const { productConfig: { cat, id, name, multiLogo } } = this.props;

    if (multiLogo) {
      return (
        <div className={classnames('product-multi-logo', { 'double-logo': multiLogo.length === 2, 'triple-logo': multiLogo.length === 3 })}>
          {multiLogo.map(logo => <img key={logo} src={logo} alt={name} onError={this.onImgLoadError} />)}
        </div>
      );
    }

    const productLogo = cat ? `/assets/svg/product-icons/${id}.svg` : `/assets/svg/category-icons/${id}.svg`;

    return (
      <img src={productLogo} alt={name} onError={this.onImgLoadError} />
    );
  }
}

export default ProductLogo;
